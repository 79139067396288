export default class ScreenUtils {
  static getViewportWidth() {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  }

  static getViewportHeight() {
    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  }

  static isNarrowScreen() {
    return ScreenUtils.getViewportWidth() < 500
  }
}
