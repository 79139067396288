import React, { useState } from 'react'

import { useAppFetch, useAbortSignal } from '../../AppFetch.js'
import SpinnerBlock from '../generic/SpinnerBlock.jsx'
import PageProfile from './PageProfile.jsx'
import API from '../../API.js'

const PageProfileContainer = () => {
  const signal = useAbortSignal()
  const [isUploadingUserImage, setIsUploadingUserImage] = useState(false)
  const { loading, responseJson: user, setResponseJson: setUser, error } = useAppFetch('/api/up/whoami')
  if (loading) {
    return <SpinnerBlock />
  }
  if (error) {
    // This error handling makes the JS errors a bit more sane for this testcase:
    // it('client redirects to login page after receiving unexpected http 401 as fetch response')
    return 'Unexpected error'
  }
  return (
    <PageProfile
      user={user}
      isUploadingUserImage={isUploadingUserImage}
      onUserImageChanged={async (newUserImage, newThumbnailUrl) => {
        if (newUserImage) {
          setUser({ ...user, imageUrl: newThumbnailUrl })
          setIsUploadingUserImage(true)
          await API.uploadUserImage(signal, newUserImage)
          setIsUploadingUserImage(false)
        } else {
          await API.unsetUserImage(signal)
          setUser({ ...user, imageUrl: undefined })
        }
      }}
    />
  )
}

export default PageProfileContainer
