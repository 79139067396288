import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField/index.js'
import { isValidEmail } from '../../../shared-universal/Utils.js'

const EditableEmail = ({ defaultValue, onChange, disabled, allowEmptyEmail, ...props }) => {
  const isActualValidEmail = (email) => (!email && allowEmptyEmail) || isValidEmail(email)

  const [emailIsValid, setEmailIsValid] = React.useState(isActualValidEmail(defaultValue))

  return (
    <TextField
      defaultValue={defaultValue}
      onChange={(ev) => {
        setEmailIsValid(isActualValidEmail(ev.target.value))
        onChange(ev.target.value)
      }}
      fullWidth={true}
      helperText={!emailIsValid ? 'Ogiltig e-mail' : ''}
      error={!emailIsValid}
      variant="outlined"
      size="small"
      inputProps={{ style: { background: disabled ? 'var(--body-bgcolor)' : '#ffffff' } }}
      FormHelperTextProps={{ style: { backgroundColor: 'transparent', margin: '0' } }}
      autoComplete="email"
      disabled={disabled}
      {...props}
    />
  )
}

EditableEmail.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  allowEmptyEmail: PropTypes.bool,
  disabled: PropTypes.bool,
}

EditableEmail.defaultProps = {
  allowEmptyEmail: false,
  disabled: false,
}

export default EditableEmail
