import React from 'react'
import PropTypes from 'prop-types'
import EditableAmount from './EditableAmount.jsx'
import { saveXbrlValue } from './EditableTableUtils.jsx'

const EditableXbrlValue = React.forwardRef(
  (
    {
      xbrlName,
      financialYear,
      abortSignal,
      onSaved,
      onChange,
      disabled,
      forceSign,
      allowNegative,
      forceAllowNegative,
      unit,
      className,
    },
    ref
  ) => {
    const unableToRenderInSignRestrictedMode = financialYear.xbrlComputed[xbrlName]?.value < 0 && forceSign
    let forceAllowNegativeInternal = forceAllowNegative
    if (unableToRenderInSignRestrictedMode) {
      forceAllowNegativeInternal = true
    }

    return (
      <EditableAmount
        className={className ?? `automation-${xbrlName}`}
        defaultValue={financialYear.xbrlValues[xbrlName]?.value}
        onChange={async (ev) => {
          onChange && onChange(ev)
          saveXbrlValue(financialYear.financialYearId, xbrlName, ev.target.value, abortSignal, onSaved)
        }}
        allowNegative={allowNegative}
        forceAllowNegative={forceAllowNegativeInternal}
        unit={unit}
        disabled={disabled}
        forceSign={forceSign}
        ref={ref}
        /* onClick={() => navigator.clipboard.writeText(`> ${xbrlName}=${financialYear.xbrlValues[xbrlName]?.value},\\`)} */
      />
    )
  }
)

EditableXbrlValue.displayName = 'EditableXbrlValue'

EditableXbrlValue.propTypes = {
  xbrlName: PropTypes.string.isRequired,
  financialYear: PropTypes.object.isRequired,
  abortSignal: PropTypes.object.isRequired,
  onSaved: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  unit: PropTypes.string,
  forceSign: PropTypes.string,
  allowNegative: PropTypes.bool,
  forceAllowNegative: PropTypes.bool,
  className: PropTypes.string,
}

EditableXbrlValue.defaultProps = {
  disabled: false,
  unit: 'kr',
  allowNegative: true,
  forceAllowNegative: false,
  onChange: () => {},
  className: undefined,
}

export default EditableXbrlValue
