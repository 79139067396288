import PropTypes from 'prop-types'
import React from 'react'

import styles from './VerticalList.module.css'

const VerticalList = ({ className = '', align, children, spaceBetweenPx, style, ...props }) => (
  <div className={`${styles.VerticalList} ${className}`} style={{ alignItems: align, ...style }} {...props}>
    {React.Children.map(
      children,
      (child, idx) =>
        child &&
        React.cloneElement(child, {
          ...child.props,
          style: {
            ...child.props.style,
            marginTop: idx ? spaceBetweenPx : 0,
          },
          key: idx,
        })
    )}
  </div>
)

VerticalList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  spaceBetweenPx: PropTypes.number,
  align: PropTypes.string,
  style: PropTypes.object,
}

VerticalList.defaultProps = {
  align: 'center',
  style: {},
}

export default VerticalList
