import PropTypes from 'prop-types'
import React from 'react'

import styles from './TextEditBox.module.css'

const TextEditBox = ({ value, onChange, onEnterKey, className = '', ...otherProps }) => {
  return (
    <textarea
      value={value || ''}
      className={`${styles.TextEditBox} ${className}`}
      onChange={(ev) => onChange && onChange(ev.target.value)}
      onKeyPress={(ev) => {
        if (ev.key === 'Enter' && onEnterKey) {
          onEnterKey()
        }
      }}
      {...otherProps}
    />
  )
}

TextEditBox.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onEnterKey: PropTypes.func,
  className: PropTypes.string,
}

TextEditBox.defaultProps = {
  value: '',
}

export default TextEditBox
