import React from 'react'
import PropTypes from 'prop-types'
import { CardElement } from '@stripe/react-stripe-js'
import { withRouter } from 'react-router'

import { withUserConfig, UserConfigKeys } from '../../ClientSideUserConfig.jsx'
import SpinnerBlock from '../generic/SpinnerBlock.jsx'
import Row from '../Row.jsx'
import Field from '../Field.jsx'
import { appFetch } from '../../AppFetch.js'
import HttpErrorCodes from '../../../shared-universal/HttpErrorCodes.js'
import { getUrlParam } from '../../UrlUtils.js'
import StyledEditBox from '../StyledEditBox.jsx'
import FillButton from '../FillButton.jsx'
import FlashMessage from '../FlashMessage.jsx'
import CardTopLevel from '../CardTopLevel.jsx'
import StyledDropdownList from '../styled/StyledDropdownList.jsx'
import { log } from '../../LoggingUtils.js'
import { StripeTaxIdTypes } from '../../../shared-universal/ConstantsShared.js'
import BaseButton from '../BaseButton.jsx'
import ReadOnlyBox from '../ReadOnlyBox.jsx'

import styles from './PageCheckout.module.css'

const VALIDATION_FAILED_MESSAGE = 'Required information missing, please review issues above'

class PageCheckout extends React.Component {
  static propTypes = {
    stripe: PropTypes.object.isRequired,
    elements: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    userConfig: PropTypes.object.isRequired,
  }

  initialState = {
    preparationDone: false,

    hasSubmitted: false,
    plan: undefined,
    setupIntentClientSecret: undefined,
    allCountries: undefined,

    // card state managed by stripe element
    cardError: '',

    name: '',
    nameError: '',
    address: '',
    addressError: '',
    postal: '',
    postalError: '',
    city: '',
    cityError: '',
    country: '',
    countryError: '',
    state: '',
    stateError: '',
    email: '',
    emailError: '',

    businessTaxIdType: StripeTaxIdTypes.NO_TAX_ID_NUMBER,
    businessTaxIdLabel: '',
    businessTaxId: '',
    businessTaxIdError: '',

    generalError: '',

    priceExclTax: undefined,
    taxRate: undefined,
    taxType: 'none',
  }
  state = this.initialState
  controller = new AbortController()

  async prepareFormForInput() {
    const signal = this.controller.signal
    const { responseJson } = await appFetch('/api/up/checkout-prepare', { signal })
    const { setupIntentClientSecret, savedCard, allCountries, name, address, postal, city, country, state, email } =
      responseJson

    this.setState({
      setupIntentClientSecret,
      savedCard,
      allCountries,
      name,
      address,
      postal,
      city,
      country,
      state,
      email,
    })

    await this.setCountry(country)
  }

  async loadPlan(planId) {
    const signal = this.controller.signal
    const { responseJson: plans } = await appFetch('/api/list-plans', { signal })
    const plan = plans.find((p) => p.id == planId)
    if (!plan) {
      this.props.history.push('/select-plan')
      return
    }
    // After await loadPlan() it should be possible to use this.state.plan
    return new Promise((resolve) => this.setState({ planLoaded: true, plan }, resolve))
  }

  async componentDidMount() {
    if (this.props.userConfig.getConfigKey(UserConfigKeys.STRIPE_ENABLED)) {
      const planId = getUrlParam('plan', location.search)
      await this.loadPlan(planId)
    }

    await this.prepareFormForInput()
    this.setState({ preparationDone: true })

    if (this.state.plan.amount === 0 && getUrlParam('autosubmit')) {
      this.setState({ autoSubmitting: true })
      const success = await this.submitCheckoutForm()
      if (!success) {
        this.setState({ autoSubmitting: false })
      }
      return
    }
  }

  componentWillUnmount() {
    this.controller.abort()
  }

  paymentDetailsIsValid = () => {
    let result = true
    if (!this.state.name) {
      this.setState({ nameError: 'Name field must not be empty' })
      result = false
    }
    if (!this.state.country || this.state.country === 'none') {
      this.setState({ countryError: 'Country field must not be empty' })
      result = false
    }
    if (!this.state.email || !this.state.email.includes('@')) {
      this.setState({ emailError: 'Must specify a valid e-mail' })
      result = false
    }
    return result
  }

  submitCheckoutForm = async () => {
    this.setState({
      hasSubmitted: true,
      generalError: undefined,

      cardError: undefined,
      nameError: undefined,
      addressError: undefined,
      postalError: undefined,
      cityError: undefined,
      countryError: undefined,
      stateError: undefined,
      emailError: undefined,
      businessTaxIdError: undefined,
    })

    let cardSetupIntent
    if (this.state.plan.amount > 0 && !this.state.savedCard) {
      if (!this.paymentDetailsIsValid()) {
        this.setState({ hasSubmitted: false, generalError: VALIDATION_FAILED_MESSAGE })
        return false
      }

      const cardSetupResponse = await this.props.stripe.confirmCardSetup(this.state.setupIntentClientSecret, {
        payment_method: {
          card: this.props.elements.getElement(CardElement),
          billing_details: {
            name: this.state.name,
            email: this.state.email,
            address: {
              line1: this.state.address,
              line2: '',
              postal_code: this.state.postal,
              city: this.state.city,
              state: this.state.state,
              country: this.state.country,
            },
          },
        },
      })
      cardSetupIntent = cardSetupResponse.setupIntent
      if (cardSetupResponse.error) {
        const error = cardSetupResponse.error
        log.info(
          `Client side validation failure in setupIntentClientSecret: ${error.type}, full error: ${JSON.stringify(
            error
          )}`
        )
        if (error.code === 'email_invalid') {
          this.setState({ hasSubmitted: false, emailError: error.message, generalError: VALIDATION_FAILED_MESSAGE })
        } else {
          this.setState({ hasSubmitted: false, cardError: error.message, generalError: VALIDATION_FAILED_MESSAGE })
        }
        return false
      }
    }

    let paymentMethodId
    if (this.state.savedCard) {
      paymentMethodId = this.state.savedCard.paymentMethodId
    } else if (cardSetupIntent) {
      paymentMethodId = cardSetupIntent.payment_method
    } else {
      // Used for plan where this.state.plan.amount === 0
      paymentMethodId = undefined
    }

    const { status, responseText, responseJson } = await appFetch('/api/up/checkout', {
      signal: this.controller.signal,
      method: 'POST',
      body: JSON.stringify({
        paymentMethodId,
        name: this.state.name,
        address: this.state.address,
        postal: this.state.postal,
        city: this.state.city,
        country: this.state.country,
        state: this.state.state,
        email: this.state.email,
        businessTaxIdType: this.state.businessTaxIdType,
        businessTaxId: this.state.businessTaxId,
        planId: this.state.plan && this.state.plan.id,
      }),
    })
    if (responseJson && responseJson.errorCode === HttpErrorCodes.UI_ERROR_MESSAGE) {
      await this.prepareFormForInput()
      this.setState({ generalError: responseJson.errorMessage, hasSubmitted: false })
      return false
    } else if (responseJson && responseJson.errorCode === HttpErrorCodes.STRIPE_CARD_ERROR) {
      await this.prepareFormForInput()
      this.setState({ cardError: responseJson.errorMessage, hasSubmitted: false })
      return false
    } else if (status != 200) {
      // Even though this is an unexpected error, it's best to fetch another
      // setup intent so we avoid "setup_intent_unexpected_state" from the
      // Stripe /v1/setup_intents/:SI_ID/confirm endpoint.
      await this.prepareFormForInput()
      this.setState({
        flashMessage: responseText.replace(/^\w/, (c) => c.toUpperCase()),
        hasSubmitted: false,
      })
      return false
    }

    await this.props.userConfig.refreshFromServer()
    window.location.href = '/checkout-finished'

    return true
  }

  handleClickCancel = (ev) => {
    ev.preventDefault()
    this.props.history.push('/')
  }

  setCountry = async (countryAlpha2) => {
    this.setState({ country: countryAlpha2, countryError: '' })
    if (!countryAlpha2 || countryAlpha2 === 'none') {
      this.setState({ priceExclTax: undefined })
      return
    }
    const signal = this.controller.signal
    const { responseJson: taxDetails } = await appFetch('/api/up/calculate-vat', {
      signal,
      method: 'POST',
      body: JSON.stringify({
        country: countryAlpha2,
        state: this.state.state,
        priceInclTax: Number(this.state.plan.amount),
        businessTaxId: this.state.businessTaxId,
      }),
    })
    // After await setCountry() it should be possible to use this.state.taxType etc
    return new Promise((resolve) =>
      this.setState(
        {
          priceExclTax: taxDetails.priceExclTax,
          taxRate: taxDetails.rate,
          taxType: taxDetails.type,
          businessTaxIdType: taxDetails.taxIdType,
          businessTaxIdLabel: taxDetails.taxIdLabel,
        },
        resolve
      )
    )
  }

  render() {
    if (!this.state.preparationDone || this.state.autoSubmitting) {
      return <SpinnerBlock />
    }
    return (
      <form>
        <br />
        <br />
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
          {this.state.plan.amount > 0 && (
            <React.Fragment>
              <div className={styles.SectionHeader}>Payment Details</div>
              <CardTopLevel>
                <Field label="Card" flashMessage={this.state.cardError} flashMessageClassName={'automation-card-error'}>
                  {this.state.savedCard && (
                    <div className="automation-saved-card" style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <ReadOnlyBox className={styles.SavedCardBox}>
                        <b>
                          <span className="automation-saved-card-brand">
                            {this.state.savedCard.brand.toUpperCase()}
                          </span>
                        </b>
                        &nbsp;************
                        <span className="automation-saved-card-last4">{this.state.savedCard.last4}</span>&nbsp; (expires{' '}
                        <span className="automation-saved-card-expiry">{this.state.savedCard.expires}</span>)
                      </ReadOnlyBox>
                      <BaseButton kind="cancel" onClick={() => this.setState({ savedCard: undefined })}>
                        Change
                      </BaseButton>
                    </div>
                  )}
                  {!this.state.savedCard && (
                    <div className={`${styles.CardElementWrapper} automation-card-element-wrapper`}>
                      <CardElement options={{ hidePostalCode: true, style: { base: { fontSize: '18px' } } }} />
                    </div>
                  )}
                </Field>
                <Field label="Name" flashMessage={this.state.nameError} flashMessageClassName="automation-name-error">
                  <StyledEditBox
                    className={'automation-name'}
                    value={this.state.name}
                    onChange={(name) => this.setState({ name, nameError: '' })}
                    disabled={this.state.hasSubmitted}
                  />
                </Field>
                <Field
                  label="Address"
                  flashMessage={this.state.addressError}
                  flashMessageClassName="automation-address-error"
                >
                  <StyledEditBox
                    className={'automation-address'}
                    value={this.state.address}
                    onChange={(address) => this.setState({ address, addressError: '' })}
                    disabled={this.state.hasSubmitted}
                  />
                </Field>
                <Field
                  label="Postal Code"
                  flashMessage={this.state.postalError}
                  flashMessageClassName="automation-postal-error"
                >
                  <StyledEditBox
                    className={'automation-postal'}
                    value={this.state.postal}
                    onChange={(postal) => this.setState({ postal, postalError: '' })}
                    disabled={this.state.hasSubmitted}
                  />
                </Field>
                <Field label="City" flashMessage={this.state.cityError} flashMessageClassName="automation-city-error">
                  <StyledEditBox
                    className={'automation-city'}
                    value={this.state.city}
                    onChange={(city) => this.setState({ city, cityError: '' })}
                    disabled={this.state.hasSubmitted}
                  />
                </Field>
                <Field
                  label="Country"
                  flashMessage={this.state.countryError}
                  flashMessageClassName="automation-country-error"
                >
                  <StyledDropdownList
                    className={'automation-country'}
                    items={[{ id: 'none', title: 'Select a country' }].concat(
                      this.state.allCountries.map((c) => ({ id: c.alpha2, title: c.name }))
                    )}
                    value={this.state.country}
                    onChange={(country) => this.setCountry(country.id)}
                    disabled={this.state.hasSubmitted}
                  />
                </Field>
                <Field
                  label="State"
                  flashMessage={this.state.stateError}
                  flashMessageClassName="automation-state-error"
                >
                  <StyledEditBox
                    className={'automation-state'}
                    value={this.state.state}
                    onChange={(state) => this.setState({ state, stateError: '' })}
                    disabled={this.state.hasSubmitted}
                  />
                </Field>
                <Field
                  label="Email"
                  flashMessage={this.state.emailError}
                  flashMessageClassName="automation-email-error"
                >
                  <StyledEditBox
                    className={'automation-email'}
                    value={this.state.email}
                    onChange={(email) => this.setState({ email, emailError: '' })}
                    disabled={this.state.hasSubmitted}
                  />
                </Field>
                {this.state.businessTaxIdType !== StripeTaxIdTypes.NO_TAX_ID_NUMBER && (
                  <Field
                    label={this.state.businessTaxIdLabel}
                    labelSuffix="(for businesses only)"
                    flashMessage={this.state.businessTaxIdError}
                    flashMessageClassName="automation-business-tax-id-error"
                  >
                    <StyledEditBox
                      className={'automation-business-tax-id'}
                      value={this.state.businessTaxId}
                      onChange={(businessTaxId) => this.setState({ businessTaxId, businessTaxIdError: '' })}
                      disabled={this.state.hasSubmitted}
                    />
                  </Field>
                )}
              </CardTopLevel>
            </React.Fragment>
          )}
          <br />
          <CardTopLevel>
            {this.state.plan && (
              <div>
                <Field label="Selected Plan">
                  {this.state.plan.nickname} (${this.state.plan.amount / 100} per {this.state.plan.interval})
                </Field>
                {(this.state.plan.amount === 0 || this.state.priceExclTax > 0) && (
                  <Field label="Price Summary" style={{ marginTop: '30px' }}>
                    <table style={{ width: '100%' }}>
                      <tbody>
                        {this.state.taxType != 'none' && (
                          <React.Fragment>
                            <tr>
                              <td>Subscription for plan: {this.state.plan.nickname}</td>
                              <td>${(this.state.priceExclTax / 100).toFixed(2)}</td>
                            </tr>
                            <tr>
                              <td>
                                Tax ({(100 * this.state.taxRate).toFixed(0)}% {this.state.taxType.toUpperCase()})
                              </td>
                              <td>${((this.state.plan.amount - this.state.priceExclTax) / 100).toFixed(2)}</td>
                            </tr>
                          </React.Fragment>
                        )}

                        {this.state.taxType == 'none' && (
                          <React.Fragment>
                            <tr>
                              <td>Subscription for plan: {this.state.plan.nickname}</td>
                              <td>${(this.state.plan.amount / 100).toFixed(2)}</td>
                            </tr>
                            <tr>
                              <td>Tax</td>
                              <td>$0.00</td>
                            </tr>
                          </React.Fragment>
                        )}
                        <tr>
                          <td>
                            Total
                            {this.state.taxType != 'none' ? ` (including ${this.state.taxType.toUpperCase()})` : ''}
                          </td>
                          <td>${(this.state.plan.amount / 100).toFixed(2)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Field>
                )}
              </div>
            )}
          </CardTopLevel>
          <br />
          <CardTopLevel>
            <FlashMessage message={this.state.generalError} className="automation-general-error" />
            <Row>
              <FillButton
                kind="primary"
                className={'automation-pay-button'}
                disabled={this.state.hasSubmitted}
                spinner={this.state.hasSubmitted}
                spinnerClassName="automation-pay-spinner"
                onClick={this.submitCheckoutForm}
              >
                {this.state.priceExclTax > 0 ? 'Pay' : 'Confirm'}
              </FillButton>
            </Row>
            <Row>
              <FillButton kind="cancel" onClick={this.handleClickCancel}>
                Cancel
              </FillButton>
            </Row>
          </CardTopLevel>
          <br />
          <br />
          <br />
        </div>
      </form>
    )
  }
}

export default withRouter(withUserConfig(PageCheckout))
