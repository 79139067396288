import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button/index.js'
import ButtonGroup from '@mui/material/ButtonGroup/index.js'

const EditableInteger = ({ value, onChange, disabled }) => {
  return (
    <ButtonGroup size="small" aria-label="small outlined button group">
      <Button disabled={value <= 1 || disabled} onClick={() => onChange(value - 1)}>
        -
      </Button>
      <Button disabled>{value}</Button>
      <Button disabled={disabled} onClick={() => onChange(value + 1)}>
        +
      </Button>
    </ButtonGroup>
  )
}

EditableInteger.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default EditableInteger
