import PropTypes from 'prop-types'
import React from 'react'

import styles from './Row.module.css'

const Row = ({ label, labelSuffix, sublabel, children, ...other }) => (
  <div className={styles.RowWrapper} {...other}>
    <label className={styles.Label}>
      {label || ''}
      <span className={styles.LabelSuffix}> {labelSuffix || ''}</span>
      <div className={styles.SubLabel}> {sublabel || ''}</div>
    </label>
    <div className={styles.RightHandSide}>{children}</div>
  </div>
)

Row.propTypes = {
  label: PropTypes.string,
  labelSuffix: PropTypes.string,
  sublabel: PropTypes.string,
  children: PropTypes.node,
}

export default Row
