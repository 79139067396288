import React from 'react'
import RmTextField from './RmTextField.jsx'

const RmTextArea = React.forwardRef(({ ...props }, ref) => {
  return <RmTextField multiline minRows="2" maxRows="10" fullWidth {...props} ref={ref} />
})

RmTextArea.displayName = 'RmTextArea'

RmTextArea.propTypes = {}
RmTextArea.defaultProps = {}

export default RmTextArea
