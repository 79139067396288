import React from 'react'
import PropTypes from 'prop-types'
import EditableAmountImpl from './EditableAmountImpl.jsx'

const EditableAmount = React.forwardRef(({ forceSign, allowNegative, forceAllowNegative, ...props }, ref) => {
  if (forceSign && allowNegative === false) {
    throw Error('forceSign and allowNegative are incompatible')
  }
  return (
    <EditableAmountImpl
      {...props}
      allowNegative={(!forceSign && allowNegative) || forceAllowNegative}
      startAdornment={forceSign === '-' ? '−' : forceSign}
      ref={ref}
    />
  )
})

EditableAmount.displayName = 'EditableAmount'

EditableAmount.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  unit: PropTypes.string,
  allowNegative: PropTypes.bool,
  forceAllowNegative: PropTypes.bool,
  startAdornment: PropTypes.string,
  scale: PropTypes.number,
  forceSign: PropTypes.string,
  allowDecimalInput: PropTypes.bool,
}

EditableAmount.defaultProps = {
  unit: '',
  allowNegative: true,
  forceAllowNegative: false,
  startAdornment: '',
  scale: 0,
  allowDecimalInput: false,
}

export default EditableAmount
