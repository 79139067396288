import React from 'react'
import PropTypes from 'prop-types'

import styles from './IconCheckmark.module.css'
import iconCheckmarkSvg from './IconCheckmark.svg'

const IconCheckmark = ({ className, ...props }) => (
  <img src={iconCheckmarkSvg} className={`${className} ${styles.IconCheckmark}`} {...props} />
)

IconCheckmark.propTypes = {
  className: PropTypes.string,
}

export default IconCheckmark
