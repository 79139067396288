import React from 'react'
import PropTypes from 'prop-types'
import InputAdornment from '@mui/material/InputAdornment/index.js'
import { NumericFormat } from 'react-number-format'
import RmTextField from './RmTextField.jsx'

const NumberFormatInput = React.forwardRef((props, ref) => {
  const { onChange, inputRef, decimalScale, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values, ev) => {
        if (ev.event) {
          onChange({
            target: {
              value: values.value,
            },
          })
        }
      }}
      thousandSeparator={' '}
      valueIsNumericString
      decimalScale={decimalScale}
      style={{ textAlign: 'right' }}
    />
  )
})

NumberFormatInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputRef: PropTypes.func,
  decimalScale: PropTypes.number,
}

NumberFormatInput.displayName = 'NumberFormatInput'

const removeFloatingPointEpsilonErrors = (val) => {
  return Number(val.toFixed(4))
}

const convertValueToEditboxString = (value, scale) => {
  let editboxValue
  if (typeof value === 'number') {
    editboxValue = String(removeFloatingPointEpsilonErrors(value / Math.pow(10, scale)))
  } else {
    editboxValue = value
  }

  return editboxValue
}

const EditableAmountImpl = React.forwardRef(
  ({ value, defaultValue, unit, allowNegative, startAdornment, scale, onChange, allowDecimalInput, ...other }, ref) => {
    return (
      <RmTextField
        inputProps={{
          style: { textAlign: 'right' },
          ...(allowNegative ? {} : { allowNegative: false }),
          scale,
          value: convertValueToEditboxString(value, scale),
          defaultValue: typeof defaultValue === 'number' ? String(defaultValue / Math.pow(10, scale)) : defaultValue,
          decimalScale: allowDecimalInput ? 2 : 0,
        }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputComponent: NumberFormatInput,
          startAdornment: startAdornment ?? (
            <InputAdornment style={{ marginRight: '10px', width: '10px' }} position="start">
              <span style={{ color: 'black' }}>{startAdornment}</span>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment style={{ marginLeft: '10px', width: '10px' }} position="end">
              {unit}
            </InputAdornment>
          ),
        }}
        onChange={(ev) => {
          if (ev.target.value) {
            ev.target.value = Number(ev.target.value) * Math.pow(10, scale)
          }
          onChange && onChange(ev)
        }}
        {...other}
        style={{ width: '162px' }}
        ref={ref}
      />
    )
  }
)

EditableAmountImpl.displayName = 'EditableAmountImpl'

EditableAmountImpl.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  unit: PropTypes.string,
  allowNegative: PropTypes.bool,
  startAdornment: PropTypes.string,
  scale: PropTypes.number,
  allowDecimalInput: PropTypes.bool,
}

EditableAmountImpl.defaultProps = {
  unit: '',
  allowNegative: true,
  startAdornment: '',
  scale: 0,
  allowDecimalInput: false,
}

export default EditableAmountImpl
