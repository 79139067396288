const getVisibleDepreciations = (annualReport) => {
  const allDepreciations = [
    {
      depreciationId: 'depByggnaderMarkAr',
      balanceXbrl: 'ByggnaderMark',
      title: 'Byggnader',
      tupleId: 'avskr-princip-byggn-id1',
      nyttjandeperiodXbrlName: 'AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
      tupleHeaderPart: `
        <ix:tuple name="se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarByggnaderTuple" tupleID="avskr-princip-byggn-id1"/>
        <ix:nonNumeric name="se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning" contextRef="period0" order="1.0" tupleRef="avskr-princip-byggn-id1">Tillämpade avskrivningstider: Byggnader</ix:nonNumeric>
      `,
    },
    {
      depreciationId: 'depMaskinerAndraTekniskaAnlaggningarAr',
      balanceXbrl: 'MaskinerAndraTekniskaAnlaggningar',
      title: 'Maskiner och andra tekniska anläggningar',
      tupleId: 'avskr-princip-mask-id1',
      nyttjandeperiodXbrlName: 'AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
      tupleHeaderPart: `
        <ix:tuple name="se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarMaskinerAndraTekniskaAnlaggningarTuple" tupleID="avskr-princip-mask-id1"/>
        <ix:nonNumeric name="se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning" contextRef="period0" order="1.0" tupleRef="avskr-princip-mask-id1">Tillämpade avskrivningstider: Maskiner och andra tekniska anläggningar</ix:nonNumeric>
      `,
    },
    {
      depreciationId: 'depInventarierVerktygInstallationerAr',
      balanceXbrl: 'InventarierVerktygInstallationer',
      title: 'Inventarier, verktyg och installationer',
      tupleId: 'avskr-princip-inv-id1',
      nyttjandeperiodXbrlName: 'AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
      tupleHeaderPart: `
        <ix:tuple name="se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarInventarierVerktygInstallationerTuple" tupleID="avskr-princip-inv-id1"/>
        <ix:nonNumeric name="se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning" contextRef="period0" order="1.0" tupleRef="avskr-princip-inv-id1">Tillämpade avskrivningstider: Inventarier, verktyg och installationer</ix:nonNumeric>
      `,
    },
    {
      depreciationId: 'depOvrigaMateriellaAnlaggningstillgangarAr',
      balanceXbrl: 'OvrigaMateriellaAnlaggningstillgangar',
      title: 'Övriga materiella anläggningstillgångar',
      tupleId: 'avskr-princip-ovr-id1',
      nyttjandeperiodXbrlName: 'AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
      tupleHeaderPart: `
        <ix:tuple name="se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarOvrigaMateriellaAnlaggningstillgangarTuple" tupleID="avskr-princip-ovr-id1"/>
        <ix:nonNumeric name="se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning" contextRef="period0" order="1.0" tupleRef="avskr-princip-ovr-id1">Tillämpade avskrivningstider: Övriga materiella anläggningstillgångar</ix:nonNumeric>
      `,
    },
  ]
  const visibleDepreciations = allDepreciations.filter(
    (depreciation) =>
      annualReport.financialYears[0].xbrlValues[depreciation.balanceXbrl]?.value ||
      annualReport.infogadeNoter[`Not${depreciation.balanceXbrl}`]
  )
  return visibleDepreciations
}

const periodiseringsfondYears = (currentFinancialYearLastDay) => {
  const year0 = Number(currentFinancialYearLastDay.slice(0, 4))
  return Array(10)
    .fill()
    .map((_, idx) => idx)
    .map((pfondYearIdx) => String(year0 - pfondYearIdx))
}

const getDomicileString = (allDomiciles, municipalityCode) => {
  const maybeDomicile = allDomiciles.find((dom) => dom.code === municipalityCode)
  const domicileString = maybeDomicile ? `${maybeDomicile.municipalityName}, ${maybeDomicile.countyName}` : ''
  return domicileString
}

const findTableNode = (nodeFilter, summaryTable) => {
  const findTableNodeImpl = (currentNode) => {
    if (currentNode.xbrlName === nodeFilter.xbrlName) {
      return currentNode
    }
    if (currentNode.items) {
      for (let item of currentNode.items) {
        const result = findTableNodeImpl(item)
        if (result) {
          return result
        }
      }
    }
    return undefined
  }
  return findTableNodeImpl(summaryTable)
}

const DEFAULT_STYRELSENS_YTTRANDE_OM_VINSTDISPOSITION =
  'Styrelsen anser att förslaget är förenligt med försiktighetsregeln i 17 kap. 3 § aktiebolagslagen enligt följande redogörelse: Styrelsens uppfattning är att vinstutdelningen är försvarlig med hänsyn till de krav som verksamhetens art, omfattning och risk ställer på storleken på det egna kapitalet, bolagets konsolideringsbehov, likviditet och ställning i övrigt. Företagets ställning i övrigt framgår av efterföljande resultat- och balansräkning med noter.'

export {
  getVisibleDepreciations,
  periodiseringsfondYears,
  getDomicileString,
  findTableNode,
  DEFAULT_STYRELSENS_YTTRANDE_OM_VINSTDISPOSITION,
}
