import React from 'react'
import PropTypes from 'prop-types'
import Container from '@mui/material/Container/index.js'
import Box from '@mui/material/Box/index.js'

import RemakerHeader from '../remaker/RemakerHeader.jsx'
import NyArsredovisning from '../remaker/NyArsredovisning.jsx'

const Graybox = ({ children }) => (
  <div
    style={{
      background: '#e0e0e0',
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingLeft: '15px',
      paddingRight: '15px',
      borderRadius: '3px',
    }}
  >
    {children}
  </div>
)

Graybox.propTypes = { children: PropTypes.node }

const PageStartAnonymous = () => {
  return (
    <div className="automation-startpage-anonymous">
      <RemakerHeader />
      <Container maxWidth="sm">
        <Box mt={10} mb={5} align="center">
          <div style={{ fontSize: '30px' }}>Gör din årsredovisning direkt!</div>
        </Box>

        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1, padding: '30px' }}>
            <Graybox>
              Vår tjänst hjälper dig beräkna och bokföra årets resultat och skatt om du inte redan har gjort detta.
            </Graybox>
            <br />
            <NyArsredovisning />
            <div style={{ marginTop: '16px' }}>
              Genom att använda vår tjänst godkänner du våra <a href="/anvandarvillkor">användarvillkor</a>.
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

PageStartAnonymous.propTypes = {}

export default PageStartAnonymous
