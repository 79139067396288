import React from 'react'
import PropTypes from 'prop-types'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'

import API from '../../API.js'
import { useAbortSignal } from '../../AppFetch.js'
import { useBroadcastChannel } from '../../BroadcastChannel.js'
import RmDialog from '../remaker/RmDialog.jsx'

const ReportActionMenu = ({ reportId, reportTitle, onChange }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const closeMenu = () => setAnchorEl(null)
  const abortSignal = useAbortSignal()
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const notifyReportDeleted = useBroadcastChannel('report-deleted')
  return (
    <React.Fragment>
      <IconButton
        variant="outlined"
        onClick={(ev) => {
          setAnchorEl(ev.currentTarget)
        }}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        onClick={closeMenu}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={async () => {
            closeMenu()
            setDialogOpen(true)
          }}
        >
          <ListItemIcon>
            <DeleteForeverOutlinedIcon />
          </ListItemIcon>
          Radera
        </MenuItem>
      </Menu>
      <RmDialog
        title="Radera årsredovisning"
        text={`Vill du radera årsredovisningen för ${reportTitle}?`}
        confirmVerb="Radera"
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false)
        }}
        onConfirm={async () => {
          setDialogOpen(false)
          await API.deleteReport(reportId, abortSignal)
          notifyReportDeleted(reportId)
          onChange && onChange()
        }}
      />
    </React.Fragment>
  )
}

ReportActionMenu.propTypes = {
  reportId: PropTypes.string.isRequired,
  reportTitle: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

ReportActionMenu.defaultProps = {}

export default ReportActionMenu
