import React from 'react'
import PropTypes from 'prop-types'

import styles from './IconBadge.module.css'

const IconBadge = ({ icon, className, children, ...props }) => (
  <div className={`${className} ${styles.IconBadge}`} {...props}>
    <div>{icon}</div>
    <div style={{ marginLeft: '20px' }}>{children}</div>
  </div>
)

IconBadge.propTypes = {
  icon: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
}

export default IconBadge
