import React from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import UAParser from 'ua-parser-js'

import AppTitle from '../AppTitle.jsx'
import TextBlock from '../TextBlock.jsx'
import Field from '../Field.jsx'
import StyledEditBox from '../StyledEditBox.jsx'
import VerticalList from '../VerticalList.jsx'
import PaddingButton from '../PaddingButton.jsx'
import FlashMessage from '../FlashMessage.jsx'
import { appFetch } from '../../AppFetch.js'
import CardTopLevel from '../CardTopLevel.jsx'
import IconBadge from '../generic/IconBadge.jsx'
import IconCheckmark from '../generic/IconCheckmark.jsx'

const ComponentModes = {
  WAITING_FOR_INPUT: Symbol(),
  REQUEST_INITIATED: Symbol(),
  REQUEST_COMPLETED: Symbol(),
}

class PageRequestPasswordReset extends React.Component {
  static propTypes = {
    history: PropTypes.object,
  }

  initialState = {
    mode: ComponentModes.WAITING_FOR_INPUT,
    email: '',
    flashMessage: '',
  }
  state = this.initialState
  controller = new AbortController()

  async componentDidMount() {
    this.elementEmail.focus()
  }

  componentWillUnmount() {
    if (this.controller) {
      this.controller.abort()
    }
  }

  resetPassword = async (ev) => {
    ev.preventDefault()
    if (!this.state.email.includes('@')) {
      this.setState({
        flashMessage: 'Please enter a valid e-mail.',
        email: '',
      })
      this.elementEmail.focus()
      return
    }
    this.setState({
      mode: ComponentModes.REQUEST_INITIATED,
    })
    const parsedUserAgent = new UAParser().getResult()
    const { status } = await appFetch('/api/request-password-reset', {
      method: 'POST',
      body: JSON.stringify({
        email: this.state.email,
        browser: `${parsedUserAgent.browser.name || ''} ${parsedUserAgent.browser.major || ''}`,
        // parsedUserAgent.os.version || '' is better than just parsedUserAgent.os.version because
        // we don't want it to say "Ubuntu undefined" in browsers that don't reveal enough information.
        // "Ubuntu undefined" happens in Firefox on Linux because navigator.oscpu is undefined.
        operatingSystem: `${parsedUserAgent.os.name || ''} ${parsedUserAgent.os.version || ''}`,
      }),
      signal: this.controller.signal,
    })
    if (status === 200) {
      this.setState({
        mode: ComponentModes.REQUEST_COMPLETED,
      })
    } else {
      this.setState({
        flashMessage: 'ERROR: The server could not process the password reset request.',
      })
    }
  }

  render() {
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <AppTitle />
          <CardTopLevel>
            {this.state.mode === ComponentModes.REQUEST_COMPLETED && (
              <IconBadge icon={<IconCheckmark />} className="automation-password-reset-requested">
                Password reset instructions has been sent to <b>{this.state.email}</b>, please check your e-mail.
              </IconBadge>
            )}
            {this.state.mode !== ComponentModes.REQUEST_COMPLETED && (
              <React.Fragment>
                <TextBlock>Enter your e-mail to reset your password.</TextBlock>
                <FlashMessage message={this.state.flashMessage} className={'automation-flash-message'} />
                <Field label="E-mail">
                  <StyledEditBox
                    ref={(element) => (this.elementEmail = element)}
                    value={this.state.email}
                    onChange={(email) => this.setState({ email, flashMessage: '' })}
                    onEnterKey={this.resetPassword}
                    className={'automation-email'}
                    disabled={this.state.mode === ComponentModes.REQUEST_INITIATED}
                  />
                </Field>
                <VerticalList spaceBetweenPx={10} align="flex-end" style={{ marginTop: '20px' }}>
                  <PaddingButton
                    kind="primary"
                    className={'automation-reset-password'}
                    onClick={this.resetPassword}
                    disabled={this.state.mode === ComponentModes.REQUEST_INITIATED}
                    spinner={this.state.mode === ComponentModes.REQUEST_INITIATED}
                    style={{ width: '200px' }}
                  >
                    Reset Password
                  </PaddingButton>
                  <PaddingButton
                    kind="cancel"
                    className={'automation-cancel'}
                    onClick={() => this.props.history.push('/')}
                    style={{ width: '200px' }}
                  >
                    Cancel
                  </PaddingButton>
                </VerticalList>
              </React.Fragment>
            )}
          </CardTopLevel>
        </div>
      </div>
    )
  }
}

export default withRouter(PageRequestPasswordReset)
