import { Previewer } from 'pjsrm'

import { getUrlParam } from '../../UrlUtils.js'
import { populateTableOfContentsAndNotesReferences, tableOfContentsCss } from '../../../shared-universal/TocUtils.js'
import { createAnnualReportPreviewHtml } from '../../../shared-universal/AnnualReportRender.js'
import { extraCss } from '../../../shared-universal/AnnualReportUtils.js'
import { stringToBoolean } from '../../../shared-universal/Utils.js'

const renderAnnualReportPreview = async (annualReport, targetElement, userConfig) => {
  let previewInputHtml = createAnnualReportPreviewHtml(
    annualReport,
    annualReport,
    {
      decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
      skipSignatureLines: stringToBoolean(getUrlParam('skipSignatureLines')) ?? true,
    },
    userConfig
  )
  previewInputHtml = populateTableOfContentsAndNotesReferences({
    document,
    content: previewInputHtml,
    tocElement: '#table-of-contents',
    titleElementSelector: '#preview-input h2',
    noteSelector: '#preview-input .note',
  })
  return new Previewer()
    .preview(
      previewInputHtml,
      [
        {
          generalCss: `
          @page {
            size: 210mm 297mm;
            /*
               We're saying to pagedjs that wkhtml will take 10mm for forced white margin and we're gonna use 5mm for padding,
               except for the bottom of the paper where wkhtml till take 10mm and we need 18mm for the footer and 2mm for padding.
             */
            margin: 15mm 15mm 30mm 15mm;
            @bottom-left {
              white-space: pre !important;
              align-self: start;
              padding-top: 4px;
              content: "${annualReport.companyName}\\00000a${annualReport.organizationNumber}";
            }
            @bottom-right {
              white-space: pre !important;
              align-self: start;
              padding-top: 4px;
              content: "Sidan " counter(page) " av " counter(pages) "\\00000a\\00000a";
            }
          }
          @page :first {
            @bottom-left {
              content: "";
            }
            @bottom-right {
              content: "";
            }
          }
          .break-after-page {
            break-after: page;
          }
          .rm_page {
            background: white;
          }
          ${tableOfContentsCss()}
          ${extraCss}
          `,
        },
      ],
      targetElement
    )
    .then((flow) => {
      targetElement.querySelectorAll('.toc-element a').forEach((tocAnchorElement) => {
        const tocTarget = targetElement.querySelector(tocAnchorElement.getAttribute('href'))
        const tocTargetPage = tocTarget.closest('.rm_page')
        const pageNumber = tocTargetPage.getAttribute('data-page-number')
        tocAnchorElement.parentNode.querySelector('.page-number').textContent = pageNumber
      })

      const frontpage = getUrlParam('frontpage')
      if (frontpage === 'both') {
        const pages = targetElement.querySelector('.rm_pages')
        const firstPage = targetElement.querySelector('.rm_page')
        const copyOfFirstPage = firstPage.cloneNode(true)
        copyOfFirstPage.querySelector('.certificate-of-approval').remove()
        pages.appendChild(copyOfFirstPage)
      } else if (frontpage === 'normal') {
        const firstPage = targetElement.querySelector('.rm_page')
        firstPage.querySelector('.certificate-of-approval').remove()
      }
      // Note: frontpage with coa and no extra frontpage at the end is the default
    })
}

export { renderAnnualReportPreview }
