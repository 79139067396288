import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker/index.js'
import TextField from '@mui/material/TextField/index.js'

const RmDatePicker = ({ defaultValue, onChange, disabled, ...props }) => {
  const [value, setValue] = useState(defaultValue)
  const [pickerIsOpen, setPickerIsOpen] = useState(false)
  return (
    <DesktopDatePicker
      open={pickerIsOpen}
      onOpen={() => setPickerIsOpen(true)}
      onClose={() => setPickerIsOpen(false)}
      onChange={(newDate) => {
        if (newDate && newDate.isValid()) {
          newDate = newDate.format('YYYY-MM-DD')
        } else {
          newDate = ''
        }
        setValue(newDate)
        onChange(newDate)
      }}
      inputFormat="YYYY-MM-DD"
      mask="____-__-__"
      renderInput={(inputProps) => (
        <TextField
          variant="outlined"
          size="small"
          style={{ width: '175px', ...(disabled ? { background: 'var(--body-bgcolor)' } : { background: '#ffffff' }) }}
          onClick={(e) => {
            if (!value) {
              setPickerIsOpen(true)
            }
          }}
          {...inputProps}
        />
      )}
      disableHighlightToday={true}
      disabled={disabled}
      value={value}
      {...props}
    />
  )
}

RmDatePicker.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

RmDatePicker.defaultProps = {
  onChange: () => {},
  disabled: false,
}

export default RmDatePicker
