import React from 'react'
import PropTypes from 'prop-types'
import RmComboBox from './RmComboBox.jsx'
import { allDomiciles } from '../../../shared-universal/Domiciles.js'

const EditableDomicile = React.forwardRef(({ defaultValue, onChange, ...other }, ref) => {
  return (
    <RmComboBox
      options={allDomiciles}
      getOptionLabel={(op) => op.municipalityName}
      isOptionEqualToValue={(a, b) => a.code === b.code}
      defaultValue={allDomiciles.find((dom) => dom.code === defaultValue)}
      onChange={(newDomicileObj) => onChange(newDomicileObj ? newDomicileObj.code : null, newDomicileObj)}
      style={{ width: '300px' }}
      {...other}
    />
  )
})

EditableDomicile.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
}

EditableDomicile.displayName = 'EditableDomicile'

export default EditableDomicile
