import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Elements } from '@stripe/react-stripe-js'
// using /pure import because of: https://github.com/stripe/stripe-js/issues/43
import { loadStripe } from '@stripe/stripe-js/pure.js'

import { withUserConfig, UserConfigKeys } from '../ClientSideUserConfig.jsx'
import SpinnerBlock from './generic/SpinnerBlock.jsx'

let stripeLib

const StripeProviderWrapper = (props) => {
  const stripeEnabled = props.userConfig.getConfigKey(UserConfigKeys.STRIPE_ENABLED)
  const stripeEverywhere = props.userConfig.getConfigKey(UserConfigKeys.STRIPE_EVERYWHERE)
  const stripePublishableKey = props.userConfig.getConfigKey(UserConfigKeys.STRIPE_PUBLISHABLE_KEY)

  const stripeShouldBeLoadedNow =
    stripeEnabled && ((stripeEverywhere && props.isRootProvider) || (!stripeEverywhere && !props.isRootProvider))

  const [loading, setLoading] = useState(stripeShouldBeLoadedNow)
  useEffect(() => {
    ;(async () => {
      if (stripeShouldBeLoadedNow) {
        if (!stripeLib) {
          setLoading(true)
          stripeLib = await loadStripe(stripePublishableKey)
        }
        setLoading(false)
      }
    })()
  }, [stripeShouldBeLoadedNow, stripePublishableKey])
  if (loading) {
    return <SpinnerBlock />
  }
  if (stripeLib) {
    return <Elements stripe={stripeLib}>{props.children}</Elements>
  }
  return <React.Fragment>{props.children}</React.Fragment>
}

StripeProviderWrapper.propTypes = {
  isRootProvider: PropTypes.bool,
  userConfig: PropTypes.object,
  children: PropTypes.node,
}

StripeProviderWrapper.defaultProps = {
  isRootProvider: false,
}

export default withUserConfig(StripeProviderWrapper)
