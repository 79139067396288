import PropTypes from 'prop-types'
import React from 'react'

import styles from './Spinner.module.css'

const DEFAULT_MINIMUM_DELAY_FOR_VISIBLE_SPINNER_MILLIS = 600

class Spinner extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    thickness: PropTypes.string,
    duration: PropTypes.string,
    delayBeforeVisibleMs: PropTypes.number,
    // Children of the spinner will not be visible until at least "delayBeforeVisibleMs" has passed
    // This is useful if you want to create for example a spinner with the text "Loading ..." next to it.
    children: PropTypes.node,
  }

  static defaultProps = {
    className: '',
    size: '1em',
    color: '#000',
    thickness: '.4em',
    duration: '0.9s',
    delayBeforeVisibleMs: DEFAULT_MINIMUM_DELAY_FOR_VISIBLE_SPINNER_MILLIS,
  }

  initialState = {
    visible: false,
  }
  state = this.initialState

  componentDidMount() {
    this.timerId = setTimeout(() => {
      delete this.timerId
      this.setState({ visible: true }, () => {
        this.loaderRef.style.fontSize = this.props.size + 'px'
      })
    }, this.props.delayBeforeVisibleMs)
  }

  componentWillUnmount() {
    if (this.timerId) {
      clearTimeout(this.timerId)
    }
  }

  render() {
    if (!this.state.visible) {
      return null
    }
    return (
      <span style={{ display: 'inline-flex', alignItems: 'center' }}>
        <span
          className={`${styles.Spinner} ${this.props.className}`}
          style={{
            borderColor: this.props.color,
            borderRightColor: 'transparent',
            width: this.props.size,
            height: this.props.size,
            borderWidth: this.props.thickness,
            animationDuration: this.props.duration,
          }}
          ref={(el) => (this.loaderRef = el)}
        ></span>
        {this.props.children}
      </span>
    )
  }
}

export default Spinner
