import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { withUserConfig } from '../../ClientSideUserConfig.jsx'
import { UserConfigKeys } from '../../../shared-universal/ConstantsShared.js'
import RemakerMenu from '../remaker/RemakerMenu.jsx'
import minarsredovisningPng from '../remaker/minarsredovisning.png'
import RemakerCenteredContent from '../remaker/RemakerCenteredContent.jsx'

const HeaderLink = ({ href, children }) => (
  <Link to={href} style={{ textDecoration: 'none', color: 'var(--primary-font-color)' }}>
    {children}
  </Link>
)
HeaderLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

const RemakerHeader = ({ userConfig }) => {
  const isLoggedIn = userConfig.getConfigKey(UserConfigKeys.IS_LOGGED_IN)

  return (
    <React.Fragment>
      <div style={{ background: '#fff', paddingTop: '20px', paddingBottom: '20px' }}>
        <RemakerCenteredContent
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: '0px',
            paddingBottom: '0px',
          }}
        >
          <HeaderLink href={'/'}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={minarsredovisningPng} width="45" />
              <div
                style={{ fontFamily: "'Roboto Slab', arial", fontWeight: 700, fontSize: '24px', marginLeft: '10px' }}
              >
                Min Årsredovisning
              </div>
            </div>
          </HeaderLink>
          <div
            style={{
              display: 'flex',
              fontSize: '15px',
              fontWeight: 'bold',
              gap: '40px',
              position: 'relative',
              top: '4px',
            }}
          >
            {!isLoggedIn && <HeaderLink href="/start-email-login">Logga in</HeaderLink>}
            {isLoggedIn && <RemakerMenu />}
          </div>
        </RemakerCenteredContent>
      </div>
    </React.Fragment>
  )
}

RemakerHeader.propTypes = {
  userConfig: PropTypes.object.isRequired,
}

RemakerHeader.defaultProps = {}

export default withUserConfig(RemakerHeader)
