import React, { useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { clog } from './LoggingUtils.js'
import AppBuildConfig from '../shared-universal/AppBuildConfig.js'

const WebSocketEventsContext = React.createContext()

// haproxy disconnects sockets that have transmitted no data in 50 seconds.
const WEBSOCKET_PING_INTERVAL_MILLIS = 45000

const connectWebSocket = (eventListenerCallbacksRef) => {
  const url = new URL('/api/authp/server-events', window.location.href)
  url.protocol = url.protocol.replace('http', 'ws')
  const ws = new WebSocket(url)

  ws.onmessage = (event) => {
    const msg = JSON.parse(event.data)
    clog('[WSOCK] ' + msg.eventType + ' (' + eventListenerCallbacksRef.current.length + ' components listening)', msg)
    eventListenerCallbacksRef.current.forEach((callback) => {
      callback(msg)
    })
  }
  ws.onclose = () => {
    clog('[WSOCK] Socket closed.')
    ws.close()
  }
  return ws
}

const WebSocketEventsProvider = ({ children }) => {
  const eventListenerCallbacksRef = useRef([])

  useEffect(() => {
    let ws = connectWebSocket(eventListenerCallbacksRef)
    const keepAliveTimer = setInterval(() => {
      if (ws.readyState == WebSocket.CLOSED) {
        ws = connectWebSocket(eventListenerCallbacksRef)
        clog('[WSOCK] Socket reconnecting.')
      } else {
        ws.send('PING')
      }
    }, WEBSOCKET_PING_INTERVAL_MILLIS)

    return () => {
      ws.close()
      clearTimeout(keepAliveTimer)
    }
  }, [])

  const addWebSocketEventsListener = (callback) => eventListenerCallbacksRef.current.push(callback)
  const removeWebSocketEventsListener = (callback) => {
    eventListenerCallbacksRef.current = eventListenerCallbacksRef.current.filter((cb) => cb !== callback)
  }

  return (
    <WebSocketEventsContext.Provider value={{ addWebSocketEventsListener, removeWebSocketEventsListener }}>
      {children}
    </WebSocketEventsContext.Provider>
  )
}
WebSocketEventsProvider.propTypes = {
  children: PropTypes.node,
}

const MaybeWebSocketEventsProvider = ({ children }) => {
  if (!AppBuildConfig.WEBSOCKETS_ENABLED) {
    return children
  }
  return <WebSocketEventsProvider>{children}</WebSocketEventsProvider>
}

MaybeWebSocketEventsProvider.propTypes = {
  children: PropTypes.node,
}

const useWebSocketEvents = ({ onEvent }) => {
  const serverEventsContext = useContext(WebSocketEventsContext)
  useEffect(() => {
    serverEventsContext.addWebSocketEventsListener(onEvent)
    return () => {
      serverEventsContext.removeWebSocketEventsListener(onEvent)
    }
  }, [serverEventsContext, onEvent])
}

export { MaybeWebSocketEventsProvider, useWebSocketEvents }
