import React from 'react'
import PropTypes from 'prop-types'

import GenericDropdownList from '../generic/GenericDropdownList.jsx'

import styles from './StyledDropdownList.module.css'

const StyledDropdownList = ({ className, disabled, ...otherProps }) => {
  return (
    <GenericDropdownList
      className={`${styles.DropdownList} ${disabled ? styles.DropdownDisabled : ''} ${className}`}
      disabled={disabled}
      {...otherProps}
    />
  )
}

StyledDropdownList.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

StyledDropdownList.defaultProps = {
  className: '',
  disabled: false,
}

export default StyledDropdownList
