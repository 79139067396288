import React from 'react'
import PropTypes from 'prop-types'

import styles from './RadioButton.module.css'

const RadioButton = ({ name, value, checked, onChange, children, className }) => (
  <span className={`${styles.RadioButton} ${className}`}>
    <input type="radio" id={name + value} name={name} value={value} checked={checked} onChange={onChange} />
    <label htmlFor={name + value}>{children}</label>
  </span>
)

RadioButton.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
}

export default RadioButton
