/* eslint-disable react/no-unescaped-entities */
import React from 'react'

import PageContent from '../PageContent.jsx'
import AppBuildConfig from '../../../shared-universal/AppBuildConfig.js'

const PagePersonuppgiftspolicy = () => {
  return (
    <PageContent style={{ padding: '25px' }}>
      <h2>
        Personuppgiftspolicy för {AppBuildConfig.APP_NAME} (drivs av {AppBuildConfig.COMPANY_NAME}, orgnr{' '}
        {AppBuildConfig.COMPANY_ORGNR})
      </h2>
      Senast uppdaterad: 2023-09-29
      <ol>
        <li>
          {AppBuildConfig.COMPANY_NAME} ("Företaget", "Vi" eller "Oss") är engagerat i att skydda och respektera din
          integritet. Denna personuppgiftspolicy beskriver hur vi samlar in, lagrar, behandlar och delar personuppgifter
          i samband med vår verksamhet gentemot svenska aktiebolag.
        </li>
        <li>
          Insamling av personuppgifter: Vi kan samla in följande kategorier av personuppgifter om företrädare och
          kontaktpersoner för svenska aktiebolag:
          <ol>
            <li>Namn och personnummer</li>
            <li>Kontaktuppgifter (e-postadress, telefonnummer, adress)</li>
            <li>Yrkesmässig information (befattning, arbetsgivare)</li>
            <li>Kommunikation med oss (via e-post, telefon eller andra kanaler)</li>
            <li>Finansiell information som kan vara relevant för våra affärsrelationer</li>
            <li>
              Eventuell annan information som du väljer att dela med oss i syfte att utföra våra tjänster (inklusive
              information i uppladdade filer)
            </li>
          </ol>
        </li>
        <li>
          Syfte med behandlingen av personuppgifter: Vi behandlar personuppgifter för följande ändamål:
          <ol>
            <li>Att hantera och upprätthålla affärsrelationer med våra kunder</li>
            <li>Kommunikation och korrespondens relaterad till våra tjänster</li>
            <li>Fakturering och bokföring</li>
            <li>Statistik och analysändamål</li>
            <li>Uppfyllande av rättsliga skyldigheter och regler</li>
            <li>Vidareutveckling, testning och felsökning i tjänsten</li>
            <li>Övriga ändamål som du har gett ditt samtycke till</li>
          </ol>
        </li>
        <li>
          Laglig grund för behandlingen: Vi behandlar personuppgifter baserat på följande rättsliga grunder:
          <ol>
            <li>Utförande av ett avtal med dig eller ditt företag.</li>
            <li>Erforderligt för att uppfylla våra rättsliga skyldigheter.</li>
            <li>Berättigat intresse av att upprätthålla och förbättra våra affärsrelationer.</li>
            <li>Ditt samtycke när det är nödvändigt.</li>
          </ol>
        </li>
        <li>
          Lagring och radering
          <ol>
            <li>
              Vår tjänst är inte ett arkiv, våra kunder ansvarar själva för att spara kopior av all data. Konton som
              inte används kan komma att tas bort vid behov.
            </li>
            <li>
              Personuppgifter raderas om kunden ber om radering (vi behåller dock loggfiler och kopior av fakturor med
              mera för vår egen bokföring)
            </li>
          </ol>
        </li>
        <li>
          Delning av personuppgifter: Vi kan dela personuppgifter med:
          <ol>
            <li>Tjänsteleverantörer som hjälper oss med våra affärsprocesser</li>
            <li>Revisorer, rättsliga rådgivare och myndigheter vid behov</li>
            <li>Andra tredje parter när det krävs enligt lag eller domstolsbeslut</li>
            <li>Samarbetspartners, när vi fastställt att personuppgifterna hanteras på ett ansvarsfullt sätt</li>
          </ol>
        </li>
        <li>
          Dina rättigheter:
          <ol>
            <li>Du har rätt att begära tillgång till, rättelse, radering eller begränsning av dina personuppgifter.</li>
            <li>Du har även rätt att invända mot viss behandling och att dra tillbaka ditt samtycke när som helst.</li>
          </ol>
        </li>
        <li>
          Dataskydd och säkerhet: Vi vidtar lämpliga tekniska och organisatoriska åtgärder för att skydda
          personuppgifter mot obehörig åtkomst, förlust eller ändring.
        </li>
        <li>
          Kontaktinformation Om du har frågor eller funderingar om vår personuppgiftspolicy eller hur vi behandlar dina
          personuppgifter, vänligen kontakta oss på: {AppBuildConfig.SUPPORT_EMAIL}
        </li>
        <li>
          Ändringar i policyn: Denna policy kan komma att uppdateras då och då. Den senaste versionen kommer alltid att
          vara tillgänglig på vår webbplats.
        </li>
      </ol>
      <h3>Se även</h3>
      <ul>
        <li>
          <a href="/anvandarvillkor">Användarvillkor</a>
        </li>
      </ul>
    </PageContent>
  )
}

PagePersonuppgiftspolicy.propTypes = {}

export default PagePersonuppgiftspolicy
