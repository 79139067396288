import React from 'react'
import PropTypes from 'prop-types'
import FormGroup from '@mui/material/FormGroup/index.js'
import FormControlLabel from '@mui/material/FormControlLabel/index.js'
import Switch from '@mui/material/Switch/index.js'

const RmSwitch = ({ label, value, disabled, onChange, ...props }) => {
  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Switch checked={value} onChange={(ev) => onChange(ev.target.checked)} color="primary" disabled={disabled} />
        }
        label={label}
      />
    </FormGroup>
  )
}

RmSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
}

RmSwitch.defaultProps = {
  onChange: () => {},
  disabled: false,
}

export default RmSwitch
