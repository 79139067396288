import React from 'react'
import PropTypes from 'prop-types'

import Spinner from '../generic/Spinner.jsx'

import styles from './InlineSpinnerRow.module.css'

class InlineSpinnerRow extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    thickness: PropTypes.string,
    children: PropTypes.node,
    delayBeforeVisibleMs: PropTypes.number,
  }

  static defaultProps = {
    className: '',
    color: '#000',
    thickness: '0.2em',
  }

  render() {
    return (
      <div className={`${styles.InlineSpinnerRow} ${this.props.className}`}>
        <Spinner
          color={this.props.color}
          thickness={this.props.thickness}
          delayBeforeVisibleMs={this.props.delayBeforeVisibleMs}
        >
          <span className={styles.InlineSpinnerText} style={{ color: this.props.color }}>
            {this.props.children}
          </span>
        </Spinner>
      </div>
    )
  }
}

export default InlineSpinnerRow
