import React from 'react'
import PropTypes from 'prop-types'
import Slider from '@mui/material/Slider/index.js'
import { formatNumber } from '../../../shared-universal/AnnualReportUtils.js'

const RmSlider = ({ value, min, max, step, unit, onChange, ...props }) => {
  return (
    <Slider
      value={value}
      min={0}
      max={max}
      step={step}
      onChange={(ev, newValue) => {
        if (newValue < min) {
          newValue = min
        }
        onChange(newValue)
      }}
      marks={[
        { value: 0, label: `${formatNumber(0, { nbspThousandSeparator: false })} ${unit}` },
        ...(min != 0 ? [{ value: min, label: `${formatNumber(min, { nbspThousandSeparator: false })} ${unit}` }] : []),
        ...(max != 0 && min != max
          ? [{ value: max, label: `${formatNumber(max, { nbspThousandSeparator: false })} ${unit}` }]
          : []),
      ]}
      {...props}
    />
  )
}

RmSlider.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  unit: PropTypes.string,
}

RmSlider.defaultProps = {
  onChange: () => {},
  step: 1,
  unit: '',
}

export default RmSlider
