import PropTypes from 'prop-types'
import React from 'react'

import FileThumbnail from './FileThumbnail.jsx'
import UPLOADABLE_CONTENT_TYPES from '../../shared-universal/UploadableContentTypes.js'
import InlineSpinnerRow from './styled/InlineSpinnerRow.jsx'
import ImagePickerDialog from './ImagePickerDialog.jsx'
import BaseButton from './BaseButton.jsx'
import FileInputDialog from './generic/FileInputDialog.jsx'

const ComponentModes = {
  IDLE: Symbol(),
  DOWNLOADING: Symbol(),
  GENERATING_THUMBNAIL: Symbol(),
}

class PickedImage extends React.Component {
  static propTypes = {
    thumbnailUrl: PropTypes.string,
    onImageSelected: PropTypes.func.isRequired,
    onImageUnset: PropTypes.func,
    renderThumbnail: PropTypes.func.isRequired,
    className: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.string),
    showUploadSpinner: PropTypes.bool,
    imageLibrarySearchEnabled: PropTypes.bool,
  }

  static defaultProps = {
    showUploadSpinner: false,
  }

  initialState = {
    mode: ComponentModes.IDLE,
    fileId: undefined,
  }
  state = this.initialState

  isUploadableType = (file) =>
    Object.keys(UPLOADABLE_CONTENT_TYPES).some((uploadableType) => file.type.startsWith(uploadableType))

  handleFileSelected = (file) => {
    if (!this.isUploadableType(file)) {
      alert('only images can be uploaded')
      return
    }
    this.setState({ mode: ComponentModes.GENERATING_THUMBNAIL })
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.addEventListener('load', async () => {
      const thumbnailUrl = reader.result
      this.setState({ mode: ComponentModes.IDLE })
      this.props.onImageSelected(file, thumbnailUrl)
    })
  }

  unsetImage = () => {
    this.setState(this.initialState, () => {
      this.props.onImageUnset()
    })
  }

  componentWillUnmount() {
    if (this.controller) {
      this.controller.abort()
    }
  }

  pickImage = () => {
    if (this.props.imageLibrarySearchEnabled) {
      ImagePickerDialog.open({
        onFileWillBeSelected: () => {
          this.setState({ mode: ComponentModes.DOWNLOADING })
        },
        onFileSelected: (file) => {
          this.setState({ mode: ComponentModes.IDLE })
          this.handleFileSelected(file)
        },
        anchorElement: document.body,
      })
    } else {
      this.fileInput.openNativeSelectFileDialog()
    }
  }

  render() {
    return (
      <div className={this.props.className} style={{ display: 'inline-block', ...this.props.style }}>
        {this.state.mode === ComponentModes.IDLE && (
          <React.Fragment>
            <FileThumbnail
              thumbnailUrl={this.props.thumbnailUrl}
              onClick={this.pickImage}
              renderThumbnail={this.props.renderThumbnail}
            >
              (click to add image)
            </FileThumbnail>
            {this.props.thumbnailUrl && (
              <center>
                <BaseButton
                  kind="ghost"
                  onClick={this.unsetImage}
                  style={{ marginTop: '5px', borderWidth: '0px', color: '#555', fontSize: '0.7rem' }}
                >
                  Remove Image
                </BaseButton>
              </center>
            )}
            {!this.props.imageLibrarySearchEnabled && (
              <FileInputDialog ref={(el) => (this.fileInput = el)} onFileSelected={this.handleFileSelected} />
            )}
          </React.Fragment>
        )}
        <React.Fragment>
          {this.state.mode === ComponentModes.DOWNLOADING && (
            <center>
              <InlineSpinnerRow
                className={'automation-download-in-progress'}
                color={'var(--primary-color)'}
                /* We know we're gonna switch to the new image once it's
                  downloaded so it feels wrong to keep displaying the old image.
                  Instead we immeditely show the spinner so the user gets a quick
                  confirmation that the image switch is in progress.
                */
                delayBeforeVisibleMs={0}
              >
                Fetching ...
              </InlineSpinnerRow>
            </center>
          )}
          {this.props.showUploadSpinner && (
            <center>
              <InlineSpinnerRow
                className={'automation-upload-in-progress'}
                color={'var(--primary-color)'}
                delayBeforeVisibleMs={0}
              >
                Uploading ...
              </InlineSpinnerRow>
            </center>
          )}
          {this.state.mode === ComponentModes.GENERATING_THUMBNAIL && (
            <center>
              <InlineSpinnerRow className={'automation-thumbnail-in-progress'} color={'var(--primary-color)'}>
                Generating thumbnail ...
              </InlineSpinnerRow>
            </center>
          )}
        </React.Fragment>
      </div>
    )
  }
}

export default PickedImage
