import React from 'react'
import PropTypes from 'prop-types'

import PaddingButton from './PaddingButton.jsx'

import styles from './FillButton.module.css'

const FillButton = ({ children, className = '', ...props }) => (
  <PaddingButton className={`${styles.FillButton} ${className}`} {...props}>
    {children}
  </PaddingButton>
)

FillButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default FillButton
