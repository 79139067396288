import PropTypes from 'prop-types'
import React from 'react'

import styles from './GenericPopupButton.module.css'

class GenericPopupButton extends React.Component {
  static propTypes = {
    button: PropTypes.func.isRequired,
    align: PropTypes.oneOf(['left', 'right']),
    content: PropTypes.func.isRequired,
  }

  static defaultProps = {
    align: 'left',
  }

  state = {
    isOpen: false,
  }
  contentWrapperRef = React.createRef()

  open = () => {
    this.setState({ isOpen: true })
    document.addEventListener('click', this.handleDocumentClicked)
  }

  close = () => {
    this.setState({ isOpen: false })
  }

  handleDocumentClicked = (ev) => {
    document.removeEventListener('click', this.handleDocumentClicked)
    if (this.contentWrapperRef.current && !this.contentWrapperRef.current.contains(ev.target)) {
      this.close()
    }
  }

  handlePopupButtonClicked = (ev) => {
    ev.stopPropagation()
    if (this.state.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  render() {
    return (
      <div style={{ position: this.state.isOpen ? 'relative' : 'static', display: 'inline-block' }}>
        {/* Only set position:relative above when the popup is open because that's when it's needed
            and it is good to avoid creating a new stacking context for this button even when the
            popup is closed because if such a context exists this button might end up rendered above
            _other_ popups that might want to display above it. */}
        {React.cloneElement(this.props.button({ isOpen: this.state.isOpen }), {
          onClick: this.handlePopupButtonClicked,
        })}
        {this.state.isOpen && (
          <div
            className={styles.ContentWrapper}
            style={{ right: this.props.align === 'right' ? 0 : undefined }}
            ref={this.contentWrapperRef}
          >
            {this.props.content({ open: this.open, close: this.close })}
          </div>
        )}
      </div>
    )
  }
}

export default GenericPopupButton
