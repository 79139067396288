/*
 * BaseError should be the base class of all thrown error/exceptions.
 * It makes sure that the errors is printed in a clear and understandable way.
 */
class BaseError extends Error {
  constructor(message) {
    super(message)

    /*
     * Setting "this.name" to the constructor name means that the name of the
     * thrown error will be visible in the error .toString() output.
     *
     * For example:
     *
     * class MyError extends Error {}
     * (new MyError()).toString() // returns "Error"
     *
     * class MyOtherError extends Error { constructor() { super(); this.name = this.constructor.name } }
     * (new MyOtherError()).toString() // returns "MyOtherError"
     */
    this.name = this.constructor.name

    /* Save the error message as well, so that JSON.stringify(err) shows both "name" and "errorMessage". */
    this.errorMessage = message
  }
}

export default BaseError
