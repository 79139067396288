import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import HoverUnderlineLink from './generic/HoverUnderlineLink.jsx'
import { doFetchJson } from '../AppFetch.js'
import Field from './Field.jsx'
import StyledEditBox from './StyledEditBox.jsx'
import FillButton from './FillButton.jsx'
import VerticalList from './VerticalList.jsx'
import FlashMessage from './FlashMessage.jsx'
import { withUserConfig, UserConfigKeys } from '../ClientSideUserConfig.jsx'

const LoginForm = ({ userConfig, loginButtonKind }) => {
  const history = useHistory()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [flashMessage, setFlashMessage] = useState('')
  const frmInputPassword = useRef(null)

  const submitLoginForm = async () => {
    const { status } = await doFetchJson('/api/login', {
      method: 'POST',
      body: JSON.stringify({
        frmInputUsername: username,
        frmInputPassword: password,
      }),
    })
    if (status === 200) {
      await userConfig.refreshFromServer()
      if (userConfig.getConfigKey(UserConfigKeys.ACTIVE_SUBSCRIPTION)) {
        history.push('/')
      } else {
        history.push('/subscription')
      }
    } else {
      setFlashMessage('Invalid username/password.')
      setPassword('')
      frmInputPassword.current.focus()
    }
  }

  return (
    <div>
      <FlashMessage message={flashMessage} className={'automation-flash-message'} />
      <form
        onSubmit={(ev) => {
          ev.preventDefault()
          submitLoginForm()
        }}
      >
        <Field label="Username">
          <StyledEditBox
            ref={frmInputPassword}
            value={username}
            onChange={setUsername}
            className={'automation-username'}
            name="username"
            autoComplete="username"
          />
        </Field>
        <Field label="Password">
          <StyledEditBox
            type="password"
            value={password}
            onChange={setPassword}
            className={'automation-password'}
            name="password"
            autoComplete="current-password"
          />
        </Field>
        <div style={{ marginTop: '20px' }}>
          <FillButton type="submit" kind={loginButtonKind} className={'automation-login-button'}>
            Log in
          </FillButton>
        </div>
        <div style={{ marginTop: '15px' }}>
          <FillButton
            type="button"
            kind={loginButtonKind}
            className={'automation-signup-button'}
            onClick={() => {
              history.push('/select-plan')
            }}
          >
            Sign Up
          </FillButton>
        </div>
        <VerticalList spaceBetweenPx={0}>
          <div>
            <HoverUnderlineLink
              to="/request-password-reset"
              style={{ fontSize: '0.8em' }}
              className="automation-forgot-password"
            >
              Forgot password/username?
            </HoverUnderlineLink>
          </div>
        </VerticalList>
      </form>
    </div>
  )
}

LoginForm.propTypes = {
  userConfig: PropTypes.object.isRequired,
  loginButtonKind: PropTypes.string,
}

LoginForm.defaultProps = {
  loginButtonKind: 'primary',
}

export default withUserConfig(LoginForm)
