import * as Sentry from '@sentry/browser'
import UAParser from 'ua-parser-js'

import { doFetchJson } from './AppFetch.js'
import LogLevels from '../shared-universal/LogLevels.js'
import { getUrlParam } from './UrlUtils.js'
import { consoleDebug } from '../shared-universal/SanctionedConsole.js'
import AppBuildConfig from '../shared-universal/AppBuildConfig.js'
import { stringToBoolean } from '../shared-universal/Utils.js'

// Client side log (printed via console.debug)
//
// A) enable VERBOSE logging messages in Chrome devtools
// B) do one of these two:
//      - append ?clog=1 url param
//      - set `${AppBuildConfig.APP_ID}-clog` to "1" in localStorage
function clog(...args) {
  // Allow overrides in localstorage to make it convenient to force ?clog=1 param despite URL redirects
  const localStorageValue = localStorage && localStorage.getItem(`${AppBuildConfig.APP_ID}-clog`)
  if (stringToBoolean(localStorageValue) || stringToBoolean(getUrlParam('clog'))) {
    consoleDebug(...args)
  }
}
window.clog = clog
window.clogEnable = () => {
  localStorage.setItem(`${AppBuildConfig.APP_ID}-clog`, '1')
  consoleDebug('clog logging enabled')
}
window.clogDisable = () => {
  localStorage.setItem(`${AppBuildConfig.APP_ID}-clog`, '0')
  consoleDebug('clog logging disabled')
}

function submitCrashReport(error, errorInfo = {}) {
  Sentry.captureException(error, { extra: errorInfo })

  if (AppBuildConfig.LOG_JS_ERRORS_IN_SERVER_LOG) {
    if (typeof errorInfo.componentStack === 'string') {
      errorInfo.componentStack = errorInfo.componentStack.split('\n')
    }
    const parsedUserAgent = new UAParser().getResult()
    errorInfo.browser = `${parsedUserAgent.browser.name || ''} ${parsedUserAgent.browser.major || ''}`
    errorInfo.operatingSystem = `${parsedUserAgent.os.name || ''} ${parsedUserAgent.os.version || ''}`
    errorInfo.timeLocal = new Date().toString()
    errorInfo.timeUTC = new Date().toISOString()
    errorInfo.currentUrl = location.href
    if (error) {
      errorInfo.errorStr = error.toString()
      errorInfo.errorStacktrace = String(error.stack).split('\n')
    }
    window.log('warn', JSON.stringify(errorInfo, null, 4), { clientJsError: true })
  }
}

async function log(level, msg, opts = {}) {
  const canonicalLevel = level.toLowerCase()
  if (!Object.values(LogLevels).includes(canonicalLevel)) {
    throw Error(`Invalid log level "${level}", valid levels are: ${Object.values(LogLevels).join(', ')}`)
  }
  return doFetchJson('/api/log', {
    method: 'POST',
    body: JSON.stringify({
      level: canonicalLevel,
      msg,
      opts,
    }),
  })
}

LogLevels.forEach((level) => {
  log[level] = (msg) => log(level, msg)
})

window.log = log

export { clog, submitCrashReport, log }
