import React from 'react'
import PropTypes from 'prop-types'

import BaseButton from './BaseButton.jsx'

import styles from './PaddingButton.module.css'

const PaddingButton = React.forwardRef(({ children, className = '', ...props }, ref) => (
  <BaseButton ref={ref} className={`${styles.PaddingButton} ${className}`} {...props}>
    {children}
  </BaseButton>
))

PaddingButton.displayName = 'PaddingButton'

PaddingButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default PaddingButton
