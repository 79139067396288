import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Select from '@mui/material/Select/index.js'
import FormControl from '@mui/material/FormControl/index.js'

const RmSelect = ({ defaultValue, disabled, style, onChange, ...props }) => {
  const [value, setValue] = useState(defaultValue)

  return (
    <FormControl variant="outlined" size="small">
      <Select
        value={value}
        style={{
          width: '650px',
          ...(disabled ? { background: 'var(--body-bgcolor)' } : { background: '#ffffff' }),
          ...style,
        }}
        onChange={(ev) => {
          setValue(ev.target.value)
          onChange(ev.target.value)
        }}
        disabled={disabled}
        {...props}
      />
    </FormControl>
  )
}

RmSelect.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  style: PropTypes.object,
}

RmSelect.defaultProps = {
  onChange: () => {},
  defaultValue: '',
  disabled: false,
  style: {},
}

export default RmSelect
