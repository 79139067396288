import React from 'react'
import PropTypes from 'prop-types'
import Radio from '@mui/material/Radio/index.js'
import RadioGroup from '@mui/material/RadioGroup/index.js'
import FormControlLabel from '@mui/material/FormControlLabel/index.js'

import { useAbortSignal } from '../../AppFetch.js'
import API from '../../API.js'
import EditableXbrlValue from './EditableXbrlValue.jsx'
import { saveXbrlValues } from './EditableTableUtils.jsx'

const EditableNyemission = ({ annualReport, onChange, isApproved }) => {
  const abortSignal = useAbortSignal()
  const FIRST_CELL_WIDTH = '240px'

  return (
    <React.Fragment>
      <div>Har företaget gjort en nyemission som har påverkat eget kapital detta året?</div>
      <RadioGroup
        aria-label="position"
        style={{ marginLeft: '20px' }}
        name="position"
        value={annualReport.egetKapitalNyemission ? 1 : 0}
        onChange={async (ev) => {
          API.saveAnnualReportValue(
            annualReport.reportId,
            'egetKapitalNyemission',
            ev.target.value === '1',
            abortSignal,
            onChange,
            { immediate: true }
          )
          if (ev.target.value === '0') {
            const xbrlRecords = [
              {
                xbrlName: 'ForandringEgetKapitalAktiekapitalNyemission',
                value: null,
              },
              {
                xbrlName: 'ForandringEgetKapitalOverkursfondNyemission',
                value: null,
              },
            ]
            saveXbrlValues(annualReport.financialYears[0].financialYearId, xbrlRecords, abortSignal, onChange)
          }
        }}
      >
        <FormControlLabel
          value="0"
          control={<Radio color="primary" />}
          label="Nej"
          labelPlacement="end"
          disabled={isApproved}
        />
        <FormControlLabel
          value="1"
          control={<Radio color="primary" />}
          label="Ja"
          labelPlacement="end"
          disabled={isApproved}
        />
      </RadioGroup>
      {annualReport.egetKapitalNyemission && (
        <table>
          <tbody>
            <tr>
              <td style={{ width: FIRST_CELL_WIDTH }}>Ökning av aktiekapital</td>
              <td>
                <EditableXbrlValue
                  xbrlName="ForandringEgetKapitalAktiekapitalNyemission"
                  financialYear={annualReport.financialYears[0]}
                  abortSignal={abortSignal}
                  onSaved={onChange}
                  disabled={isApproved}
                  allowNegative={false}
                />
              </td>
            </tr>
            <tr>
              <td style={{ width: FIRST_CELL_WIDTH }}>Ökning av fri överkursfond</td>
              <td>
                <EditableXbrlValue
                  xbrlName="ForandringEgetKapitalOverkursfondNyemission"
                  financialYear={annualReport.financialYears[0]}
                  abortSignal={abortSignal}
                  onSaved={onChange}
                  disabled={isApproved}
                  allowNegative={false}
                />
              </td>
            </tr>
            <tr>
              <td style={{ width: FIRST_CELL_WIDTH }}>Förändring av ej registrerat aktiekapital</td>
              <td>
                <EditableXbrlValue
                  xbrlName="ForandringEgetKapitalEjRegistreratAktiekapitalNyemission"
                  financialYear={annualReport.financialYears[0]}
                  abortSignal={abortSignal}
                  onSaved={onChange}
                  disabled={isApproved}
                />
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </React.Fragment>
  )
}

EditableNyemission.propTypes = {
  annualReport: PropTypes.object,
  onChange: PropTypes.func,
  isApproved: PropTypes.bool.isRequired,
}

export default EditableNyemission
