import React from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import HamburgerButton from './HamburgerButton.jsx'
import PopupMenuButton from './styled/PopupMenuButton.jsx'
import AppLogo from './AppLogo.jsx'
import PaidLink from './PaidLink.jsx'
import { withUserConfig, UserConfigKeys } from '../ClientSideUserConfig.jsx'

import styles from './TopMenu.module.css'

const TopMenu = ({ history, location, userConfig }) => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <div className={`${styles.TopMenuContainer} automation-top-menu`} style={{ width: '100%', maxWidth: '960px' }}>
      <Link
        draggable={false}
        to="/"
        className={`${styles.AppLogoLink}`}
        style={{ paddingTop: '4px', paddingBottom: '8px', fontSize: '1.0rem' }}
      >
        <AppLogo />
      </Link>
      <div className={styles.TopMenuMenuItemContainer}>
        <div className={styles.TopMenuMenuItemContainerInner}>
          <div>
            <Link
              draggable={false}
              className={`automation-topmenu-start ${styles.MenuItem} ${
                location.pathname.endsWith('/') ? styles.MenuItemSelected : ''
              }`}
              to="/"
            >
              Start
            </Link>
          </div>
          {userConfig.getConfigKey(UserConfigKeys.IS_LOGGED_IN) && (
            <div>
              <PaidLink
                draggable={false}
                className={`automation-topmenu-profile ${styles.MenuItem} ${
                  location.pathname.includes('/profile') ? styles.MenuItemSelected : ''
                }`}
                to="/profile"
              >
                Profile
              </PaidLink>
            </div>
          )}
          {!userConfig.getConfigKey(UserConfigKeys.IS_LOGGED_IN) && (
            <div>
              <Link
                draggable={false}
                className={`${styles.MenuItem} ${location.pathname.includes('/login') ? styles.MenuItemSelected : ''}`}
                to="/login"
              >
                Log in
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className={styles.HamburgerMenuContainer}>
        <PopupMenuButton
          button={({ isOpen }) => (
            <HamburgerButton
              isOpen={isOpen}
              className={`automation-main-menu ${styles.HamburgerButton}`}
              color="#505050"
            />
          )}
          align={'right'}
          menuItems={[
            {
              id: 'login',
              title: 'Log in',
              onClick: () => history.push('/login'),
              className: 'automation-login',
              visibleForAuthenticatedUsers: false,
              visibleForAnonymousUsers: true,
            },
            {
              id: 'profile',
              title: 'Profile',
              onClick: () => history.push('/profile'),
              className: 'automation-profile',
              visibleForAuthenticatedUsers: true,
              visibleForAnonymousUsers: false,
            },
            {
              id: 'logout',
              title: 'Log out',
              onClick: () => history.push('/logout'),
              className: 'automation-logout',
              visibleForAuthenticatedUsers: true,
              visibleForAnonymousUsers: false,
            },
          ].filter(
            (item) =>
              (item.visibleForAuthenticatedUsers && userConfig.getConfigKey(UserConfigKeys.IS_LOGGED_IN)) ||
              (item.visibleForAnonymousUsers && !userConfig.getConfigKey(UserConfigKeys.IS_LOGGED_IN))
          )}
        />
      </div>
    </div>
  </div>
)

TopMenu.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  userConfig: PropTypes.object.isRequired,
}

export default withRouter(withUserConfig(TopMenu))
