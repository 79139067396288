import { useRef, useEffect } from 'react'

const useBroadcastChannel = (channelName, onMessage) => {
  const broadcastChannelRef = useRef(null)
  useEffect(() => {
    broadcastChannelRef.current = new BroadcastChannel(channelName)
    broadcastChannelRef.current.onmessage = (ev) => {
      if (onMessage) {
        onMessage(ev)
      }
    }
    return () => {
      broadcastChannelRef.current.close()
    }
  })
  return (messageData) => broadcastChannelRef.current.postMessage(messageData)
}

export { useBroadcastChannel }
