import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button/index.js'
import Box from '@mui/material/Box/index.js'

import { useAppFetch } from '../../AppFetch.js'
import SpinnerBlock from '../generic/SpinnerBlock.jsx'
import RemakerHeader from '../remaker/RemakerHeader.jsx'
import ReportActionMenu from '../remaker/ReportActionMenu.jsx'
import RmTheme from '../../../shared-universal/RmTheme.js'
import RemakerCenteredContent from '../remaker/RemakerCenteredContent.jsx'

const HoverTr = styled('tr')(({ theme }) => ({
  '&:hover': {
    backgroundColor: RmTheme.SELECTION_COLOR,
  },
}))

const PageListReports = ({ history }) => {
  const { loading, responseJson: allAnnualReports, refetch } = useAppFetch(`/api/up/list-annual-reports`)

  return (
    <div className="automation-list-reports">
      <RemakerHeader />
      <RemakerCenteredContent>
        <Paper style={{ padding: '25px' }}>
          {loading && <SpinnerBlock />}
          {!loading && (
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <thead style={{ fontWeight: 'bold' }}>
                <tr>
                  <td>Företag</td>
                  <td style={{ width: '80px' }}>År</td>
                  <td style={{ width: '140px' }}>Skapad</td>
                  <td style={{ width: '80px' }}>Hantera</td>
                </tr>
              </thead>
              <tbody>
                {allAnnualReports.map((annualReport) => {
                  return (
                    <HoverTr key={annualReport.reportId}>
                      <td>
                        <Link
                          to={`/edit-report/${annualReport.reportId}`}
                          style={{ textDecoration: 'none', color: RmTheme.TEXT_COLOR, width: '100%', display: 'block' }}
                        >
                          {annualReport.companyName}
                        </Link>
                      </td>
                      <td>{annualReport.fYear}</td>
                      <td>{annualReport.created}</td>
                      <td>
                        <ReportActionMenu
                          reportId={annualReport.reportId}
                          reportTitle={annualReport.title}
                          onChange={() => {
                            refetch()
                          }}
                        />
                      </td>
                    </HoverTr>
                  )
                })}
              </tbody>
            </table>
          )}
        </Paper>
        <Box mt={1} />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <a href="/new">
            <Button variant="contained">Skapa ny årsredovisning</Button>
          </a>
        </div>
      </RemakerCenteredContent>
    </div>
  )
}

PageListReports.propTypes = {
  history: PropTypes.object,
}

export default withRouter(PageListReports)
