import React from 'react'
import PropTypes from 'prop-types'
import InputAdornment from '@mui/material/InputAdornment/index.js'

import NotEntreprenadtjanst from './NotEntreprenadtjanst.jsx'
import { useAbortSignal } from '../../AppFetch.js'
import RmTextField from './RmTextField.jsx'
import API from '../../API.js'
import { getVisibleDepreciations } from '../../../shared-universal/RemakerUtils.js'
import NoteHeading from './NoteHeading.jsx'
import RmEditorHeading3 from './RmEditorHeading3.jsx'

const NotRedovisningsprinciperEditor = ({ annualReport, onChange, disabled }) => {
  const abortSignal = useAbortSignal()
  const firstCellStyle = {
    width: '100%',
  }
  const visibleDepreciations = getVisibleDepreciations(annualReport)
  return (
    <React.Fragment>
      <NoteHeading title="Redovisningsprinciper" annualReport={annualReport} onChange={onChange}/>
      {visibleDepreciations.length > 0 && <RmEditorHeading3>Avskrivningstider</RmEditorHeading3>}
      <table>
        <tbody>
          {visibleDepreciations.map((depreciation) => {
            return (
              <tr key={depreciation.depreciationId}>
                <td style={firstCellStyle}>{depreciation.title}</td>
                <td>
                  <RmTextField
                    className={`automation-${depreciation.xbrlName}`}
                    defaultValue={annualReport.depreciations[depreciation.depreciationId]}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment style={{ marginLeft: '10px', width: '10px' }} position="end">
                          år
                        </InputAdornment>
                      ),
                    }}
                    onChange={(ev) => {
                      API.saveAnnualReportValue(
                        annualReport.reportId,
                        'depreciations',
                        { [depreciation.depreciationId]: ev.target.value },
                        abortSignal,
                        onChange
                      )
                    }}
                    unit="år"
                    disabled={disabled}
                    style={{ width: '162px' }}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <NotEntreprenadtjanst annualReport={annualReport} onChange={onChange} disabled={disabled} />
    </React.Fragment>
  )
}

NotRedovisningsprinciperEditor.propTypes = {
  annualReport: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default NotRedovisningsprinciperEditor
