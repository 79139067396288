import React, { useState } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box/index.js'

import { withUserConfig } from '../../ClientSideUserConfig.jsx'
import Button from '@mui/material/Button/index.js'
import EditableEmail from '../remaker/EditableEmail.jsx'
import { useAbortSignal } from '../../AppFetch.js'
import API from '../../API.js'
import RemakerHeader from '../remaker/RemakerHeader.jsx'
import { isValidEmail } from '../../../shared-universal/Utils.js'

const PageContinueWithEmail = () => {
  const [email, setEmail] = useState('')
  const [emailLoginError, setEmailLoginError] = useState('')
  const signal = useAbortSignal()
  const [sendEmailButtonDisabled, setSendEmailButtonDisabled] = React.useState(false)

  const submitForm = async () => {
    if (isValidEmail(email)) {
      setSendEmailButtonDisabled(true)
      await API.startEmailLogin(email, signal)
      setTimeout(() => {
        setSendEmailButtonDisabled(false)
      }, 6000)

      setEmailLoginError('Du har nu fått ett mail med en länk för att logga in. Kolla din mail och följ länken.')
    }
  }

  return (
    <div>
      <RemakerHeader />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ flexBasis: '1200px' }}>
          <div style={{ flex: 1, padding: '30px' }}>
            E-post
            <br />
            <EditableEmail
              defaultValue={email}
              onChange={setEmail}
              allowEmptyEmail={true}
              style={{ width: '300px' }}
              onKeyDown={async (ev) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault()
                  await submitForm()
                }
              }}
            />
            <Box mt={3} />
            <Button
              variant="contained"
              style={{ marginBottom: '8px' }}
              onClick={submitForm}
              disabled={sendEmailButtonDisabled}
            >
              Login
            </Button>
            <div>{emailLoginError}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

PageContinueWithEmail.propTypes = {
  history: PropTypes.object,
  userConfig: PropTypes.object.isRequired,
}

export default withRouter(withUserConfig(PageContinueWithEmail))
