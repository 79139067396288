import PropTypes from 'prop-types'
import React from 'react'

const GenericDropdownList = ({ items, onChange, ...otherProps }) => {
  return (
    <select {...otherProps} onChange={(ev) => onChange(items.find((i) => i.id === ev.target.value))}>
      {items.map((item) => (
        <option key={item.id} value={item.id}>
          {item.title}
        </option>
      ))}
    </select>
  )
}

GenericDropdownList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default GenericDropdownList
