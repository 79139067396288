import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import CardTopLevel from '../CardTopLevel.jsx'
import LoginForm from '../LoginForm.jsx'
import { logout } from '../../ClientAuth.js'
import { withUserConfig, UserConfigKeys } from '../../ClientSideUserConfig.jsx'

class PageLoginForm extends React.Component {
  static propTypes = {
    userConfig: PropTypes.object.isRequired,
  }

  async componentDidMount() {
    if (this.props.userConfig.getConfigKey(UserConfigKeys.IS_LOGGED_IN)) {
      await logout(this.props.userConfig)
      await this.props.userConfig.refreshFromServer()
      return
    }
  }

  render() {
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
          <br />
          <CardTopLevel>
            <LoginForm />
          </CardTopLevel>
        </div>
      </div>
    )
  }
}

export default withRouter(withUserConfig(PageLoginForm))
