import React from 'react'
import PropTypes from 'prop-types'

import styles from './FlashMessage.module.css'

const FlashMessage = ({ message, className = '', ...props }) =>
  !message ? null : (
    <div className={`${styles.FlashMessage} ${className}`} {...props}>
      {message}
    </div>
  )

FlashMessage.propTypes = {
  message: PropTypes.node,
  className: PropTypes.string,
}

export default FlashMessage
