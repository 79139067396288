import React from 'react'
import PropTypes from 'prop-types'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import Button from '@mui/material/Button'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Check from '@mui/icons-material/Check'

import { useAbortSignal } from '../../AppFetch.js'
import { renderEditableTable } from './EditableTableUtils.jsx'
import NotBalancePostEditor from './NotBalancePostEditor.jsx'
import NotLangfristigaSkulderEditor from './NotLangfristigaSkulderEditor.jsx'
import NotRedovisningsprinciperEditor from './NotRedovisningsprinciperEditor.jsx'
import { notLangfristigaSkulderVisible } from '../../../shared-universal/AnnualReportUtils.js'
import NotUpplysningModerforetagEditor from './NotUpplysningModerforetagEditor.jsx'
import NotVasentligaHandelserRakenskapsaretsSlutEditor from './NotVasentligaHandelserRakenskapsaretsSlutEditor.jsx'
import NotAndraOvrigaUpplysningarEditor from './NotAndraOvrigaUpplysningarEditor.jsx'
import { loadVisibleBalanceNoteSpecs } from '../../../shared-universal/BalanceNoteSpecs.js'
import EventualForpliktelserEditor from './EventualForpliktelserEditor.jsx'
import StalldaSakerheterEditor from './StalldaSakerheterEditor.jsx'
import CheckrakningskreditEditor from './CheckrakningskreditEditor.jsx'
import RmCard from './RmCard.jsx'
import RmTheme from '../../../shared-universal/RmTheme.js'
import RmEditorHeading2 from './RmEditorHeading2.jsx'
import NoteHeading from './NoteHeading.jsx'
import API from '../../API.js'
import RmTooltip from './RmTooltip.jsx'
import { loadAllBalanceNoteSpecs } from '../../../shared-universal/BalanceNoteSpecs.js'

const NotesEditor = ({ annualReport, onChange, isApproved }) => {
  const abortSignal = useAbortSignal()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const OPTIONAL_NOTES = [
    ...loadAllBalanceNoteSpecs(annualReport.clientBalanceSheetTemplate).map((bsNoteSpec) => ({
      title: bsNoteSpec.title,
      xbrlName: `Not${bsNoteSpec.balanceXbrlName}`,
      mandatoryForBalancePosts: [bsNoteSpec.balanceXbrlName],
    })),
    {
      title: 'Checkräkningskredit',
      xbrlName: 'NotCheckrakningskredit',
      mandatoryForBalancePosts: ['CheckrakningskreditLangfristig', 'CheckrakningskreditKortfristig'],
    },
    {
      title: 'Ställda säkerheter',
      xbrlName: 'NotStalldaSakerheter',
    },
    {
      title: 'Eventualförpliktelser',
      xbrlName: 'NotEventualforpliktelser',
    },
    {
      title: 'Upplysning om moderföretag',
      xbrlName: 'NotUpplysningModerforetag',
    },
    {
      title: 'Väsentliga händelser efter räkenskapsårets slut',
      xbrlName: 'NotVasentligaHandelserRakenskapsaretsSlut',
    },
    {
      title: 'Andra övriga upplysningar',
      xbrlName: 'NotAndraOvrigaUpplysningar',
    },
  ]

  return (
    <div
      style={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: RmTheme.VERTICAL_SPACING_MEDIUM }}
    >
      <div
        style={{
          background: '#e6e6e6',
          paddingLeft: '16px',
          paddingRight: '16px',
          paddingTop: '7px',
          paddingBottom: '7px',
          top: 0,
          marginTop: '-16px',
          position: 'sticky',
          zIndex: 2,
          marginLeft: '-16px',
          marginRight: '-16px',
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Button
          variant="outlined"
          onClick={(event) => {
            setAnchorEl(event.currentTarget)
          }}
          disabled={isApproved}
        >
          Visa fler noter
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={() => {
            setAnchorEl(null)
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuList dense>
            {OPTIONAL_NOTES.map((opNoteSpec) => {
              let menuItemDisabled = false
              let noteEditSectionVisible = annualReport.infogadeNoter[opNoteSpec.xbrlName]
              if (opNoteSpec.mandatoryForBalancePosts) {
                const mandatoryVisibility = opNoteSpec.mandatoryForBalancePosts.some((balanceXbrlName) =>
                  Boolean(annualReport.financialYears[0].xbrlValues[balanceXbrlName]?.value)
                )
                noteEditSectionVisible ||= mandatoryVisibility
                menuItemDisabled = mandatoryVisibility
              }

              return (
                <RmTooltip
                  title={'Noten måste redovisas eftersom det finns ett tillhörande saldo i balansräkningen.'}
                  visible={menuItemDisabled}
                  key={opNoteSpec.xbrlName}
                >
                  <MenuItem
                    disabled={menuItemDisabled}
                    onClick={() => {
                      API.saveAnnualReportValue(
                        annualReport.reportId,
                        'infogadeNoter',
                        { [opNoteSpec.xbrlName]: !annualReport.infogadeNoter[opNoteSpec.xbrlName] },
                        abortSignal,
                        onChange,
                        { immediate: true }
                      )
                    }}
                  >
                    {noteEditSectionVisible ? (
                      <React.Fragment>
                        <ListItemIcon>
                          <Check />
                        </ListItemIcon>
                        {opNoteSpec.title}
                      </React.Fragment>
                    ) : (
                      <ListItemText inset>{opNoteSpec.title}</ListItemText>
                    )}
                  </MenuItem>
                </RmTooltip>
              )
            })}
          </MenuList>
        </Menu>
      </div>
      <RmCard>
        <NotRedovisningsprinciperEditor annualReport={annualReport} onChange={onChange} disabled={isApproved} />
      </RmCard>
      <RmCard>
        <RmEditorHeading2>Medelantal anställda</RmEditorHeading2>
        <table>
          <tbody>
            {renderEditableTable(
              annualReport.clientAverageEmployeeCountTemplate,
              annualReport.financialYears.slice(0, 1),
              {
                fyTitleType: 'duration',
                readOnlyColumnCount: 0,
                forceAllDisabled: isApproved,
                firstCellStyle: { width: '100%' },
                abortSignal,
                onChange,
              },
              0
            )}
          </tbody>
        </table>
      </RmCard>

      {loadVisibleBalanceNoteSpecs(annualReport, annualReport.clientBalanceSheetTemplate).map((balanceNoteSpec) => {
        return (
          <RmCard key={balanceNoteSpec.balanceXbrlName}>
            <NoteHeading
              title={balanceNoteSpec.title}
              closeButton={!annualReport.financialYears[0].xbrlValues[balanceNoteSpec.balanceXbrlName]?.value}
              noteXbrlName={`Not${balanceNoteSpec.balanceXbrlName}`}
              annualReport={annualReport}
              onChange={onChange}
            />
            <NotBalancePostEditor
              balanceXbrl={balanceNoteSpec.balanceXbrlName}
              annualReport={annualReport}
              onChange={onChange}
              disabled={isApproved}
            />
          </RmCard>
        )
      })}

      {notLangfristigaSkulderVisible(annualReport.financialYears) && (
        <RmCard>
          <NotLangfristigaSkulderEditor annualReport={annualReport} onChange={onChange} disabled={isApproved} />
        </RmCard>
      )}

      {(annualReport.infogadeNoter['NotCheckrakningskredit'] ||
        Boolean(annualReport.financialYears[0].xbrlValues['CheckrakningskreditLangfristig']?.value) ||
        Boolean(annualReport.financialYears[0].xbrlValues['CheckrakningskreditKortfristig']?.value)) && (
        <RmCard>
          <CheckrakningskreditEditor annualReport={annualReport} onChange={onChange} disabled={isApproved} />
        </RmCard>
      )}

      {annualReport.infogadeNoter['NotStalldaSakerheter'] && (
        <RmCard>
          <StalldaSakerheterEditor annualReport={annualReport} onChange={onChange} disabled={isApproved} />
        </RmCard>
      )}

      {annualReport.infogadeNoter['NotEventualforpliktelser'] && (
        <RmCard>
          <EventualForpliktelserEditor annualReport={annualReport} onChange={onChange} disabled={isApproved} />
        </RmCard>
      )}

      {annualReport.infogadeNoter['NotUpplysningModerforetag'] && (
        <RmCard>
          <NotUpplysningModerforetagEditor annualReport={annualReport} onChange={onChange} disabled={isApproved} />
        </RmCard>
      )}

      {annualReport.infogadeNoter['NotVasentligaHandelserRakenskapsaretsSlut'] && (
        <RmCard>
          <NotVasentligaHandelserRakenskapsaretsSlutEditor
            annualReport={annualReport}
            onChange={onChange}
            disabled={isApproved}
          />
        </RmCard>
      )}

      {annualReport.infogadeNoter['NotAndraOvrigaUpplysningar'] && (
        <RmCard>
          <NotAndraOvrigaUpplysningarEditor annualReport={annualReport} onChange={onChange} disabled={isApproved} />
        </RmCard>
      )}
    </div>
  )
}

NotesEditor.propTypes = {
  annualReport: PropTypes.object,
  onChange: PropTypes.func,
  isApproved: PropTypes.bool.isRequired,
}

export default NotesEditor
