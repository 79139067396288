import React from 'react'

import { useAppFetch } from '../../AppFetch.js'
import SpinnerBlock from '../generic/SpinnerBlock.jsx'
import HttpErrorCodes from '../../../shared-universal/HttpErrorCodes.js'
import AppBuildConfig from '../../../shared-universal/AppBuildConfig.js'

const PageConfirmEmail = () => {
  const token = new URLSearchParams(location.search).get('token')
  const { loading, responseJson } = useAppFetch(`/api/confirm-email/${token}`, { method: 'POST' })

  if (loading) {
    return <SpinnerBlock />
  }

  if (responseJson?.errorCode === HttpErrorCodes.EMAIL_CONFIRMATION_TOKEN_EXPIRED) {
    return <div>Denna bekräftelselänk för e-post är för gammal, begär en ny i appen.</div>
  }

  if (responseJson?.errorCode) {
    return <div>Ogiltigt token, kunde inte bekräfta e-post.</div>
  }

  return (
    <div style={{ padding: '32px' }}>
      <h3>{AppBuildConfig.APP_NAME}</h3>
      <div>Din e-post är nu bekräftad.</div>
    </div>
  )
}

export default PageConfirmEmail
