import React from 'react'
import PropTypes from 'prop-types'

const RemakerReceipt = ({ annualReport }) => {
  const convertReceiptUrlToReceiptPdfUrl = (receiptUrl) => receiptUrl.replace('?', '/pdf?')

  return (
    <React.Fragment>
      {annualReport.goodToGo && (
        <p>
          Betalning godkänd.
          {(annualReport.invoiceUrl || annualReport.receiptUrl) && <span> Ladda hem </span>}
          {annualReport.invoiceUrl && <a href={annualReport.invoiceUrl}>faktura</a>}{' '}
          {annualReport.invoiceUrl && annualReport.receiptUrl && <span>eller</span>}{' '}
          {annualReport.receiptUrl && <a href={convertReceiptUrlToReceiptPdfUrl(annualReport.receiptUrl)}>kvitto</a>}
        </p>
      )}
    </React.Fragment>
  )
}

RemakerReceipt.propTypes = {
  annualReport: PropTypes.object.isRequired,
}

RemakerReceipt.defaultProps = {}

export default RemakerReceipt
