import React from 'react'
import { getUrlParam } from './UrlUtils.js'
import { consoleLog } from '../shared-universal/SanctionedConsole.js'
import { sleepMillis } from '../shared-universal/Utils.js'
import { isJsonString } from '../shared-universal/Utils.js'
import { useRef, useEffect, useState, useCallback } from 'react'
import isDeepEqual from 'fast-deep-equal/react.js'
import { clog, submitCrashReport } from './LoggingUtils.js'
import BaseError from '../shared-universal/BaseError.js'

function sleepIfSlowdownParameter(...args) {
  const first = args[0]
  const slowMillis = getUrlParam('slowms')
  const slowTarget = getUrlParam('slowtarget')
  const url = typeof first == 'string' ? first : first.url
  const urlMinusQueryString = url.includes('?') ? url.split('?')[0] : url
  const makeSlow = slowMillis && slowTarget && urlMinusQueryString == slowTarget
  if (slowMillis || slowTarget) {
    consoleLog(
      '[FETCH] slowtarget: ' +
        slowTarget +
        ', target: ' +
        urlMinusQueryString +
        ', will slowdown:        ' +
        makeSlow +
        '        url: ' +
        url
    )
  }
  if (makeSlow) {
    return sleepMillis(slowMillis)
  }
  return Promise.resolve()
}

async function fetchWrapper(...args) {
  await sleepIfSlowdownParameter(...args)
  return await fetch(...args)
}

// If you're using Chrome the time taken for each request is presented in the "Time" column of the network tab.
// The first number is "total time taken until the last byte of the response was received" and the second
// smaller number is "TTFB, time to first byte".
// The clogFetchRequestDetails() can be enabled if you need to debug request times in older or mobile browsers
// where the devtools are less helpful.
const clogFetchRequestDetails = (startTimestampMs, input, fetchOptions) => {
  clog(`[FETCH] took ${Date.now() - startTimestampMs} ms for request to ${input}`, fetchOptions)
}

async function doFetchJson(input, fetchOptions = {}) {
  const contentType = fetchOptions.headers && fetchOptions.headers['Content-Type']
  const hasJsonBody = fetchOptions.body && isJsonString(fetchOptions.body)
  if (hasJsonBody && !contentType) {
    fetchOptions = {
      ...fetchOptions,
      headers: {
        ...fetchOptions.headers,
        'Content-Type': 'application/json;charset=utf-8',
      },
    }
  }
  try {
    const startTimestampMs = Date.now()
    const resp = await fetchWrapper(input, fetchOptions)
    const responseText = await resp.text()
    clogFetchRequestDetails(startTimestampMs, input, fetchOptions)
    let responseJson
    try {
      responseJson = JSON.parse(responseText)
    } catch (err) {
      if (err.name !== 'SyntaxError') {
        throw err
      }
    }
    return { status: resp.status, responseText, responseJson }
  } catch (err) {
    if (err.name == 'AbortError') {
      // Abort promise chain by returning a promise that will never resolve
      // nor reject. This is slightly quirky, but the alternative is to wrap
      // all appFetch() calls in try/catch which gets quite ugly/verbose.
      return new Promise((resolve) => {})
    }
    throw err
  }
}

async function doFetchBlob(...args) {
  try {
    const startTimestampMs = Date.now()
    const resp = await fetchWrapper(...args)
    const responseBlob = await resp.blob()
    clogFetchRequestDetails(startTimestampMs, ...args)
    return { status: resp.status, responseBlob }
  } catch (err) {
    if (err.name == 'AbortError') {
      // Abort promise chain by returning a promise that will never resolve
      // nor reject. This is slightly quirky, but the alternative is to wrap
      // all appFetch() calls in try/catch which gets quite ugly/verbose.
      return new Promise((resolve) => {})
    }
    throw err
  }
}

async function appFetch(input, fetchOptions = {}) {
  if (!fetchOptions || !fetchOptions.signal) {
    throw Error('fetch call without AbortSignal set')
  }
  const responseObj = await doFetchJson(input, { credentials: 'same-origin', ...fetchOptions })
  if (responseObj.status === 401) {
    location.href = '/'
    throw Error('unexpected 401, redirecting to root')
  } else if (responseObj.status === 403) {
    location.href = '/subscription'
    throw Error('unexpected 403, redirecting to subscription status')
  }
  return responseObj
}

const useAbortSignal = () => {
  const controllerRef = useRef(new AbortController())
  useEffect(() => {
    const controller = controllerRef.current
    return () => {
      controller.abort()
    }
  }, [])
  return controllerRef.current.signal
}

class UnexpectedHttpStatusError extends BaseError {
  constructor(httpStatus, errorMessage = '') {
    super(`HTTP status ${httpStatus}`)
    if (typeof httpStatus !== 'number') {
      throw Error('httpStatus must be a number')
    }
    this.httpStatus = httpStatus
    this.errorMessage = errorMessage
  }
}

function useAppFetch(url, fetchOptions = {}) {
  if (process.env.NODE_ENV !== 'production' && fetchOptions.signal) {
    throw Error(
      'options passed into useAppFetch() should never contain a "signal" since this is added automatically by the useAppFetch hook'
    )
  }
  const signal = useAbortSignal()
  const fetchOptionsRef = useRef(null)
  const newFetchOptions = !isDeepEqual(fetchOptionsRef.current, fetchOptions)
  if (newFetchOptions) {
    fetchOptionsRef.current = fetchOptions
  }
  const [requestInfo, setRequestInfo] = useState({
    loading: true,
    status: undefined,
    responseJson: undefined,
    responseText: undefined,
    error: undefined,
  })
  // Because we did the isDeepEqual above, "fetchOptionsObj" will be a new
  // object if and only if we're rendering with a changed fetchOptions compared
  // to last time.
  const fetchOptionsObj = fetchOptionsRef.current
  const doFetch = useCallback(
    () =>
      appFetch(url, { ...fetchOptionsObj, signal })
        .then((result) =>
          setRequestInfo({
            loading: false,
            status: result.status,
            responseJson: result.responseJson,
            responseText: result.responseText,
            error: result.status !== 200 ? new UnexpectedHttpStatusError(result.status) : undefined,
          })
        )
        .catch((error) => {
          setRequestInfo({ loading: false, status: undefined, responseJson: undefined, responseText: undefined, error })
          submitCrashReport(error)
        }),
    [url, fetchOptionsObj, signal]
  )

  useEffect(() => {
    doFetch()
  }, [doFetch])
  return {
    ...requestInfo,
    setResponseJson: (responseJson) => setRequestInfo({ ...requestInfo, responseJson }),
    refetch: doFetch,
  }
}

const getRefetchIntervalFromPageAge = (pageAgeSeconds) => {
  if (pageAgeSeconds < 60) {
    return 2
  } else if (pageAgeSeconds < 300) {
    return 10
  } else if (pageAgeSeconds < 1200) {
    return 15
  } else if (pageAgeSeconds < 3600) {
    return 300
  }
  return 3_600
}

const useWaningPoller = (pollingActive, refetch) => {
  const initialRenderTimeRef = React.useRef(Date.now())

  useEffect(() => {
    let timerId

    const scheduleRefetch = () => {
      if (timerId) {
        clearTimeout(timerId)
      }
      const pageAgeSeconds = (Date.now() - initialRenderTimeRef.current) / 1000
      timerId = setTimeout(
        () => {
          refetch()
          scheduleRefetch()
        },
        1000 * getRefetchIntervalFromPageAge(pageAgeSeconds)
      )
    }

    if (pollingActive) {
      scheduleRefetch()
    }

    return () => {
      clearInterval(timerId)
    }
  }, [pollingActive, refetch])
}

export { doFetchJson, doFetchBlob, appFetch, useAbortSignal, useAppFetch, useWaningPoller }
