import React from 'react'
import PropTypes from 'prop-types'

import Spinner from './Spinner.jsx'

import styles from './SpinnerBlock.module.css'

const SpinnerBlock = (props) => {
  return (
    <div className={styles.SpinnerBlock}>
      <Spinner size={'5em'} color={'var(--primary-color)'} thickness={'.5em'} duration={'1s'} {...props} />
    </div>
  )
}

SpinnerBlock.propTypes = {
  delayBeforeVisibleMs: PropTypes.number,
}

export default SpinnerBlock
