import React from 'react'

import StripeProviderWrapper from '../StripeProviderWrapper.jsx'
import PageCheckoutWrapperInner from './PageCheckoutWrapperInner.jsx'

const PageCheckoutWrapper = () => {
  return (
    <StripeProviderWrapper isRootProvider={false}>
      <PageCheckoutWrapperInner />
    </StripeProviderWrapper>
  )
}

export default PageCheckoutWrapper
