import React from 'react'
import PropTypes from 'prop-types'

import { useAbortSignal } from '../../AppFetch.js'
import RmTextField from './RmTextField.jsx'
import API from '../../API.js'
import NoteHeading from './NoteHeading.jsx'
import EditableOrganizationNumber from './EditableOrganizationNumber.jsx'
import EditableDomicile from './EditableDomicile.jsx'

const NotUpplysningModerforetagEditor = ({ annualReport, onChange, disabled }) => {
  const abortSignal = useAbortSignal()
  return (
    <React.Fragment>
      <NoteHeading
        title="Upplysning om moderföretag"
        closeButton={true}
        noteXbrlName="NotUpplysningModerforetag"
        annualReport={annualReport}
        onChange={onChange}
      />
      <table>
        <tbody>
          <tr>
            <td style={{ width: '200px' }}>Företagsnamn</td>
            <td>
              <RmTextField
                defaultValue={annualReport.notUpplysningModerforetagNamn}
                onChange={(ev) => {
                  API.saveAnnualReportValue(
                    annualReport.reportId,
                    'notUpplysningModerforetagNamn',
                    ev.target.value,
                    abortSignal,
                    onChange
                  )
                }}
                disabled={disabled}
                style={{ width: '300px' }}
              />
            </td>
          </tr>
          <tr>
            <td>Organisationsnummer</td>
            <td>
              <EditableOrganizationNumber
                value={annualReport.notUpplysningModerforetagOrgNr}
                onChange={(ev) => {
                  API.saveAnnualReportValue(
                    annualReport.reportId,
                    'notUpplysningModerforetagOrgNr',
                    ev.target.value,
                    abortSignal,
                    onChange,
                    { immediate: true }
                  )
                }}
                disabled={disabled}
                style={{ width: '300px' }}
              />
            </td>
          </tr>
          <tr>
            <td>Säte</td>
            <td>
              <EditableDomicile
                defaultValue={annualReport.notUpplysningModerforetagSate}
                onChange={(newDomicile) => {
                  API.saveAnnualReportValue(
                    annualReport.reportId,
                    'notUpplysningModerforetagSate',
                    newDomicile,
                    abortSignal,
                    onChange
                  )
                }}
                disabled={disabled}
                className="automation-domicile"
                style={{ width: '300px' }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  )
}

NotUpplysningModerforetagEditor.propTypes = {
  annualReport: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default NotUpplysningModerforetagEditor
