import React from 'react'
import PropTypes from 'prop-types'

import styles from './RoundedImage.module.css'

// Image with slightly rounded corners,
// and which is not too big for mobile screens.
const RoundedImage = ({ className, ...props }) => {
  return <img className={`${styles.RoundedImage} ${className}`} {...props} />
}

RoundedImage.propTypes = {
  className: PropTypes.string,
}

RoundedImage.defaultProps = {
  className: '',
}

export default RoundedImage
