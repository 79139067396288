const allDomiciles = [
  {
    code: '1440',
    municipalityName: 'Ale kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1489',
    municipalityName: 'Alingsås kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '764',
    municipalityName: 'Alvesta kommun',
    countyName: 'Kronobergs län',
  },
  {
    code: '604',
    municipalityName: 'Aneby kommun',
    countyName: 'Jönköpings län',
  },
  {
    code: '1984',
    municipalityName: 'Arboga kommun',
    countyName: 'Västmanlands län',
  },
  {
    code: '2506',
    municipalityName: 'Arjeplogs kommun',
    countyName: 'Norrbottens län',
  },
  {
    code: '2505',
    municipalityName: 'Arvidsjaurs kommun',
    countyName: 'Norrbottens län',
  },
  {
    code: '1784',
    municipalityName: 'Arvika kommun',
    countyName: 'Värmlands län',
  },
  {
    code: '1882',
    municipalityName: 'Askersunds kommun',
    countyName: 'Örebro län',
  },
  {
    code: '2084',
    municipalityName: 'Avesta kommun',
    countyName: 'Dalarnas län',
  },
  {
    code: '1460',
    municipalityName: 'Bengtsfors kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '2326',
    municipalityName: 'Bergs kommun',
    countyName: 'Jämtlands län',
  },
  {
    code: '2403',
    municipalityName: 'Bjurholms kommun',
    countyName: 'Västerbottens län',
  },
  {
    code: '1260',
    municipalityName: 'Bjuvs kommun',
    countyName: 'Skåne län',
  },
  {
    code: '2582',
    municipalityName: 'Bodens kommun',
    countyName: 'Norrbottens län',
  },
  {
    code: '1443',
    municipalityName: 'Bollebygds kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '2183',
    municipalityName: 'Bollnäs kommun',
    countyName: 'Gävleborgs län',
  },
  {
    code: '885',
    municipalityName: 'Borgholms kommun',
    countyName: 'Kalmar län',
  },
  {
    code: '2081',
    municipalityName: 'Borlänge kommun',
    countyName: 'Dalarnas län',
  },
  {
    code: '1490',
    municipalityName: 'Borås kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '127',
    municipalityName: 'Botkyrka kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '560',
    municipalityName: 'Boxholms kommun',
    countyName: 'Östergötlands län',
  },
  {
    code: '1272',
    municipalityName: 'Bromölla kommun',
    countyName: 'Skåne län',
  },
  {
    code: '2305',
    municipalityName: 'Bräcke kommun',
    countyName: 'Jämtlands län',
  },
  {
    code: '1231',
    municipalityName: 'Burlövs kommun',
    countyName: 'Skåne län',
  },
  {
    code: '1278',
    municipalityName: 'Båstads kommun',
    countyName: 'Skåne län',
  },
  {
    code: '1438',
    municipalityName: 'Dals-Eds kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '162',
    municipalityName: 'Danderyds kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '1862',
    municipalityName: 'Degerfors kommun',
    countyName: 'Örebro län',
  },
  {
    code: '2425',
    municipalityName: 'Dorotea kommun',
    countyName: 'Västerbottens län',
  },
  {
    code: '1730',
    municipalityName: 'Eda kommun',
    countyName: 'Värmlands län',
  },
  {
    code: '125',
    municipalityName: 'Ekerö kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '686',
    municipalityName: 'Eksjö kommun',
    countyName: 'Jönköpings län',
  },
  {
    code: '862',
    municipalityName: 'Emmaboda kommun',
    countyName: 'Kalmar län',
  },
  {
    code: '381',
    municipalityName: 'Enköpings kommun',
    countyName: 'Uppsala län',
  },
  {
    code: '484',
    municipalityName: 'Eskilstuna kommun',
    countyName: 'Södermanlands län',
  },
  {
    code: '1285',
    municipalityName: 'Eslövs kommun',
    countyName: 'Skåne län',
  },
  {
    code: '1445',
    municipalityName: 'Essunga kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1982',
    municipalityName: 'Fagersta kommun',
    countyName: 'Västmanlands län',
  },
  {
    code: '1382',
    municipalityName: 'Falkenbergs kommun',
    countyName: 'Hallands län',
  },
  {
    code: '1499',
    municipalityName: 'Falköpings kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '2080',
    municipalityName: 'Falu kommun',
    countyName: 'Dalarnas län',
  },
  {
    code: '1782',
    municipalityName: 'Filipstads kommun',
    countyName: 'Värmlands län',
  },
  {
    code: '562',
    municipalityName: 'Finspångs kommun',
    countyName: 'Östergötlands län',
  },
  {
    code: '482',
    municipalityName: 'Flens kommun',
    countyName: 'Södermanlands län',
  },
  {
    code: '1763',
    municipalityName: 'Forshaga kommun',
    countyName: 'Värmlands län',
  },
  {
    code: '1439',
    municipalityName: 'Färgelanda kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '2026',
    municipalityName: 'Gagnefs kommun',
    countyName: 'Dalarnas län',
  },
  {
    code: '662',
    municipalityName: 'Gislaveds kommun',
    countyName: 'Jönköpings län',
  },
  {
    code: '461',
    municipalityName: 'Gnesta kommun',
    countyName: 'Södermanlands län',
  },
  {
    code: '617',
    municipalityName: 'Gnosjö kommun',
    countyName: 'Jönköpings län',
  },
  {
    code: '980',
    municipalityName: 'Gotlands kommun',
    countyName: 'Gotlands län',
  },
  {
    code: '1764',
    municipalityName: 'Grums kommun',
    countyName: 'Värmlands län',
  },
  {
    code: '1444',
    municipalityName: 'Grästorps kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1447',
    municipalityName: 'Gullspångs kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '2523',
    municipalityName: 'Gällivare kommun',
    countyName: 'Norrbottens län',
  },
  {
    code: '2180',
    municipalityName: 'Gävle kommun',
    countyName: 'Gävleborgs län',
  },
  {
    code: '1480',
    municipalityName: 'Göteborgs kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1471',
    municipalityName: 'Götene kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '643',
    municipalityName: 'Habo kommun',
    countyName: 'Jönköpings län',
  },
  {
    code: '1783',
    municipalityName: 'Hagfors kommun',
    countyName: 'Värmlands län',
  },
  {
    code: '1861',
    municipalityName: 'Hallsbergs kommun',
    countyName: 'Örebro län',
  },
  {
    code: '1961',
    municipalityName: 'Hallstahammars kommun',
    countyName: 'Västmanlands län',
  },
  {
    code: '1380',
    municipalityName: 'Halmstads kommun',
    countyName: 'Hallands län',
  },
  {
    code: '1761',
    municipalityName: 'Hammarö kommun',
    countyName: 'Värmlands län',
  },
  {
    code: '136',
    municipalityName: 'Haninge kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '2583',
    municipalityName: 'Haparanda kommun',
    countyName: 'Norrbottens län',
  },
  {
    code: '331',
    municipalityName: 'Heby kommun',
    countyName: 'Uppsala län',
  },
  {
    code: '2083',
    municipalityName: 'Hedemora kommun',
    countyName: 'Dalarnas län',
  },
  {
    code: '1283',
    municipalityName: 'Helsingborgs kommun',
    countyName: 'Skåne län',
  },
  {
    code: '1466',
    municipalityName: 'Herrljunga kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1497',
    municipalityName: 'Hjo kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '2104',
    municipalityName: 'Hofors kommun',
    countyName: 'Gävleborgs län',
  },
  {
    code: '126',
    municipalityName: 'Huddinge kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '2184',
    municipalityName: 'Hudiksvalls kommun',
    countyName: 'Gävleborgs län',
  },
  {
    code: '860',
    municipalityName: 'Hultsfreds kommun',
    countyName: 'Kalmar län',
  },
  {
    code: '1315',
    municipalityName: 'Hylte kommun',
    countyName: 'Hallands län',
  },
  {
    code: '305',
    municipalityName: 'Håbo kommun',
    countyName: 'Uppsala län',
  },
  {
    code: '1863',
    municipalityName: 'Hällefors kommun',
    countyName: 'Örebro län',
  },
  {
    code: '2361',
    municipalityName: 'Härjedalens kommun',
    countyName: 'Jämtlands län',
  },
  {
    code: '2280',
    municipalityName: 'Härnösands kommun',
    countyName: 'Västernorrlands län',
  },
  {
    code: '1401',
    municipalityName: 'Härryda kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1293',
    municipalityName: 'Hässleholms kommun',
    countyName: 'Skåne län',
  },
  {
    code: '1284',
    municipalityName: 'Höganäs kommun',
    countyName: 'Skåne län',
  },
  {
    code: '821',
    municipalityName: 'Högsby kommun',
    countyName: 'Kalmar län',
  },
  {
    code: '1266',
    municipalityName: 'Hörby kommun',
    countyName: 'Skåne län',
  },
  {
    code: '1267',
    municipalityName: 'Höörs kommun',
    countyName: 'Skåne län',
  },
  {
    code: '2510',
    municipalityName: 'Jokkmokks kommun',
    countyName: 'Norrbottens län',
  },
  {
    code: '123',
    municipalityName: 'Järfälla kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '680',
    municipalityName: 'Jönköpings kommun',
    countyName: 'Jönköpings län',
  },
  {
    code: '2514',
    municipalityName: 'Kalix kommun',
    countyName: 'Norrbottens län',
  },
  {
    code: '880',
    municipalityName: 'Kalmar kommun',
    countyName: 'Kalmar län',
  },
  {
    code: '1446',
    municipalityName: 'Karlsborgs kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1082',
    municipalityName: 'Karlshamns kommun',
    countyName: 'Blekinge län',
  },
  {
    code: '1883',
    municipalityName: 'Karlskoga kommun',
    countyName: 'Örebro län',
  },
  {
    code: '1080',
    municipalityName: 'Karlskrona kommun',
    countyName: 'Blekinge län',
  },
  {
    code: '1780',
    municipalityName: 'Karlstads kommun',
    countyName: 'Värmlands län',
  },
  {
    code: '483',
    municipalityName: 'Katrineholms kommun',
    countyName: 'Södermanlands län',
  },
  {
    code: '1715',
    municipalityName: 'Kils kommun',
    countyName: 'Värmlands län',
  },
  {
    code: '513',
    municipalityName: 'Kinda kommun',
    countyName: 'Östergötlands län',
  },
  {
    code: '2584',
    municipalityName: 'Kiruna kommun',
    countyName: 'Norrbottens län',
  },
  {
    code: '1276',
    municipalityName: 'Klippans kommun',
    countyName: 'Skåne län',
  },
  {
    code: '330',
    municipalityName: 'Knivsta kommun',
    countyName: 'Uppsala län',
  },
  {
    code: '2282',
    municipalityName: 'Kramfors kommun',
    countyName: 'Västernorrlands län',
  },
  {
    code: '1290',
    municipalityName: 'Kristianstads kommun',
    countyName: 'Skåne län',
  },
  {
    code: '1781',
    municipalityName: 'Kristinehamns kommun',
    countyName: 'Värmlands län',
  },
  {
    code: '2309',
    municipalityName: 'Krokoms kommun',
    countyName: 'Jämtlands län',
  },
  {
    code: '1881',
    municipalityName: 'Kumla kommun',
    countyName: 'Örebro län',
  },
  {
    code: '1384',
    municipalityName: 'Kungsbacka kommun',
    countyName: 'Hallands län',
  },
  {
    code: '1960',
    municipalityName: 'Kungsörs kommun',
    countyName: 'Västmanlands län',
  },
  {
    code: '1482',
    municipalityName: 'Kungälvs kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1261',
    municipalityName: 'Kävlinge kommun',
    countyName: 'Skåne län',
  },
  {
    code: '1983',
    municipalityName: 'Köpings kommun',
    countyName: 'Västmanlands län',
  },
  {
    code: '1381',
    municipalityName: 'Laholms kommun',
    countyName: 'Hallands län',
  },
  {
    code: '1282',
    municipalityName: 'Landskrona kommun',
    countyName: 'Skåne län',
  },
  {
    code: '1860',
    municipalityName: 'Laxå kommun',
    countyName: 'Örebro län',
  },
  {
    code: '1814',
    municipalityName: 'Lekebergs kommun',
    countyName: 'Örebro län',
  },
  {
    code: '2029',
    municipalityName: 'Leksands kommun',
    countyName: 'Dalarnas län',
  },
  {
    code: '1441',
    municipalityName: 'Lerums kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '761',
    municipalityName: 'Lessebo kommun',
    countyName: 'Kronobergs län',
  },
  {
    code: '186',
    municipalityName: 'Lidingö kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '1494',
    municipalityName: 'Lidköpings kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1462',
    municipalityName: 'Lilla Edets kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1885',
    municipalityName: 'Lindesbergs kommun',
    countyName: 'Örebro län',
  },
  {
    code: '580',
    municipalityName: 'Linköpings kommun',
    countyName: 'Östergötlands län',
  },
  {
    code: '781',
    municipalityName: 'Ljungby kommun',
    countyName: 'Kronobergs län',
  },
  {
    code: '2161',
    municipalityName: 'Ljusdals kommun',
    countyName: 'Gävleborgs län',
  },
  {
    code: '1864',
    municipalityName: 'Ljusnarsbergs kommun',
    countyName: 'Örebro län',
  },
  {
    code: '1262',
    municipalityName: 'Lomma kommun',
    countyName: 'Skåne län',
  },
  {
    code: '2085',
    municipalityName: 'Ludvika kommun',
    countyName: 'Dalarnas län',
  },
  {
    code: '2580',
    municipalityName: 'Luleå kommun',
    countyName: 'Norrbottens län',
  },
  {
    code: '1281',
    municipalityName: 'Lunds kommun',
    countyName: 'Skåne län',
  },
  {
    code: '2481',
    municipalityName: 'Lycksele kommun',
    countyName: 'Västerbottens län',
  },
  {
    code: '1484',
    municipalityName: 'Lysekils kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1280',
    municipalityName: 'Malmö kommun',
    countyName: 'Skåne län',
  },
  {
    code: '2023',
    municipalityName: 'Malung-Sälens kommun',
    countyName: 'Dalarnas län',
  },
  {
    code: '2418',
    municipalityName: 'Malå kommun',
    countyName: 'Västerbottens län',
  },
  {
    code: '1493',
    municipalityName: 'Mariestads kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1463',
    municipalityName: 'Marks kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '767',
    municipalityName: 'Markaryds kommun',
    countyName: 'Kronobergs län',
  },
  {
    code: '1461',
    municipalityName: 'Melleruds kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '586',
    municipalityName: 'Mjölby kommun',
    countyName: 'Östergötlands län',
  },
  {
    code: '2062',
    municipalityName: 'Mora kommun',
    countyName: 'Dalarnas län',
  },
  {
    code: '583',
    municipalityName: 'Motala kommun',
    countyName: 'Östergötlands län',
  },
  {
    code: '642',
    municipalityName: 'Mullsjö kommun',
    countyName: 'Jönköpings län',
  },
  {
    code: '1430',
    municipalityName: 'Munkedals kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1762',
    municipalityName: 'Munkfors kommun',
    countyName: 'Värmlands län',
  },
  {
    code: '1481',
    municipalityName: 'Mölndals kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '861',
    municipalityName: 'Mönsterås kommun',
    countyName: 'Kalmar län',
  },
  {
    code: '840',
    municipalityName: 'Mörbylånga kommun',
    countyName: 'Kalmar län',
  },
  {
    code: '182',
    municipalityName: 'Nacka kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '1884',
    municipalityName: 'Nora kommun',
    countyName: 'Örebro län',
  },
  {
    code: '1962',
    municipalityName: 'Norbergs kommun',
    countyName: 'Västmanlands län',
  },
  {
    code: '2132',
    municipalityName: 'Nordanstigs kommun',
    countyName: 'Gävleborgs län',
  },
  {
    code: '2401',
    municipalityName: 'Nordmalings kommun',
    countyName: 'Västerbottens län',
  },
  {
    code: '581',
    municipalityName: 'Norrköpings kommun',
    countyName: 'Östergötlands län',
  },
  {
    code: '188',
    municipalityName: 'Norrtälje kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '2417',
    municipalityName: 'Norsjö kommun',
    countyName: 'Västerbottens län',
  },
  {
    code: '881',
    municipalityName: 'Nybro kommun',
    countyName: 'Kalmar län',
  },
  {
    code: '140',
    municipalityName: 'Nykvarns kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '480',
    municipalityName: 'Nyköpings kommun',
    countyName: 'Södermanlands län',
  },
  {
    code: '192',
    municipalityName: 'Nynäshamns kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '682',
    municipalityName: 'Nässjö kommun',
    countyName: 'Jönköpings län',
  },
  {
    code: '2101',
    municipalityName: 'Ockelbo kommun',
    countyName: 'Gävleborgs län',
  },
  {
    code: '1060',
    municipalityName: 'Olofströms kommun',
    countyName: 'Blekinge län',
  },
  {
    code: '2034',
    municipalityName: 'Orsa kommun',
    countyName: 'Dalarnas län',
  },
  {
    code: '1421',
    municipalityName: 'Orusts kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1273',
    municipalityName: 'Osby kommun',
    countyName: 'Skåne län',
  },
  {
    code: '882',
    municipalityName: 'Oskarshamns kommun',
    countyName: 'Kalmar län',
  },
  {
    code: '2121',
    municipalityName: 'Ovanåkers kommun',
    countyName: 'Gävleborgs län',
  },
  {
    code: '481',
    municipalityName: 'Oxelösunds kommun',
    countyName: 'Södermanlands län',
  },
  {
    code: '2521',
    municipalityName: 'Pajala kommun',
    countyName: 'Norrbottens län',
  },
  {
    code: '1402',
    municipalityName: 'Partille kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1275',
    municipalityName: 'Perstorps kommun',
    countyName: 'Skåne län',
  },
  {
    code: '2581',
    municipalityName: 'Piteå kommun',
    countyName: 'Norrbottens län',
  },
  {
    code: '2303',
    municipalityName: 'Ragunda kommun',
    countyName: 'Jämtlands län',
  },
  {
    code: '2409',
    municipalityName: 'Robertsfors kommun',
    countyName: 'Västerbottens län',
  },
  {
    code: '1081',
    municipalityName: 'Ronneby kommun',
    countyName: 'Blekinge län',
  },
  {
    code: '2031',
    municipalityName: 'Rättviks kommun',
    countyName: 'Dalarnas län',
  },
  {
    code: '1981',
    municipalityName: 'Sala kommun',
    countyName: 'Västmanlands län',
  },
  {
    code: '128',
    municipalityName: 'Salems kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '2181',
    municipalityName: 'Sandvikens kommun',
    countyName: 'Gävleborgs län',
  },
  {
    code: '191',
    municipalityName: 'Sigtuna kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '1291',
    municipalityName: 'Simrishamns kommun',
    countyName: 'Skåne län',
  },
  {
    code: '1265',
    municipalityName: 'Sjöbo kommun',
    countyName: 'Skåne län',
  },
  {
    code: '1495',
    municipalityName: 'Skara kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '2482',
    municipalityName: 'Skellefteå kommun',
    countyName: 'Västerbottens län',
  },
  {
    code: '1904',
    municipalityName: 'Skinnskattebergs kommun',
    countyName: 'Västmanlands län',
  },
  {
    code: '1264',
    municipalityName: 'Skurups kommun',
    countyName: 'Skåne län',
  },
  {
    code: '1496',
    municipalityName: 'Skövde kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '2061',
    municipalityName: 'Smedjebackens kommun',
    countyName: 'Dalarnas län',
  },
  {
    code: '2283',
    municipalityName: 'Sollefteå kommun',
    countyName: 'Västernorrlands län',
  },
  {
    code: '163',
    municipalityName: 'Sollentuna kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '184',
    municipalityName: 'Solna kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '2422',
    municipalityName: 'Sorsele kommun',
    countyName: 'Västerbottens län',
  },
  {
    code: '1427',
    municipalityName: 'Sotenäs kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1230',
    municipalityName: 'Staffanstorps kommun',
    countyName: 'Skåne län',
  },
  {
    code: '1415',
    municipalityName: 'Stenungsunds kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '180',
    municipalityName: 'Stockholms kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '1760',
    municipalityName: 'Storfors kommun',
    countyName: 'Värmlands län',
  },
  {
    code: '2421',
    municipalityName: 'Storumans kommun',
    countyName: 'Västerbottens län',
  },
  {
    code: '486',
    municipalityName: 'Strängnäs kommun',
    countyName: 'Södermanlands län',
  },
  {
    code: '1486',
    municipalityName: 'Strömstads kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '2313',
    municipalityName: 'Strömsunds kommun',
    countyName: 'Jämtlands län',
  },
  {
    code: '183',
    municipalityName: 'Sundbybergs kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '2281',
    municipalityName: 'Sundsvalls kommun',
    countyName: 'Västernorrlands län',
  },
  {
    code: '1766',
    municipalityName: 'Sunne kommun',
    countyName: 'Värmlands län',
  },
  {
    code: '1907',
    municipalityName: 'Surahammars kommun',
    countyName: 'Västmanlands län',
  },
  {
    code: '1214',
    municipalityName: 'Svalövs kommun',
    countyName: 'Skåne län',
  },
  {
    code: '1263',
    municipalityName: 'Svedala kommun',
    countyName: 'Skåne län',
  },
  {
    code: '1465',
    municipalityName: 'Svenljunga kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1785',
    municipalityName: 'Säffle kommun',
    countyName: 'Värmlands län',
  },
  {
    code: '2082',
    municipalityName: 'Säters kommun',
    countyName: 'Dalarnas län',
  },
  {
    code: '684',
    municipalityName: 'Sävsjö kommun',
    countyName: 'Jönköpings län',
  },
  {
    code: '2182',
    municipalityName: 'Söderhamns kommun',
    countyName: 'Gävleborgs län',
  },
  {
    code: '582',
    municipalityName: 'Söderköpings kommun',
    countyName: 'Östergötlands län',
  },
  {
    code: '181',
    municipalityName: 'Södertälje kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '1083',
    municipalityName: 'Sölvesborgs kommun',
    countyName: 'Blekinge län',
  },
  {
    code: '1435',
    municipalityName: 'Tanums kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1472',
    municipalityName: 'Tibro kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1498',
    municipalityName: 'Tidaholms kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '360',
    municipalityName: 'Tierps kommun',
    countyName: 'Uppsala län',
  },
  {
    code: '2262',
    municipalityName: 'Timrå kommun',
    countyName: 'Västernorrlands län',
  },
  {
    code: '763',
    municipalityName: 'Tingsryds kommun',
    countyName: 'Kronobergs län',
  },
  {
    code: '1419',
    municipalityName: 'Tjörns kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1270',
    municipalityName: 'Tomelilla kommun',
    countyName: 'Skåne län',
  },
  {
    code: '1737',
    municipalityName: 'Torsby kommun',
    countyName: 'Värmlands län',
  },
  {
    code: '834',
    municipalityName: 'Torsås kommun',
    countyName: 'Kalmar län',
  },
  {
    code: '1452',
    municipalityName: 'Tranemo kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '687',
    municipalityName: 'Tranås kommun',
    countyName: 'Jönköpings län',
  },
  {
    code: '1287',
    municipalityName: 'Trelleborgs kommun',
    countyName: 'Skåne län',
  },
  {
    code: '1488',
    municipalityName: 'Trollhättans kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '488',
    municipalityName: 'Trosa kommun',
    countyName: 'Södermanlands län',
  },
  {
    code: '138',
    municipalityName: 'Tyresö kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '160',
    municipalityName: 'Täby kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '1473',
    municipalityName: 'Töreboda kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1485',
    municipalityName: 'Uddevalla kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1491',
    municipalityName: 'Ulricehamns kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '2480',
    municipalityName: 'Umeå kommun',
    countyName: 'Västerbottens län',
  },
  {
    code: '114',
    municipalityName: 'Upplands Väsby kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '139',
    municipalityName: 'Upplands-Bro kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '380',
    municipalityName: 'Uppsala kommun',
    countyName: 'Uppsala län',
  },
  {
    code: '760',
    municipalityName: 'Uppvidinge kommun',
    countyName: 'Kronobergs län',
  },
  {
    code: '584',
    municipalityName: 'Vadstena kommun',
    countyName: 'Östergötlands län',
  },
  {
    code: '665',
    municipalityName: 'Vaggeryds kommun',
    countyName: 'Jönköpings län',
  },
  {
    code: '563',
    municipalityName: 'Valdemarsviks kommun',
    countyName: 'Östergötlands län',
  },
  {
    code: '115',
    municipalityName: 'Vallentuna kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '2021',
    municipalityName: 'Vansbro kommun',
    countyName: 'Dalarnas län',
  },
  {
    code: '1470',
    municipalityName: 'Vara kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1383',
    municipalityName: 'Varbergs kommun',
    countyName: 'Hallands län',
  },
  {
    code: '187',
    municipalityName: 'Vaxholms kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '1233',
    municipalityName: 'Vellinge kommun',
    countyName: 'Skåne län',
  },
  {
    code: '685',
    municipalityName: 'Vetlanda kommun',
    countyName: 'Jönköpings län',
  },
  {
    code: '2462',
    municipalityName: 'Vilhelmina kommun',
    countyName: 'Västerbottens län',
  },
  {
    code: '884',
    municipalityName: 'Vimmerby kommun',
    countyName: 'Kalmar län',
  },
  {
    code: '2404',
    municipalityName: 'Vindelns kommun',
    countyName: 'Västerbottens län',
  },
  {
    code: '428',
    municipalityName: 'Vingåkers kommun',
    countyName: 'Södermanlands län',
  },
  {
    code: '1442',
    municipalityName: 'Vårgårda kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '1487',
    municipalityName: 'Vänersborgs kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '2460',
    municipalityName: 'Vännäs kommun',
    countyName: 'Västerbottens län',
  },
  {
    code: '120',
    municipalityName: 'Värmdö kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '683',
    municipalityName: 'Värnamo kommun',
    countyName: 'Jönköpings län',
  },
  {
    code: '883',
    municipalityName: 'Västerviks kommun',
    countyName: 'Kalmar län',
  },
  {
    code: '1980',
    municipalityName: 'Västerås kommun',
    countyName: 'Västmanlands län',
  },
  {
    code: '780',
    municipalityName: 'Växjö kommun',
    countyName: 'Kronobergs län',
  },
  {
    code: '512',
    municipalityName: 'Ydre kommun',
    countyName: 'Östergötlands län',
  },
  {
    code: '1286',
    municipalityName: 'Ystads kommun',
    countyName: 'Skåne län',
  },
  {
    code: '1492',
    municipalityName: 'Åmåls kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '2260',
    municipalityName: 'Ånge kommun',
    countyName: 'Västernorrlands län',
  },
  {
    code: '2321',
    municipalityName: 'Åre kommun',
    countyName: 'Jämtlands län',
  },
  {
    code: '1765',
    municipalityName: 'Årjängs kommun',
    countyName: 'Värmlands län',
  },
  {
    code: '2463',
    municipalityName: 'Åsele kommun',
    countyName: 'Västerbottens län',
  },
  {
    code: '1277',
    municipalityName: 'Åstorps kommun',
    countyName: 'Skåne län',
  },
  {
    code: '561',
    municipalityName: 'Åtvidabergs kommun',
    countyName: 'Östergötlands län',
  },
  {
    code: '765',
    municipalityName: 'Älmhults kommun',
    countyName: 'Kronobergs län',
  },
  {
    code: '2039',
    municipalityName: 'Älvdalens kommun',
    countyName: 'Dalarnas län',
  },
  {
    code: '319',
    municipalityName: 'Älvkarleby kommun',
    countyName: 'Uppsala län',
  },
  {
    code: '2560',
    municipalityName: 'Älvsbyns kommun',
    countyName: 'Norrbottens län',
  },
  {
    code: '1292',
    municipalityName: 'Ängelholms kommun',
    countyName: 'Skåne län',
  },
  {
    code: '1407',
    municipalityName: 'Öckerö kommun',
    countyName: 'Västra Götalands län',
  },
  {
    code: '509',
    municipalityName: 'Ödeshögs kommun',
    countyName: 'Östergötlands län',
  },
  {
    code: '1880',
    municipalityName: 'Örebro kommun',
    countyName: 'Örebro län',
  },
  {
    code: '1257',
    municipalityName: 'Örkelljunga kommun',
    countyName: 'Skåne län',
  },
  {
    code: '2284',
    municipalityName: 'Örnsköldsviks kommun',
    countyName: 'Västernorrlands län',
  },
  {
    code: '2380',
    municipalityName: 'Östersunds kommun',
    countyName: 'Jämtlands län',
  },
  {
    code: '117',
    municipalityName: 'Österåkers kommun',
    countyName: 'Stockholms län',
  },
  {
    code: '382',
    municipalityName: 'Östhammars kommun',
    countyName: 'Uppsala län',
  },
  {
    code: '1256',
    municipalityName: 'Östra Göinge kommun',
    countyName: 'Skåne län',
  },
  {
    code: '2513',
    municipalityName: 'Överkalix kommun',
    countyName: 'Norrbottens län',
  },
  {
    code: '2518',
    municipalityName: 'Övertorneå kommun',
    countyName: 'Norrbottens län',
  },
]

export { allDomiciles }
