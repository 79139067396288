import React from 'react'

import { useAppFetch } from '../../AppFetch.js'
import SpinnerBlock from '../generic/SpinnerBlock.jsx'

const PageTokenLogin = () => {
  const token = new URLSearchParams(window.location.search).get('token')
  const reportId = new URLSearchParams(window.location.search).get('rid')

  const { loading, status } = useAppFetch(`/api/login`, {
    method: 'POST',
    body: JSON.stringify({ token }),
  })

  if (loading) {
    return <SpinnerBlock />
  }

  if (status != 200) {
    return <div>Inloggningen misslyckades.</div>
  }

  if (reportId) {
    window.location.href = `/edit-report/${reportId}`
  } else {
    window.location.href = '/'
  }

  return null
}

PageTokenLogin.propTypes = {}

export default PageTokenLogin
