import PropTypes from 'prop-types'
import React from 'react'

import styles from './Tabs.module.css'

class Tab extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    tabButtonClassName: PropTypes.string,
    onClick: PropTypes.func.isRequired,
  }

  static defaultProps = {
    onClick: () => {},
  }

  render() {
    return <div className={this.props.className}>{this.props.children}</div>
  }
}

class Tabs extends React.Component {
  static propTypes = {
    activeTabColor: PropTypes.string,
    inactiveTabColor: PropTypes.string,
    className: PropTypes.string,
    tabContentClassName: PropTypes.string,
    children: PropTypes.node,
    initialTabIndex: PropTypes.number,
  }

  static defaultProps = {
    activeTabColor: 'lightgray',
    inactiveTabColor: 'darkgray',
    initialTabIndex: 0,
  }

  constructor(props) {
    super(props)
    this.state = {
      activeTabIndex: this.props.initialTabIndex,
    }
  }

  render() {
    return (
      <div className={`${styles.Tabs} ${this.props.className}`}>
        <div className={styles.TabButtonBar}>
          {React.Children.map(this.props.children, (tab, idx) => {
            const isActiveTab = idx === this.state.activeTabIndex
            const extraClassName = isActiveTab ? styles.SelectedTabBarButton : ''
            return (
              <div
                className={`${styles.TabBarButton} ${extraClassName} ${tab.props.tabButtonClassName}`}
                style={{ backgroundColor: isActiveTab ? this.props.activeTabColor : this.props.inactiveTabColor }}
                onClick={() => {
                  this.setState({ activeTabIndex: idx })
                  tab.props.onClick()
                }}
              >
                {tab.props.label}
              </div>
            )
          })}
        </div>
        <div
          className={`${styles.SelectedTabPanel} ${this.props.tabContentClassName}`}
          style={{ backgroundColor: this.props.activeTabColor }}
        >
          {this.props.children[this.state.activeTabIndex]}
        </div>
      </div>
    )
  }
}

export { Tabs, Tab }
