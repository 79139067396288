import React from 'react'
import PropTypes from 'prop-types'

import AppBuildConfig from '../../shared-universal/AppBuildConfig.js'

import styles from './AppTitle.module.css'

const AppTitle = ({ children }) => <div className={styles.AppTitle}>{AppBuildConfig.APP_NAME}</div>

AppTitle.propTypes = {
  children: PropTypes.node,
}

export default AppTitle
