const AppBuildConfig = {
  APP_ID: 'remaker',
  APP_NAME: 'Min Årsredovisning',
  COMPANY_NAME: 'P3G Innovation AB',
  COMPANY_ORGNR: '559378-7848',
  META_DESCRIPTION: '',
  APP_EMAIL_FROM: 'minarsredovisning.se <info@minarsredovisning.se>',
  SUPPORT_URL: 'mailto:info@minarsredovisning.se',
  SUPPORT_EMAIL: 'info@minarsredovisning.se',
  HOMEPAGE_URL: 'https://www.minarsredovisning.se',
  HOMEPAGE_URL_WITHOUT_SCHEME: 'minarsredovisning.se',
  ADDRESS_LINE1: '',
  ADDRESS_LINE2: '',
  ADDRESS_LINE3: '',
  TWITTER_USERNAME: '',
  SESSION_COOKIE_NAME: 'rm-session-cookie',
  IMAGEPICKER_TAB_LOCALSTORAGE_KEY: 'rm-imagepicker-tab',
  DEBUG_LOCALSTORAGE_KEY: 'rm-debug',

  // Light-weight mechanism to send and log client-side errors in the server log.
  // Can be used in parallell with, or also without, submitting to Sentry.
  LOG_JS_ERRORS_IN_SERVER_LOG: true,

  WEBSOCKETS_ENABLED: false,

  // NOTE: AppBuildConfigSensitive.js contains server-side only build
  //       config values (these values are never sent to client-side Javascript).
}

export default AppBuildConfig
