import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, Checkbox } from '@mui/material'
import API from '../../API.js'
import { useAbortSignal } from '../../AppFetch.js'
import RmTooltip from './RmTooltip.jsx'

const SectionApprovalPanel = ({
  annualReport,
  sectionId,
  onChange,
  isApprovableText,
  approveCheckboxText,
  disabled,
  selectedTabLintErrors,
}) => {
  const abortSignal = useAbortSignal()
  const currentApprovalObj = annualReport.sectionApprovals.find((appr) => appr.sectionId === sectionId)
  const [isApproved, setIsApproved] = useState(currentApprovalObj ? currentApprovalObj.isApproved : false)

  return (
    <div>
      {selectedTabLintErrors.length === 0 && <div>{isApprovableText}</div>}
      <RmTooltip title="Denna årsredovisning är låst." visible={annualReport.locked}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isApproved}
              onChange={(ev) => {
                setIsApproved(ev.target.checked)
                const newSectionApprovals = annualReport.sectionApprovals
                  .filter((appr) => appr.sectionId !== sectionId)
                  .concat([
                    {
                      sectionId,
                      isApproved: ev.target.checked,
                    },
                  ])
                API.saveAnnualReportValue(
                  annualReport.reportId,
                  'sectionApprovals',
                  newSectionApprovals,
                  abortSignal,
                  onChange,
                  { immediate: true }
                )
              }}
              color="primary"
              disabled={disabled}
              className={`automation-approve-checkbox-${sectionId}`}
            />
          }
          style={{ userSelect: 'none' }}
          label={approveCheckboxText}
        />
      </RmTooltip>
    </div>
  )
}

SectionApprovalPanel.propTypes = {
  annualReport: PropTypes.object.isRequired,
  sectionId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isApprovableText: PropTypes.string,
  approveCheckboxText: PropTypes.string,
  disabled: PropTypes.bool,
  selectedTabLintErrors: PropTypes.array.isRequired,
}

SectionApprovalPanel.defaultProps = {
  isApprovableText: 'Sidan är klar att godkännas.',
  approveCheckboxText: 'Jag har kontrollerat sidan och godkänner',
  disabled: false,
}

export default SectionApprovalPanel
