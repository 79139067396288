import React from 'react'
import PropTypes from 'prop-types'

const RmCard = ({ children, style, ...props }) => {
  return (
    <div style={{ background: '#e6e6e6', padding: '10px', borderRadius: '5px', ...style }} {...props}>
      {children}
    </div>
  )
}

RmCard.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
}

RmCard.defaultProps = {
  style: {},
}

export default RmCard
