import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'

import AppBuildConfig from '../shared-universal/AppBuildConfig.js'
import { UserConfigKeys } from '../shared-universal/ConstantsShared.js'
import { doFetchJson } from './AppFetch.js'
import { clog } from './LoggingUtils.js'

const UserConfigContext = React.createContext()

class UserConfigProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  }

  // It's a bit weird that the "set" method is inside state but this
  // approach is recommended by React documentation, see for example the
  // "toggleTheme: this.toggleTheme" part in:
  // https://reactjs.org/docs/context.html
  //
  // See also this blog post:
  // https://medium.com/@ryanflorence/react-context-and-re-renders-react-take-the-wheel-cd1d20663647

  initialState = {
    __userConfigData: undefined,
    setConfigKey: (key, val) => {
      if (!Object.values(UserConfigKeys).includes(key)) {
        throw Error('invalid config key')
      }
      this.setState((prevState) => ({
        __userConfigData: {
          ...prevState.__userConfigData,
          [key]: val,
        },
      }))
    },
    getConfigKey: (key) => {
      if (!Object.values(UserConfigKeys).includes(key)) {
        throw Error(`invalid config key "${key}"`)
      }
      clog(`[CONFIG]: ${key} = ${this.state.__userConfigData[key]}`)
      return this.state.__userConfigData[key]
    },
    refreshFromServer: async () => {
      const userConfig = await ClientSideUserConfigHelper.refreshFromServer()
      if (JSON.stringify(this.state.__userConfigData) !== JSON.stringify(userConfig)) {
        return new Promise((resolve) => this.setState({ __userConfigData: userConfig }, resolve))
      } else {
        return Promise.resolve()
      }
    },
  }
  state = this.initialState

  async componentDidMount() {
    const initialUserConfig = await ClientSideUserConfigHelper.refreshFromServer()
    this.setState({ __userConfigData: initialUserConfig })
  }

  render() {
    if (this.state.__userConfigData === undefined) {
      return null
    }
    return <UserConfigContext.Provider value={this.state}>{this.props.children}</UserConfigContext.Provider>
  }
}

function withUserConfig(Component) {
  return function UserConfigConsumerHOC(props) {
    return (
      <UserConfigContext.Consumer>
        {(userConfig) => <Component {...props} userConfig={userConfig} />}
      </UserConfigContext.Consumer>
    )
  }
}

class ClientSideUserConfigHelper {
  static async refreshFromServer() {
    const { responseJson: userConfig } = await doFetchJson('/api/user-config')
    ClientSideUserConfigHelper.configureSentryBasedOnUserConfig(userConfig)
    return userConfig
  }
  static configureSentryBasedOnUserConfig(userConfig) {
    Sentry.configureScope(function (scope) {
      scope.setUser({
        username: userConfig[UserConfigKeys.INFO_USERNAME],
      })
    })
    Sentry.configureScope((scope) => {
      scope.addEventProcessor((event) => {
        event.release = `${AppBuildConfig.APP_ID}@${userConfig[UserConfigKeys.APP_REVISION]}`
        event.environment = userConfig[UserConfigKeys.SENTRY_ENVIRONMENT]
        return event
      })
    })
  }
}

const useUserConfig = () => useContext(UserConfigContext)

const isDebugTrue = () => localStorage && localStorage.getItem(AppBuildConfig.DEBUG_LOCALSTORAGE_KEY) === 'true'

export { UserConfigProvider, withUserConfig, UserConfigKeys, useUserConfig, isDebugTrue }
