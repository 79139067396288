import PropTypes from 'prop-types'
import React from 'react'

import styles from './FileInputDialog.module.css'

/**
 * Thin React wrapper around a native <input type="file"> element.
 * This component has no visible DOM elements but will open the browser's native
 * "select file" dialog when openNativeSelectFileDialog() is invoked.
 */
class FileInputDialog extends React.Component {
  static propTypes = {
    onFileSelected: PropTypes.func.isRequired,
  }

  handleFileSelected = (ev) => {
    const file = this.fileInput.files[0]
    this.props.onFileSelected(file)
  }

  openNativeSelectFileDialog() {
    this.fileInput.click()
  }

  render() {
    return (
      <div className={`${styles.FileInputDialog}`}>
        <input type="file" ref={(el) => (this.fileInput = el)} onChange={this.handleFileSelected} />
      </div>
    )
  }
}

export default FileInputDialog
