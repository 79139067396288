import React from 'react'
import PropTypes from 'prop-types'

import Modal from './generic/Modal.jsx'
import ModalButton from './ModalButton.jsx'
import StyledEditBox from './StyledEditBox.jsx'

import styles from './ModalStringInput.module.css'

class ModalStringInput extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    hideFunc: PropTypes.func,
    onInputFinished: PropTypes.func,
    primaryButtonVerb: PropTypes.string,
    primaryButtonColor: PropTypes.string,
    inputClassName: PropTypes.string,
  }
  state = {
    currentInput: '',
  }

  static open({ content, primaryButtonVerb, primaryButtonColor, anchorElement, onInputFinished, inputClassName }) {
    const modalContainer = Modal.open({
      content: (
        <ModalStringInput
          onInputFinished={onInputFinished}
          hideFunc={() => Modal.close(modalContainer)}
          primaryButtonVerb={primaryButtonVerb}
          primaryButtonColor={primaryButtonColor}
          inputClassName={inputClassName}
        >
          {content}
        </ModalStringInput>
      ),
      anchorElement,
      popupModeClassName: styles.StringInputInPopupMode,
    })
  }

  static close(modalContainer) {
    Modal.close(modalContainer)
  }

  handleOK() {
    this.props.hideFunc()
    this.props.onInputFinished(this.state.currentInput)
  }

  componentDidMount() {
    this.editBox.focus()
  }

  render() {
    return (
      <div tabIndex="-1" className={styles.ModalStringInput}>
        <div>{this.props.children}</div>
        <div>
          <StyledEditBox
            ref={(el) => (this.editBox = el)}
            value={this.state.currentInput}
            onChange={(currentInput) => this.setState({ currentInput })}
            onEnterKey={() => this.handleOK()}
            className={this.props.inputClassName}
          />
        </div>
        <div className={styles.ButtonRow}>
          <ModalButton kind="cancel" className="automation-input-cancel-button" onClick={this.props.hideFunc}>
            Cancel
          </ModalButton>
          <ModalButton
            kind="primary"
            className="automation-input-ok-button"
            ref={(el) => (this.okButton = el)}
            onClick={() => this.handleOK()}
            color={this.props.primaryButtonColor}
          >
            {this.props.primaryButtonVerb || 'OK'}
          </ModalButton>
        </div>
      </div>
    )
  }
}

ModalStringInput.defaultProps = {
  inputClassName: '',
}

export default ModalStringInput
