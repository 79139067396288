import React from 'react'
import PropTypes from 'prop-types'

import { useAbortSignal } from '../../AppFetch.js'
import { makeFinancialYearTitleHtml } from '../../../shared-universal/AnnualReportRender.js'
import { DATA_FIELD_RIGHT_MARGIN } from './EditableTableUtils.jsx'
import EditableComment from './EditableComment.jsx'
import EditableXbrlValue from './EditableXbrlValue.jsx'
import NoteHeading from './NoteHeading.jsx'

const EventualForpliktelserEditor = ({ annualReport, onChange, disabled }) => {
  const abortSignal = useAbortSignal()
  const firstCellStyle = {
    width: '100%',
  }
  return (
    <React.Fragment>
      <NoteHeading
        title="Eventualförpliktelser"
        closeButton={true}
        noteXbrlName="NotEventualforpliktelser"
        annualReport={annualReport}
        onChange={onChange}
      />
      <table>
        <thead>
          <tr>
            <th style={firstCellStyle}></th>
            <td
              key={annualReport.financialYears[0].financialYearId}
              dangerouslySetInnerHTML={{
                __html: makeFinancialYearTitleHtml(annualReport.financialYears[0], 'instant'),
              }}
              style={{
                textAlign: 'right',
                paddingRight: DATA_FIELD_RIGHT_MARGIN,
                fontWeight: 'bold',
                verticalAlign: 'top',
              }}
            ></td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={firstCellStyle}>Eventualförpliktelser</td>
            <td>
              <EditableXbrlValue
                xbrlName="EventualForpliktelser"
                financialYear={annualReport.financialYears[0]}
                abortSignal={abortSignal}
                onSaved={onChange}
                disabled={disabled}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <EditableComment
        xbrlName="NotEventualForpliktelserKommentar"
        expandedTitle="Kommentar till not"
        annualReport={annualReport}
        onChange={onChange}
        disabled={disabled}
      />
    </React.Fragment>
  )
}

EventualForpliktelserEditor.propTypes = {
  annualReport: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default EventualForpliktelserEditor
