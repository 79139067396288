import React from 'react'
import PropTypes from 'prop-types'

const RmEditorHeading2 = ({ children, style }) => {
  return <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '0px', ...style }}>{children}</h2>
}

RmEditorHeading2.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
}

RmEditorHeading2.defaultProps = {
  style: {},
}

export default RmEditorHeading2
