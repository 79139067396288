import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box/index.js'
import Button from '@mui/material/Button/index.js'

import RmTextField from './RmTextField.jsx'
import { useAbortSignal } from '../../AppFetch.js'
import API from '../../API.js'
import { DEFAULT_STYRELSENS_YTTRANDE_OM_VINSTDISPOSITION } from '../../../shared-universal/RemakerUtils.js'

const EditableUppskrivningAvAnlaggningstillgang = ({ annualReport, onChange, isApproved }) => {
  const abortSignal = useAbortSignal()
  const [reactKey, setReactKey] = useState(0)

  return (
    <React.Fragment>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>Styrelsens yttrande om vinstdispositionen</div>
        <div>
          <Button
            onClick={() => {
              API.saveAnnualReportValue(
                annualReport.reportId,
                'customizedStyrelsensYttrandeOmVinstdisposition',
                DEFAULT_STYRELSENS_YTTRANDE_OM_VINSTDISPOSITION,
                abortSignal,
                async () => {
                  await onChange()
                  setReactKey(reactKey + 1)
                }
              )
            }}
          >
            Infoga standardiserat yttrande
          </Button>
        </div>
      </div>
      <Box mt={1} />
      <RmTextField
        defaultValue={
          annualReport.customizedStyrelsensYttrandeOmVinstdisposition === null
            ? DEFAULT_STYRELSENS_YTTRANDE_OM_VINSTDISPOSITION
            : annualReport.customizedStyrelsensYttrandeOmVinstdisposition
        }
        onChange={(ev) => {
          API.saveAnnualReportValue(
            annualReport.reportId,
            'customizedStyrelsensYttrandeOmVinstdisposition',
            ev.target.value === DEFAULT_STYRELSENS_YTTRANDE_OM_VINSTDISPOSITION ? null : ev.target.value,
            abortSignal,
            onChange
          )
        }}
        key={reactKey}
        multiline
        rows="4"
        fullWidth
        disabled={isApproved}
        className="automation-styrelsens-yttrande-om-vinstdisposition"
      />
      <Box mt={2} />
    </React.Fragment>
  )
}

EditableUppskrivningAvAnlaggningstillgang.propTypes = {
  annualReport: PropTypes.object,
  onChange: PropTypes.func,
  isApproved: PropTypes.bool.isRequired,
}

export default EditableUppskrivningAvAnlaggningstillgang
