// NOTE: This data was created via:
/*

cat docs/basplan-json/accounts.json | jq -r '.Accounts[] |
select(
    .IsGroup == false and
    .NotAllowedInK2 != true and
    (
            (.ValidForOrganizationTypes[] | contains("LTD")) or
            (.ValidForOrganizationTypes[] | contains("ALL"))
    )
    and ([.AccountNumber] | inside(["1080", "2080", "2098", "2099", "2110", "8990"]) | not) and (.AccountNumber | startswith("213") | not) and ((.AccountNumber | tonumber) < 2610 or (.AccountNumber | tonumber) >= 2650)
) | "{ account: \"" + .AccountNumber + "\", accountName: \"" + .SwedishName + "\"},"'

NOTE: It makes no sense that the official accounts.json from BAS contains 1080, 2080 and 8990.
      Because these accounts are not summed into any posts, if you want to book results you
      should use 8999 and if you want to book "Bundet eget kapital" you'd use for example 2087 etc.
*/
const AB_K2_LEAF_BAS_ACCOUNTS = [
  { account: '1020', accountName: 'Koncessioner m.m.' },
  { account: '1028', accountName: 'Ackumulerade nedskrivningar på koncessioner m.m.' },
  { account: '1029', accountName: 'Ackumulerade avskrivningar på koncessioner m.m.' },
  { account: '1030', accountName: 'Patent' },
  { account: '1038', accountName: 'Ackumulerade nedskrivningar på patent' },
  { account: '1039', accountName: 'Ackumulerade avskrivningar på patent' },
  { account: '1040', accountName: 'Licenser' },
  { account: '1048', accountName: 'Ackumulerade nedskrivningar på licenser' },
  { account: '1049', accountName: 'Ackumulerade avskrivningar på licenser' },
  { account: '1050', accountName: 'Varumärken' },
  { account: '1058', accountName: 'Ackumulerade nedskrivningar på varumärken' },
  { account: '1059', accountName: 'Ackumulerade avskrivningar på varumärken' },
  { account: '1060', accountName: 'Hyresrätter, tomträtter och liknande' },
  { account: '1068', accountName: 'Ackumulerade nedskrivningar på hyresrätter, tomträtter och liknande' },
  { account: '1069', accountName: 'Ackumulerade avskrivningar på hyresrätter, tomträtter och liknande' },
  { account: '1070', accountName: 'Goodwill' },
  { account: '1078', accountName: 'Ackumulerade nedskrivningar på goodwill' },
  { account: '1079', accountName: 'Ackumulerade avskrivningar på goodwill' },
  { account: '1088', accountName: 'Förskott för immateriella anläggningstillgångar' },
  { account: '1110', accountName: 'Byggnader' },
  { account: '1111', accountName: 'Byggnader på egen mark' },
  { account: '1112', accountName: 'Byggnader på annans mark' },
  { account: '1118', accountName: 'Ackumulerade nedskrivningar på byggnader' },
  { account: '1119', accountName: 'Ackumulerade avskrivningar på byggnader' },
  { account: '1120', accountName: 'Förbättringsutgifter på annans fastighet' },
  { account: '1129', accountName: 'Ackumulerade avskrivningar på förbättringsutgifter på annans fastighet' },
  { account: '1130', accountName: 'Mark' },
  { account: '1140', accountName: 'Tomter och obebyggda markområden' },
  { account: '1150', accountName: 'Markanläggningar' },
  { account: '1158', accountName: 'Ackumulerade nedskrivningar på markanläggningar' },
  { account: '1159', accountName: 'Ackumulerade avskrivningar på markanläggningar' },
  { account: '1180', accountName: 'Pågående nyanläggningar och förskott för byggnader och mark' },
  { account: '1181', accountName: 'Pågående ny-, till- och ombyggnad' },
  { account: '1188', accountName: 'Förskott för byggnader och mark' },
  { account: '1210', accountName: 'Maskiner och andra tekniska anläggningar' },
  { account: '1211', accountName: 'Maskiner' },
  { account: '1213', accountName: 'Andra tekniska anläggningar' },
  { account: '1218', accountName: 'Ackumulerade nedskrivningar på maskiner och andra tekniska anläggningar' },
  { account: '1219', accountName: 'Ackumulerade avskrivningar på maskiner och andra tekniska anläggningar' },
  { account: '1220', accountName: 'Inventarier och verktyg' },
  { account: '1221', accountName: 'Inventarier' },
  { account: '1222', accountName: 'Byggnadsinventarier' },
  { account: '1223', accountName: 'Markinventarier' },
  { account: '1225', accountName: 'Verktyg' },
  { account: '1228', accountName: 'Ackumulerade nedskrivningar på inventarier och verktyg' },
  { account: '1229', accountName: 'Ackumulerade avskrivningar på inventarier och verktyg' },
  { account: '1230', accountName: 'Installationer' },
  { account: '1231', accountName: 'Installationer på egen fastighet' },
  { account: '1232', accountName: 'Installationer på annans fastig het' },
  { account: '1238', accountName: 'Ackumulerade nedskrivningar på installationer' },
  { account: '1239', accountName: 'Ackumulerade avskrivningar på installationer' },
  { account: '1240', accountName: 'Bilar och andra transportmedel' },
  { account: '1241', accountName: 'Personbilar' },
  { account: '1242', accountName: 'Lastbilar' },
  { account: '1243', accountName: 'Truckar' },
  { account: '1244', accountName: 'Arbetsmaskiner' },
  { account: '1245', accountName: 'Traktorer' },
  { account: '1246', accountName: 'Motorcyklar, mopeder och skotrar' },
  { account: '1247', accountName: 'Båtar, flygplan och helikoptrar' },
  { account: '1248', accountName: 'Ackumulerade nedskrivningar på bilar och andra transportmedel' },
  { account: '1249', accountName: 'Ackumulerade avskrivningar på bilar och andra transportmedel' },
  { account: '1250', accountName: 'Datorer' },
  { account: '1251', accountName: 'Datorer, företaget' },
  { account: '1257', accountName: 'Datorer, personal' },
  { account: '1258', accountName: 'Ackumulerade nedskrivningar på datorer' },
  { account: '1259', accountName: 'Ackumulerade avskrivningar på datorer' },
  { account: '1280', accountName: 'Pågående nyanläggningar och förskott för maskiner och inventarier' },
  { account: '1281', accountName: 'Pågående nyanläggningar, maskiner och inventarier' },
  { account: '1288', accountName: 'Förskott för maskiner och inventarier' },
  { account: '1290', accountName: 'Övriga materiella anläggningstillgångar' },
  { account: '1291', accountName: 'Konst och liknande tillgångar' },
  { account: '1292', accountName: 'Djur som klassificeras som anläggningstillgång' },
  { account: '1298', accountName: 'Ackumulerade nedskrivningar på övriga materiella anläggningstillgångar' },
  { account: '1299', accountName: 'Ackumulerade avskrivningar på övriga materiella anläggningstillgångar' },
  { account: '1310', accountName: 'Andelar i koncernföretag' },
  { account: '1311', accountName: 'Aktier i noterade svenska koncernföretag' },
  { account: '1312', accountName: 'Aktier i onoterade svenska koncernföretag' },
  { account: '1313', accountName: 'Aktier i noterade utländska koncernföretag' },
  { account: '1314', accountName: 'Aktier i onoterade utländska koncernföretag' },
  { account: '1316', accountName: 'Andra andelar i svenska koncernföretag' },
  { account: '1317', accountName: 'Andra andelar i utländska koncernförertag' },
  { account: '1318', accountName: 'Ackumulerade nedskrivningar av andelar i koncernföretag' },
  { account: '1320', accountName: 'Långfristiga fordringar hos koncernföretag' },
  { account: '1321', accountName: 'Långfristiga fordringar hos moderföretag' },
  { account: '1322', accountName: 'Långfristiga fordringar hos dotterföretag' },
  { account: '1323', accountName: 'Långfristiga fordringar hos andra koncernföretag' },
  { account: '1328', accountName: 'Ackumulerade nedskrivningar av långfristiga fordringar hos koncernföretag' },
  {
    account: '1330',
    accountName:
      'Andelar i intresseföretag, gemensamt styrda företag och övriga företag som det finns ett ägarintresse i',
  },
  { account: '1331', accountName: 'Andelar i intresseföretag' },
  { account: '1332', accountName: 'Ackumulerade nedskrivningar av andelar i intresseföretag' },
  { account: '1333', accountName: 'Andelar i gemensamt styrda företag' },
  { account: '1334', accountName: 'Ackumulerade nedskrivningar av andelar i gemensamt styrda företag' },
  { account: '1336', accountName: 'Andelar i övriga företag som det finns ett ägarintresse i' },
  {
    account: '1337',
    accountName: 'Ackumulerade nedskrivningar av andelar i övriga företag som det finns ett ägarintresse i',
  },
  {
    account: '1338',
    accountName:
      'Ackumulerade nedskrivningar av andelar i intresseföretag, gemensamt styrda företag och övriga företag som det finns ett ägarintresse i',
  },
  {
    account: '1340',
    accountName:
      'Långfristiga fordringar hos intresseföretag, gemensamt styrda företag och övriga företag som det finns ett ägarintresse i',
  },
  { account: '1341', accountName: 'Långfristiga fordringar hos intresseföretag' },
  { account: '1342', accountName: 'Ackumulerade nedskrivningar av långfristiga fordringar hos intresseföretag' },
  { account: '1343', accountName: 'Långfristiga fordringar hos gemensamt styrda företag' },
  {
    account: '1344',
    accountName: 'Ackumulerade nedskrivningar av långfristiga fordringar hos gemensamt styrda företag',
  },
  { account: '1346', accountName: 'Långfristiga fordringar hos övriga företag som det finns ett ägarintresse i' },
  {
    account: '1347',
    accountName:
      'Ackumulerade nedskrivningar av långfristiga fordringar hos övriga företag som det finns ett ägarintresse i',
  },
  {
    account: '1348',
    accountName:
      'Ackumulerade nedskrivningar av långfristiga fordringar hos intresseföretag, gemensamt styrda företag och övriga företag som det finns ett ägarintresse i',
  },
  { account: '1350', accountName: 'Andelar och värdepapper i andra företag' },
  { account: '1351', accountName: 'Andelar i noterade företag' },
  { account: '1352', accountName: 'Andra andelar' },
  { account: '1353', accountName: 'Andelar i bostadsrättsföreningar' },
  { account: '1354', accountName: 'Obligationer' },
  { account: '1356', accountName: 'Andelar i ekonomiska föreningar, övriga företag' },
  { account: '1357', accountName: 'Andelar i handelsbolag, andra företag' },
  { account: '1358', accountName: 'Ackumulerade nedskrivningar av andra andelar och värdepapper' },
  { account: '1360', accountName: 'Lån till delägare eller närstående enligt ABL, långfristig del' },
  {
    account: '1369',
    accountName: 'Ackumulerade nedskrivningar av lån till delägare eller närstående enligt ABL, långfristig del',
  },
  { account: '1380', accountName: 'Andra långfristiga fordringar' },
  { account: '1381', accountName: 'Långfristiga reversfordringar' },
  { account: '1382', accountName: 'Långfristiga fordringar hos anställda' },
  { account: '1383', accountName: 'Lämnade depositioner, långfristiga' },
  { account: '1384', accountName: 'Derivat' },
  { account: '1385', accountName: 'Kapitalförsäkring' },
  { account: '1387', accountName: 'Långfristiga kontraktsfordringar' },
  { account: '1388', accountName: 'Långfristiga kundfordringar' },
  { account: '1389', accountName: 'Ackumulerade nedskrivningar av andra långfristiga fordringar' },
  { account: '1410', accountName: 'Lager av råvaror' },
  { account: '1419', accountName: 'Förändring av lager av råvaror' },
  { account: '1420', accountName: 'Lager av tillsatsmaterial och förnödenheter' },
  { account: '1429', accountName: 'Förändring av lager av tillsatsmaterial och förnödenheter' },
  { account: '1440', accountName: 'Produkter i arbete' },
  { account: '1449', accountName: 'Förändring av produkter i arbete' },
  { account: '1450', accountName: 'Lager av färdiga varor' },
  { account: '1459', accountName: 'Förändring av lager av färdiga varor' },
  { account: '1460', accountName: 'Lager av handelsvaror' },
  { account: '1465', accountName: 'Lager av varor VMB' },
  { account: '1466', accountName: 'Nedskrivning av varor VMB' },
  { account: '1467', accountName: 'Lager av varor VMB förenklad' },
  { account: '1469', accountName: 'Förändring av lager av handelsvaror' },
  { account: '1470', accountName: 'Pågående arbeten' },
  { account: '1471', accountName: 'Pågående arbeten, nedlagda kostnader' },
  { account: '1478', accountName: 'Pågående arbeten, fakturering' },
  { account: '1479', accountName: 'Förändring av pågående arbeten' },
  { account: '1480', accountName: 'Förskott för varor och tjänster' },
  { account: '1481', accountName: 'Remburser' },
  { account: '1489', accountName: 'Övriga förskott till leverantörer' },
  { account: '1490', accountName: 'Övriga lagertillgångar' },
  { account: '1491', accountName: 'Lager av värdepapper' },
  { account: '1492', accountName: 'Lager av fastigheter' },
  { account: '1493', accountName: 'Djur som klassificeras som omsättningstillgång' },
  { account: '1510', accountName: 'Kundfordringar' },
  { account: '1511', accountName: 'Kundfordringar' },
  { account: '1512', accountName: 'Belånade kundfordringar (factoring)' },
  { account: '1513', accountName: 'Kundfordringar – delad faktura' },
  { account: '1516', accountName: 'Tvistiga kundfordringar' },
  { account: '1518', accountName: 'Ej reskontraförda kundfordringar' },
  { account: '1519', accountName: 'Nedskrivning av kundfordringar' },
  { account: '1520', accountName: 'Växelfordringar' },
  { account: '1525', accountName: 'Osäkra växelfordringar' },
  { account: '1529', accountName: 'Nedskrivning av växelfordringar' },
  { account: '1530', accountName: 'Kontraktsfordringar' },
  { account: '1531', accountName: 'Kontraktsfordringar' },
  { account: '1532', accountName: 'Belånade kontraktsfordringar' },
  { account: '1536', accountName: 'Tvistiga kontraktsfordringar' },
  { account: '1539', accountName: 'Nedskrivning av kontraktsfordringar' },
  { account: '1550', accountName: 'Konsignationsfordringar' },
  { account: '1560', accountName: 'Kundfordringar hos koncernföretag' },
  { account: '1561', accountName: 'Kundfordringar hos moderföretag' },
  { account: '1562', accountName: 'Kundfordringar hos dotterföretag' },
  { account: '1563', accountName: 'Kundfordringar hos andra koncernföretag' },
  { account: '1568', accountName: 'Ej reskontraförda kundfordringar hos koncernföretag' },
  { account: '1569', accountName: 'Nedskrivning av kundfordringar hos koncernföretag' },
  {
    account: '1570',
    accountName:
      'Kundfordringar hos intresseföretag, gemensamt styrda företag och övriga företag som det finns ett ägarintresse i',
  },
  { account: '1571', accountName: 'Kundfordringar hos intresseföretag' },
  { account: '1572', accountName: 'Kundfordringar hos gemensamt styrda företag' },
  { account: '1573', accountName: 'Kundfordringar hos övriga företag som det finns ett ägarintresse i' },
  { account: '1580', accountName: 'Fordringar för kontokort och kuponger' },
  { account: '1610', accountName: 'Kortfristiga fordringar hos anställda' },
  { account: '1611', accountName: 'Reseförskott' },
  { account: '1612', accountName: 'Kassaförskott' },
  { account: '1613', accountName: 'Övriga förskott' },
  { account: '1614', accountName: 'Tillfälliga lån till anställda' },
  { account: '1619', accountName: 'Övriga fordringar hos anställda' },
  { account: '1620', accountName: 'Upparbetad men ej fakturerad intäkt' },
  { account: '1630', accountName: 'Avräkning för skatter och avgifter (skattekonto)' },
  { account: '1640', accountName: 'Skattefordringar' },
  { account: '1650', accountName: 'Momsfordran' },
  { account: '1660', accountName: 'Kortfristiga fordringar hos koncernföretag' },
  { account: '1661', accountName: 'Kortfristiga fordringar hos moderföretag' },
  { account: '1662', accountName: 'Kortfristiga fordringar hos dotterföretag' },
  { account: '1663', accountName: 'Kortfristiga fordringar hos andra koncernföretag' },
  {
    account: '1670',
    accountName:
      'Kortfristiga fordringar hos intresseföretag, gemensamt styrda företag och övriga företag som det finns ett ägarintresse i',
  },
  { account: '1671', accountName: 'Kortfristiga fordringar hos intresseföretag' },
  { account: '1672', accountName: 'Kortfristiga fordringar hos gemensamt styrda företag' },
  { account: '1673', accountName: 'Kortfristiga fordringar hos övriga företag som det finns ett ägarintresse i' },
  { account: '1680', accountName: 'Andra kortfristiga fordringar' },
  { account: '1681', accountName: 'Utlägg för kunder' },
  { account: '1682', accountName: 'Kortfristiga lånefordringar' },
  { account: '1683', accountName: 'Derivat' },
  { account: '1684', accountName: 'Kortfristiga fordringar hos leverantörer' },
  { account: '1685', accountName: 'Kortfristiga fordringar hos delägare eller närstående' },
  { account: '1687', accountName: 'Kortfristig del av långfristiga fordringar' },
  { account: '1688', accountName: 'Fordran arbetsmarknadsförsäkringar' },
  { account: '1689', accountName: 'Övriga kortfristiga fordringar' },
  { account: '1690', accountName: 'Fordringar för tecknat men ej inbetalt aktiekapital' },
  { account: '1710', accountName: 'Förutbetalda hyreskostnader' },
  { account: '1720', accountName: 'Förutbetalda leasingavgifter' },
  { account: '1730', accountName: 'Förutbetalda försäkringspremier' },
  { account: '1740', accountName: 'Förutbetalda räntekostnader' },
  { account: '1750', accountName: 'Upplupna hyresintäkter' },
  { account: '1760', accountName: 'Upplupna ränteintäkter' },
  { account: '1770', accountName: 'Tillgångar av kostnadsnatur' },
  { account: '1780', accountName: 'Upplupna avtalsintäkter' },
  { account: '1790', accountName: 'Övriga förutbetalda kostnader och upplupna intäkter' },
  { account: '1810', accountName: 'Andelar i börsnoterade företag' },
  { account: '1820', accountName: 'Obligationer' },
  { account: '1830', accountName: 'Konvertibla skuldebrev' },
  { account: '1860', accountName: 'Andelar i koncernföretag, kortfristigt' },
  { account: '1880', accountName: 'Andra kortfristiga placeringar' },
  { account: '1886', accountName: 'Derivat' },
  { account: '1889', accountName: 'Andelar i övriga företag' },
  { account: '1890', accountName: 'Nedskrivning av kortfristiga placeringar' },
  { account: '1910', accountName: 'Kassa' },
  { account: '1911', accountName: 'Huvudkassa' },
  { account: '1912', accountName: 'Kassa 2' },
  { account: '1913', accountName: 'Kassa 3' },
  { account: '1920', accountName: 'PlusGiro' },
  { account: '1930', accountName: 'Företagskonto/checkkonto/affärskonto' },
  { account: '1940', accountName: 'Övriga bankkonton' },
  { account: '1950', accountName: 'Bankcertifikat' },
  { account: '1960', accountName: 'Koncernkonto moderföretag' },
  { account: '1970', accountName: 'Särskilda bankkonton' },
  { account: '1972', accountName: 'Upphovsmannakonto' },
  { account: '1973', accountName: 'Skogskonto' },
  { account: '1974', accountName: 'Spärrade bankmedel' },
  { account: '1979', accountName: 'Övriga särskilda bankkonton' },
  { account: '1980', accountName: 'Valutakonton' },
  { account: '1990', accountName: 'Redovisningsmedel' },
  { account: '2081', accountName: 'Aktiekapital' },
  { account: '2082', accountName: 'Ej registrerat aktiekapital' },
  { account: '2085', accountName: 'Uppskrivningsfond' },
  { account: '2086', accountName: 'Reservfond' },
  { account: '2087', accountName: 'Bunden överkursfond' },
  { account: '2090', accountName: 'Fritt eget kapital' },
  { account: '2091', accountName: 'Balanserad vinst eller förlust' },
  { account: '2093', accountName: 'Erhållna aktieägartillskott' },
  { account: '2094', accountName: 'Egna aktier' },
  { account: '2095', accountName: 'Fusionsresultat' },
  { account: '2097', accountName: 'Fri överkursfond' },
  { account: '2120', accountName: 'Periodiseringsfond 2020' },
  { account: '2121', accountName: 'Periodiseringsfond 2021' },
  { account: '2122', accountName: 'Periodiseringsfond 2022' },
  { account: '2123', accountName: 'Periodiseringsfond 2023' },
  { account: '2125', accountName: 'Periodiseringsfond 2015' },
  { account: '2126', accountName: 'Periodiseringsfond 2016' },
  { account: '2127', accountName: 'Periodiseringsfond 2017' },
  { account: '2128', accountName: 'Periodiseringsfond 2018' },
  { account: '2129', accountName: 'Periodiseringsfond 2019' },
  { account: '2150', accountName: 'Ackumulerade överavskrivningar' },
  { account: '2151', accountName: 'Ackumulerade överavskrivningar på immateriella anläggningstillgångar' },
  { account: '2152', accountName: 'Ackumulerade överavskrivningar på byggnader och markanläggningar' },
  { account: '2153', accountName: 'Ackumulerade överavskrivningar på maskiner och inventarier' },
  { account: '2160', accountName: 'Ersättningsfond' },
  { account: '2161', accountName: 'Ersättningsfond maskiner och inventarier' },
  { account: '2162', accountName: 'Ersättningsfond byggnader och markanläggningar' },
  { account: '2164', accountName: 'Ersättningsfond för djurlager i jordbruk och renskötsel' },
  { account: '2190', accountName: 'Övriga obeskattade reserver' },
  { account: '2196', accountName: 'Lagerreserv' },
  { account: '2199', accountName: 'Övriga obeskattade reserver' },
  { account: '2210', accountName: 'Avsättningar för pensioner enligt tryggandelagen' },
  { account: '2220', accountName: 'Avsättningar för garantier' },
  { account: '2230', accountName: 'Övriga avsättningar för pensioner och liknande förpliktelser' },
  { account: '2250', accountName: 'Övriga avsättningar för skatter' },
  { account: '2252', accountName: 'Avsättningar för tvistiga skatter' },
  { account: '2253', accountName: 'Avsättningar särskild löneskatt, deklarationspost' },
  { account: '2290', accountName: 'Övriga avsättningar' },
  { account: '2310', accountName: 'Obligations- och förlagslån' },
  { account: '2320', accountName: 'Konvertibla lån och liknande' },
  { account: '2321', accountName: 'Konvertibla lån' },
  { account: '2322', accountName: 'Lån förenade med optionsrätt' },
  { account: '2323', accountName: 'Vinstandelslån' },
  { account: '2324', accountName: 'Kapitalandelslån' },
  { account: '2330', accountName: 'Checkräkningskredit' },
  { account: '2331', accountName: 'Checkräkningskredit 1' },
  { account: '2332', accountName: 'Checkräkningskredit 2' },
  { account: '2340', accountName: 'Byggnadskreditiv' },
  { account: '2350', accountName: 'Andra långfristiga skulder till kreditinstitut' },
  { account: '2351', accountName: 'Fastighetslån, långfristig del' },
  { account: '2355', accountName: 'Långfristiga lån i utländsk valuta från kreditinstitut' },
  { account: '2359', accountName: 'Övriga långfristiga lån från kreditinstitut' },
  { account: '2360', accountName: 'Långfristiga skulder till koncernföretag' },
  { account: '2361', accountName: 'Långfristiga skulder till moderföretag' },
  { account: '2362', accountName: 'Långfristiga skulder till dotterföretag' },
  { account: '2363', accountName: 'Långfristiga skulder till andra koncernföretag' },
  {
    account: '2370',
    accountName:
      'Långfristiga skulder till intresseföretag, gemensamt styrda företag och övriga företag som det finns ett ägarintresse i',
  },
  { account: '2371', accountName: 'Långfristiga skulder till intresseföretag' },
  { account: '2372', accountName: 'Långfristiga skulder till gemensamt styrda företag' },
  { account: '2373', accountName: 'Långfristiga skulder till övriga företag som det finns ett ägarintresse i' },
  { account: '2390', accountName: 'Övriga långfristiga skulder' },
  { account: '2391', accountName: 'Avbetalningskontrakt, långfristig del' },
  { account: '2392', accountName: 'Villkorliga långfristiga skulder' },
  { account: '2393', accountName: 'Lån från närstående personer, långfristig del' },
  { account: '2394', accountName: 'Långfristiga leverantörskrediter' },
  { account: '2395', accountName: 'Andra långfristiga lån i utländsk valuta' },
  { account: '2396', accountName: 'Derivat' },
  { account: '2397', accountName: 'Mottagna depositioner, långfristiga' },
  { account: '2399', accountName: 'Övriga långfristiga skulder' },
  { account: '2410', accountName: 'Andra kortfristiga låneskulder till kreditinstitut' },
  { account: '2411', accountName: 'Kortfristiga lån från kreditinstitut' },
  { account: '2412', accountName: 'Byggnadskreditiv, kortfristig del' },
  { account: '2417', accountName: 'Kortfristig del av långfristiga skulder till kreditinstitut' },
  { account: '2419', accountName: 'Övriga kortfristiga skulder till kreditinstitut' },
  { account: '2420', accountName: 'Förskott från kunder' },
  { account: '2421', accountName: 'Ej inlösta presentkort' },
  { account: '2429', accountName: 'Övriga förskott från kunder' },
  { account: '2430', accountName: 'Pågående arbeten' },
  { account: '2431', accountName: 'Pågående arbeten, fakturering' },
  { account: '2438', accountName: 'Pågående arbeten, nedlagda kostnader' },
  { account: '2439', accountName: 'Beräknad förändring av pågående arbeten' },
  { account: '2440', accountName: 'Leverantörsskulder' },
  { account: '2441', accountName: 'Leverantörsskulder' },
  { account: '2443', accountName: 'Konsignationsskulder' },
  { account: '2445', accountName: 'Tvistiga leverantörsskulder' },
  { account: '2448', accountName: 'Ej reskontraförda leverantörsskulder' },
  { account: '2450', accountName: 'Fakturerad men ej upparbetad intäkt' },
  { account: '2460', accountName: 'Leverantörsskulder till koncernföretag' },
  { account: '2461', accountName: 'Leverantörsskulder till moderföretag' },
  { account: '2462', accountName: 'Leverantörsskulder till dotterföretag' },
  { account: '2463', accountName: 'Leverantörsskulder till andra koncernföretag' },
  {
    account: '2470',
    accountName:
      'Leverantörsskulder till intresseföretag, gemensamt styrda företag och övriga företag som det finns ett ägarintresse i',
  },
  { account: '2471', accountName: 'Leverantörsskulder till intresseföretag' },
  { account: '2472', accountName: 'Leverantörsskulder till gemensamt styrda företag' },
  { account: '2473', accountName: 'Leverantörsskulder till övriga företag som det finns ett ägarintresse i' },
  { account: '2480', accountName: 'Checkräkningskredit, kortfristig' },
  { account: '2490', accountName: 'Övriga kortfristiga skulder till kreditinstitut, kunder och leverantörer' },
  { account: '2491', accountName: 'Avräkning spelarrangörer' },
  { account: '2492', accountName: 'Växelskulder' },
  { account: '2499', accountName: 'Andra övriga kortfristiga skulder' },
  { account: '2510', accountName: 'Skatteskulder' },
  { account: '2512', accountName: 'Beräknad inkomstskatt' },
  { account: '2513', accountName: 'Beräknad fastighetsskatt/fastighetsavgift' },
  { account: '2514', accountName: 'Beräknad särskild löneskatt på pensionskostnader' },
  { account: '2515', accountName: 'Beräknad avkastningsskatt' },
  { account: '2517', accountName: 'Beräknad utländsk skatt' },
  { account: '2518', accountName: 'Betald F-skatt' },
  { account: '2650', accountName: 'Redovisningskonto för moms' },
  { account: '2660', accountName: 'Särskilda punktskatter' },
  { account: '2661', accountName: 'Reklamskatt' },
  { account: '2669', accountName: 'Övriga punktskatter' },
  { account: '2710', accountName: 'Personalskatt' },
  { account: '2730', accountName: 'Lagstadgade sociala avgifter och särskild löneskatt' },
  { account: '2731', accountName: 'Avräkning lagstadgade sociala avgifter' },
  { account: '2732', accountName: 'Avräkning särskild löneskatt' },
  { account: '2740', accountName: 'Avtalade sociala avgifter' },
  { account: '2750', accountName: 'Utmätning i lön m.m.' },
  { account: '2760', accountName: 'Semestermedel' },
  { account: '2761', accountName: 'Avräkning semesterlöner' },
  { account: '2762', accountName: 'Semesterlönekassa' },
  { account: '2790', accountName: 'Övriga löneavdrag' },
  { account: '2791', accountName: 'Personalens intressekonto' },
  { account: '2792', accountName: 'Lönsparande' },
  { account: '2793', accountName: 'Gruppförsäkringspremier' },
  { account: '2794', accountName: 'Fackföreningsavgifter' },
  { account: '2795', accountName: 'Mätnings- och granskningsarvoden' },
  { account: '2799', accountName: 'Övriga löneavdrag' },
  { account: '2810', accountName: 'Avräkning för factoring och belånade kontraktsfordringar' },
  { account: '2811', accountName: 'Avräkning för factoring' },
  { account: '2812', accountName: 'Avräkning för belånade kontraktsfordringar' },
  { account: '2820', accountName: 'Kortfristiga skulder till anställda' },
  { account: '2821', accountName: 'Löneskulder' },
  { account: '2822', accountName: 'Reseräkningar' },
  { account: '2823', accountName: 'Tantiem, gratifikationer' },
  { account: '2829', accountName: 'Övriga kortfristiga skulder till anställda' },
  { account: '2830', accountName: 'Avräkning för annans räkning' },
  { account: '2840', accountName: 'Kortfristiga låneskulder' },
  { account: '2841', accountName: 'Kortfristig del av långfristiga skulder' },
  { account: '2849', accountName: 'Övriga kortfristiga låneskulder' },
  { account: '2850', accountName: 'Avräkning för skatter och avgifter (skattekonto)' },
  { account: '2852', accountName: 'Anståndsbelopp för moms, arbetsgivaravgifter och personalskatt' },
  { account: '2860', accountName: 'Kortfristiga skulder till koncernföretag' },
  { account: '2861', accountName: 'Kortfristiga skulder till moderföretag' },
  { account: '2862', accountName: 'Kortfristiga skulder till dotterföretag' },
  { account: '2863', accountName: 'Kortfristiga skulder till andra koncernföretag' },
  {
    account: '2870',
    accountName:
      'Kortfristiga skulder till intresseföretag, gemensamt styrda företag och övriga företag som det finns ett ägarintresse i',
  },
  { account: '2871', accountName: 'Kortfristiga skulder till intresseföretag' },
  { account: '2872', accountName: 'Kortfristiga skulder till gemensamt styrda företag' },
  { account: '2873', accountName: 'Kortfristiga skulder till övriga företag som det finns ett ägarintresse i' },
  { account: '2880', accountName: 'Skuld erhållna bidrag' },
  { account: '2890', accountName: 'Övriga kortfristiga skulder' },
  { account: '2891', accountName: 'Skulder under indrivning' },
  { account: '2892', accountName: 'Inre reparationsfond/underhållsfond' },
  { account: '2893', accountName: 'Skulder till närstående personer, kortfristig del' },
  { account: '2895', accountName: 'Derivat (kortfristiga skulder)' },
  { account: '2897', accountName: 'Mottagna depositioner, kortfristiga' },
  { account: '2898', accountName: 'Outtagen vinstutdelning' },
  { account: '2899', accountName: 'Övriga kortfristiga skulder' },
  { account: '2910', accountName: 'Upplupna löner' },
  { account: '2911', accountName: 'Löneskulder' },
  { account: '2912', accountName: 'Ackordsöverskott' },
  { account: '2919', accountName: 'Övriga upplupna löner' },
  { account: '2920', accountName: 'Upplupna semesterlöner' },
  { account: '2930', accountName: 'Upplupna pensionskostnader' },
  { account: '2931', accountName: 'Upplupna pensionsutbetalningar' },
  { account: '2940', accountName: 'Upplupna lagstadgade sociala och andra avgifter' },
  { account: '2941', accountName: 'Beräknade upplupna lagstadgade sociala avgifter' },
  { account: '2942', accountName: 'Beräknad upplupen särskild löneskatt' },
  { account: '2943', accountName: 'Beräknad upplupen särskild löneskatt på pensionskostnader, deklarationspost' },
  { account: '2944', accountName: 'Beräknad upplupen avkastningsskatt på pensionskostnader' },
  { account: '2950', accountName: 'Upplupna avtalade sociala avgifter' },
  { account: '2951', accountName: 'Upplupna avtalade arbetsmarknadsförsäkringar' },
  { account: '2959', accountName: 'Upplupna avtalade pensionsförsäkringsavgifter, deklarationspost' },
  { account: '2960', accountName: 'Upplupna räntekostnader' },
  { account: '2970', accountName: 'Förutbetalda intäkter' },
  { account: '2971', accountName: 'Förutbetalda hyresintäkter' },
  { account: '2972', accountName: 'Förutbetalda medlemsavgifter' },
  { account: '2979', accountName: 'Övriga förutbetalda intäkter' },
  { account: '2980', accountName: 'Upplupna avtalskostnader' },
  { account: '2990', accountName: 'Övriga upplupna kostnader och förutbetalda intäkter' },
  { account: '2991', accountName: 'Beräknat arvode för bokslut' },
  { account: '2992', accountName: 'Beräknat arvode för revision' },
  { account: '2993', accountName: 'Ospecificerad skuld till leverantörer' },
  { account: '2998', accountName: 'Övriga upplupna kostnader och förutbetalda intäkter' },
  { account: '2999', accountName: 'OBS-konto' },
  { account: '3000', accountName: 'Försäljning inom Sverige' },
  { account: '3001', accountName: 'Försäljning inom Sverige, 25 % moms' },
  { account: '3002', accountName: 'Försäljning inom Sverige, 12 % moms' },
  { account: '3003', accountName: 'Försäljning inom Sverige, 6 % moms' },
  { account: '3004', accountName: 'Försäljning inom Sverige, momsfri' },
  { account: '3100', accountName: 'Försäljning av varor utanför Sverige' },
  { account: '3105', accountName: 'Försäljning varor till land utanför EU' },
  { account: '3106', accountName: 'Försäljning varor till annat EU-land, momspliktig' },
  { account: '3108', accountName: 'Försäljning varor till annat EU-land, momsfri' },
  { account: '3200', accountName: 'Försäljning VMB och omvänd moms' },
  { account: '3211', accountName: 'Försäljning positiv VMB 25 %' },
  { account: '3212', accountName: 'Försäljning negativ VMB 25 %' },
  { account: '3231', accountName: 'Försäljning inom byggsektorn, omvänd skattskyldighet moms' },
  { account: '3300', accountName: 'Försäljning av tjänster utanför Sverige' },
  { account: '3305', accountName: 'Försäljning tjänster till land utanför EU' },
  { account: '3308', accountName: 'Försäljning tjänster till annat EU-land' },
  { account: '3400', accountName: 'Försäljning, egna uttag' },
  { account: '3401', accountName: 'Egna uttag momspliktiga, 25 %' },
  { account: '3402', accountName: 'Egna uttag momspliktiga, 12 %' },
  { account: '3403', accountName: 'Egna uttag momspliktiga, 6 %' },
  { account: '3404', accountName: 'Egna uttag, momsfria' },
  { account: '3500', accountName: 'Fakturerade kostnader (gruppkonto)' },
  { account: '3510', accountName: 'Fakturerat emballage' },
  { account: '3511', accountName: 'Fakturerat emballage' },
  { account: '3518', accountName: 'Returnerat emballage' },
  { account: '3520', accountName: 'Fakturerade frakter' },
  { account: '3521', accountName: 'Fakturerade frakter, EU-land' },
  { account: '3522', accountName: 'Fakturerade frakter, export' },
  { account: '3530', accountName: 'Fakturerade tull- och speditionskostnader m.m.' },
  { account: '3540', accountName: 'Faktureringsavgifter' },
  { account: '3541', accountName: 'Faktureringsavgifter, EU-land' },
  { account: '3542', accountName: 'Faktureringsavgifter, export' },
  { account: '3550', accountName: 'Fakturerade resekostnader' },
  { account: '3560', accountName: 'Fakturerade kostnader till koncernföretag' },
  { account: '3561', accountName: 'Fakturerade kostnader till moderföretag' },
  { account: '3562', accountName: 'Fakturerade kostnader till dotterföretag' },
  { account: '3563', accountName: 'Fakturerade kostnader till andra koncernföretag' },
  {
    account: '3570',
    accountName:
      'Fakturerade kostnader till intresseföretag, gemensamt styrda företag och övriga företag som det finns ett ägarintresse i',
  },
  { account: '3590', accountName: 'Övriga fakturerade kostnader' },
  { account: '3600', accountName: 'Rörelsens sidointäkter (gruppkonto)' },
  { account: '3610', accountName: 'Försäljning av material' },
  { account: '3611', accountName: 'Försäljning av råmaterial' },
  { account: '3612', accountName: 'Försäljning av skrot' },
  { account: '3613', accountName: 'Försäljning av förbrukningsmaterial' },
  { account: '3619', accountName: 'Försäljning av övrigt material' },
  { account: '3620', accountName: 'Tillfällig uthyrning av personal' },
  { account: '3630', accountName: 'Tillfällig uthyrning av transportmedel' },
  { account: '3670', accountName: 'Intäkter från värdepapper' },
  { account: '3671', accountName: 'Försäljning av värdepapper' },
  { account: '3672', accountName: 'Utdelning från värdepapper' },
  { account: '3679', accountName: 'Övriga intäkter från värdepapper' },
  { account: '3680', accountName: 'Management fees' },
  { account: '3690', accountName: 'Övriga sidointäkter' },
  { account: '3700', accountName: 'Intäktskorrigeringar (gruppkonto)' },
  { account: '3710', accountName: 'Ofördelade intäktsreduktioner' },
  { account: '3730', accountName: 'Lämnade rabatter' },
  { account: '3731', accountName: 'Lämnade kassarabatter' },
  { account: '3732', accountName: 'Lämnade mängdrabatter' },
  { account: '3740', accountName: 'Öres- och kronutjämning' },
  { account: '3750', accountName: 'Punktskatter' },
  { account: '3751', accountName: 'Intäktsförda punktskatter (kreditkonto)' },
  { account: '3752', accountName: 'Skuldförda punktskatter (debetkonto)' },
  { account: '3790', accountName: 'Övriga intäktskorrigeringar' },
  { account: '3800', accountName: 'Aktiverat arbete för egen räkning (gruppkonto)' },
  { account: '3840', accountName: 'Aktiverat arbete (material)' },
  { account: '3850', accountName: 'Aktiverat arbete (omkostnader)' },
  { account: '3870', accountName: 'Aktiverat arbete (personal)' },
  { account: '3900', accountName: 'Övriga rörelseintäkter (gruppkonto)' },
  { account: '3910', accountName: 'Hyres- och arrendeintäkter' },
  { account: '3911', accountName: 'Hyresintäkter' },
  { account: '3912', accountName: 'Arrendeintäkter' },
  { account: '3913', accountName: 'Frivilligt momspliktiga hyresintäkter' },
  { account: '3914', accountName: 'Övriga momspliktiga hyresintäkter' },
  { account: '3920', accountName: 'Provisionsintäkter, licensintäkter och royalties' },
  { account: '3921', accountName: 'Provisionsintäkter' },
  { account: '3922', accountName: 'Licensintäkter och royalties' },
  { account: '3925', accountName: 'Franchiseintäkter' },
  { account: '3950', accountName: 'Återvunna, tidigare avskrivna kundfordringar' },
  { account: '3960', accountName: 'Valutakursvinster på fordringar och skulder av rörelsekaraktär' },
  { account: '3970', accountName: 'Vinst vid avyttring av immateriella och materiella anläggningstillgångar' },
  { account: '3971', accountName: 'Vinst vid avyttring av immateriella anläggningstillgångar' },
  { account: '3972', accountName: 'Vinst vid avyttring av byggnader och mark' },
  { account: '3973', accountName: 'Vinst vid avyttring av maskiner och inventarier' },
  { account: '3980', accountName: 'Erhållna offentliga bidrag' },
  { account: '3981', accountName: 'Erhållna EU-bidrag' },
  { account: '3985', accountName: 'Erhållna statliga bidrag' },
  { account: '3987', accountName: 'Erhållna kommunala bidrag' },
  { account: '3988', accountName: 'Erhållna offentliga bidrag för personal' },
  { account: '3989', accountName: 'Övriga erhållna offentliga bidrag' },
  { account: '3990', accountName: 'Övriga ersättningar, bidrag och intäkter' },
  { account: '3991', accountName: 'Konfliktersättning' },
  { account: '3992', accountName: 'Erhållna skadestånd' },
  { account: '3993', accountName: 'Erhållna donationer och gåvor' },
  { account: '3994', accountName: 'Försäkringsersättningar' },
  { account: '3995', accountName: 'Erhållet ackord på skulder av rörelsekaraktär' },
  { account: '3996', accountName: 'Erhållna reklambidrag' },
  { account: '3997', accountName: 'Sjuklöneersättning' },
  { account: '3998', accountName: 'Återbäring av överskott från försäkringsföretag' },
  { account: '3999', accountName: 'Övriga rörelseintäkter' },
  { account: '4000', accountName: 'Inköp av varor från Sverige' },
  { account: '4200', accountName: 'Sålda varor VMB' },
  { account: '4211', accountName: 'Sålda varor positiv VMB 25 %' },
  { account: '4212', accountName: 'Sålda varor negativ VMB 25 %' },
  { account: '4400', accountName: 'Momspliktiga inköp i Sverige' },
  { account: '4415', accountName: 'Inköpta varor i Sverige, omvänd skattskyldighet, 25 % moms' },
  { account: '4416', accountName: 'Inköpta varor i Sverige, omvänd skattskyldighet, 12 % moms' },
  { account: '4417', accountName: 'Inköpta varor i Sverige, omvänd skattskyldighet, 6 % moms' },
  { account: '4425', accountName: 'Inköpta tjänster i Sverige, omvänd skattskyldighet, 25 % moms' },
  { account: '4426', accountName: 'Inköpta tjänster i Sverige, omvänd skattskyldighet, 12 %' },
  { account: '4427', accountName: 'Inköpta tjänster i Sverige, omvänd skattskyldighet, 6 %' },
  { account: '4500', accountName: 'Övriga momspliktiga inköp' },
  { account: '4515', accountName: 'Inköp av varor från annat EU-land, 25 %' },
  { account: '4516', accountName: 'Inköp av varor från annat EU-land, 12 %' },
  { account: '4517', accountName: 'Inköp av varor från annat EU-land, 6 %' },
  { account: '4518', accountName: 'Inköp av varor från annat EU-land, momsfri' },
  { account: '4531', accountName: 'Inköp av tjänster från ett land utanför EU, 25 % moms' },
  { account: '4532', accountName: 'Inköp av tjänster från ett land utanför EU, 12 % moms' },
  { account: '4533', accountName: 'Inköp av tjänster från ett land utanför EU, 6 % moms' },
  { account: '4535', accountName: 'Inköp av tjänster från annat EU-land, 25 %' },
  { account: '4536', accountName: 'Inköp av tjänster från annat EU-land, 12 %' },
  { account: '4537', accountName: 'Inköp av tjänster från annat EU-land, 6 %' },
  { account: '4538', accountName: 'Inköp av tjänster från annat EU-land, momsfri' },
  { account: '4545', accountName: 'Import av varor, 25 % moms' },
  { account: '4546', accountName: 'Import av varor, 12 % moms' },
  { account: '4547', accountName: 'Import av varor, 6 % moms' },
  { account: '4600', accountName: 'Legoarbeten och underentreprenader (gruppkonto)' },
  { account: '4700', accountName: 'Reduktion av inköpspriser (gruppkonto)' },
  { account: '4730', accountName: 'Erhållna rabatter' },
  { account: '4731', accountName: 'Erhållna kassarabatter' },
  { account: '4732', accountName: 'Erhållna mängdrabatter (inkl. bonus)' },
  { account: '4733', accountName: 'Erhållet aktivitetsstöd' },
  { account: '4790', accountName: 'Övriga reduktioner av inköpspriser' },
  { account: '4900', accountName: 'Förändring av lager (gruppkonto)' },
  { account: '4910', accountName: 'Förändring av lager av råvaror' },
  { account: '4920', accountName: 'Förändring av lager av tillsatsmaterial och förnödenheter' },
  { account: '4940', accountName: 'Förändring av produkter i arbete' },
  { account: '4944', accountName: 'Förändring av produkter i arbete, material och utlägg' },
  { account: '4945', accountName: 'Förändring av produkter i arbete, omkostnader' },
  { account: '4947', accountName: 'Förändring av produkter i arbete, personalkostnader' },
  { account: '4950', accountName: 'Förändring av lager av färdiga varor' },
  { account: '4960', accountName: 'Förändring av lager av handelsvaror' },
  { account: '4970', accountName: 'Förändring av pågående arbeten, nedlagda kostnader' },
  { account: '4974', accountName: 'Förändring av pågående arbeten, material och utlägg' },
  { account: '4975', accountName: 'Förändring av pågående arbeten, omkostnader' },
  { account: '4977', accountName: 'Förändring av pågående arbeten, personalkostnader' },
  { account: '4980', accountName: 'Förändring av lager av värdepapper' },
  { account: '4981', accountName: 'Sålda värdepappers anskaffningsvärde' },
  { account: '4987', accountName: 'Nedskrivning av värdepapper' },
  { account: '4988', accountName: 'Återföring av nedskrivning av värdepapper' },
  { account: '5000', accountName: 'Lokalkostnader (gruppkonto)' },
  { account: '5010', accountName: 'Lokalhyra' },
  { account: '5011', accountName: 'Hyra för kontorslokaler' },
  { account: '5012', accountName: 'Hyra för garage' },
  { account: '5013', accountName: 'Hyra för lagerlokaler' },
  { account: '5020', accountName: 'El för belysning' },
  { account: '5030', accountName: 'Värme' },
  { account: '5040', accountName: 'Vatten och avlopp' },
  { account: '5050', accountName: 'Lokaltillbehör' },
  { account: '5060', accountName: 'Städning och renhållning' },
  { account: '5061', accountName: 'Städning' },
  { account: '5062', accountName: 'Sophämtning' },
  { account: '5063', accountName: 'Hyra för sopcontainer' },
  { account: '5064', accountName: 'Snöröjning' },
  { account: '5065', accountName: 'Trädgårdsskötsel' },
  { account: '5070', accountName: 'Reparation och underhåll av lokaler' },
  { account: '5090', accountName: 'Övriga lokalkostnader' },
  { account: '5098', accountName: 'Övriga lokalkostnader, avdragsgilla' },
  { account: '5099', accountName: 'Övriga lokalkostnader, ej avdragsgilla' },
  { account: '5100', accountName: 'Fastighetskostnader (gruppkonto)' },
  { account: '5110', accountName: 'Tomträttsavgäld/arrende' },
  { account: '5120', accountName: 'El för belysning' },
  { account: '5130', accountName: 'Värme' },
  { account: '5131', accountName: 'Uppvärmning' },
  { account: '5132', accountName: 'Sotning' },
  { account: '5140', accountName: 'Vatten och avlopp' },
  { account: '5160', accountName: 'Städning och renhållning' },
  { account: '5161', accountName: 'Städning' },
  { account: '5162', accountName: 'Sophämtning' },
  { account: '5163', accountName: 'Hyra för sopcontainer' },
  { account: '5164', accountName: 'Snöröjning' },
  { account: '5165', accountName: 'Trädgårdsskötsel' },
  { account: '5170', accountName: 'Reparation och underhåll av fastighet' },
  { account: '5190', accountName: 'Övriga fastighetskostnader' },
  { account: '5191', accountName: 'Fastighetsskatt/fastighetsavgift' },
  { account: '5192', accountName: 'Fastighetsförsäkringspremier' },
  { account: '5193', accountName: 'Fastighetsskötsel och förvaltning' },
  { account: '5198', accountName: 'Övriga fastighetskostnader, avdragsgilla' },
  { account: '5199', accountName: 'Övriga fastighetskostnader, ej avdragsgilla' },
  { account: '5200', accountName: 'Hyra av anläggningstillgångar (gruppkonto)' },
  { account: '5210', accountName: 'Hyra av maskiner och andra tekniska anläggningar' },
  { account: '5211', accountName: 'Korttidshyra av maskiner och andra tekniska anläggningar' },
  { account: '5212', accountName: 'Leasing av maskiner och andra tekniska anläggningar' },
  { account: '5220', accountName: 'Hyra av inventarier och verktyg' },
  { account: '5221', accountName: 'Korttidshyra av inventarier och verktyg' },
  { account: '5222', accountName: 'Leasing av inventarier och verktyg' },
  { account: '5250', accountName: 'Hyra av datorer' },
  { account: '5251', accountName: 'Korttidshyra av datorer' },
  { account: '5252', accountName: 'Leasing av datorer' },
  { account: '5290', accountName: 'Övriga hyreskostnader för anläggningstillgångar' },
  { account: '5300', accountName: 'Energikostnader (gruppkonto)' },
  { account: '5310', accountName: 'El för drift' },
  { account: '5320', accountName: 'Gas' },
  { account: '5330', accountName: 'Eldningsolja' },
  { account: '5340', accountName: 'Stenkol och koks' },
  { account: '5350', accountName: 'Torv, träkol, ved och annat träbränsle' },
  { account: '5360', accountName: 'Bensin, fotogen och motorbrännolja' },
  { account: '5370', accountName: 'Fjärrvärme, kyla och ånga' },
  { account: '5380', accountName: 'Vatten' },
  { account: '5390', accountName: 'Övriga energikostnader' },
  { account: '5400', accountName: 'Förbrukningsinventarier och förbrukningsmaterial (gruppkonto)' },
  { account: '5410', accountName: 'Förbrukningsinventarier' },
  { account: '5411', accountName: 'Förbrukningsinventarier med en livslängd på mer än ett år' },
  { account: '5412', accountName: 'Förbrukningsinventarier med en livslängd på ett år eller mindre' },
  { account: '5420', accountName: 'Programvaror' },
  { account: '5430', accountName: 'Transportinventarier' },
  { account: '5440', accountName: 'Förbrukningsemballage' },
  { account: '5460', accountName: 'Förbrukningsmaterial' },
  { account: '5480', accountName: 'Arbetskläder och skyddsmaterial' },
  { account: '5490', accountName: 'Övriga förbrukningsinventarier och förbrukningsmaterial' },
  { account: '5491', accountName: 'Övriga förbrukningsinventarier med en livslängd på mer än ett år' },
  { account: '5492', accountName: 'Övriga förbrukningsinventarier med en livslängd på ett år eller mindre' },
  { account: '5493', accountName: 'Övrigt förbrukningsmaterial' },
  { account: '5500', accountName: 'Reparation och underhåll (gruppkonto)' },
  { account: '5510', accountName: 'Reparation och underhåll av maskiner och andra tekniska anläggningar' },
  { account: '5520', accountName: 'Reparation och underhåll av inventarier, verktyg och datorer m.m.' },
  { account: '5530', accountName: 'Reparation och underhåll av installationer' },
  { account: '5550', accountName: 'Reparation och underhåll av förbrukningsinventarier' },
  { account: '5580', accountName: 'Underhåll och tvätt av arbetskläder' },
  { account: '5590', accountName: 'Övriga kostnader för reparation och underhåll' },
  { account: '5600', accountName: 'Kostnader för transportmedel (gruppkonto)' },
  { account: '5610', accountName: 'Personbilskostnader' },
  { account: '5611', accountName: 'Drivmedel för personbilar' },
  { account: '5612', accountName: 'Försäkring och skatt för personbilar' },
  { account: '5613', accountName: 'Reparation och underhåll av personbilar' },
  { account: '5615', accountName: 'Leasing av personbilar' },
  { account: '5616', accountName: 'Trängselskatt, avdragsgill' },
  { account: '5619', accountName: 'Övriga personbilskostnader' },
  { account: '5620', accountName: 'Lastbilskostnader' },
  { account: '5630', accountName: 'Truckkostnader' },
  { account: '5640', accountName: 'Kostnader för arbetsmaskiner' },
  { account: '5650', accountName: 'Traktorkostnader' },
  { account: '5660', accountName: 'Motorcykel-, moped- och skoterkostnader' },
  { account: '5670', accountName: 'Båt-, flygplans- och helikopterkostnader' },
  { account: '5690', accountName: 'Övriga kostnader för transportmedel' },
  { account: '5700', accountName: 'Frakter och transporter (gruppkonto)' },
  { account: '5710', accountName: 'Frakter, transporter och försäkringar vid varudistribution' },
  { account: '5720', accountName: 'Tull- och speditionskostnader m.m.' },
  { account: '5730', accountName: 'Arbetstransporter' },
  { account: '5790', accountName: 'Övriga kostnader för frakter och transporter' },
  { account: '5800', accountName: 'Resekostnader (gruppkonto)' },
  { account: '5810', accountName: 'Biljetter' },
  { account: '5820', accountName: 'Hyrbilskostnader' },
  { account: '5830', accountName: 'Kost och logi' },
  { account: '5831', accountName: 'Kost och logi i Sverige' },
  { account: '5832', accountName: 'Kost och logi i utlandet' },
  { account: '5890', accountName: 'Övriga resekostnader' },
  { account: '5900', accountName: 'Reklam och PR (gruppkonto)' },
  { account: '5910', accountName: 'Annonsering' },
  { account: '5920', accountName: 'Utomhus- och trafikreklam' },
  { account: '5930', accountName: 'Reklamtrycksaker och direktreklam' },
  { account: '5940', accountName: 'Utställningar och mässor' },
  { account: '5950', accountName: 'Butiksreklam och återförsäljarreklam' },
  { account: '5960', accountName: 'Varuprover, reklamgåvor, presentreklam och tävlingar' },
  { account: '5970', accountName: 'Film-, radio-, TV- och Internetreklam' },
  { account: '5980', accountName: 'PR, institutionell reklam och sponsring' },
  { account: '5990', accountName: 'Övriga kostnader för reklam och PR' },
  { account: '6000', accountName: 'Övriga försäljningskostnader (gruppkonto)' },
  { account: '6010', accountName: 'Kataloger, prislistor m.m.' },
  { account: '6020', accountName: 'Egna facktidskrifter' },
  { account: '6030', accountName: 'Speciella orderkostnader' },
  { account: '6040', accountName: 'Kontokortsavgifter' },
  { account: '6050', accountName: 'Försäljningsprovisioner' },
  { account: '6055', accountName: 'Franchisekostnader o.dyl.' },
  { account: '6060', accountName: 'Kreditförsäljningskostnader' },
  { account: '6061', accountName: 'Kreditupplysning' },
  { account: '6062', accountName: 'Inkasso och KFM-avgifter' },
  { account: '6063', accountName: 'Kreditförsäkringspremier' },
  { account: '6064', accountName: 'Factoringavgifter' },
  { account: '6069', accountName: 'Övriga kreditförsäljningskostnader' },
  { account: '6070', accountName: 'Representation' },
  { account: '6071', accountName: 'Representation, avdragsgill' },
  { account: '6072', accountName: 'Representation, ej avdragsgill' },
  { account: '6080', accountName: 'Bankgarantier' },
  { account: '6090', accountName: 'Övriga försäljningskostnader' },
  { account: '6100', accountName: 'Kontorsmateriel och trycksaker (gruppkonto)' },
  { account: '6110', accountName: 'Kontorsmateriel' },
  { account: '6150', accountName: 'Trycksaker' },
  { account: '6200', accountName: 'Tele och post (gruppkonto)' },
  { account: '6210', accountName: 'Telekommunikation' },
  { account: '6211', accountName: 'Fast telefoni' },
  { account: '6212', accountName: 'Mobiltelefon' },
  { account: '6213', accountName: 'Mobilsökning' },
  { account: '6214', accountName: 'Fax' },
  { account: '6215', accountName: 'Telex' },
  { account: '6230', accountName: 'Datakommunikation' },
  { account: '6250', accountName: 'Postbefordran' },
  { account: '6300', accountName: 'Företagsförsäkringar och övriga riskkostnader (gruppkonto)' },
  { account: '6310', accountName: 'Företagsförsäkringar' },
  { account: '6320', accountName: 'Självrisker vid skada' },
  { account: '6330', accountName: 'Förluster i pågående arbeten' },
  { account: '6340', accountName: 'Lämnade skadestånd' },
  { account: '6341', accountName: 'Lämnade skadestånd, avdragsgilla' },
  { account: '6342', accountName: 'Lämnade skadestånd, ej avdragsgilla' },
  { account: '6350', accountName: 'Förluster på kundfordringar' },
  { account: '6351', accountName: 'Konstaterade förluster på kundfordringar' },
  { account: '6352', accountName: 'Befarade förluster på kundfordringar' },
  { account: '6360', accountName: 'Garantikostnader' },
  { account: '6361', accountName: 'Förändring av garantiavsättning' },
  { account: '6362', accountName: 'Faktiska garantikostnader' },
  { account: '6370', accountName: 'Kostnader för bevakning och larm' },
  { account: '6380', accountName: 'Förluster på övriga kortfristiga fordringar' },
  { account: '6390', accountName: 'Övriga riskkostnader' },
  { account: '6400', accountName: 'Förvaltningskostnader (gruppkonto)' },
  { account: '6410', accountName: 'Styrelsearvoden som inte är lön' },
  { account: '6420', accountName: 'Ersättningar till revisor' },
  { account: '6421', accountName: 'Revision' },
  { account: '6422', accountName: 'Revisonsverksamhet utöver revision' },
  { account: '6423', accountName: 'Skatterådgivning – revisor' },
  { account: '6424', accountName: 'Övriga tjänster – revisor' },
  { account: '6430', accountName: 'Management fees' },
  { account: '6440', accountName: 'Årsredovisning och delårsrapporter' },
  { account: '6450', accountName: 'Bolagsstämma/års- eller föreningsstämma' },
  { account: '6490', accountName: 'Övriga förvaltningskostnader' },
  { account: '6500', accountName: 'Övriga externa tjänster (gruppkonto)' },
  { account: '6510', accountName: 'Mätningskostnader' },
  { account: '6520', accountName: 'Ritnings- och kopieringskostnader' },
  { account: '6530', accountName: 'Redovisningstjänster' },
  { account: '6540', accountName: 'IT-tjänster' },
  { account: '6550', accountName: 'Konsultarvoden' },
  { account: '6551', accountName: 'Arkitekttjänster' },
  { account: '6552', accountName: 'Teknisk provning och analys' },
  { account: '6553', accountName: 'Tekniska konsulttjänster' },
  { account: '6554', accountName: 'Finansiell- och övrig ekonomisk rådgivning' },
  { account: '6555', accountName: 'Skatterådgivning inkl. insolvens- och konkursförvaltning' },
  { account: '6556', accountName: 'Köpta tjänster avseende forskning och utveckling' },
  { account: '6559', accountName: 'Övrig konsultverksamhet' },
  { account: '6560', accountName: 'Serviceavgifter till branschorganisationer' },
  { account: '6570', accountName: 'Bankkostnader' },
  { account: '6580', accountName: 'Advokat- och rättegångskostnader' },
  { account: '6590', accountName: 'Övriga externa tjänster' },
  { account: '6800', accountName: 'Inhyrd personal (gruppkonto)' },
  { account: '6810', accountName: 'Inhyrd produktionspersonal' },
  { account: '6820', accountName: 'Inhyrd lagerpersonal' },
  { account: '6830', accountName: 'Inhyrd transportpersonal' },
  { account: '6840', accountName: 'Inhyrd kontors- och ekonomipersonal' },
  { account: '6850', accountName: 'Inhyrd IT-personal' },
  { account: '6860', accountName: 'Inhyrd marknads- och försäljningspersonal' },
  { account: '6870', accountName: 'Inhyrd restaurang- och butikspersonal' },
  { account: '6880', accountName: 'Inhyrda företagsledare' },
  { account: '6890', accountName: 'Övrig inhyrd personal' },
  { account: '6900', accountName: 'Övriga externa kostnader (gruppkonto)' },
  { account: '6910', accountName: 'Licensavgifter och royalties' },
  { account: '6920', accountName: 'Kostnader för egna patent' },
  { account: '6930', accountName: 'Kostnader för varumärken m.m.' },
  { account: '6940', accountName: 'Kontroll-, provnings- och stämpelavgifter' },
  { account: '6950', accountName: 'Tillsynsavgifter myndigheter' },
  { account: '6970', accountName: 'Tidningar, tidskrifter och facklitteratur' },
  { account: '6980', accountName: 'Föreningsavgifter' },
  { account: '6981', accountName: 'Föreningsavgifter, avdragsgilla' },
  { account: '6982', accountName: 'Föreningsavgifter, ej avdragsgilla' },
  { account: '6990', accountName: 'Övriga externa kostnader' },
  { account: '6991', accountName: 'Övriga externa kostnader, avdragsgilla' },
  { account: '6992', accountName: 'Övriga externa kostnader, ej avdragsgilla' },
  { account: '6993', accountName: 'Lämnade bidrag och gåvor' },
  { account: '6996', accountName: 'Betald utländsk inkomstskatt' },
  { account: '6997', accountName: 'Obetald utländsk inkomstskatt' },
  { account: '6998', accountName: 'Utländsk moms' },
  { account: '6999', accountName: 'Ingående moms, blandad verksamhet' },
  { account: '7000', accountName: 'Löner till kollektivanställda (gruppkonto)' },
  { account: '7010', accountName: 'Löner till kollektivanställda' },
  { account: '7011', accountName: 'Löner till kollektivanställda' },
  { account: '7012', accountName: 'Vinstandelar till kollektivanställda' },
  { account: '7013', accountName: 'Lön växa-stöd kollektivanställda 10,21 %' },
  { account: '7017', accountName: 'Avgångsvederlag till kollektivanställda' },
  { account: '7018', accountName: 'Bruttolöneavdrag, kollektivanställda' },
  { account: '7019', accountName: 'Upplupna löner och vinstandelar till kollektivanställda' },
  { account: '7030', accountName: 'Löner till kollektivanställda (utlandsanställda)' },
  { account: '7031', accountName: 'Löner till kollektivanställda (utlandsanställda)' },
  { account: '7032', accountName: 'Vinstandelar till kollektivanställda (utlandsanställda)' },
  { account: '7037', accountName: 'Avgångsvederlag till kollektivanställda (utlandsanställda)' },
  { account: '7038', accountName: 'Bruttolöneavdrag, kollektivanställda (utlandsanställda)' },
  { account: '7039', accountName: 'Upplupna löner och vinstandelar till kollektivanställda (utlandsanställda)' },
  { account: '7080', accountName: 'Löner till kollektivanställda för ej arbetad tid' },
  { account: '7081', accountName: 'Sjuklöner till kollektivanställda' },
  { account: '7082', accountName: 'Semesterlöner till kollektivanställda' },
  { account: '7083', accountName: 'Föräldraersättning till kollektivanställda' },
  { account: '7089', accountName: 'Övriga löner till kollektivanställda för ej arbetad tid' },
  { account: '7090', accountName: 'Förändring av semesterlöneskuld' },
  { account: '7200', accountName: 'Löner till tjänstemän och företagsledare (gruppkonto)' },
  { account: '7210', accountName: 'Löner till tjänstemän' },
  { account: '7211', accountName: 'Löner till tjänstemän' },
  { account: '7212', accountName: 'Vinstandelar till tjänstemän' },
  { account: '7213', accountName: 'Lön växa-stöd tjänstemän 10,21 %' },
  { account: '7217', accountName: 'Avgångsvederlag till tjänstemän' },
  { account: '7218', accountName: 'Bruttolöneavdrag, tjänstemän' },
  { account: '7219', accountName: 'Upplupna löner och vinstandelar till tjänstemän' },
  { account: '7220', accountName: 'Löner till företagsledare' },
  { account: '7221', accountName: 'Löner till företagsledare' },
  { account: '7222', accountName: 'Tantiem till företagsledare' },
  { account: '7227', accountName: 'Avgångsvederlag till företagsledare' },
  { account: '7228', accountName: 'Bruttolöneavdrag, företagsledare' },
  { account: '7229', accountName: 'Upplupna löner och tantiem till företagsledare' },
  { account: '7230', accountName: 'Löner till tjänstemän och ftgsledare (utlandsanställda)' },
  { account: '7231', accountName: 'Löner till tjänstemän och ftgsledare (utlandsanställda)' },
  { account: '7232', accountName: 'Vinstandelar till tjänstemän och ftgsledare (utlandsanställda)' },
  { account: '7237', accountName: 'Avgångsvederlag till tjänstemän och ftgsledare (utlandsanställda)' },
  { account: '7238', accountName: 'Bruttolöneavdrag, tjänstemän och ftgsledare (utlandsanställda)' },
  { account: '7239', accountName: 'Upplupna löner och vinstandelar till tjänstemän och ftgsledare (utlandsanställda)' },
  { account: '7240', accountName: 'Styrelsearvoden' },
  { account: '7280', accountName: 'Löner till tjänstemän och företagsledare för ej arbetad tid' },
  { account: '7281', accountName: 'Sjuklöner till tjänstemän' },
  { account: '7282', accountName: 'Sjuklöner till företagsledare' },
  { account: '7283', accountName: 'Föräldraersättning till tjänstemän' },
  { account: '7284', accountName: 'Föräldraersättning till företagsledare' },
  { account: '7285', accountName: 'Semesterlöner till tjänstemän' },
  { account: '7286', accountName: 'Semesterlöner till företagsledare' },
  { account: '7288', accountName: 'Övriga löner till tjänstemän för ej arbetad tid' },
  { account: '7289', accountName: 'Övriga löner till företagsledare för ej arbetad tid' },
  { account: '7290', accountName: 'Förändring av semesterlöneskuld' },
  { account: '7291', accountName: 'Förändring av semesterlöneskuld till tjänstemän' },
  { account: '7292', accountName: 'Förändring av semesterlöneskuld till företagsledare' },
  { account: '7300', accountName: 'Kostnadsersättningar och förmåner (gruppkonto)' },
  { account: '7310', accountName: 'Kontanta extraersättningar' },
  { account: '7311', accountName: 'Ersättningar för sammanträden m.m.' },
  { account: '7312', accountName: 'Ersättningar för förslagsverksamhet och uppfinningar' },
  { account: '7313', accountName: 'Ersättningar för/bidrag till bostadskostnader' },
  { account: '7314', accountName: 'Ersättningar för/bidrag till måltidskostnader' },
  { account: '7315', accountName: 'Ersättningar för/bidrag till resor till och från arbetsplatsen' },
  { account: '7316', accountName: 'Ersättningar för/bidrag till arbetskläder' },
  { account: '7317', accountName: 'Ersättningar för/bidrag till arbetsmaterial och arbetsverktyg' },
  { account: '7318', accountName: 'Felräkningspengar' },
  { account: '7319', accountName: 'Övriga kontanta extraersättningar' },
  { account: '7320', accountName: 'Traktamenten vid tjänsteresa' },
  { account: '7321', accountName: 'Skattefria traktamenten, Sverige' },
  { account: '7322', accountName: 'Skattepliktiga traktamenten, Sverige' },
  { account: '7323', accountName: 'Skattefria traktamenten, utlandet' },
  { account: '7324', accountName: 'Skattepliktiga traktamenten, utlandet' },
  { account: '7330', accountName: 'Bilersättningar' },
  { account: '7331', accountName: 'Skattefria bilersättningar' },
  { account: '7332', accountName: 'Skattepliktiga bilersättningar' },
  { account: '7333', accountName: 'Ersättning för trängselskatt, skattefri' },
  { account: '7350', accountName: 'Ersättningar för föreskrivna arbetskläder' },
  { account: '7370', accountName: 'Representationsersättningar' },
  { account: '7380', accountName: 'Kostnader för förmåner till anställda' },
  { account: '7381', accountName: 'Kostnader för fri bostad' },
  { account: '7382', accountName: 'Kostnader för fria eller subventionerade måltider' },
  { account: '7383', accountName: 'Kostnader för fria resor till och från arbetsplatsen' },
  { account: '7384', accountName: 'Kostnader för fria eller subventionerade arbetskläder' },
  { account: '7385', accountName: 'Kostnader för fri bil' },
  { account: '7386', accountName: 'Subventionerad ränta' },
  { account: '7387', accountName: 'Kostnader för lånedatorer' },
  { account: '7388', accountName: 'Anställdas ersättning för erhållna förmåner' },
  { account: '7389', accountName: 'Övriga kostnader för förmåner' },
  { account: '7390', accountName: 'Övriga kostnadsersättningar och förmåner' },
  { account: '7391', accountName: 'Kostnad för trängselskatteförmån' },
  { account: '7392', accountName: 'Kostnad för förmån av hushållsnära tjänster' },
  { account: '7400', accountName: 'Pensionskostnader (gruppkonto)' },
  { account: '7410', accountName: 'Pensionsförsäkringspremier' },
  { account: '7411', accountName: 'Premier för kollektiva pensionsförsäkringar' },
  { account: '7412', accountName: 'Premier för individuella pensionsförsäkringar' },
  { account: '7420', accountName: 'Förändring av pensionsskuld' },
  { account: '7430', accountName: 'Avdrag för räntedel i pensionskostnad' },
  { account: '7440', accountName: 'Förändring av pensionsstiftelsekapital' },
  { account: '7441', accountName: 'Överföring av medel till pensionsstiftelse' },
  { account: '7448', accountName: 'Gottgörelse från pensionsstiftelse' },
  { account: '7460', accountName: 'Pensionsutbetalningar' },
  { account: '7461', accountName: 'Pensionsutbetalningar till f.d. kollektivanställda' },
  { account: '7462', accountName: 'Pensionsutbetalningar till f.d. tjänstemän' },
  { account: '7463', accountName: 'Pensionsutbetalningar till f.d. företagsledare' },
  { account: '7470', accountName: 'Förvaltnings- och kreditförsäkringsavgifter' },
  { account: '7490', accountName: 'Övriga pensionskostnader' },
  { account: '7500', accountName: 'Sociala och andra avgifter enligt lag och avtal (gruppkonto)' },
  { account: '7510', accountName: 'Arbetsgivaravgifter 31,42 %' },
  { account: '7511', accountName: 'Arbetsgivaravgifter för löner och ersättningar' },
  { account: '7512', accountName: 'Arbetsgivaravgifter för förmånsvärden' },
  { account: '7515', accountName: 'Arbetsgivaravgifter på skattepliktiga kostnadsersättningar' },
  { account: '7516', accountName: 'Arbetsgivaravgifter på arvoden' },
  { account: '7518', accountName: 'Arbetsgivaravgifter på bruttolöneavdrag m.m.' },
  { account: '7519', accountName: 'Arbetsgivaravgifter för semester- och löneskulder' },
  { account: '7530', accountName: 'Särskild löneskatt' },
  { account: '7531', accountName: 'Särskild löneskatt för vissa försäkringsersättningar m.m.' },
  { account: '7532', accountName: 'Särskild löneskatt pensionskostnader, deklarationspost' },
  { account: '7533', accountName: 'Särskild löneskatt för pensionskostnader' },
  { account: '7550', accountName: 'Avkastningsskatt på pensionsmedel' },
  { account: '7551', accountName: 'Avkastningsskatt 15 % försäkringsföretag m.fl. samt avsatt till pensioner' },
  { account: '7552', accountName: 'Avkastningsskatt 15 % utländska pensionsförsäkringar' },
  { account: '7553', accountName: 'Avkastningsskatt 30 % utländska försäkringsföretag m.fl.' },
  { account: '7554', accountName: 'Avkastningsskatt 30 % utländska kapitalförsäkringar' },
  { account: '7570', accountName: 'Premier för arbetsmarknadsförsäkringar' },
  { account: '7571', accountName: 'Arbetsmarknadsförsäkringar' },
  { account: '7572', accountName: 'Arbetsmarknadsförsäkringar pensionsförsäkringspremier, deklarationspost' },
  { account: '7580', accountName: 'Gruppförsäkringspremier' },
  { account: '7581', accountName: 'Grupplivförsäkringspremier' },
  { account: '7582', accountName: 'Gruppsjukförsäkringspremier' },
  { account: '7583', accountName: 'Gruppolycksfallsförsäkringspremier' },
  { account: '7589', accountName: 'Övriga gruppförsäkringspremier' },
  { account: '7590', accountName: 'Övriga sociala och andra avgifter enligt lag och avtal' },
  { account: '7600', accountName: 'Övriga personalkostnader (gruppkonto)' },
  { account: '7610', accountName: 'Utbildning' },
  { account: '7620', accountName: 'Sjuk- och hälsovård' },
  { account: '7621', accountName: 'Sjuk- och hälsovård, avdragsgill' },
  { account: '7622', accountName: 'Sjuk- och hälsovård, ej avdragsgill' },
  { account: '7623', accountName: 'Sjukvårdsförsäkring, ej avdragsgill' },
  { account: '7630', accountName: 'Personalrepresentation' },
  { account: '7631', accountName: 'Personalrepresentation, avdragsgill' },
  { account: '7632', accountName: 'Personalrepresentation, ej avdragsgill' },
  { account: '7650', accountName: 'Sjuklöneförsäkring' },
  { account: '7670', accountName: 'Förändring av personalstiftelsekapital' },
  { account: '7671', accountName: 'Avsättning till personalstiftelse' },
  { account: '7678', accountName: 'Gottgörelse från personalstiftelse' },
  { account: '7690', accountName: 'Övriga personalkostnader' },
  { account: '7691', accountName: 'Personalrekrytering' },
  { account: '7692', accountName: 'Begravningshjälp' },
  { account: '7693', accountName: 'Fritidsverksamhet' },
  { account: '7699', accountName: 'Övriga personalkostnader' },
  { account: '7710', accountName: 'Nedskrivningar av immateriella anläggningstillgångar' },
  { account: '7720', accountName: 'Nedskrivningar av byggnader och mark' },
  { account: '7730', accountName: 'Nedskrivningar av maskiner och inventarier' },
  { account: '7740', accountName: 'Nedskrivningar av vissa omsättningstillgångar' },
  { account: '7760', accountName: 'Återföring av nedskrivningar av immateriella anläggningstillgångar' },
  { account: '7770', accountName: 'Återföring av nedskrivningar av byggnader och mark' },
  { account: '7780', accountName: 'Återföring av nedskrivningar av maskiner och inventarier' },
  { account: '7790', accountName: 'Återföring av nedskrivningar av vissa omsättningstillgångar' },
  { account: '7810', accountName: 'Avskrivningar på immateriella anläggningstillgångar' },
  { account: '7811', accountName: 'Avskrivningar på balanserade utgifter' },
  { account: '7812', accountName: 'Avskrivningar på koncessioner m.m.' },
  { account: '7813', accountName: 'Avskrivningar på patent' },
  { account: '7814', accountName: 'Avskrivningar på licenser' },
  { account: '7815', accountName: 'Avskrivningar på varumärken' },
  { account: '7816', accountName: 'Avskrivningar på hyresrätter' },
  { account: '7817', accountName: 'Avskrivningar på goodwill' },
  { account: '7819', accountName: 'Avskrivningar på övriga immateriella anläggningstillgångar' },
  { account: '7820', accountName: 'Avskrivningar på byggnader och markanläggningar' },
  { account: '7821', accountName: 'Avskrivningar på byggnader' },
  { account: '7824', accountName: 'Avskrivningar på markanläggningar' },
  { account: '7829', accountName: 'Avskrivningar på övriga byggnader' },
  { account: '7830', accountName: 'Avskrivningar på maskiner och inventarier' },
  { account: '7831', accountName: 'Avskrivningar på maskiner och andra tekniska anläggningar' },
  { account: '7832', accountName: 'Avskrivningar på inventarier och verktyg' },
  { account: '7833', accountName: 'Avskrivningar på installationer' },
  { account: '7834', accountName: 'Avskrivningar på bilar och andra transportmedel' },
  { account: '7835', accountName: 'Avskrivningar på datorer' },
  { account: '7836', accountName: 'Avskrivningar på leasade tillgångar' },
  { account: '7839', accountName: 'Avskrivningar på övriga maskiner och inventarier' },
  { account: '7840', accountName: 'Avskrivningar på förbättringsutgifter på annans fastighet' },
  { account: '7960', accountName: 'Valutakursförluster på fordringar och skulder av rörelsekaraktär' },
  { account: '7970', accountName: 'Förlust vid avyttring av immateriella och materiella anläggningstillgångar' },
  { account: '7971', accountName: 'Förlust vid avyttring av immateriella anläggningstillgångar' },
  { account: '7972', accountName: 'Förlust vid avyttring av byggnader och mark' },
  { account: '7973', accountName: 'Förlust vid avyttring av maskiner och inventarier' },
  { account: '7990', accountName: 'Övriga rörelsekostnader' },
  { account: '8010', accountName: 'Utdelning på andelar i koncernföretag' },
  { account: '8012', accountName: 'Utdelning på andelar i dotterföretag' },
  { account: '8016', accountName: 'Emissionsinsats, koncernföretag' },
  { account: '8020', accountName: 'Resultat vid försäljning av andelar i koncernföretag' },
  { account: '8022', accountName: 'Resultat vid försäljning av andelar i dotterföretag' },
  { account: '8030', accountName: 'Resultatandelar från handelsbolag (dotterföretag)' },
  { account: '8070', accountName: 'Nedskrivningar av andelar i och långfristiga fordringar hos koncernföretag' },
  { account: '8072', accountName: 'Nedskrivningar av andelar i dotterföretag' },
  { account: '8076', accountName: 'Nedskrivningar av långfristiga fordringar hos moderföretag' },
  { account: '8077', accountName: 'Nedskrivningar av långfristiga fordringar hos dotterföretag' },
  {
    account: '8080',
    accountName: 'Återföringar av nedskrivningar av andelar i och långfristiga fordringar hos koncernföretag',
  },
  { account: '8082', accountName: 'Återföringar av nedskrivningar av andelar i dotterföretag' },
  { account: '8086', accountName: 'Återföringar av nedskrivningar av långfristiga fordringar hos moderföretag' },
  { account: '8087', accountName: 'Återföringar av nedskrivningar av långfristiga fordringar hos dotterföretag' },
  {
    account: '8110',
    accountName:
      'Utdelningar på andelar i intresseföretag, gemensamt styrda företag och övriga företag som det finns ett ägarintresse i',
  },
  { account: '8111', accountName: 'Utdelningar på andelar i intresseföretag' },
  { account: '8112', accountName: 'Utdelningar på andelar i gemensamt styrda företag' },
  { account: '8113', accountName: 'Utdelningar på andelar i övriga företag som det finns ett ägarintresse i' },
  { account: '8116', accountName: 'Emissionsinsats, intresseföretag' },
  { account: '8117', accountName: 'Emissionsinsats, gemensamt styrda företag' },
  { account: '8118', accountName: 'Emissionsinsats, övriga företag som det finns ett ägarintresse i' },
  {
    account: '8120',
    accountName:
      'Resultat vid försäljning av andelar i intresseföretag, gemensamt styrda företag och övriga företag som det finns ett ägarintresse i',
  },
  { account: '8121', accountName: 'Resultat vid försäljning av andelar i intresseföretag' },
  { account: '8122', accountName: 'Resultat vid försäljning av andelar i gemensamt styrda företag' },
  {
    account: '8123',
    accountName: 'Resultat vid försäljning av andelar i övriga företag som det finns ett ägarintresse i',
  },
  {
    account: '8130',
    accountName:
      'Resultatandelar från handelsbolag (intresseföretag, gemensamt styrda företag och övriga företag som det finns ett ägarintresse i)',
  },
  { account: '8131', accountName: 'Resultatandelar från handelsbolag (intresseföretag)' },
  { account: '8132', accountName: 'Resultatandelar från handelsbolag (gemensamt styrda företag)' },
  {
    account: '8133',
    accountName: 'Resultatandelar från handelsbolag (övriga företag som det finns ett ägarintresse i)',
  },
  {
    account: '8170',
    accountName:
      'Nedskrivningar av andelar i och långfristiga fordringar hos intresseföretag, gemensamt styrda företag och övriga företag som det finns ett ägarintresse i',
  },
  { account: '8171', accountName: 'Nedskrivningar av andelar i intresseföretag' },
  { account: '8172', accountName: 'Nedskrivningar av långfristiga fordringar hos intresseföretag' },
  { account: '8173', accountName: 'Nedskrivningar av andelar i gemensamt styrda företag' },
  { account: '8174', accountName: 'Nedskrivningar av långfristiga fordringar hos gemensamt styrda företag' },
  { account: '8176', accountName: 'Nedskrivningar av andelar i övriga företag som det finns ett ägarintresse i' },
  {
    account: '8177',
    accountName: 'Nedskrivningar av långfristiga fordringar hos övriga företag som det finns ett ägarintresse i',
  },
  {
    account: '8180',
    accountName: 'Återföringar av nedskrivningar av andelar i och långfristiga fordringar hos intresseföretag',
  },
  { account: '8181', accountName: 'Återföringar av nedskrivningar av andelar i intresseföretag' },
  { account: '8182', accountName: 'Återföringar av nedskrivningar av långfristiga fordringar hos intresseföretag' },
  { account: '8183', accountName: 'Återföringar av nedskrivningar av andelar i gemensamt styrda företag' },
  {
    account: '8184',
    accountName: 'Återföringar av nedskrivningar av långfristiga fordringar hos gemensamt styrda företag',
  },
  {
    account: '8186',
    accountName: 'Återföringar av nedskrivningar av andelar i övriga företag som det finns ett ägarintresse i',
  },
  {
    account: '8187',
    accountName:
      'Återföringar av nedskrivningar av långfristiga fordringar hos övriga företag som det finns ett ägarintresse i',
  },
  { account: '8210', accountName: 'Utdelningar på andelar i andra företag' },
  { account: '8212', accountName: 'Utdelningar, övriga företag' },
  { account: '8216', accountName: 'Insatsemissioner, övriga företag' },
  {
    account: '8220',
    accountName: 'Resultat vid försäljning av värdepapper i och långfristiga fordringar hos andra företag',
  },
  { account: '8221', accountName: 'Resultat vid försäljning av andelar i andra företag' },
  { account: '8222', accountName: 'Resultat vid försäljning av långfristiga fordringar hos andra företag' },
  { account: '8223', accountName: 'Resultat vid försäljning av långfristiga fordringar hos andra företag' },
  { account: '8230', accountName: 'Valutakursdifferenser på långfristiga fordringar' },
  { account: '8231', accountName: 'Valutakursvinster på långfristiga fordringar' },
  { account: '8236', accountName: 'Valutakursförluster på långfristiga fordringar' },
  { account: '8240', accountName: 'Resultatandelar från handelsbolag (andra företag)' },
  { account: '8250', accountName: 'Ränteintäkter från långfristiga fordringar hos och värdepapper i andra företag' },
  { account: '8251', accountName: 'Ränteintäkter från långfristiga fordringar' },
  { account: '8252', accountName: 'Ränteintäkter från övriga värdepapper' },
  { account: '8254', accountName: 'Skattefria ränteintäkter, långfristiga tillgångar' },
  { account: '8255', accountName: 'Avkastningsskatt kapitalplacering' },
  { account: '8260', accountName: 'Ränteintäkter från långfristiga fordringar hos koncernföretag' },
  { account: '8261', accountName: 'Ränteintäkter från långfristiga fordringar hos moderföretag' },
  { account: '8262', accountName: 'Ränteintäkter från långfristiga fordringar hos dotterföretag' },
  { account: '8263', accountName: 'Ränteintäkter från långfristiga fordringar hos andra koncernföretag' },
  {
    account: '8270',
    accountName: 'Nedskrivningar av innehav av andelar i och långfristiga fordringar hos andra företag',
  },
  { account: '8271', accountName: 'Nedskrivningar av andelar i andra företag' },
  { account: '8272', accountName: 'Nedskrivningar av långfristiga fordringar hos andra företag' },
  { account: '8273', accountName: 'Nedskrivningar av övriga värdepapper hos andra företag' },
  {
    account: '8280',
    accountName: 'Återföringar av nedskrivningar av andelar i och långfristiga fordringar hos andra företag',
  },
  { account: '8281', accountName: 'Återföringar av nedskrivningar av andelar i andra företag' },
  { account: '8282', accountName: 'Återföringar av nedskrivningar av långfristiga fordringar hos andra företag' },
  { account: '8283', accountName: 'Återföringar av nedskrivningar av övriga värdepapper i andra företag' },
  { account: '8310', accountName: 'Ränteintäkter från omsättningstillgångar' },
  { account: '8311', accountName: 'Ränteintäkter från bank' },
  { account: '8312', accountName: 'Ränteintäkter från kortfristiga placeringar' },
  { account: '8313', accountName: 'Ränteintäkter från kortfristiga fordringar' },
  { account: '8314', accountName: 'Skattefria ränteintäkter' },
  { account: '8317', accountName: 'Ränteintäkter för dold räntekompensation' },
  { account: '8319', accountName: 'Övriga ränteintäkter från omsättningstillgångar' },
  { account: '8330', accountName: 'Valutakursdifferenser på kortfristiga fordringar och placeringar' },
  { account: '8331', accountName: 'Valutakursvinster på kortfristiga fordringar och placeringar' },
  { account: '8336', accountName: 'Valutakursförluster på kortfristiga fordringar och placeringar' },
  { account: '8340', accountName: 'Utdelningar på kortfristiga placeringar' },
  { account: '8350', accountName: 'Resultat vid försäljning av kortfristiga placeringar' },
  { account: '8360', accountName: 'Övriga ränteintäkter från koncernföretag' },
  { account: '8361', accountName: 'Övriga ränteintäkter från moderföretag' },
  { account: '8362', accountName: 'Övriga ränteintäkter från dotterföretag' },
  { account: '8363', accountName: 'Övriga ränteintäkter från andra koncernföretag' },
  { account: '8370', accountName: 'Nedskrivningar av kortfristiga placeringar' },
  { account: '8380', accountName: 'Återföringar av nedskrivningar av kortfristiga placeringar' },
  { account: '8390', accountName: 'Övriga finansiella intäkter' },
  { account: '8400', accountName: 'Räntekostnader (gruppkonto)' },
  { account: '8410', accountName: 'Räntekostnader för långfristiga skulder' },
  { account: '8411', accountName: 'Räntekostnader för obligations-, förlags- och konvertibla lån' },
  { account: '8412', accountName: 'Räntedel i årets pensionskostnad' },
  { account: '8413', accountName: 'Räntekostnader för checkräkningskredit' },
  { account: '8415', accountName: 'Räntekostnader för andra skulder till kreditinstitut' },
  { account: '8417', accountName: 'Räntekostnader för dold räntekompensation m.m.' },
  { account: '8418', accountName: 'Avdragspost för räntesubventioner' },
  { account: '8419', accountName: 'Övriga räntekostnader för långfristiga skulder' },
  { account: '8420', accountName: 'Räntekostnader för kortfristiga skulder' },
  { account: '8421', accountName: 'Räntekostnader till kreditinstitut' },
  { account: '8422', accountName: 'Dröjsmålsräntor för leverantörsskulder' },
  { account: '8423', accountName: 'Räntekostnader för skatter och avgifter' },
  { account: '8424', accountName: 'Räntekostnader byggnadskreditiv' },
  { account: '8429', accountName: 'Övriga räntekostnader för kortfristiga skulder' },
  { account: '8430', accountName: 'Valutakursdifferenser på skulder' },
  { account: '8431', accountName: 'Valutakursvinster på skulder' },
  { account: '8436', accountName: 'Valutakursförluster på skulder' },
  { account: '8440', accountName: 'Erhållna räntebidrag' },
  { account: '8460', accountName: 'Räntekostnader till koncernföretag' },
  { account: '8461', accountName: 'Räntekostnader till moderföretag' },
  { account: '8462', accountName: 'Räntekostnader till dotterföretag' },
  { account: '8463', accountName: 'Räntekostnader till andra koncernföretag' },
  { account: '8490', accountName: 'Övriga skuldrelaterade poster' },
  { account: '8491', accountName: 'Erhållet ackord på skulder till kreditinstitut m.m.' },
  { account: '8810', accountName: 'Förändring av periodiseringsfond' },
  { account: '8811', accountName: 'Avsättning till periodiseringsfond' },
  { account: '8819', accountName: 'Återföring från periodiseringsfond' },
  { account: '8820', accountName: 'Mottagna koncernbidrag' },
  { account: '8830', accountName: 'Lämnade koncernbidrag' },
  { account: '8850', accountName: 'Förändring av överavskrivningar' },
  { account: '8851', accountName: 'Förändring av överavskrivningar, immateriella anläggningstillgångar' },
  { account: '8852', accountName: 'Förändring av överavskrivningar, byggnader och markanläggningar' },
  { account: '8853', accountName: 'Förändring av överavskrivningar, maskiner och inventarier' },
  { account: '8860', accountName: 'Förändring av ersättningsfond' },
  { account: '8861', accountName: 'Avsättning till ersättningsfond för inventarier' },
  { account: '8862', accountName: 'Avsättning till ersättningsfond för byggnader och markanläggningar' },
  { account: '8864', accountName: 'Avsättning till ersättningsfond för djurlager i jordbruk och renskötsel' },
  { account: '8865', accountName: 'Ianspråktagande av ersättningsfond för avskrivningar' },
  { account: '8866', accountName: 'Ianspråktagande av ersättningsfond för annat än avskrivningar' },
  { account: '8869', accountName: 'Återföring från ersättningsfond' },
  { account: '8890', accountName: 'Övriga bokslutsdispositioner' },
  { account: '8892', accountName: 'Nedskrivningar av konsolideringskaraktär av anläggningstillgångar' },
  { account: '8896', accountName: 'Förändring av lagerreserv' },
  { account: '8899', accountName: 'Övriga bokslutsdispositioner' },
  { account: '8910', accountName: 'Skatt som belastar årets resultat' },
  { account: '8920', accountName: 'Skatt på grund av ändrad beskattning' },
  { account: '8930', accountName: 'Restituerad skatt' },
  { account: '8980', accountName: 'Övriga skatter' },
  { account: '8999', accountName: 'Årets resultat' },
]

export { AB_K2_LEAF_BAS_ACCOUNTS }
