import React from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'

import { appFetch } from '../../AppFetch.js'
import CardTopLevel from '../CardTopLevel.jsx'
import SpinnerBlock from '../generic/SpinnerBlock.jsx'
import FillButton from '../FillButton.jsx'
import PlanItem from '../PlanItem.jsx'
import { withUserConfig, UserConfigKeys } from '../../ClientSideUserConfig.jsx'
import screenUtils from '../../ScreenUtils.js'
import IconCheckmark from '../generic/IconCheckmark.jsx'

import styles from './PageSelectPlan.module.css'

class PageSelectPlan extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    userConfig: PropTypes.object,
  }

  state = {
    loading: true,
    plans: [],
    userProfile: undefined,
    narrow: screenUtils.isNarrowScreen(),
  }
  controller = new AbortController()

  async componentDidMount() {
    if (!this.props.userConfig.getConfigKey(UserConfigKeys.STRIPE_ENABLED)) {
      this.props.history.push('/signup')
      return
    }
    const signal = this.controller.signal
    const { responseJson: plans } = await appFetch('/api/list-plans', { signal })
    if (this.props.userConfig.getConfigKey(UserConfigKeys.IS_LOGGED_IN)) {
      const { responseJson: userProfile } = await appFetch('/api/up/whoami', { signal })
      this.setState({ userProfile })
    }
    this.setState({ loading: false, plans })
  }

  componentWillUnmount() {
    this.controller.abort()
  }

  lastIsNarrow = screenUtils.isNarrowScreen()
  handleWindowResize = () => {
    const isNarrow = screenUtils.isNarrowScreen()
    if (this.lastIsNarrow !== isNarrow) {
      this.setState({ narrow: isNarrow })
      this.lastIsNarrow = isNarrow
    }
  }
  handlePlanClicked(plan) {
    if (this.props.userConfig.getConfigKey(UserConfigKeys.IS_LOGGED_IN)) {
      // This is an upgrade/downgrade.
      this.props.history.push(`/checkout?plan=${plan.id}`)
    } else {
      this.props.history.push(`/signup?plan=${plan.id}`)
    }
  }

  render() {
    if (this.state.loading) {
      return <SpinnerBlock />
    }
    return (
      <div>
        <br />
        <br />
        <center>
          {!this.props.userConfig.getConfigKey(UserConfigKeys.IS_LOGGED_IN) && <h2>Find a plan that works for you</h2>}
          {this.props.userConfig.getConfigKey(UserConfigKeys.IS_LOGGED_IN) && (
            <React.Fragment>
              <h2>Adjust your subscription</h2>
            </React.Fragment>
          )}
        </center>
        <br />
        {this.state.plans.length === 0 && (
          <div>
            ERROR: No plans available (only plans with Stripe metadata containing &quot;visible&quot; set to
            &quot;true&quot; and a valid role_plan_XYZ is shown)
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            flexDirection: this.state.narrow ? 'column' : 'row',
          }}
        >
          {this.state.plans
            .sort((a, b) => a.amount - b.amount)
            .map((plan) => {
              const isCurrentlySelectedPlan =
                this.props.userConfig.getConfigKey(UserConfigKeys.IS_LOGGED_IN) &&
                this.state.userProfile.plan &&
                this.state.userProfile.plan.id === plan.id
              return (
                <CardTopLevel
                  key={plan.id}
                  className={styles.PlanCard}
                  style={
                    isCurrentlySelectedPlan
                      ? { border: '2px solid var(--primary-color)', background: 'var(--primary-color-bright)' }
                      : {}
                  }
                >
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div className={styles.PlanTitle}>{plan.nickname}</div>
                    <div className={styles.Price}>${plan.amount / 100}</div>
                    <div className={styles.PriceInterval}>per {plan.interval}</div>
                    <div>&nbsp;</div>
                    {isCurrentlySelectedPlan && (
                      <div className={styles.CurrentlySelected}>
                        <IconCheckmark style={{ width: '28px', height: '28px' }} />
                        <div>This is your current subscription plan.</div>
                      </div>
                    )}
                    {!isCurrentlySelectedPlan && (
                      <FillButton
                        className={`automation-choose-${plan.role} ${styles.ChooseButton}`}
                        kind="primary"
                        onClick={() => this.handlePlanClicked(plan)}
                      >
                        Select {plan.nickname}
                      </FillButton>
                    )}
                    {plan.metadata.description && (
                      <React.Fragment>
                        <div>&nbsp;</div>
                        <div className={styles.IntendedAudience}>{plan.metadata.description}</div>
                        <div>&nbsp;</div>
                      </React.Fragment>
                    )}
                    <div>&nbsp;</div>
                    <PlanItem>Customer service via e-mail</PlanItem>
                  </div>
                </CardTopLevel>
              )
            })}
        </div>
      </div>
    )
  }
}

export default withRouter(withUserConfig(PageSelectPlan))
