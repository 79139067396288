import PropTypes from 'prop-types'
import React from 'react'

import styles from './PlanItem.module.css'
import IconCross from './generic/IconCross.jsx'
import IconCheckmark from './generic/IconCheckmark.jsx'

const PlanItem = ({ children, className, icon, ...props }) => (
  <div className={`${styles.PlanItemRow} ${className}`} {...props}>
    <div className={styles.IconContainer}>
      {icon === 'cross' ? (
        <IconCross style={{ width: '14px', height: '14px' }} />
      ) : (
        <IconCheckmark style={{ width: '14px', height: '14px' }} />
      )}
    </div>
    <div className={styles.ChildContainer}>{children}</div>
  </div>
)

PlanItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  icon: PropTypes.string,
}

PlanItem.defaultProps = {
  className: '',
  icon: 'checkmark',
}

export default PlanItem
