import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Radio from '@mui/material/Radio/index.js'
import RadioGroup from '@mui/material/RadioGroup/index.js'
import FormControlLabel from '@mui/material/FormControlLabel/index.js'
import MenuItem from '@mui/material/MenuItem/index.js'

import { useAbortSignal } from '../../AppFetch.js'
import API from '../../API.js'
import EditableXbrlValue from './EditableXbrlValue.jsx'
import {
  hasNonZeroRawAmount,
  getOpeningBalancesForChangesToEquityTable,
} from '../../../shared-universal/AnnualReportUtils.js'

import { saveXbrlValues } from './EditableTableUtils.jsx'
import RmSelect from './RmSelect.jsx'

const fondemissionSources = [
  {
    title: 'Ej registrerat aktiekapital',
    xbrlName: 'ForandringEgetKapitalEjRegistreratAktiekapitalFondemission',
    balanceSheetXbrlName: 'EjRegistreratAktiekapital',
  },
  {
    title: 'Bunden överkursfond',
    xbrlName: 'ForandringEgetKapitalBundenOverkursfondFondemission',
    balanceSheetXbrlName: 'BundenOverkursfond',
  },
  {
    title: 'Uppskrivningsfond',
    xbrlName: 'ForandringEgetKapitaUppskrivningsfondFondemission',
    balanceSheetXbrlName: 'Uppskrivningsfond',
  },
  {
    title: 'Reservfond',
    xbrlName: 'ForandringEgetKapitalReservfondFondemission',
    balanceSheetXbrlName: 'Reservfond',
  },
  {
    title: 'Fri överkursfond',
    xbrlName: 'ForandringEgetKapitalOverkursfondFondemission',
    balanceSheetXbrlName: 'Overkursfond',
  },
  {
    title: 'Balanserat resultat',
    xbrlName: 'ForandringEgetKapitalBalanseratResultatFondemission',
    balanceSheetXbrlName: 'BalanseratResultat',
  },
  {
    title: 'Årets resultat',
    xbrlName: 'ForandringEgetKapitalAretsResultatFondemission',
    balanceSheetXbrlName: 'AretsResultat',
  },
]

const EditableFondemission = ({ annualReport, onChange, isApproved }) => {
  const abortSignal = useAbortSignal()
  const FIRST_CELL_WIDTH = '240px'
  const { openingBalanceXbrlValues } = getOpeningBalancesForChangesToEquityTable(annualReport)
  const closingBalanceXbrlValues = annualReport.financialYears[0].xbrlValues

  const [fondemissionAmount, setFondemissionAmount] = React.useState(
    annualReport.financialYears[0].xbrlValues['ForandringEgetKapitalAktiekapitalFondemission']?.value ?? null
  )
  const selectedFondemissionSource = fondemissionSources.find(
    (fondSrc) => annualReport.financialYears[0].xbrlValues[fondSrc.xbrlName]?.value
  )
  const [fondemissionSource, setFondemissionSource] = React.useState(selectedFondemissionSource?.xbrlName)
  useEffect(() => {
    const xbrlRecords = fondemissionSources.map((field) => ({
      xbrlName: field.xbrlName,
      value: fondemissionSource === field.xbrlName ? fondemissionAmount : null,
    }))
    const saveIsNeeded = xbrlRecords.some((record) => {
      return annualReport.financialYears[0].xbrlValues[record.xbrlName]?.value !== record.value
    })
    if (saveIsNeeded) {
      saveXbrlValues(annualReport.financialYears[0].financialYearId, xbrlRecords, abortSignal, onChange)
    }
  }, [fondemissionAmount, fondemissionSource, annualReport, abortSignal, onChange])

  return (
    <React.Fragment>
      <div>Har företaget gjort en fondemission som har påverkat eget kapital detta året?</div>
      <RadioGroup
        aria-label="position"
        style={{ marginLeft: '20px' }}
        name="position"
        value={annualReport.egetKapitalFondemission ? 1 : 0}
        onChange={async (ev) => {
          API.saveAnnualReportValue(
            annualReport.reportId,
            'egetKapitalFondemission',
            ev.target.value === '1',
            abortSignal,
            onChange,
            { immediate: true }
          )
          if (ev.target.value === '0') {
            const xbrlRecords = fondemissionSources.map((field) => ({
              xbrlName: field.xbrlName,
              value: null,
            }))
            xbrlRecords.push({
              xbrlName: 'ForandringEgetKapitalAktiekapitalFondemission',
              value: null,
            })
            saveXbrlValues(annualReport.financialYears[0].financialYearId, xbrlRecords, abortSignal, onChange)
          }
        }}
      >
        <FormControlLabel
          value="0"
          control={<Radio color="primary" />}
          label="Nej"
          labelPlacement="end"
          disabled={isApproved}
        />
        <FormControlLabel
          value="1"
          control={<Radio color="primary" />}
          label="Ja"
          labelPlacement="end"
          disabled={isApproved}
        />
      </RadioGroup>
      {annualReport.egetKapitalFondemission && (
        <table>
          <tbody>
            <tr>
              <td style={{ width: FIRST_CELL_WIDTH }}>Fondemissionsbelopp</td>
              <td>
                <EditableXbrlValue
                  xbrlName="ForandringEgetKapitalAktiekapitalFondemission"
                  financialYear={annualReport.financialYears[0]}
                  abortSignal={abortSignal}
                  onSaved={onChange}
                  onChange={(ev) => {
                    setFondemissionAmount(typeof ev.target.value === 'number' ? ev.target.value : null)
                  }}
                  disabled={isApproved}
                  allowNegative={false}
                />
              </td>
            </tr>
            <tr>
              <td style={{ width: FIRST_CELL_WIDTH }}>Vid fondemissionen togs pengar från:</td>
              <td>
                <RmSelect
                  defaultValue={selectedFondemissionSource?.xbrlName}
                  onChange={(newSelectedXbrlName) => {
                    setFondemissionSource(newSelectedXbrlName)
                  }}
                  style={{ width: '300px' }}
                  disabled={isApproved}
                >
                  {fondemissionSources
                    .filter(
                      (col) =>
                        hasNonZeroRawAmount(openingBalanceXbrlValues[col.balanceSheetXbrlName]) ||
                        hasNonZeroRawAmount(closingBalanceXbrlValues[col.balanceSheetXbrlName])
                    )
                    .map((src) => (
                      <MenuItem key={src.xbrlName} value={src.xbrlName}>
                        {src.title}
                      </MenuItem>
                    ))}
                </RmSelect>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </React.Fragment>
  )
}

EditableFondemission.propTypes = {
  annualReport: PropTypes.object,
  onChange: PropTypes.func,
  isApproved: PropTypes.bool.isRequired,
}

export default EditableFondemission
