import React from 'react'
import PropTypes from 'prop-types'

import { useAbortSignal } from '../../AppFetch.js'
import EditableXbrlValue from './EditableXbrlValue.jsx'
import { makeFinancialYearTitleHtml } from '../../../shared-universal/AnnualReportRender.js'
import { DATA_FIELD_RIGHT_MARGIN } from './EditableTableUtils.jsx'
import EditableAmount from './EditableAmount.jsx'
import RmEditorHeading2 from './RmEditorHeading2.jsx'

const NotLangfristigaSkulderEditor = ({ annualReport, onChange, disabled }) => {
  const abortSignal = useAbortSignal()
  const firstCellStyle = {
    width: '100%',
  }
  return (
    <React.Fragment>
      <RmEditorHeading2>Långfrista skulder</RmEditorHeading2>
      <table>
        <thead>
          <tr>
            <th style={firstCellStyle}></th>
            <td
              key={annualReport.financialYears[0].financialYearId}
              dangerouslySetInnerHTML={{
                __html: makeFinancialYearTitleHtml(annualReport.financialYears[0], 'instant'),
              }}
              style={{
                textAlign: 'right',
                paddingRight: DATA_FIELD_RIGHT_MARGIN,
                fontWeight: 'bold',
                verticalAlign: 'top',
              }}
            ></td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={firstCellStyle}>Långfristiga skulder totalt</td>
            <td>
              <EditableAmount
                className={`automation-LangfristigaSkulder`}
                defaultValue={annualReport.financialYears[0].xbrlValues['LangfristigaSkulder']?.value}
                unit="kr"
                disabled={true}
              />
            </td>
          </tr>
          <tr>
            <td style={firstCellStyle}>Skulder som förfaller till betalning senare än 5 år efter balansdagen</td>
            <td>
              <EditableXbrlValue
                xbrlName="LangfristigaSkulderForfallerSenare5Ar"
                financialYear={annualReport.financialYears[0]}
                abortSignal={abortSignal}
                onSaved={onChange}
                disabled={disabled}
                className="automation-LangfristigaSkulderForfallerSenare5Ar-0"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  )
}

NotLangfristigaSkulderEditor.propTypes = {
  annualReport: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default NotLangfristigaSkulderEditor
