import React from 'react'
import PropTypes from 'prop-types'

import styles from './HamburgerButton.module.css'

const HamburgerButton = ({ isOpen, className = '', color, ...props }) => (
  <div className={`${styles.HamburgerButton} ${className} ` + (isOpen ? styles.IsOpen : '')} {...props}>
    <div className={styles.BarOne} style={{ backgroundColor: color }}></div>
    <div className={styles.BarTwo} style={{ backgroundColor: color }}></div>
    <div className={styles.BarThree} style={{ backgroundColor: color }}></div>
  </div>
)

HamburgerButton.defaultProps = {
  color: '#000',
}

HamburgerButton.propTypes = {
  color: PropTypes.string,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
}

export default HamburgerButton
