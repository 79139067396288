import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'

const RmDialog = ({ open, onClose, title, text, content, confirmVerb, cancelVerb, onConfirm }) => {
  if ((!text && !content) || (text && content)) {
    throw Error('invalid text/content props')
  }
  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content ? content : <DialogContentText>{text}</DialogContentText>}</DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>{cancelVerb}</Button>
        <Button variant="contained" onClick={() => onConfirm()}>
          {confirmVerb}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

RmDialog.propTypes = {
  open: PropTypes.bool.isRequired,

  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  content: PropTypes.node,
  confirmVerb: PropTypes.string,
  cancelVerb: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
}

RmDialog.defaultProps = {
  confirmVerb: 'OK',
  cancelVerb: 'Avbryt',
}

export default RmDialog
