/**
 * Sometimes a balance post needs to be split into several parts that are shown
 * separately in note details. For example "ByggnaderMark" is split into
 * anskaffningsvärden, nedskrivningar and avskrivningar. Typically, we need to
 * show "opening balance", "outgoing balance" in addition to including these
 * account ranges in the balance post itself. So effectively, there are at least
 * three places in the code where we need to use these account ranges, which is
 * why they are declared per "part" in this file and then reused in the three
 * respective locations.
 */

const VILANDE_MOMSKONTON = [2618, 2628, 2638, 2648]

// Balansräkning

const arOvrigaFordringarKortfristiga = '161x, 1630-1659, 168x'
const arOvrigaKortfristigaSkulder = '2490-2491, 2493-2499, 2610-2669, 271x, 2730-2769, 279x, 2810-2859, 2880-2899'

const arKoncessionerPatentLicenserVarumarkenLiknandeRattigheterAnskaffningsvarden = '1020, 1030, 1040, 1050'
const arKoncessionerPatentLicenserVarumarkenLiknandeRattigheterAvskrivningar = '1029, 1039, 1049, 1059'
const arKoncessionerPatentLicenserVarumarkenLiknandeRattigheterNedskrivningar = '1028, 1038, 1048, 1058'

const arHyresratterLiknandeRattigheterAnskaffningsvarden = '1060'
const arHyresratterLiknandeRattigheterAvskrivningar = '1069'
const arHyresratterLiknandeRattigheterNedskrivningar = '1068'

const arGoodwillAnskaffningsvarden = '1070'
const arGoodwillAvskrivningar = '1079'
const arGoodwillNedskrivningar = '1078'

const arByggnaderMarkAnskaffningsvarden = '1110,1111,1112,1130,1140,1150'
const arByggnaderMarkNedskrivningar = '1118,1158'
const arByggnaderMarkAvskrivningar = '1119,1159'

const arMaskinerAndraTekniskaAnlaggningarAnskaffningsvarden = '1210,1211,1213'
const arMaskinerAndraTekniskaAnlaggningarNedskrivningar = '1218'
const arMaskinerAndraTekniskaAnlaggningarAvskrivningar = '1219'

const arInventarierVerktygInstallationerAnskaffningsvarden = '1220-1223, 1225, 1230-1232, 1240-1247, 1250, 1251, 1257'
const arInventarierVerktygInstallationerNedskrivningar = '1228, 1238, 1248, 1258'
const arInventarierVerktygInstallationerAvskrivningar = '1229, 1239, 1249, 1259'

const arOvrigaMateriellaAnlaggningstillgangarAnskaffningsvarden = '1290,1291,1292'
const arOvrigaMateriellaAnlaggningstillgangarNedskrivningar = '1298'
const arOvrigaMateriellaAnlaggningstillgangarAvskrivningar = '1299'

const arPagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAnskaffningsvarden = '118x, 128x'

const arAndelarKoncernforetagAnskaffningsvarden = '1310-1314, 1316, 1317'
const arAndelarKoncernforetagNedskrivningar = '1318'

const arFordringarKoncernforetagLangfristigaAnskaffningsvarden = '1320, 1321, 1322, 1323'
const arFordringarKoncernforetagLangfristigaNedskrivningar = '1328'

const arAndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvarden = '1330-1334'
const arAndelarIntresseforetagGemensamtStyrdaForetagNedskrivningar = '1338'

const arAgarintressenOvrigaForetagAnskaffningsvarden = '1336-1337'

const arAndraLangfristigaVardepappersinnehavAnskaffningsvarden = '1350,1351,1352,1353,1354,1356,1357'
const arAndraLangfristigaVardepappersinnehavNedskrivningar = '1358'

const arAndraLangfristigaFordringarAnskaffningsvarden = '1380, 1381, 1382, 1383, 1384, 1385, 1387, 1388'
const arAndraLangfristigaFordringarNedskrivningar = '1389'

const arSkatteskulder = '25xx'

// Skatteberäkning

const arSkattPaGrundAvAndradBeskattning = '8920'
const arSkattRestitueradSkatt = '8930'

// Resultaträkning

const arNedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar =
  '8070-8089, 8170-8189, 8270-8289, 8370-8389'

export {
  VILANDE_MOMSKONTON,
  arOvrigaFordringarKortfristiga,
  arOvrigaKortfristigaSkulder,
  arKoncessionerPatentLicenserVarumarkenLiknandeRattigheterAnskaffningsvarden,
  arKoncessionerPatentLicenserVarumarkenLiknandeRattigheterAvskrivningar,
  arKoncessionerPatentLicenserVarumarkenLiknandeRattigheterNedskrivningar,
  arHyresratterLiknandeRattigheterAnskaffningsvarden,
  arHyresratterLiknandeRattigheterAvskrivningar,
  arHyresratterLiknandeRattigheterNedskrivningar,
  arGoodwillAnskaffningsvarden,
  arGoodwillAvskrivningar,
  arGoodwillNedskrivningar,
  arByggnaderMarkAnskaffningsvarden,
  arByggnaderMarkNedskrivningar,
  arByggnaderMarkAvskrivningar,
  arMaskinerAndraTekniskaAnlaggningarAnskaffningsvarden,
  arMaskinerAndraTekniskaAnlaggningarNedskrivningar,
  arMaskinerAndraTekniskaAnlaggningarAvskrivningar,
  arInventarierVerktygInstallationerAnskaffningsvarden,
  arInventarierVerktygInstallationerNedskrivningar,
  arInventarierVerktygInstallationerAvskrivningar,
  arOvrigaMateriellaAnlaggningstillgangarAnskaffningsvarden,
  arOvrigaMateriellaAnlaggningstillgangarNedskrivningar,
  arOvrigaMateriellaAnlaggningstillgangarAvskrivningar,
  arPagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAnskaffningsvarden,
  arAndelarKoncernforetagAnskaffningsvarden,
  arAndelarKoncernforetagNedskrivningar,
  arFordringarKoncernforetagLangfristigaAnskaffningsvarden,
  arFordringarKoncernforetagLangfristigaNedskrivningar,
  arAndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvarden,
  arAndelarIntresseforetagGemensamtStyrdaForetagNedskrivningar,
  arAgarintressenOvrigaForetagAnskaffningsvarden,
  arAndraLangfristigaVardepappersinnehavAnskaffningsvarden,
  arAndraLangfristigaVardepappersinnehavNedskrivningar,
  arAndraLangfristigaFordringarAnskaffningsvarden,
  arAndraLangfristigaFordringarNedskrivningar,
  arSkatteskulder,
  arSkattPaGrundAvAndradBeskattning,
  arSkattRestitueradSkatt,
  arNedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar,
}
