import PropTypes from 'prop-types'
import React from 'react'

import Card from '../Card.jsx'
import Row from '../Row.jsx'
import HoverUnderlineLink from '../generic/HoverUnderlineLink.jsx'
import PickedImage from '../PickedImage.jsx'
import RoundedImage from '../RoundedImage.jsx'
import { withUserConfig, UserConfigKeys } from '../../ClientSideUserConfig.jsx'

const PageProfile = ({ user, onUserImageChanged, isUploadingUserImage, userConfig }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <br />
      <br />
      <Card style={{ width: '600px' }}>
        <Row label="Username">
          <div className={'automation-username'}>{user.username}</div>
        </Row>
        <Row label="Profile Picture">
          <div className={'automation-profile-picture'}>{user.avatar}</div>
          <PickedImage
            thumbnailUrl={user.imageUrl}
            onImageSelected={onUserImageChanged}
            onImageUnset={() => onUserImageChanged(null)}
            renderThumbnail={(url) => (
              <RoundedImage src={url} draggable={false} style={{ maxWidth: '200px', maxHeight: '200px' }} />
            )}
            className={'automation-profile-picture'}
            showUploadSpinner={isUploadingUserImage}
            style={{ minHeight: '120px' }}
            imageLibrarySearchEnabled={userConfig.getConfigKey(UserConfigKeys.PIXABAY_ENABLED)}
          />
        </Row>
        <Row label="Registration Date">
          <div className={'automation-registration-date'}>{user.registrationDate}</div>
        </Row>
        <Row label="Subscription">
          <div>
            <span className={'automation-subscription'}>{user.planDescription}</span>
            {userConfig.getConfigKey(UserConfigKeys.STRIPE_ENABLED) && (
              <span>
                {' '}
                (
                <HoverUnderlineLink to="/select-plan" className="automation-switch-subscription">
                  Switch
                </HoverUnderlineLink>
                )
              </span>
            )}
          </div>
        </Row>
        <Row label="Account Status">
          <div className={'automation-account-status'}>
            {user.activeSubscription ? 'Active' : 'Inactive (waiting for payment)'}
          </div>
        </Row>
        {user.currentPeriodEnds !== null && (
          <Row label="Paid Until">
            <div className={'automation-paid-until'}>{user.currentPeriodEnds}</div>
          </Row>
        )}
      </Card>
    </div>
  )
}

PageProfile.propTypes = {
  user: PropTypes.object.isRequired,
  onUserImageChanged: PropTypes.func.isRequired,
  isUploadingUserImage: PropTypes.bool,
  userConfig: PropTypes.object.isRequired,
}

export default withUserConfig(PageProfile)
