import React from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'

import Row from '../Row.jsx'
import Field from '../Field.jsx'

import { appFetch } from '../../AppFetch.js'
import StyledEditBox from '../StyledEditBox.jsx'
import FillButton from '../FillButton.jsx'
import FlashMessage from '../FlashMessage.jsx'
import HttpErrorCodes from '../../../shared-universal/HttpErrorCodes.js'
import CardTopLevel from '../CardTopLevel.jsx'
import { getUrlParam } from '../../UrlUtils.js'
import SpinnerBlock from '../generic/SpinnerBlock.jsx'
import { withUserConfig, UserConfigKeys } from '../../ClientSideUserConfig.jsx'

const ComponentModes = {
  WAITING_FOR_INPUT: Symbol(),
  SIGNUP_REQUEST_SUBIMITTED: Symbol(),
}

class PageSignupForm extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    userConfig: PropTypes.object.isRequired,
  }

  initialState = {
    mode: ComponentModes.WAITING_FOR_INPUT,
    planLoaded: false,
    plan: undefined,
    flashMessage: '',
  }
  state = this.initialState
  controller = new AbortController()

  async componentDidMount() {
    const planId = getUrlParam('plan', location.search)
    const signal = this.controller.signal
    const { responseJson: plans } = await appFetch('/api/list-plans', { signal })
    const plan = plans.find((p) => p.id == planId)
    const stripeEnabled = plans.length > 0
    if (stripeEnabled && !plan) {
      this.props.history.push('/select-plan')
      return
    }
    this.setState({ planLoaded: true, plan })
  }

  submitSignupForm = async (ev) => {
    ev.preventDefault()
    const form = ev.target
    const formJson = Object.assign(...Array.from(new FormData(form).entries(), ([x, y]) => ({ [x]: y })))
    this.setState({ mode: ComponentModes.SIGNUP_REQUEST_SUBIMITTED })
    const { status, responseText, responseJson } = await appFetch('/api/signup', {
      signal: this.controller.signal,
      method: 'POST',
      body: JSON.stringify({ ...formJson, planId: this.state.plan && this.state.plan.id }),
    })
    if (responseJson.errorCode === HttpErrorCodes.UI_ERROR_MESSAGE) {
      this.setState({ mode: ComponentModes.WAITING_FOR_INPUT, flashMessage: responseJson.errorMessage })
      return
    } else if (status != 200) {
      this.setState({
        mode: ComponentModes.WAITING_FOR_INPUT,
        flashMessage: responseText.replace(/^\w/, (c) => c.toUpperCase()),
      })
      return
    }

    await this.props.userConfig.refreshFromServer()

    if (!responseJson.stripeEnabled) {
      this.props.history.push('/')
    } else if (this.state.plan.amount === 0) {
      this.props.history.push(`/checkout?plan=${this.state.plan.id}&autosubmit=1`)
    } else {
      this.props.history.push(`/checkout?plan=${this.state.plan.id}`)
    }
  }

  handleClickCancel = (ev) => {
    ev.preventDefault()
    this.props.history.push('/')
  }

  render() {
    if (!this.state.planLoaded) {
      ;<SpinnerBlock />
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
        <br />
        <br />
        <CardTopLevel>
          {this.props.userConfig.getConfigKey(UserConfigKeys.STRIPE_ENABLED) && this.state.plan && (
            <div>
              <Field label="Selected Plan">
                <div className="automation-plan-name">{this.state.plan.nickname}</div>
              </Field>
              {this.state.plan.amount > 0 && (
                <Field label="Price">
                  <div className="automation-plan-price">
                    ${this.state.plan.amount / 100} per {this.state.plan.interval}
                  </div>
                </Field>
              )}
              <div>&nbsp;</div>
            </div>
          )}
          {!this.props.userConfig.getConfigKey(UserConfigKeys.STRIPE_ENABLED) && (
            <div className="automation-stripe-disabled"></div>
          )}
          <FlashMessage message={this.state.flashMessage} className={'automation-flash-message'} />
          <form onSubmit={this.submitSignupForm}>
            <Field label="Username">
              <StyledEditBox name="frmUsername" className={'automation-username'} />
            </Field>
            <Field label="Password">
              <StyledEditBox
                type="password"
                name="frmPassword"
                className={'automation-password'}
                autoComplete="new-password"
              />
            </Field>
            <Field label="Password" labelSuffix="(again)">
              <StyledEditBox
                type="password"
                name="frmPasswordAgain"
                className={'automation-password-again'}
                autoComplete="new-password"
              />
            </Field>
            <Field label="E-mail">
              <StyledEditBox name="frmEmail" className={'automation-email'} />
            </Field>

            <Row>
              <FillButton
                type="submit"
                kind="primary"
                className={'automation-create-user-button'}
                disabled={this.state.mode === ComponentModes.SIGNUP_REQUEST_SUBIMITTED}
                spinner={this.state.mode === ComponentModes.SIGNUP_REQUEST_SUBIMITTED}
              >
                Create new user
              </FillButton>
            </Row>
            <Row>
              <FillButton kind="cancel" onClick={this.handleClickCancel}>
                Cancel
              </FillButton>
            </Row>
          </form>
        </CardTopLevel>
      </div>
    )
  }
}

export default withRouter(withUserConfig(PageSignupForm))
