const UserConfigKeys = {
  APP_REVISION: 'app_revision',
  STRIPE_ENABLED: 'stripe_enabled',
  STRIPE_PUBLISHABLE_KEY: 'stripe_publishable_key',
  STRIPE_EVERYWHERE: 'stripe_everywhere',
  HTTP_ORIGIN: 'http_origin',
  HTTP_ORIGIN_ENFORCE: 'http_origin_enforce',
  IS_LOGGED_IN: 'logged_in',
  ACTIVE_SUBSCRIPTION: 'active_subscription',
  INFO_USERNAME: 'info_username',
  PIXABAY_ENABLED: 'pixabay_enabled',
  SHOW_IBXRL_DOWNLOAD_LINK: 'show_ixbrl_download_link',
  REQUIRE_EMAIL_FOR_IMPORT: 'REQUIRE_EMAIL_FOR_IMPORT',
  SENTRY_ENVIRONMENT: 'sentry_environment',
  ESIGN_VENDOR: 'esign_vendor',

  ARSSTAMMA_JUSTERAD_DISPOSITION_ENABLED: 'arsstamma_justerad_disposition_enabled',
  INK2_API_ENABLED: 'ink2_api_enabled',
}

/* See: https://stripe.com/docs/billing/taxes/tax-ids
 * And: 'tax_id_data' param passed to:
 *      https://stripe.com/docs/api/customers/create
 */
const StripeTaxIdTypes = {
  NO_TAX_ID_NUMBER: 'no_vat',
  EU_VAT: 'eu_vat',
  IN_GST: 'in_gst',
  AU_ABN: 'au_abn',
  NZ_GST: 'nz_gst',
}

const StripeTaxIdTypeInfo = [
  {
    id: StripeTaxIdTypes.NO_TAX_ID_NUMBER,
    taxIdLabel: '',
  },
  {
    id: StripeTaxIdTypes.EU_VAT,
    taxIdLabel: 'VAT Number',
  },
  {
    id: StripeTaxIdTypes.IN_GST,
    taxIdLabel: 'GST Number (GSTIN)',
  },
  {
    id: StripeTaxIdTypes.AU_ABN,
    taxIdLabel: 'ABN Number',
  },
  {
    // https://en.wikipedia.org/wiki/Goods_and_Services_Tax_(New_Zealand)
    // Threshold: 60K New Zealand Dollar
    id: StripeTaxIdTypes.NZ_GST,
    taxIdLabel: 'GST Number',
  },
]

export { UserConfigKeys, StripeTaxIdTypes, StripeTaxIdTypeInfo }
