import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box/index.js'
import dayjs from 'dayjs'

import RmDatePicker from './RmDatePicker.jsx'
import API from '../../API.js'
import { useAbortSignal } from '../../AppFetch.js'

const ArsstammaDatumEditor = ({ annualReport, onChange, disabled }) => {
  const abortSignal = useAbortSignal()
  return (
    <React.Fragment>
      <div>Datum för årsstämma</div>
      <Box mt={1} />
      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '50px' }}>
        <div>
          <RmDatePicker
            className={`automation-annual-meeting-date`}
            defaultValue={annualReport.annualMeetingDate || null}
            onChange={async (newDate) => {
              API.saveAnnualReportValue(
                annualReport.reportId,
                'annualMeetingDate',
                newDate || '',
                abortSignal,
                onChange,
                {
                  immediate: true,
                }
              )
            }}
            disabled={disabled}
          />
        </div>
        <div>
          {!annualReport.annualMeetingDate
            ? ''
            : dayjs().isBefore(dayjs(annualReport.annualMeetingDate))
              ? `Årsredovisningen kan laddas upp till Bolagsverket tidigast ${annualReport.annualMeetingDate}.`
              : `Årsredovisningen kan laddas upp till Bolagsverket direkt.`}
        </div>
      </div>
    </React.Fragment>
  )
}

ArsstammaDatumEditor.propTypes = {
  annualReport: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

export default ArsstammaDatumEditor
