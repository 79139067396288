import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import styles from './HoverUnderlineLink.module.css'

const HoverUnderlineLink = ({ className, children, ...props }) => (
  <Link {...props} className={`${styles.HoverUnderlineLink} ${className}`}>
    {children}
  </Link>
)

HoverUnderlineLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

HoverUnderlineLink.defaultProps = {
  className: '',
}

export default HoverUnderlineLink
