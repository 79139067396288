import PropTypes from 'prop-types'
import React from 'react'

import styles from './PageContent.module.css'

const PageContent = ({ children, ...props }) => (
  <div className={styles.PageContent} {...props}>
    {children}
  </div>
)

PageContent.propTypes = {
  children: PropTypes.any,
}

export default PageContent
