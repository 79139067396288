import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'

import API from '../../API.js'
import { useAbortSignal } from '../../AppFetch.js'
import RmDialog from '../remaker/RmDialog.jsx'
import EditableEmail from './EditableEmail.jsx'
import { isValidEmail } from '../../../shared-universal/Utils.js'

const ReportActionMenu = ({ signature, onSignLinkSent }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const closeMenu = () => setAnchorEl(null)
  const abortSignal = useAbortSignal()
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [foretradareSignLinkEmail, setForetradareSignLinkEmail] = useState(signature.email)

  const closeDialogAndSendSignLink = async () => {
    setDialogOpen(false)
    await API.sendSignLinkTo(signature.foretradareId, foretradareSignLinkEmail, abortSignal)
    onSignLinkSent()
  }

  return (
    <React.Fragment>
      <IconButton
        variant="outlined"
        onClick={(ev) => {
          setAnchorEl(ev.currentTarget)
        }}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        onClick={closeMenu}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={async () => {
            closeMenu()
            setDialogOpen(true)
          }}
        >
          <ListItemIcon>
            <EmailOutlinedIcon />
          </ListItemIcon>
          Skicka signeringslänk med e-post
        </MenuItem>
      </Menu>
      <RmDialog
        title="Skicka signeringslänk med e-post"
        content={
          <div>
            Ange e-postadress för {signature.firstName} {signature.lastName}
            <EditableEmail
              defaultValue={signature.email}
              onChange={setForetradareSignLinkEmail}
              autoFocus
              onKeyDown={async (ev) => {
                if (ev.key === 'Enter' && isValidEmail(foretradareSignLinkEmail)) {
                  ev.preventDefault()
                  await closeDialogAndSendSignLink()
                }
              }}
            />
          </div>
        }
        confirmVerb="Skicka"
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false)
        }}
        onConfirm={closeDialogAndSendSignLink}
      />
    </React.Fragment>
  )
}

ReportActionMenu.propTypes = {
  signature: PropTypes.object.isRequired,
  onSignLinkSent: PropTypes.func.isRequired,
}

ReportActionMenu.defaultProps = {}

export default ReportActionMenu
