import React, { useState } from 'react'
import PropTypes from 'prop-types'
import lodashMerge from 'lodash.merge'
import withStyles from '@mui/styles/withStyles/index.js'

import { DropzoneArea } from 'material-ui-dropzone'
import { useAbortSignal } from '../../AppFetch.js'
import FlashMessage from '../FlashMessage.jsx'
import SpinnerBlock from '../generic/SpinnerBlock.jsx'

const nonDuploStyle = {
  root: {
    minHeight: '140px !important',
  },
  text: {
    fontSize: '1.25rem !important',
  },
  icon: {
    width: '40px !important',
    height: '40px !important',
  },
}

const disabledStyle = {
  root: {
    color: 'gray',
    cursor: 'default !important',
  },
  icon: {
    color: 'gray !important',
  },
}

const makeDropzoneArea = (duploSize, disabled) => {
  let dropzoneStyle = {}
  if (disabled) {
    dropzoneStyle = lodashMerge(dropzoneStyle, disabledStyle)
  }
  if (!duploSize) {
    dropzoneStyle = lodashMerge(dropzoneStyle, nonDuploStyle)
  }
  return withStyles(dropzoneStyle)(DropzoneArea)
}

const duploDisabled = makeDropzoneArea(true, true)
const duploEnabled = makeDropzoneArea(true, false)
const miniDisabled = makeDropzoneArea(false, true)
const miniEnabled = makeDropzoneArea(false, false)

const SieDropzone = ({ duploSize, disabled, text, uploadFunc, onSieUploaded }) => {
  const signal = useAbortSignal()
  const [errorMessage, setErrorMessage] = useState(undefined)
  const [processing, setProcessing] = useState(false)
  const ActualDropzoneArea = duploSize
    ? disabled
      ? duploDisabled
      : duploEnabled
    : disabled
    ? miniDisabled
    : miniEnabled
  return (
    <React.Fragment>
      <FlashMessage message={errorMessage} />
      <div style={processing ? { display: 'none' } : {}}>
        <ActualDropzoneArea
          dropzoneText={text}
          showPreviewsInDropzone={false}
          showAlerts={['error']}
          filesLimit={1}
          maxFileSize={50000000}
          dropzoneProps={{ disabled }}
          onChange={async (files) => {
            if (files.length === 1) {
              setErrorMessage('')
              setProcessing(true)
              try {
                const result = await uploadFunc(signal, files[0])
                setProcessing(false)
                if (result.errorMessage) {
                  setErrorMessage(result.errorMessage)
                } else {
                  onSieUploaded(result)
                }
              } catch (ex) {
                setErrorMessage(String(ex))
                setProcessing(false)
              }
            }
          }}
        />
      </div>
      {processing && (
        <div>
          <div style={{ textAlign: 'center' }}>Läser in bokföring ....</div>
          <SpinnerBlock />
        </div>
      )}
    </React.Fragment>
  )
}

SieDropzone.propTypes = {
  duploSize: PropTypes.bool,
  disabled: PropTypes.bool,
  text: PropTypes.string.isRequired,
  uploadFunc: PropTypes.func.isRequired,
  onSieUploaded: PropTypes.func.isRequired,
}

SieDropzone.defaultProps = {
  duploSize: true,
  disabled: false,
  onChange: () => {},
}

export default SieDropzone
