import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter } from 'react-router-dom'

import StripeProviderWrapper from './StripeProviderWrapper.jsx'
import { withUserConfig, UserConfigKeys } from '../ClientSideUserConfig.jsx'
import { consoleLog, consoleError } from '../../shared-universal/SanctionedConsole.js'
import AppClientRoutes from './AppClientRoutes.jsx'
import { MaybeWebSocketEventsProvider } from '../WebSocketEvents.jsx'
import { getUrlParam } from '../UrlUtils.js'

const maybeRedirectToExpectedHttpOrigin = (originEnforce, expectedHttpOrigin) => {
  if (originEnforce && location.origin !== expectedHttpOrigin) {
    consoleError(
      'WARNING: Application loaded via incorrect origin, redirecting to proper HTTP origin so that client/server agrees. This is important because if client-side sets cookies and writes localstorage data at 127.0.0.1 and then server-side triggers a redirect sequence that ends up at localstorage, then the cookies and localstorage data will not be available when the client tries to read them back.'
    )
    consoleError('Expected origin: ' + expectedHttpOrigin)
    consoleError('Actual origin: ' + location.origin)
    location.href = location.href.replace(location.origin, expectedHttpOrigin)
    return true
  }
  return false
}

const AppMain = (props) => {
  const userConfig = props.userConfig

  const browserRouterRef = useRef(null)
  useEffect(() => {
    if (browserRouterRef.current) {
      browserRouterRef.current.history.listen((location, action) => {
        if (window.GOOGLE_ANALYTICS_TRACKING_ID) {
          window.gtag('config', window.GOOGLE_ANALYTICS_TRACKING_ID, { page_path: location.pathname })
        }
      })
    }
  }, [])

  const originEnforce =
    userConfig.getConfigKey(UserConfigKeys.HTTP_ORIGIN_ENFORCE) && getUrlParam('http_origin_enforce') !== 'no'
  const expectedHttpOrigin = userConfig.getConfigKey(UserConfigKeys.HTTP_ORIGIN)
  const appRevision = userConfig.getConfigKey(UserConfigKeys.APP_REVISION)
  if (maybeRedirectToExpectedHttpOrigin(originEnforce, expectedHttpOrigin)) {
    return null
  }
  if (!window.revisionPrinted) {
    consoleLog('Server revision: ' + appRevision)
    window.revisionPrinted = true
  }

  return (
    <StripeProviderWrapper isRootProvider={true}>
      <MaybeWebSocketEventsProvider>
        <BrowserRouter ref={browserRouterRef}>
          <AppClientRoutes />
        </BrowserRouter>
      </MaybeWebSocketEventsProvider>
    </StripeProviderWrapper>
  )
}

AppMain.propTypes = {
  userConfig: PropTypes.object.isRequired,
}

export default withUserConfig(AppMain)
