import React from 'react'
import PropTypes from 'prop-types'

import { useAbortSignal } from '../../AppFetch.js'
import { makeFinancialYearTitleHtml } from '../../../shared-universal/AnnualReportRender.js'
import { DATA_FIELD_RIGHT_MARGIN } from './EditableTableUtils.jsx'
import EditableComment from './EditableComment.jsx'
import EditableXbrlValue from './EditableXbrlValue.jsx'
import NoteHeading from './NoteHeading.jsx'
import { renderEditableReadonlyRow } from './EditableTableUtils.jsx'

const StalldaSakerheterEditor = ({ annualReport, onChange, disabled }) => {
  const abortSignal = useAbortSignal()
  const firstCellStyle = {
    width: '100%',
  }
  const VARAV_KONCERN_LEFT_PADDING = '30px'
  return (
    <React.Fragment>
      <NoteHeading
        title="Ställda säkerheter"
        closeButton={true}
        noteXbrlName="NotStalldaSakerheter"
        annualReport={annualReport}
        onChange={onChange}
      />
      <table>
        <thead>
          <tr>
            <th style={firstCellStyle}></th>
            <td
              key={annualReport.financialYears[0].financialYearId}
              dangerouslySetInnerHTML={{
                __html: makeFinancialYearTitleHtml(annualReport.financialYears[0], 'instant'),
              }}
              style={{
                textAlign: 'right',
                paddingRight: DATA_FIELD_RIGHT_MARGIN,
                fontWeight: 'bold',
                verticalAlign: 'top',
              }}
            ></td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={firstCellStyle}>Företagsinteckningar</td>
            <td>
              <EditableXbrlValue
                xbrlName="StalldaSakerheterForetagsinteckningar"
                financialYear={annualReport.financialYears[0]}
                abortSignal={abortSignal}
                onSaved={onChange}
                disabled={disabled}
              />
            </td>
          </tr>
          <tr>
            <td style={{ ...firstCellStyle, paddingLeft: VARAV_KONCERN_LEFT_PADDING }}>
              Varav till koncernföretag, intresseföretag eller gemensamt styrt företag
            </td>
            <td>
              <EditableXbrlValue
                xbrlName="StalldaSakerheterForetagsinteckningarKoncernforetag"
                financialYear={annualReport.financialYears[0]}
                abortSignal={abortSignal}
                onSaved={onChange}
                disabled={disabled}
              />
            </td>
          </tr>
          <tr>
            <td style={firstCellStyle}>Fastighetsinteckningar</td>
            <td>
              <EditableXbrlValue
                xbrlName="StalldaSakerheterFastighetsinteckningar"
                financialYear={annualReport.financialYears[0]}
                abortSignal={abortSignal}
                onSaved={onChange}
                disabled={disabled}
              />
            </td>
          </tr>
          <tr>
            <td style={{ ...firstCellStyle, paddingLeft: VARAV_KONCERN_LEFT_PADDING }}>
              Varav till koncernföretag, intresseföretag eller gemensamt styrt företag
            </td>
            <td>
              <EditableXbrlValue
                xbrlName="StalldaSakerheterFastighetsinteckningarKoncernforetag"
                financialYear={annualReport.financialYears[0]}
                abortSignal={abortSignal}
                onSaved={onChange}
                disabled={disabled}
              />
            </td>
          </tr>
          <tr>
            <td style={firstCellStyle}>Tillgångar med äganderättsförbehåll</td>
            <td>
              <EditableXbrlValue
                xbrlName="StalldaSakerheterTillgangarAganderattsforbehall"
                financialYear={annualReport.financialYears[0]}
                abortSignal={abortSignal}
                onSaved={onChange}
                disabled={disabled}
              />
            </td>
          </tr>
          <tr>
            <td style={{ ...firstCellStyle, paddingLeft: VARAV_KONCERN_LEFT_PADDING }}>
              Varav till koncernföretag, intresseföretag eller gemensamt styrt företag
            </td>
            <td>
              <EditableXbrlValue
                xbrlName="StalldaSakerheterTillgangaraganderattsforbehallKoncernforetag"
                financialYear={annualReport.financialYears[0]}
                abortSignal={abortSignal}
                onSaved={onChange}
                disabled={disabled}
              />
            </td>
          </tr>
          <tr>
            <td style={firstCellStyle}>Belånade fordringar</td>
            <td>
              <EditableXbrlValue
                xbrlName="StalldaSakerheterBelanadeFordringar"
                financialYear={annualReport.financialYears[0]}
                abortSignal={abortSignal}
                onSaved={onChange}
                disabled={disabled}
              />
            </td>
          </tr>
          <tr>
            <td style={{ ...firstCellStyle, paddingLeft: VARAV_KONCERN_LEFT_PADDING }}>
              Varav till koncernföretag, intresseföretag eller gemensamt styrt företag
            </td>
            <td>
              <EditableXbrlValue
                xbrlName="StalldaSakerheterBelanadeFordringarKoncernforetag"
                financialYear={annualReport.financialYears[0]}
                abortSignal={abortSignal}
                onSaved={onChange}
                disabled={disabled}
              />
            </td>
          </tr>
          <tr>
            <td style={firstCellStyle}>Andra ställda säkerheter</td>
            <td>
              <EditableXbrlValue
                xbrlName="StalldaSakerheterAndraStalldaSakerheter"
                financialYear={annualReport.financialYears[0]}
                abortSignal={abortSignal}
                onSaved={onChange}
                disabled={disabled}
              />
            </td>
          </tr>
          <tr>
            <td style={{ ...firstCellStyle, paddingLeft: VARAV_KONCERN_LEFT_PADDING }}>
              Varav till koncernföretag, intresseföretag eller gemensamt styrt företag
            </td>
            <td>
              <EditableXbrlValue
                xbrlName="StalldaSakerheterAndraStalldaSakerheterKoncernforetag"
                financialYear={annualReport.financialYears[0]}
                abortSignal={abortSignal}
                onSaved={onChange}
                disabled={disabled}
              />
            </td>
          </tr>
          {renderEditableReadonlyRow(
            annualReport.financialYears[0].xbrlValues,
            'StalldaSakerheter',
            'Summa ställda säkerheter'
          )}
        </tbody>
      </table>
      <EditableComment
        xbrlName="NotStalldaSakerheterKommentarer"
        expandedTitle="Kommentar till not"
        annualReport={annualReport}
        onChange={onChange}
        disabled={disabled}
      />
    </React.Fragment>
  )
}

StalldaSakerheterEditor.propTypes = {
  annualReport: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default StalldaSakerheterEditor
