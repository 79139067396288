import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button/index.js'
import LoadingButton from '@mui/lab/LoadingButton/index.js'
import Dialog from '@mui/material/Dialog//index.js'
import DialogActions from '@mui/material/DialogActions/index.js'
import DialogContent from '@mui/material/DialogContent/index.js'
import DialogContentText from '@mui/material/DialogContentText/index.js'
import DialogTitle from '@mui/material/DialogTitle/index.js'
import { styled } from '@mui/material/styles/index.js'
import { withRouter } from 'react-router'
import SendIcon from '@mui/icons-material/Send.js'

import { useAbortSignal } from '../../AppFetch.js'
import API from '../../API.js'

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: '25px',
  },
}))

const SubmitButtonDigital = ({
  history,
  annualReport,
  onChange,
  atleastOneUploadedToEgetUtrymme,
  onSubmitStart,
  onSubmitStop,
  submitting,
  disabled,
}) => {
  const abortSignal = useAbortSignal()
  const [open, setOpen] = React.useState(false)
  const [avtalstext, setAvtalstext] = React.useState('')
  const [utfall, setUtfall] = React.useState([])
  const [bolagsverketAvtalstextDatum, setBolagsverketAvtalstextDatum] = React.useState('')

  const submitToBolagsverket = async (reportId, abortSignal) => {
    onSubmitStart()
    const res = await API.lamnaInArsredovisning(reportId, abortSignal)
    onSubmitStop()
    if (res.status === 'need_avtal') {
      setAvtalstext(res.avtalstext)
      setBolagsverketAvtalstextDatum(res.bolagsverketAvtalstextDatum)
      setOpen(true)
    } else if (res.status === 'utfall') {
      setUtfall(res.utfall)
    } else {
      await onChange()
      history.push(`/edit-report/${reportId}/dokument`)
    }
  }

  return (
    <React.Fragment>
      <LoadingButton
        variant={atleastOneUploadedToEgetUtrymme ? 'outlined' : 'contained'}
        loading={submitting}
        loadingPosition="end"
        endIcon={<SendIcon />}
        onClick={() => {
          submitToBolagsverket(annualReport.reportId, abortSignal)
        }}
        disabled={disabled}
        className="automation-submit-to-bolagsverket"
      >
        Ladda upp till eget utrymme på Bolagsverket
      </LoadingButton>
      <StyledDialog
        open={open}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{'Godkänn Bolagsverkets avtal för eget utrymme'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span style={{ border: '1px solid block', whiteSpace: 'pre-wrap' }}>{avtalstext}</span>
          </DialogContentText>
          <p>
            <em>Avtalstexten kommer från Bolagsverket och är senast uppdaterad {bolagsverketAvtalstextDatum}</em>
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false)
            }}
          >
            Avbryt
          </Button>
          <Button
            variant="contained"
            onClick={async () => {
              setOpen(false)
              await API.accepteraAvtal(annualReport.reportId, bolagsverketAvtalstextDatum, abortSignal)
              await submitToBolagsverket(annualReport.reportId, abortSignal)
            }}
            autoFocus
            className="automation-approve-avtal"
          >
            Godkänn
          </Button>
        </DialogActions>
      </StyledDialog>
      <StyledDialog
        open={utfall.length > 0}
        onClose={() => {}}
        aria-labelledby="utfall-dialog-title"
        aria-describedby="utfall-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="utfall-dialog-title">{'Inlämningen misslyckades'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="utfall-dialog-description" component="div">
            Bolagsverkets automatiska kontroller hittade följande fel som behöver åtgärdas:
            <ul>
              {utfall.map(({ kod, text }, utfallIdx) => (
                <li key={`${kod}${utfallIdx}`}>{text}</li>
              ))}
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={async () => {
              setUtfall([])
            }}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </StyledDialog>
    </React.Fragment>
  )
}

SubmitButtonDigital.propTypes = {
  annualReport: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  atleastOneUploadedToEgetUtrymme: PropTypes.bool,
  onSubmitStart: PropTypes.func.isRequired,
  onSubmitStop: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  disabled: PropTypes.bool,
  history: PropTypes.object.isRequired,
}

export default withRouter(SubmitButtonDigital)
