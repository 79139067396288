import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useAbortSignal } from '../../AppFetch.js'
import { renderEditableTable, ComparisonNumbersNudge } from './EditableTableUtils.jsx'
import RmSwitch from './RmSwitch.jsx'

const EditableIncomeStatement = ({ annualReport, onChange, isApproved }) => {
  const abortSignal = useAbortSignal()
  const [advancedIsVisible, setAdvancedIsVisible] = useState(false)
  return (
    <React.Fragment>
      <ComparisonNumbersNudge annualReport={annualReport} forText="Resultaträkningen" badgeBottomMargin="-80px" />

      <RmSwitch label="Visa alla poster" value={advancedIsVisible} onChange={setAdvancedIsVisible} />
      <table>
        <tbody>
          {renderEditableTable(
            annualReport.clientIncomeStatementTemplate,
            annualReport.financialYears.slice(0, 2),
            {
              fyTitleType: 'duration',
              readOnlyColumnCount: 0,
              editableExpressions: false,
              forceAllDisabled: isApproved,
              advancedIsHidden: !advancedIsVisible,
              firstCellStyle: { width: '100%' },
              abortSignal,
              onChange,
              maxIndent: 2,
            },
            0
          )}
        </tbody>
      </table>
    </React.Fragment>
  )
}

EditableIncomeStatement.propTypes = {
  annualReport: PropTypes.object,
  onChange: PropTypes.func,
  isApproved: PropTypes.bool.isRequired,
}

export default EditableIncomeStatement
