import { Previewer } from 'pjsrm'

import { getUrlParam } from '../../UrlUtils.js'
import { renderTable } from '../../../shared-universal/AnnualReportRender.js'

const createDeklarationPreviewHtml = (annualReport, userConfig) => {
  return `
    <style>
    .rm_page {
      background: white;
    }
    </style>
    <div id="preview-input">
      <h2 id="deklaration-ink2">INK2</h2>
      ${renderTable(annualReport.clientInk2Template, annualReport, 1, {
        fyTitleType: 'none',
        decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
        hideNoteColumn: true,
        disableXbrlTagging: true,
      })}

      <h2 id="deklaration-ink2">INK2R</h2>
      ${renderTable(annualReport.clientInk2rBalansrakningTemplate, annualReport, 1, {
        fyTitleType: 'none',
        decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
        hideNoteColumn: true,
        disableXbrlTagging: true,
      })}
      ${renderTable(annualReport.clientInk2rResultatrakningTemplate, annualReport, 1, {
        fyTitleType: 'none',
        decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
        hideNoteColumn: true,
        disableXbrlTagging: true,
      })}

      <h2 id="deklaration-ink2">INK2S</h2>
      ${renderTable(annualReport.clientInk2sTemplate, annualReport, 1, {
        fyTitleType: 'none',
        decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
        hideNoteColumn: true,
        disableXbrlTagging: true,
      })}
    </div>
    `
}

const renderDeklarationPreview = async (annualReport, targetElement, userConfig) => {
  let previewInputHtml = createDeklarationPreviewHtml(annualReport, userConfig)
  const previewer = new Previewer()
  return previewer.preview(
    previewInputHtml,
    [
      {
        generalCss: `
          @page {
            size: A4;
          }
          .break-after-page {
            break-after: page;
          }
          `,
      },
    ],
    targetElement
  )
}

export { renderDeklarationPreview }
