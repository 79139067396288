import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box/index.js'
import MenuItem from '@mui/material/MenuItem/index.js'

import RmSelect from './RmSelect.jsx'
import {
  canSignatureRoleSignCoa,
  getCertificateOfApprovalSignatureRole,
  getForetradareRoleLabel,
} from '../../../shared-universal/SignatureRoles.js'
import API from '../../API.js'
import { useAbortSignal } from '../../AppFetch.js'

const CoaSignerEditor = ({ annualReport, onChange, disabled }) => {
  const abortSignal = useAbortSignal()
  const coaSigner = annualReport.signatures.find((sig) => sig.foretradareId === annualReport.coaSigner)
  const existsAtleastOneCoaSignerCandidate =
    annualReport.signatures.filter((sig) => canSignatureRoleSignCoa(getForetradareRoleLabel(sig.role))).length > 0

  return (
    <React.Fragment>
      <div>Undertecknare av fastställelseintyg (signerar med BankID hos Bolagsverket)</div>
      <Box mt={1} />
      <RmSelect
        displayEmpty
        defaultValue={coaSigner && existsAtleastOneCoaSignerCandidate ? coaSigner.foretradareId : ''}
        onChange={(selectedForetradareId) => {
          API.saveAnnualReportValue(annualReport.reportId, 'coaSigner', selectedForetradareId, abortSignal, onChange, {
            immediate: true,
          })
        }}
        disabled={disabled}
      >
        {annualReport.signatures.flatMap((sig) =>
          canSignatureRoleSignCoa(getForetradareRoleLabel(sig.role))
            ? [
                <MenuItem key={sig.foretradareId} value={sig.foretradareId}>{`${sig.firstName} ${sig.lastName}${
                  sig.role ? ` (${getCertificateOfApprovalSignatureRole(getForetradareRoleLabel(sig.role))})` : ''
                }`}</MenuItem>,
              ]
            : []
        )}
      </RmSelect>
    </React.Fragment>
  )
}

CoaSignerEditor.propTypes = {
  annualReport: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default CoaSignerEditor
