import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button/index.js'

import { appFetch, useAbortSignal } from '../../AppFetch.js'
import { getBuyerVatNr, getIsApproved } from '../../../shared-universal/AnnualReportUtils.js'
import RmTooltip from './RmTooltip.jsx'
import RmRadioButtons from './RmRadioButtons.jsx'
import RmCard from './RmCard.jsx'
import EditableOrganizationNumber from './EditableOrganizationNumber.jsx'
import { isValidOrganizationNumber } from '../../../shared-universal/Utils.js'
import API from '../../API.js'

const RemakerBuyingCard = ({ annualReport, onChange }) => {
  const abortSignal = useAbortSignal()
  const [editBuyerOrgnrVisible, setEditBuyerOrgnrVisible] = useState(false)
  const readyToBuy = getIsApproved(annualReport, 'noter')

  return (
    <React.Fragment>
      <RmCard>
        Handla som
        <RmRadioButtons
          value={annualReport.buyerType}
          items={[
            { value: 'foretag', label: 'Företag (rekommenderas)' },
            { value: 'privatperson', label: 'Privatperson' },
          ]}
          onChange={(ev) => {
            API.saveAnnualReportValue(annualReport.reportId, 'buyerType', ev.target.value, abortSignal, onChange, {
              immediate: true,
            })
          }}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ marginLeft: '20px' }}>
            {annualReport.buyerType === 'foretag' ? (
              <React.Fragment>
                Köparens VAT nr: {getBuyerVatNr(annualReport)}
                <Button
                  style={{ marginLeft: '5px', marginBottom: '2px' }}
                  onClick={() => setEditBuyerOrgnrVisible(true)}
                >
                  Ändra?
                </Button>
                {editBuyerOrgnrVisible && (
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>Köparens organisationsnummer</div>
                    <EditableOrganizationNumber
                      className="automation-buyer-orgnr"
                      value={annualReport.buyerOrgnr}
                      onChange={(ev) => {
                        API.saveAnnualReportValue(
                          annualReport.reportId,
                          'buyerOrgnr',
                          ev.target.value,
                          abortSignal,
                          onChange,
                          { immediate: true }
                        )
                      }}
                      style={{ marginLeft: '15px' }}
                    />
                  </div>
                )}
              </React.Fragment>
            ) : (
              ''
            )}
          </div>
          <RmTooltip
            title={annualReport.blockMessage || 'Steg ett till sju behöver godkännas innan betalning.'}
            visible={Boolean(annualReport.blockMessage) || !readyToBuy}
          >
            <Button
              variant="contained"
              type="submit"
              onClick={async () => {
                const { responseJson } = await appFetch(
                  `/api/up/create-checkout-session?reportId=${annualReport.reportId}`,
                  {
                    method: 'POST',
                    signal: abortSignal,
                  }
                )
                window.location.replace(responseJson.url)
              }}
              disabled={
                !readyToBuy ||
                Boolean(annualReport.blockMessage) ||
                (annualReport.buyerType === 'foretag' && !isValidOrganizationNumber(annualReport.buyerOrgnr))
              }
              style={{ marginRight: '15px', marginTop: '15px', marginBottom: '15px' }}
            >
              Betala
            </Button>
          </RmTooltip>
        </div>
      </RmCard>
    </React.Fragment>
  )
}

RemakerBuyingCard.propTypes = {
  annualReport: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

RemakerBuyingCard.defaultProps = {}

export default RemakerBuyingCard
