import React from 'react'
import PropTypes from 'prop-types'
import Radio from '@mui/material/Radio/index.js'
import RadioGroup from '@mui/material/RadioGroup/index.js'
import FormControlLabel from '@mui/material/FormControlLabel/index.js'

import { useAbortSignal } from '../../AppFetch.js'
import { renderEditableTable } from './EditableTableUtils.jsx'
import API from '../../API.js'

const EditableMultiYearSummary = ({ annualReport, onChange, firstCellStyle, disabled }) => {
  const abortSignal = useAbortSignal()
  return (
    <React.Fragment>
      <table>
        <tbody>
          {renderEditableTable(
            annualReport.clientMultiYearSummaryTemplate,
            annualReport.financialYears,
            {
              fyTitleType: 'shortened-iso-range',
              readOnlyColumnCount: 2,
              editableExpressions: true,
              forceAllDisabled: disabled,
              firstCellStyle,
              abortSignal,
              onChange,
            },
            0
          )}
        </tbody>
      </table>
      <RadioGroup
        aria-label="position"
        style={{ marginLeft: '20px' }}
        name="position"
        value={annualReport.multiYearSummaryScale}
        onChange={async (ev) => {
          API.saveAnnualReportValue(annualReport.reportId, 'multiYearSummaryScale', ev.target.value, abortSignal, onChange, {
            immediate: true,
          })
        }}
      >
        <FormControlLabel
          value={0}
          control={<Radio color="primary" disabled={disabled} />}
          label="Visa belopp som kronor"
          labelPlacement="end"
        />
        <FormControlLabel
          value={3}
          control={<Radio color="primary" disabled={disabled} />}
          label="Visa belopp som tusentals kronor (tkr)"
          labelPlacement="end"
        />
      </RadioGroup>
    </React.Fragment>
  )
}

EditableMultiYearSummary.propTypes = {
  annualReport: PropTypes.object,
  onChange: PropTypes.func,
  firstCellStyle: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
}

export default EditableMultiYearSummary
