import React from 'react'
import PropTypes from 'prop-types'

import Modal from './generic/Modal.jsx'
import { Tabs, Tab } from './generic/Tabs.jsx'
import FileThumbnail from './FileThumbnail.jsx'
import FileInputDialog from './generic/FileInputDialog.jsx'
import screenUtils from '../ScreenUtils.js'
import ImageLibraryPanel from './ImageLibraryPanel.jsx'
import AppBuildConfig from '../../shared-universal/AppBuildConfig.js'

import styles from './ImagePickerDialog.module.css'

class ImagePickerDialog extends React.Component {
  static propTypes = {
    onFileWillBeSelected: PropTypes.func,
    onFileSelected: PropTypes.func.isRequired,
    anchorElement: PropTypes.instanceOf(Element),
  }

  static defaultProps = {
    onFileWillBeSelected: () => {},
  }

  initialState = {
    fullscreen: screenUtils.isNarrowScreen(),
  }
  state = this.initialState

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  handleWindowResize = () => {
    this.setState({ fullscreen: screenUtils.isNarrowScreen() })
  }

  static open({ anchorElement, onFileWillBeSelected, onFileSelected }) {
    const modalContainer = Modal.open({
      content: (
        <ImagePickerDialog
          onFileWillBeSelected={() => {
            onFileWillBeSelected()
            // Just hide, cannot close the modal yet because we only know which
            // search result the user clicked on, we still have not downloaded
            // that image yet (so we cannot return a "file" object for it)
            // And returning a file object rather than a URL is the main response
            // you get from the picker dialog because it also supports file
            // direct uploads.
            Modal.hide(modalContainer)
          }}
          onFileSelected={(file) => {
            onFileSelected(file)
            // Calling Modal.close() will immediately unmount ImagePickerDialog
            // so we have to wait until after we got the "file" object from onFileSelected
            // before we call it (otherwise the abortcontroll in componentWillUmount() will
            // block the download that happens between clicking a search result and
            // the call to onFileSelected).
            Modal.close(modalContainer)
          }}
        />
      ),
      className: styles.ImagePickerDialogModalContainer,
      fullscreenIf: screenUtils.isNarrowScreen,
    })
  }

  render() {
    return (
      <div className={this.state.fullscreen ? styles.ImagePickerDialog_Fullscreen : styles.ImagePickerDialog_Popup}>
        <Tabs
          className={styles.Tabs}
          tabContentClassName={styles.TabContentPanel}
          activeTabColor={'#fff'}
          inactiveTabColor={'#ddd'}
          initialTabIndex={
            localStorage && localStorage.getItem(AppBuildConfig.IMAGEPICKER_TAB_LOCALSTORAGE_KEY) === 'upload' ? 1 : 0
          }
        >
          <Tab
            label="Pick From Image Library"
            tabButtonClassName={`${styles.TabButton} automation-image-library-tab`}
            className={styles.ImageLibraryTabContent}
            onClick={() =>
              localStorage && localStorage.setItem(AppBuildConfig.IMAGEPICKER_TAB_LOCALSTORAGE_KEY, 'library')
            }
          >
            <ImageLibraryPanel
              onFileWillBeSelected={this.props.onFileWillBeSelected}
              onFileSelected={this.props.onFileSelected}
            />
          </Tab>
          <Tab
            label="Upload Image File"
            tabButtonClassName={`${styles.TabButton} automation-upload-file-tab`}
            onClick={() =>
              localStorage && localStorage.setItem(AppBuildConfig.IMAGEPICKER_TAB_LOCALSTORAGE_KEY, 'upload')
            }
          >
            <FileThumbnail
              thumbnailUrl={this.state.uploadedThumbnailUrl}
              onClick={() => this.fileInput.openNativeSelectFileDialog()}
              renderThumbnail={(url) => <img src={url} className={styles.UploadImageThumbnail} />}
              className={styles.UploadFileSelector}
            >
              (click to add image)
            </FileThumbnail>
            <FileInputDialog ref={(el) => (this.fileInput = el)} onFileSelected={this.props.onFileSelected} />
          </Tab>
        </Tabs>
      </div>
    )
  }
}

export default ImagePickerDialog
