import React from 'react'
import PropTypes from 'prop-types'

import PaddingButton from './PaddingButton.jsx'

import styles from './ModalButton.module.css'

const ModalButton = React.forwardRef(({ children, className = '', ...props }, ref) => {
  return (
    <PaddingButton className={`${styles.ModalButton} ${className}`} ref={ref} {...props}>
      {children}
    </PaddingButton>
  )
})

ModalButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

ModalButton.displayName = 'ModalButton'

export default ModalButton
