import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { withUserConfig, UserConfigKeys } from '../ClientSideUserConfig.jsx'

const PaidLink = ({ userConfig, children, ...otherProps }) => {
  if (!userConfig.getConfigKey(UserConfigKeys.ACTIVE_SUBSCRIPTION)) {
    return (
      <Link {...otherProps} to="/subscription">
        {children}
      </Link>
    )
  }
  return <Link {...otherProps}>{children}</Link>
}

PaidLink.propTypes = {
  userConfig: PropTypes.object.isRequired,
  children: PropTypes.node,
}

export default withUserConfig(PaidLink)
