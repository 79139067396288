import { useEffect } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'

import { withUserConfig, UserConfigKeys } from '../../ClientSideUserConfig.jsx'
import { logout } from '../../ClientAuth.js'

const PageLogout = ({ userConfig }) => {
  useEffect(() => {
    async function doLogout() {
      if (userConfig.getConfigKey(UserConfigKeys.IS_LOGGED_IN)) {
        await logout(userConfig)
      }
      location.href = '/'
    }

    doLogout()
  }, [userConfig])
  return null
}

PageLogout.propTypes = {
  history: PropTypes.object,
  userConfig: PropTypes.object.isRequired,
}

export default withRouter(withUserConfig(PageLogout))
