import React from 'react'
import PropTypes from 'prop-types'

import AppBuildConfig from '../../shared-universal/AppBuildConfig.js'

import appLogoSvg from './AppLogo.svg'
import styles from './AppLogo.module.css'

const AppLogo = () => (
  <div className={styles.AppLogoWrapper}>
    <img src={appLogoSvg} className={styles.AppLogoIcon} />
    <span className={styles.AppLogoText}>{AppBuildConfig.APP_NAME.toUpperCase()}</span>
  </div>
)

AppLogo.propTypes = {
  children: PropTypes.node,
}

export default AppLogo
