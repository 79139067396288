import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField/index.js'
import { PatternFormat } from 'react-number-format'
import { isValidOrganizationNumber } from '../../../shared-universal/Utils.js'

const NumberFormatInput = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values, ev) => {
        // See section "Notes and Quirks" at: https://www.npmjs.com/package/react-number-format
        if (ev.event) {
          onChange({
            target: {
              value: values.value.slice(0, 6) + (values.value.length > 6 ? '-' : '') + values.value.slice(6),
            },
          })
        }
      }}
      format={'######-####'}
      valueIsNumericString
    />
  )
})

NumberFormatInput.displayName = 'NumberFormatInput'

NumberFormatInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputRef: PropTypes.func,
}

const EditableOrganizationNumber = React.forwardRef(({ defaultValue, value, ...other }, ref) => {
  const orgNr = value || defaultValue || ''
  return (
    <TextField
      variant="outlined"
      size="small"
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: NumberFormatInput,
        style: { background: '#ffffff' },
      }}
      defaultValue={defaultValue?.replace(/-/g, '')}
      value={value?.replace(/-/g, '')}
      ref={ref}
      helperText={orgNr !== '' && !isValidOrganizationNumber(orgNr) ? 'Ogiltigt organisationsnummer' : ''}
      error={orgNr !== '' && !isValidOrganizationNumber(orgNr)}
      FormHelperTextProps={{ style: { backgroundColor: 'transparent', margin: '0' } }}
      inputProps={{ style: { background: '#ffffff' } }}
      {...other}
    />
  )
})

EditableOrganizationNumber.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
}

EditableOrganizationNumber.displayName = 'EditableOrganizationNumber'

export default EditableOrganizationNumber
