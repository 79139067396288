import React from 'react'
import PropTypes from 'prop-types'

import styles from './ReadOnlyBox.module.css'

const ReadOnlyBox = ({ className, ...props }) => {
  return (
    <div className={`${styles.ReadOnlyBox} ${className}`} {...props}>
      {props.children}
    </div>
  )
}

ReadOnlyBox.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

ReadOnlyBox.defaultProps = {
  className: '',
}

export default ReadOnlyBox
