import React, { useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'

import SpinnerBlock from '../generic/SpinnerBlock.jsx'
import IconBadge from '../generic/IconBadge.jsx'
import { appFetch, useAbortSignal } from '../../AppFetch.js'
import { withUserConfig, UserConfigKeys } from '../../ClientSideUserConfig.jsx'

const PageCheckoutFinished = ({ userConfig }) => {
  const signal = useAbortSignal()
  const timerIdRef = useRef(undefined)

  const refreshSubscriptionStatus = useCallback(async () => {
    const { responseJson } = await appFetch('/api/up/subscription-status', {
      signal,
    })
    await userConfig.refreshFromServer()
    if (!responseJson.hasActiveSubscription) {
      const timerId = setTimeout(refreshSubscriptionStatus, 2000)
      timerIdRef.current = timerId
    }
  }, [signal, userConfig])

  useEffect(() => {
    refreshSubscriptionStatus()
    return () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current)
      }
    }
  }, [refreshSubscriptionStatus])

  if (!userConfig.getConfigKey(UserConfigKeys.ACTIVE_SUBSCRIPTION)) {
    return <SpinnerBlock delayBeforeVisibleMs={1000} />
  }
  return <IconBadge className="automation-checkout-finished">Enjoy your new account!</IconBadge>
}

PageCheckoutFinished.propTypes = {
  userConfig: PropTypes.object.isRequired,
}

export default withUserConfig(PageCheckoutFinished)
