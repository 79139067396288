import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

import { withUserConfig, UserConfigKeys } from '../ClientSideUserConfig.jsx'
import { consoleError } from '../../shared-universal/SanctionedConsole.js'

const AuthenticatedRoute = ({ userConfig, component: Component, render, path, ...otherProps }) => {
  if (!userConfig.getConfigKey(UserConfigKeys.IS_LOGGED_IN)) {
    consoleError(`AuthenticatedRoute: User is not logged in, will redirect to /login instead of rendering ${path}`)
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    )
  }
  if (Component) {
    return <Route {...otherProps} render={(props) => <Component {...props} />} />
  }
  return <Route {...otherProps} render={render} />
}

AuthenticatedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  userConfig: PropTypes.object.isRequired,
  component: PropTypes.func,
  render: PropTypes.func,
}

export default withUserConfig(AuthenticatedRoute)
