import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import ToggleButton from '@mui/material/ToggleButton/index.js'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup/index.js'

import RemakerMenu from '../remaker/RemakerMenu.jsx'

const RenderInToolbar = ({ children }) => ReactDOM.createPortal(children, document.getElementById('preview-toolbar'))

const ToolbarPortal = ({ annualReport, selectedPreview, onPreviewSelected }) => {
  const toolbarRef = React.createRef()
  useEffect(() => {
    if (toolbarRef.current) {
      toolbarRef.current.style.width = `${toolbarRef.current.parentNode.clientWidth - 10}px`
    }
    setTimeout(() => {
      if (toolbarRef.current) {
        toolbarRef.current.style.width = `${toolbarRef.current.parentNode.clientWidth - 10}px`
      }
    }, 0)
  }, [toolbarRef])
  return (
    <RenderInToolbar>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        ref={toolbarRef}
      >
        <ToggleButtonGroup
          value={selectedPreview}
          exclusive
          onChange={(ev, newSelectedPreview) => {
            if (newSelectedPreview) {
              onPreviewSelected(newSelectedPreview)
            }
          }}
        >
          <ToggleButton value="arsredovisning" style={{ textTransform: 'none' }}>
            Årsredovisning
          </ToggleButton>
          <ToggleButton value="deklaration" style={{ textTransform: 'none' }}>
            Deklaration
          </ToggleButton>
        </ToggleButtonGroup>
        <RemakerMenu style={{ marginRight: '20px' }} />
      </div>
    </RenderInToolbar>
  )
}

ToolbarPortal.propTypes = {
  annualReport: PropTypes.object.isRequired,
  selectedPreview: PropTypes.string.isRequired,
  onPreviewSelected: PropTypes.func.isRequired,
}

export default ToolbarPortal
