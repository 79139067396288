import React from 'react'
import PropTypes from 'prop-types'
import RmTextField from './RmTextField.jsx'
import RmComboBox from './RmComboBox.jsx'
import { selectableSignatureRoles, getForetradareRoleLabel } from '../../../shared-universal/SignatureRoles.js'
import API from '../../API.js'
import { useAbortSignal } from '../../AppFetch.js'

const Signature = ({ signature, onChange, disabled, index }) => {
  const abortSignal = useAbortSignal()
  return (
    <tr>
      <td>
        <RmTextField
          defaultValue={signature.firstName}
          onChange={(ev) => {
            API.saveForetradareValue(signature.foretradareId, 'firstName', ev.target.value, abortSignal, onChange)
          }}
          disabled={disabled}
          className={`automation-signature-firstname-${index}`}
        />
      </td>
      <td>
        <RmTextField
          defaultValue={signature.lastName}
          onChange={(ev) => {
            API.saveForetradareValue(signature.foretradareId, 'lastName', ev.target.value, abortSignal, onChange)
          }}
          disabled={disabled}
          className={`automation-signature-lastname-${index}`}
        />
      </td>
      <td>
        <RmComboBox
          options={selectableSignatureRoles}
          defaultValue={getForetradareRoleLabel(signature.role) || ''}
          onChange={(newRole) => {
            API.saveForetradareValue(signature.foretradareId, 'role', newRole, abortSignal, onChange)
          }}
          style={{ width: '280px' }}
          disabled={disabled}
          className={`automation-signature-role-${index}`}
        />
      </td>
    </tr>
  )
}

Signature.propTypes = {
  signature: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
}

export default Signature
