import React from 'react'
import PropTypes from 'prop-types'

import svg from './IconCross.svg'

const IconCross = ({ style, ...props }) => (
  <img src={svg} style={{ width: '40px', height: '40px', ...style }} {...props} />
)

IconCross.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
}

export default IconCross
