import React from 'react'
import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'

import { useAbortSignal } from '../../AppFetch.js'
import RmTextArea from './RmTextArea.jsx'
import API from '../../API.js'
import RmButtonLink from './RmButtonLink.jsx'
import RmTooltip from './RmTooltip.jsx'

const EditableComment = ({
  xbrlName,
  addCommentText = 'Lägg till kommentar',
  expandedTitle,
  annualReport,
  onChange,
  disabled,
  classNameAdd,
  classNameTextarea,
}) => {
  const abortSignal = useAbortSignal()
  const isCommentSet = typeof annualReport.comments[xbrlName] === 'string'
  return (
    <React.Fragment>
      {!isCommentSet && (
        <RmButtonLink
          onClick={() => {
            API.saveAnnualReportValue(annualReport.reportId, 'comments', { [xbrlName]: '' }, abortSignal, onChange, {
              immediate: true,
            })
          }}
          className={classNameAdd}
        >
          {addCommentText}
        </RmButtonLink>
      )}
      {isCommentSet && (
        <React.Fragment>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>{expandedTitle}</div>
            <div>
              <RmTooltip title="Ta bort kommentar">
                <IconButton
                  size="small"
                  onClick={() => {
                    API.saveAnnualReportValue(
                      annualReport.reportId,
                      'comments',
                      { [xbrlName]: null },
                      abortSignal,
                      onChange,
                      { immediate: true }
                    )
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </RmTooltip>
            </div>
          </div>
          <RmTextArea
            defaultValue={annualReport.comments[xbrlName]}
            onChange={(ev) => {
              API.saveAnnualReportValue(
                annualReport.reportId,
                'comments',
                { [xbrlName]: ev.target.value },
                abortSignal,
                onChange
              )
            }}
            disabled={disabled}
            className={classNameTextarea}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

EditableComment.propTypes = {
  xbrlName: PropTypes.string.isRequired,
  addCommentText: PropTypes.string,
  expandedTitle: PropTypes.string.isRequired,
  annualReport: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  classNameAdd: PropTypes.string,
  classNameTextarea: PropTypes.string,
}

export default EditableComment
