import PropTypes from 'prop-types'
import React from 'react'

import FlashMessage from './FlashMessage.jsx'

import styles from './Field.module.css'

const Field = ({ label, labelSuffix, children, className, flashMessage, flashMessageClassName, ...props }) => (
  <div className={`${styles.FieldWrapper} ${className}`} {...props}>
    <label className={styles.Label}>
      {label}
      <span className={styles.LabelSuffix}> {labelSuffix}</span>
    </label>
    <div>{children}</div>
    <FlashMessage message={flashMessage} className={flashMessageClassName} />
  </div>
)

Field.defaultProps = {
  label: '',
  labelSuffix: '',
  flashMessageClassName: '',
  className: '',
}

Field.propTypes = {
  label: PropTypes.node,
  labelSuffix: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  flashMessage: PropTypes.node,
  flashMessageClassName: PropTypes.string,
}

export default Field
