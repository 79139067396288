import React from 'react'
import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'

import RmEditorHeading2 from './RmEditorHeading2.jsx'
import API from '../../API.js'
import { useAbortSignal } from '../../AppFetch.js'

const NoteHeading = ({ title, noteXbrlName, closeButton, annualReport, onChange, ...props }) => {
  const abortSignal = useAbortSignal()
  if (closeButton && (!annualReport || !onChange || !noteXbrlName)) {
    throw Error('missing prop annualReport|onChange|noteXbrlName')
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
      <RmEditorHeading2 style={{ marginBottom: '0px' }}>{title}</RmEditorHeading2>
      {closeButton && (
        <div>
          <IconButton
            size="small"
            onClick={() => {
              API.saveAnnualReportValue(
                annualReport.reportId,
                'infogadeNoter',
                { [noteXbrlName]: false },
                abortSignal,
                onChange,
                { immediate: true }
              )
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      )}
    </div>
  )
}

NoteHeading.propTypes = {
  title: PropTypes.string.isRequired,
  closeButton: PropTypes.bool,
  noteXbrlName: PropTypes.string,
  annualReport: PropTypes.object,
  onChange: PropTypes.func,
}

NoteHeading.defaultTypes = {
  closeButton: false,
}

export default NoteHeading
