import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import withStyles from '@mui/styles/withStyles/index.js'
import { DropzoneArea } from 'material-ui-dropzone'
import { withRouter } from 'react-router-dom'

import { withUserConfig } from '../../ClientSideUserConfig.jsx'
import API from '../../API.js'
import { useAbortSignal } from '../../AppFetch.js'
import FlashMessage from '../FlashMessage.jsx'
import RmTooltip from '../remaker/RmTooltip.jsx'
import EditableEmail from '../remaker/EditableEmail.jsx'
import EditableOrganizationNumber from '../remaker/EditableOrganizationNumber.jsx'
import { UserConfigKeys } from '../../../shared-universal/ConstantsShared.js'
import { isValidEmail, isValidOrganizationNumber } from '../../../shared-universal/Utils.js'
import SpinnerBlock from '../generic/SpinnerBlock.jsx'

let StyledDropzone

const NyArsredovisning = ({ history, userConfig }) => {
  const abortSignal = useAbortSignal()
  const [sieMode, setSieMode] = useState(true)
  const [sieFile, setSieFile] = useState()
  const [missingOrgNrInSie, setMissingOrgNrInSie] = useState(false)
  const [email, setEmail] = useState('')
  const [orgNr, setOrgNr] = useState('')
  const isLoggedIn = userConfig.getConfigKey(UserConfigKeys.IS_LOGGED_IN)
  const bIsEmailInputOk =
    isValidEmail(email) || !userConfig.getConfigKey(UserConfigKeys.REQUIRE_EMAIL_FOR_IMPORT) || isLoggedIn
  const submitDisabled = sieMode ? !bIsEmailInputOk || !sieFile : !bIsEmailInputOk || !isValidOrganizationNumber(orgNr)
  const [errorMessage, setErrorMessage] = useState(undefined)
  const [processing, setProcessing] = useState(false)

  if (!StyledDropzone) {
    StyledDropzone = withStyles({
      root: {
        minHeight: '140px !important',
      },
      text: {
        fontSize: '1.25rem !important',
      },
      icon: {
        width: '40px !important',
        height: '40px !important',
      },
    })(DropzoneArea)
  }

  if (processing) {
    return (
      <div>
        <div style={{ textAlign: 'center' }}>Läser in bokföring ....</div>
        <SpinnerBlock />
      </div>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        fontSize: '14px',
        marginTop: '15px',
        marginBottom: '15px',
      }}
    >
      {!isLoggedIn && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '3px',
            width: '100%',
          }}
        >
          <div>
            <b>E-mail</b>
          </div>
          <EditableEmail
            className="automation-email"
            value={email}
            onChange={(value) => {
              setEmail(value)
            }}
            allowEmptyEmail={true}
          />
        </div>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '3px',
          width: '100%',
        }}
      >
        {sieMode && (
          <React.Fragment>
            <div>
              <b>SIE-fil</b> (exporteras från ditt bokföringsprogram)
            </div>
            <React.Fragment>
              <div style={processing || sieFile ? { display: 'none' } : {}}>
                <StyledDropzone
                  dropzoneText={'Läs in SIE-fil'}
                  showPreviewsInDropzone={false}
                  showAlerts={['error']}
                  filesLimit={1}
                  maxFileSize={50000000}
                  onChange={async (files) => {
                    if (files.length === 1) {
                      setSieFile(files[0])
                    }
                  }}
                />
              </div>
              {sieFile && (
                <div
                  style={{
                    border: 'dashed',
                    borderColor: '#0000001f',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                    padding: '24px',
                    fontSize: '18px',
                  }}
                >
                  {sieFile.name}{' '}
                  <IconButton
                    style={{ marginTop: '-2px' }}
                    onClick={() => {
                      setSieFile(undefined)
                      setMissingOrgNrInSie(false)
                      setErrorMessage('')
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              )}
            </React.Fragment>
          </React.Fragment>
        )}
        {(!sieMode || missingOrgNrInSie) && (
          <React.Fragment>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '3px',
                width: '100%',
              }}
            >
              <div>
                <b>Organisationsnummer</b>
              </div>
              <EditableOrganizationNumber
                className="automation-organization-number"
                value={orgNr}
                onChange={(ev) => {
                  setOrgNr(ev.target.value)
                }}
              />
            </div>
          </React.Fragment>
        )}
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: errorMessage ? 'space-between' : 'flex-end',
          alignItems: 'center',
        }}
      >
        <FlashMessage message={errorMessage} style={{ marginBottom: '0px' }} />

        <RmTooltip
          title={
            sieMode
              ? !isLoggedIn
                ? 'Både e-mail och SIE-fil måste anges'
                : 'SIE-fil måste anges'
              : !isLoggedIn
                ? 'Både e-mail och organisationsnummer måste anges'
                : 'Organisationsnummer måste anges'
          }
          visible={submitDisabled}
        >
          <Button
            className="automation-fortsatt"
            variant="contained"
            disabled={submitDisabled}
            onClick={async () => {
              setErrorMessage('')
              setProcessing(true)
              try {
                const result = await API.createAnnualReport(
                  abortSignal,
                  sieMode ? sieFile : undefined,
                  sieMode && !missingOrgNrInSie ? undefined : orgNr,
                  !isLoggedIn ? email : undefined
                )
                if (result?.details?.sieMissingOrgNr) {
                  setMissingOrgNrInSie(true)
                }
                if (result.errorMessage) {
                  setProcessing(false)
                  setErrorMessage(result.errorMessage)
                } else {
                  const maybeForetagsinformation = result.bokforingIsApproved ? '/foretagsinformation' : ''
                  window.location.href = `/edit-report/${result.reportId}${maybeForetagsinformation}`
                }
              } catch (ex) {
                setErrorMessage(String(ex))
                setProcessing(false)
              }
            }}
          >
            Fortsätt
          </Button>
        </RmTooltip>
      </div>
      {sieMode && (
        <div style={{ marginTop: '16px' }}>
          Om du saknar SIE-fil kan du istället fylla i dina uppgifter manuellt genom att starta med en{' '}
          <a
            href="#"
            onClick={() => {
              setErrorMessage('')
              setSieMode(false)
            }}
            className="automation-start-without-sie"
          >
            tom årsredovisning
          </a>
          .
        </div>
      )}
      {!sieMode && (
        <div style={{ marginTop: '16px', fontSize: '17px' }}>
          För de flesta användare är det bättre att{' '}
          <a href="#" onClick={() => setSieMode(true)} className="automation-start-with-sie">
            ladda upp en SIE-fil
          </a>
          , man får då de flesta uppgifterna förifyllda och även exakta bokslutsverifikat att mata in i sitt
          bokföringsprogram.
        </div>
      )}
    </div>
  )
}

NyArsredovisning.propTypes = {
  history: PropTypes.object.isRequired,
  userConfig: PropTypes.object.isRequired,
}

export default withRouter(withUserConfig(NyArsredovisning))
