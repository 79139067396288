import PropTypes from 'prop-types'
import React from 'react'

const EditBox = React.forwardRef(({ value, type, onChange, onEnterKey, autoComplete = 'off', ...otherProps }, ref) => {
  return (
    <input
      type={type}
      onChange={(ev) => onChange && onChange(ev.target.value)}
      onKeyPress={(ev) => {
        if (ev.key === 'Enter' && onEnterKey) {
          onEnterKey(ev)
        }
      }}
      autoComplete={autoComplete}
      spellCheck={false}
      ref={ref}
      value={value}
      {...otherProps}
    />
  )
})

EditBox.propTypes = {
  type: PropTypes.oneOf(['text', 'password']),
  value: PropTypes.string,
  selStart: PropTypes.number,
  selEnd: PropTypes.number,
  onChange: PropTypes.func,
  onEnterKey: PropTypes.func,
  autoComplete: PropTypes.string,
  className: PropTypes.string,
}

EditBox.defaultProps = {
  type: 'text',
}

EditBox.displayName = 'EditBox'

export default EditBox
