function getUrlParam(urlParamName, searchString) {
  let haystack = searchString || location.search
  if (haystack.startsWith('?')) {
    haystack = haystack.slice(1)
  }
  const urlParam = haystack
    .split('&')
    .map((kv) => kv.replace(/\+/g, '%20'))
    .map(decodeURIComponent)
    .map((kv) => (kv.includes('=') ? kv.split('=') : [kv, '']))
    .find((p) => p[0] == urlParamName)
  return urlParam ? urlParam[1] : undefined
}

function queryString(params) {
  // jest is stuck on jsdom 10 for node v4 compat so it has no URLSearchParams
  //return '?' + new URLSearchParams(Object.entries(params).sort()).toString()
  return (
    '?' +
    Object.entries(params)
      .sort()
      .map(([key, val]) => encodeURIComponent(key) + '=' + encodeURIComponent(val))
      .join('&')
  )
}

// Because the TextEncoder polyfill in npm package "text-encoding" is huge, try this trick instead:
// http://ecmanaut.blogspot.com/2006/07/encoding-decoding-utf8-in-javascript.html
// FWIW; there is also the npm package "text-encoder-lite" which is a quite small polyfill.
const encodeUtf8 = (str) => unescape(encodeURIComponent(str))
const decodeUtf8 = (str) => decodeURIComponent(escape(str))

function encodePackedObject(object) {
  const btoaUrlSafe = (str) => btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
  const json = JSON.stringify(object, Object.keys(object).sort())
  return btoaUrlSafe(encodeUtf8(json))
}

function decodePackedObject(packedObjectBase64) {
  const atobUrlSafe = (base64str) => {
    const nonUrlSafeBase64 = (base64str + '==='.slice((base64str.length + 3) % 4)).replace(/-/g, '+').replace(/_/g, '/')
    return atob(nonUrlSafeBase64)
  }
  return JSON.parse(decodeUtf8(atobUrlSafe(packedObjectBase64)))
}

export { getUrlParam, queryString, encodePackedObject, decodePackedObject }
