const convertTitleToPreviewHeadingSelector = (title) => `.preview-heading-${title.replace(/ /g, '')}`

const populateNoteReferences = (config, contentElem) => {
  const allNoteElems = contentElem.querySelectorAll(config.noteSelector)
  allNoteElems.forEach((noteElem, idx) => {
    noteElem.textContent = `Not ${idx + 1}. ${noteElem.textContent}`
    const noteId = noteElem.id
    contentElem.querySelectorAll(`a.note-ref[href="#${noteId}"]`).forEach((noteRefElem) => {
      noteRefElem.textContent = `${noteRefElem.textContent}${idx + 1}`
    })
  })
}

const populateTableOfContentsAndNotesReferences = (config) => {
  const contentElem = config.document.createElement('div')
  contentElem.innerHTML = config.content

  populateNoteReferences(config, contentElem)

  let tocElementDiv = contentElem.querySelector(config.tocElement)
  let tocUl = config.document.createElement('ul')
  tocUl.classList.add('list-toc-generated')
  tocElementDiv.appendChild(tocUl)

  contentElem.querySelectorAll(config.titleElementSelector).forEach((element, idx) => {
    element.classList.add(convertTitleToPreviewHeadingSelector(element.textContent).replace('.', ''))
    if (element.id == '') {
      element.id = 'title-element-' + (idx + 1)
    }

    let tocNewLi = config.document.createElement('li')
    tocNewLi.classList.add('toc-element')
    tocNewLi.innerHTML = '<a href="#' + element.id + '">' + element.innerHTML + '</a><span class="page-number"></span>'
    tocUl.appendChild(tocNewLi)
  })

  return contentElem.innerHTML
}

const tableOfContentsCss = () => `

  .toc-table {
    position: relative;
  }

  .page-number {
    position: absolute;
    right: 0;
    background-color: white;
    padding-left: 6px;
  }

  .toc-box {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .toc-box-inner {
    width: 48%;
    margin: auto;
  }
  .toc-box-heading {
    text-align: center;
    font-size: 1.5em;
  }

  .list-toc-generated {
    list-style: none;
    padding-left: 0;
    overflow-x: hidden;
  }

  .list-toc-generated .toc-element::after {
    content: '.................................................................................................................................................';
    float: left;
    width: 0;
    padding-left: 5px;
    letter-spacing: 2px;
  }

  .list-toc-generated .toc-element {
    display: flex;
  }

  .list-toc-generated .toc-element a {
    font-weight: normal;
    background: #fff;
  }
`
// Note: "background: #fff" on ".list-toc-generated .toc-element a" is needed to avoid dots under TOC items in Bolagsverket wkhtml.

export { convertTitleToPreviewHeadingSelector, populateTableOfContentsAndNotesReferences, tableOfContentsCss }
