import React from 'react'
import PropTypes from 'prop-types'

import GenericPopupButton from '../generic/GenericPopupButton.jsx'

import styles from './PopupMenuButton.module.css'

class PopupMenuButton extends React.Component {
  static propTypes = {
    button: PropTypes.func.isRequired,
    align: PropTypes.oneOf(['left', 'right']),
    menuItems: PropTypes.array.isRequired,
  }

  render() {
    return (
      <GenericPopupButton
        button={this.props.button}
        align={this.props.align}
        content={({ close }) => (
          <div className={styles.PopupContentWrapper}>
            {this.props.menuItems
              .filter((item) => item.visible === true || item.visible === undefined)
              .map((item) => (
                <div
                  key={item.id}
                  onClick={() => {
                    close()
                    item.onClick()
                  }}
                  className={`${styles.MenuItem} ${item.className}`}
                >
                  {item.title}
                </div>
              ))}
          </div>
        )}
      />
    )
  }
}

export default PopupMenuButton
