import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField/index.js'
import Autocomplete from '@mui/material/Autocomplete/index.js'

const RmComboBox = ({ defaultValue, options, disabled, onChange, ...props }) => {
  const [value, setValue] = useState(defaultValue)
  return (
    <Autocomplete
      options={options}
      size="small"
      value={value || null}
      onChange={(ev, newValue) => {
        setValue(newValue)
        onChange(newValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="none"
          variant="outlined"
          style={{ ...(disabled ? { background: 'var(--body-bgcolor)' } : { background: '#ffffff' }) }}
        />
      )}
      disabled={disabled}
      {...props}
    />
  )
}

RmComboBox.propTypes = {
  defaultValue: PropTypes.any,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

RmComboBox.defaultProps = {
  disabled: false,
  onChange: () => {},
}

export default RmComboBox
