import PropTypes from 'prop-types'
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles/index.js'
import React from 'react'

import RmTheme from '../../shared-universal/RmTheme.js'

const mainTheme = createTheme({
  palette: {
    primary: {
      main: RmTheme.PRIMARY_COLOR,
    },
    error: {
      main: RmTheme.ERROR_COLOR,
    },
    warning: {
      main: '#FFDA00',
    },
  },
  typography: {
    fontFamily: `"Open Sans", "Helvetica", "Arial", sans-serif`,
    th1: {
      fontWeight: 'bold',
    },
    th2: {
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
    th3: {
      fontStyle: 'italic',
    },
    summary: {
      fontWeight: 'bold',
    },
  },
})

const MainThemeProvider = (props) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mainTheme}>{props.children}</ThemeProvider>
    </StyledEngineProvider>
  )
}

MainThemeProvider.propTypes = {
  children: PropTypes.node,
}

export default MainThemeProvider
