import React from 'react'
import PropTypes from 'prop-types'
import RmTextField from './RmTextField.jsx'
import { PatternFormat } from 'react-number-format'

const NumberFormatInput = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values, ev) => {
        // See section "Notes and Quirks" at: https://www.npmjs.com/package/react-number-format
        if (ev.event) {
          onChange({
            target: {
              value: values.value.slice(0, 8) + (values.value.length > 8 ? '-' : '') + values.value.slice(8),
            },
          })
        }
      }}
      format={'########-####'}
      valueIsNumericString
    />
  )
})

NumberFormatInput.displayName = 'NumberFormatInput'

NumberFormatInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputRef: PropTypes.func,
}

const EditablePersonnummer = React.forwardRef(({ defaultValue, value, style, ...other }, ref) => {
  return (
    <RmTextField
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: NumberFormatInput,
      }}
      defaultValue={defaultValue?.replace(/-/g, '')}
      value={value?.replace(/-/g, '')}
      ref={ref}
      {...other}
      style={{ maxWidth: '175px', ...style }}
    />
  )
})

EditablePersonnummer.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
}

EditablePersonnummer.displayName = 'EditablePersonnummer'

export default EditablePersonnummer
