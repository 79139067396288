import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import LogoutIcon from '@mui/icons-material/Logout'
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import { Link } from 'react-router-dom'
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined'

import RmTheme from '../../../shared-universal/RmTheme.js'
import { withUserConfig } from '../../ClientSideUserConfig.jsx'
import { useAppFetch } from '../../AppFetch.js'
import RmDialog from '../remaker/RmDialog.jsx'

const RemakerMenu = ({ userConfig, style }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const MenuDivider = ({ label = '' }) => (
    <div style={{ position: 'relative', textAlign: 'center', textTransform: 'uppercase', fontSize: '13px' }}>
      <hr style={{ borderBottom: 'none', borderTop: `1px solid ${RmTheme.TEXT_COLOR}`, margin: '16px 0px' }} />
      <span
        style={{
          top: '-0.075em',
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          background: '#fff',
          padding: '0 8px',
        }}
      >
        {label}
      </span>
    </div>
  )
  MenuDivider.propTypes = {
    label: PropTypes.string,
  }

  const MenuLink = ({ href, title, icon = null, style, onClick }) => (
    <Link
      to={href}
      onClick={(ev) => {
        setAnchorEl(null)
        onClick(ev)
      }}
    >
      <Button
        variant="text"
        fullWidth
        style={{ justifyContent: 'flex-start', color: RmTheme.TEXT_COLOR, ...style }}
        startIcon={icon}
      >
        {title}
      </Button>
    </Link>
  )

  MenuLink.propTypes = {
    href: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.node,
    style: PropTypes.object,
    onClick: PropTypes.func,
  }
  MenuLink.defaultProps = {
    style: {},
    onClick: () => {},
  }

  const { loading, responseJson: menuInfo, refetch } = useAppFetch(`/api/up/menu`)
  const [confirmLogoutDialogOpen, setConfirmLogoutDialogOpen] = useState(false)

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        endIcon={<MoreVertIcon fontSize="large" />}
        onClick={(ev) => {
          refetch()
          setAnchorEl(ev.currentTarget)
        }}
        style={style}
      >
        Meny
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <div style={{ width: '400px', padding: '16px' }}>
          <MenuLink href="/new" title="Ny årsredovisning" icon={<CreateNewFolderOutlinedIcon fontSize="large" />} />
          <MenuDivider label="Årsredovisningar" />
          {!loading &&
            menuInfo?.userReports &&
            menuInfo.userReports.map((annualReport) => (
              <MenuLink
                key={annualReport.reportId}
                href={`/edit-report/${annualReport.reportId}`}
                title={annualReport.title}
                icon={<FolderOutlinedIcon fontSize="large" />}
                style={{ textTransform: 'none' }}
              />
            ))}
          <MenuLink
            href={`/list`}
            title={'Visa alla årsredovisningar'}
            icon={<FolderCopyOutlinedIcon fontSize="large" />}
          />
          <MenuDivider />
          <MenuLink
            href="/logout"
            onClick={(ev) => {
              if (!menuInfo.emailConfirmed) {
                setConfirmLogoutDialogOpen(true)
                ev.preventDefault()
              }
            }}
            title="Logga ut"
            icon={<LogoutIcon fontSize="large" />}
          />
        </div>
      </Popover>
      <RmDialog
        title="Logga ut?"
        text={`Den e-post som du har angett är ännu ej bekräftad, vilket innebär att du inte kan logga in igen. Vill du verkligen logga ut?`}
        confirmVerb="Logga ut"
        open={confirmLogoutDialogOpen}
        onClose={() => {
          setConfirmLogoutDialogOpen(false)
        }}
        onConfirm={async () => {
          setConfirmLogoutDialogOpen(false)
          window.location.href = '/logout'
        }}
      />
    </React.Fragment>
  )
}

RemakerMenu.propTypes = {
  userConfig: PropTypes.object.isRequired,
  style: PropTypes.object,
}

RemakerMenu.defaultProps = {
  style: {},
}

RemakerMenu.defaultProps = {}

export default withUserConfig(RemakerMenu)
