/*
 * This function should be used for things that the application should be
 * allowed to print even in production.
 *
 * The eslint configuration has a "no-console" rule that will help developers
 * remember to delete all other console.log() calls, added for debugging etc,
 * before pushing changes.
 */
const consoleDebug = (...args) => {
  // eslint-disable-next-line no-console
  console.debug(...args)
}

const consoleLog = (...args) => {
  // eslint-disable-next-line no-console
  console.log(...args)
}

const consoleError = (...args) => {
  // eslint-disable-next-line no-console
  console.error(...args)
}

export { consoleDebug, consoleLog, consoleError }
