const FORETRADARE_ROLES = [
  { id: 'ofvd', label: 'Styrelseordförande och VD' },
  { id: 'levd', label: 'Styrelseledamot och VD' },
  { id: 'le', label: 'Styrelseledamot' },
  { id: 'vd', label: 'Verkställande direktör' },
  { id: 'su', label: 'Styrelsesuppleant' },
  { id: 'li', label: 'Likvidator' },
  { id: 'lisu', label: 'Likvidatorssuppleant' },
]
const getForetradareRoleLabel = (foretradareRole) =>
  FORETRADARE_ROLES.find((fRole) => fRole.id === foretradareRole)?.label || ''

const getForetradareRoleId = (foretradareRoleLabel) =>
  FORETRADARE_ROLES.find((fRole) => fRole.label === foretradareRoleLabel)?.id || null

const selectableSignatureRoles = FORETRADARE_ROLES.map((sigRole) => sigRole.label)

const selectableAuditorRoles = ['Revisor', 'Godkänd revisor', 'Auktoriserad revisor']

const coaSignerRoles = ['Verkställande direktör', 'Styrelseledamot', 'Likvidator', 'Likvidatorssuppleant']

const getCertificateOfApprovalSignatureRole = (signatureRole) => {
  if (signatureRole === 'Styrelseordförande och VD') {
    return 'Verkställande direktör'
  } else if (signatureRole === 'Styrelseledamot och VD') {
    return 'Verkställande direktör'
  } else if (signatureRole === 'Styrelseledamot') {
    return 'Styrelseledamot'
  } else if (signatureRole === 'Verkställande direktör') {
    return 'Verkställande direktör'
  } else if (signatureRole === 'Likvidator') {
    return 'Likvidator'
  } else if (signatureRole === 'Likvidatorssuppleant') {
    return 'Likvidatorssuppleant'
  } else {
    return ''
  }
}

const canSignatureRoleSignCoa = (signatureRole) => {
  return coaSignerRoles.includes(getCertificateOfApprovalSignatureRole(signatureRole))
}

const getSignatureRoleFromForetradare = (foretradare) => {
  if (
    foretradare.funktioner.some((funk) => funk.kod === 'OF') &&
    foretradare.funktioner.some((funk) => funk.kod === 'VD')
  ) {
    return 'Styrelseordförande och VD'
  } else if (
    foretradare.funktioner.some((funk) => funk.kod === 'LE') &&
    foretradare.funktioner.some((funk) => funk.kod === 'VD')
  ) {
    return 'Styrelseledamot och VD'
  } else if (foretradare.funktioner.some((funk) => funk.kod === 'LE')) {
    return 'Styrelseledamot'
  } else if (foretradare.funktioner.some((funk) => funk.kod === 'VD')) {
    return 'Verkställande direktör'
  } else if (foretradare.funktioner.some((funk) => funk.kod === 'EVD')) {
    return 'Verkställande direktör'
  } else if (foretradare.funktioner.some((funk) => funk.kod === 'SU')) {
    return 'Styrelsesuppleant'
  } else if (foretradare.funktioner.some((funk) => funk.kod === 'LI')) {
    return 'Likvidator'
  } else if (foretradare.funktioner.some((funk) => funk.kod === 'REV')) {
    return 'Revisor'
  } else if (foretradare.funktioner.some((funk) => funk.kod === 'REVH')) {
    return 'Revisor'
  }
}

export {
  selectableSignatureRoles,
  selectableAuditorRoles,
  getCertificateOfApprovalSignatureRole,
  canSignatureRoleSignCoa,
  getSignatureRoleFromForetradare,
  getForetradareRoleLabel,
  getForetradareRoleId,
}
