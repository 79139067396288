import React from 'react'
import PropTypes from 'prop-types'

const RmClickToSelect = ({ text }) => {
  return (
    <span
      dangerouslySetInnerHTML={{ __html: text }}
      onClick={(ev) => {
        const selection = window.getSelection()
        const range = document.createRange()
        range.selectNodeContents(ev.target)
        selection.removeAllRanges()
        selection.addRange(range)
      }}
    ></span>
  )
}

RmClickToSelect.propTypes = {
  text: PropTypes.string,
}

RmClickToSelect.defaultProps = {
  text: '',
}

export default RmClickToSelect
