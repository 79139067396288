import PropTypes from 'prop-types'
import Button from '@mui/material/Button/index.js'
import { styled } from '@mui/material/styles'

const RmButtonLink = styled(Button)(({ theme }) => ({
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
}))

RmButtonLink.propTypes = {
  children: PropTypes.node,
}

export default RmButtonLink
