import React from 'react'
import PropTypes from 'prop-types'

const RmEditorHeading3 = ({ children, style }) => {
  return <h3 style={{ fontSize: '16px', marginTop: '0px', ...style }}>{children}</h3>
}

RmEditorHeading3.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
}

RmEditorHeading3.defaultProps = {
  style: {},
}

export default RmEditorHeading3
