import React, { useState } from 'react'
import PropTypes from 'prop-types'
import RmSlider from './RmSlider.jsx'
import { saveXbrlValue } from './EditableTableUtils.jsx'
import EditableAmount from './EditableAmount.jsx'

const EditableXbrlSlider = ({
  title,
  xbrlName,
  allowNegative,
  min,
  max,
  financialYear,
  abortSignal,
  onChange,
  disabled,
  unit,
}) => {
  const [value, setValue] = useState(financialYear.xbrlValues[xbrlName]?.value || 0)
  return (
    <>
      <tr>
        <td style={{ width: '100%' }}>{title}</td>
        <td>
          <EditableAmount
            className={`automation-${xbrlName}`}
            allowNegative={allowNegative}
            value={value}
            onChange={async (ev) => {
              saveXbrlValue(financialYear.financialYearId, xbrlName, ev.target.value, abortSignal, onChange)
              setValue(Number(ev.target.value))
            }}
            unit={unit}
            disabled={disabled}
          />
        </td>
      </tr>
      <tr>
        <td colSpan="2" style={{ paddingLeft: '13px', paddingRight: '40px' }}>
          <RmSlider
            className={`automation-${xbrlName}-slider`}
            value={value}
            min={min}
            max={max}
            step={1}
            onChange={async (value) => {
              saveXbrlValue(financialYear.financialYearId, xbrlName, value, abortSignal, onChange)
              setValue(value)
            }}
            unit={unit}
            disabled={disabled}
          />
        </td>
      </tr>
    </>
  )
}

EditableXbrlSlider.propTypes = {
  title: PropTypes.string.isRequired,
  xbrlName: PropTypes.string.isRequired,
  allowNegative: PropTypes.bool,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  financialYear: PropTypes.object.isRequired,
  abortSignal: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  unit: PropTypes.string,
}

EditableXbrlSlider.defaultProps = {
  allowNegative: true,
  disabled: false,
  unit: 'kr',
}

export default EditableXbrlSlider
