import PropTypes from 'prop-types'
import React from 'react'

import styles from './FileThumbnail.module.css'

class FileThumbnail extends React.Component {
  static propTypes = {
    thumbnailUrl: PropTypes.string,
    onClick: PropTypes.func,
    renderThumbnail: PropTypes.func.isRequired,
    className: PropTypes.string,
    children: PropTypes.node,
  }

  render() {
    return (
      <div className={`${styles.FileSelector} ${this.props.className}`}>
        {!this.props.thumbnailUrl && (
          <div
            className={`${styles.NoFileSelectedYet} automation-no-file-selected`}
            onClick={() => this.props.onClick()}
          >
            {this.props.children}
          </div>
        )}
        {this.props.thumbnailUrl && (
          <div>
            <div className={styles.FileContainer} onClick={() => this.props.onClick()}>
              {this.props.renderThumbnail(this.props.thumbnailUrl)}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default FileThumbnail
