import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Button from '@mui/material/Button/index.js'
import Box from '@mui/material/Box/index.js'
import Grid from '@mui/material/Grid/index.js'
import Tabs from '@mui/material/Tabs/index.js'
import Tab from '@mui/material/Tab/index.js'
import makeStyles from '@mui/styles/makeStyles/index.js'
import withStyles from '@mui/styles/withStyles/index.js'
import Badge from '@mui/material/Badge/index.js'
import { Redirect } from 'react-router-dom'

import SpinnerBlock from '../generic/SpinnerBlock.jsx'
import { debounced } from '../../../shared-universal/Utils.js'
import { useAppFetch } from '../../AppFetch.js'
import ForetagsinformationEditor from '../remaker/ForetagsinformationEditor.jsx'
import BodReport from '../remaker/BodReport.jsx'
import { renderAnnualReportPreview } from '../remaker/AnnualReportPreviewer.js'
import { renderDeklarationPreview } from '../remaker/DeklarationRender.jsx'
import EditableIncomeStatement from '../remaker/EditableIncomeStatement.jsx'
import EditableBalanceSheet from '../remaker/EditableBalanceSheet.jsx'
import { convertTitleToPreviewHeadingSelector } from '../../../shared-universal/TocUtils.js'
import NotesEditor from '../remaker/NotesEditor.jsx'
import KontoinformationEditor from '../remaker/KontoinformationEditor.jsx'
import SectionApprovalPanel from '../remaker/SectionApprovalPanel.jsx'
import EditableTaxDetails from '../remaker/EditableTaxDetails.jsx'
import FardigstallEditor from '../remaker/FardigstallEditor.jsx'
import { getApprovableSections, getIsApproved } from '../../../shared-universal/AnnualReportUtils.js'
import Bokforingsimport from '../remaker/Bokforingsimport.jsx'
import ToolbarPortal from '../remaker/ToolbarPortal.jsx'
import Dokument from '../remaker/Dokument.jsx'
import { withUserConfig, isDebugTrue } from '../../ClientSideUserConfig.jsx'
import minarsredovisningInverted from '../remaker/minarsredovisning-inverted.png'
import API from '../../API.js'
import { useBroadcastChannel } from '../../BroadcastChannel.js'
import { useAbortSignal } from '../../AppFetch.js'
import HttpErrorCodes from '../../../shared-universal/HttpErrorCodes.js'

const APPROVED_BACKGROUND_COLOR = '#119163'
const NOT_APPROVED_COLOR = '#333'

const TabPanel = React.forwardRef((props, ref) => {
  const { children, tabId, ...other } = props

  return (
    <div
      style={{
        height: '100vh',
        overflowY: 'auto',
        width: '100%',
        backgroundImage: 'url(/bg.png)',
        background1: 'var(--body-bgcolor)',
      }}
      role="tabpanel"
      id={`vertical-tabpanel-${tabId}`}
      aria-labelledby={`vertical-tab-${tabId}`}
      {...other}
      ref={ref}
    >
      {<Box p={2}>{children}</Box>}
    </div>
  )
})

TabPanel.displayName = 'TabPanel'

TabPanel.propTypes = {
  children: PropTypes.node,
  tabId: PropTypes.string.isRequired,
}

const LEFT_MENU_WIDTH = 300

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100vh',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    flex: `0 0 ${LEFT_MENU_WIDTH}px`,
  },
}))

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0])

  useEffect(() => {
    const updateSize = debounced(50, () => {
      setSize([window.innerWidth, window.innerHeight])
    })
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return size
}

const ErrorBadge = withStyles((theme) => ({
  badge: {
    right: -17,
    top: 9,
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.error.main,
    height: '19px',
    minWidth: '19px',
  },
}))(Badge)

const WarningBadge = withStyles((theme) => ({
  badge: {
    right: -17,
    top: 9,
    color: '#000',
    backgroundColor: theme.palette.warning.main,
    height: '19px',
    minWidth: '19px',
  },
}))(Badge)

const CircledText = ({ text, textColor, circleColor, backgroundColor }) => (
  <div style={{ position: 'relative', width: '50px', height: '50px', transform: 'scale(0.6)' }}>
    <div
      style={{
        top: 0,
        left: 0,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <svg
        focusable={false}
        viewBox="0 0 24 24"
        aria-hidden={true}
        style={{ fill: backgroundColor, stroke: circleColor, strokeWidth: '1.5px' }}
      >
        <circle cx="12" cy="12" r="11" />
      </svg>
    </div>
    <div
      style={{
        top: 0,
        left: 0,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <div
        style={{
          fontWeight: 'bold',
          fontSize: '24px',
          color: textColor,
        }}
      >
        {text}
      </div>
    </div>
  </div>
)
CircledText.propTypes = {
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  circleColor: PropTypes.string,
  backgroundColor: PropTypes.string,
}
CircledText.defaultProps = {
  textColor: '#000',
  circleColor: '#000',
  backgroundColor: 'transparent',
}

const PageEditPreview = ({ userConfig, history, reportId, tabId }) => {
  const classes = useStyles()
  const abortSignal = useAbortSignal()
  const [width, height] = useWindowSize() // actual value is almost ignored, only used to trigger a scale op when window resizes
  useBroadcastChannel('report-deleted', (ev) => {
    if (ev.data === reportId) {
      location.href = '/list'
    }
  })

  // Show or hide the report preview divs
  useEffect(() => {
    const previewOutput = document.querySelector('#preview-output-wrapper')
    previewOutput.style.display = 'block'
    return () => {
      previewOutput.style.display = 'none'
      document.head.querySelectorAll('[data-rm-inserted-styles]').forEach((e) => e.parentNode?.removeChild(e))
    }
  }, [])

  // Set scaling on the zoom div
  useEffect(() => {
    const previewWrapper = document.querySelector('#preview-output-wrapper')
    const previewOutputZoom = document.querySelector('#preview-output-zoom')
    if (previewWrapper.style.display === 'block') {
      // A4 paper is rendered on 794px by 1123px in pagedjs
      // 2 * 32 px is the margin for .rm_page
      const scaleX = (previewWrapper.clientWidth - 2 * 32) / 794
      const scaleY = (previewWrapper.clientHeight - 2 * 32) / 1123
      previewOutputZoom.style.transform = `scale(${Math.min(scaleX, scaleY)})`
      previewOutputZoom.style.transformOrigin = '32px 0px'
    }
  }, [width, height])

  const [selectedPreview, setSelectedPreview] = useState('arsredovisning')
  const {
    loading,
    error,
    status,
    responseJson: refreshResponseJson,
    refetch,
  } = useAppFetch(`/api/up/annual-report?reportId=${reportId}`)
  const annualReport = refreshResponseJson?.errorCode ? undefined : refreshResponseJson

  if (!loading && error) {
    throw error
  }
  if (!loading && status !== 200) {
    throw Error(
      `invalid HTTP response for annual report, status ${status} with body ${JSON.stringify(annualReport, null, 4)}`
    )
  }

  const scrollPreviewTo = (targetSelector) => {
    const previewScrollTargetElement = document.querySelector(targetSelector)
    if (previewScrollTargetElement) {
      previewScrollTargetElement.closest('.rm_page').scrollIntoView()
      document.querySelector('#preview-output-wrapper').scrollBy(0, -92)
    }
  }

  const TAB_INFO_FOR_APPROVABLE_SECTIONS = [
    {
      id: 'foretagsinformation',
      previewPanel: 'arsredovisning',
      previewScrollTarget: '.rm_sheet',
      element: (
        <ForetagsinformationEditor
          isApproved={getIsApproved(annualReport, 'foretagsinformation')}
          annualReport={annualReport}
          onChange={refetch}
        />
      ),
    },
    {
      id: 'bokforing',
      previewPanel: 'arsredovisning',
      previewScrollTarget: convertTitleToPreviewHeadingSelector('Resultaträkning'),
      element: (
        <Bokforingsimport
          isApproved={getIsApproved(annualReport, 'bokforing')}
          annualReport={annualReport}
          onChange={refetch}
        />
      ),
    },
    {
      id: 'forvaltningsberattelse',
      previewPanel: 'arsredovisning',
      previewScrollTarget: convertTitleToPreviewHeadingSelector('Förvaltningsberättelse'),
      element: (
        <BodReport
          isApproved={getIsApproved(annualReport, 'forvaltningsberattelse')}
          annualReport={annualReport}
          onChange={refetch}
        />
      ),
    },
    {
      id: 'resultatrakning',
      previewPanel: 'arsredovisning',
      previewScrollTarget: convertTitleToPreviewHeadingSelector('Resultaträkning'),
      element: (
        <EditableIncomeStatement
          isApproved={getIsApproved(annualReport, 'resultatrakning')}
          annualReport={annualReport}
          onChange={refetch}
        />
      ),
    },
    {
      id: 'balansrakning',
      previewPanel: 'arsredovisning',
      previewScrollTarget: convertTitleToPreviewHeadingSelector('Balansräkning'),
      element: (
        <EditableBalanceSheet
          isApproved={getIsApproved(annualReport, 'balansrakning')}
          annualReport={annualReport}
          onChange={refetch}
        />
      ),
    },
    {
      id: 'skatteberakning',
      previewPanel: 'deklaration',
      previewScrollTarget: '#deklaration-ink2',
      element: (
        <EditableTaxDetails
          isApproved={getIsApproved(annualReport, 'skatteberakning')}
          annualReport={annualReport}
          onChange={refetch}
        />
      ),
    },
    {
      id: 'noter',
      previewPanel: 'arsredovisning',
      previewScrollTarget: convertTitleToPreviewHeadingSelector('Noter'),
      element: (
        <NotesEditor isApproved={getIsApproved(annualReport, 'noter')} annualReport={annualReport} onChange={refetch}>
          Noter
        </NotesEditor>
      ),
    },
    {
      id: 'fardigstall',
      previewPanel: 'arsredovisning',
      element: (
        <FardigstallEditor
          annualReport={annualReport}
          onChange={refetch}
          isApproved={getIsApproved(annualReport, 'fardigstall')}
        />
      ),
    },
  ]

  const allTabInfo = [
    ...getApprovableSections(annualReport).map((section) => ({
      ...TAB_INFO_FOR_APPROVABLE_SECTIONS.find((tabInfo) => tabInfo.id === section.sectionId),
      title: section.title,
    })),
    {
      id: 'dokument',
      title: 'Dokument',
      element: <Dokument annualReport={annualReport} onChange={refetch} />,
    },
    {
      id: 'kontoinformation',
      title: 'Kontoinformation',
      element: <KontoinformationEditor annualReport={annualReport} onChange={refetch} />,
    },
  ]
  const initialTabId = allTabInfo.find((tabInfo) => tabInfo.id === tabId) ? tabId : 'bokforing'
  const [selectedTabId, setSelectedTabId] = React.useState(initialTabId)
  const selectedTabInfo = allTabInfo.find((tabInfo) => tabInfo.id === selectedTabId)
  useEffect(() => {
    ;(async () => {
      if (annualReport) {
        const previewOutputWrapper = document.querySelector('#preview-output-wrapper')
        let allTargets

        const renderPreviewDoubleBuffered = async (renderFunc, targetSelector) => {
          const previewOutput = document.querySelector(targetSelector)
          const previewRenderingTargetElement = document.createElement('div')
          previewRenderingTargetElement.classList.add('preview-rendering-target')
          previewOutputWrapper.style['overflow-y'] = 'hidden'
          // While pagedjs rendering runs it will be added to the document but not inside
          // #preview-output-zoom because we don't want the transform:scale() to mess with pagedjs rendering
          document.querySelector('#preview-render-area').appendChild(previewRenderingTargetElement)
          await renderFunc(annualReport, previewRenderingTargetElement, userConfig)
          // Move rendering target into the "scaled" viewport
          previewOutput.appendChild(previewRenderingTargetElement)
          allTargets = previewOutput.querySelectorAll('.preview-rendering-target')
          if (allTargets.length > 1) {
            previewOutput.removeChild(allTargets[0])
          }
        }

        // Render "arsredovisning" preview
        await renderPreviewDoubleBuffered(renderAnnualReportPreview, '#preview-output-arsredovisning')
        await renderPreviewDoubleBuffered(renderDeklarationPreview, '#preview-output-deklaration')

        previewOutputWrapper.style['overflow-y'] = 'auto'
      }
    })()
  }, [annualReport, userConfig])
  useEffect(() => {
    setSelectedTabId(initialTabId)
  }, [initialTabId])
  useEffect(() => {
    if (selectedTabInfo.previewPanel) {
      setSelectedPreview(selectedTabInfo.previewPanel)
    }
  }, [selectedTabId, selectedTabInfo.previewPanel])
  const tabPanelRef = React.createRef()
  useEffect(() => {
    if (selectedTabInfo.previewScrollTarget) {
      scrollPreviewTo(selectedTabInfo.previewScrollTarget)
    }
  }, [selectedTabId, selectedTabInfo.previewScrollTarget])

  if (refreshResponseJson?.errorCode === HttpErrorCodes.USES_OLDID) {
    return <Redirect to={{ pathname: '/' }} />
  }
  if (refreshResponseJson?.errorCode === 'ui_error_message') {
    return (
      <div style={{ padding: '32px' }}>
        <h3>Min Årsredovisning</h3>
        {refreshResponseJson?.errorMessage}
      </div>
    )
  }

  if (loading) {
    return <SpinnerBlock />
  }
  let selectedTabLintErrors = annualReport.lintErrors.filter((lintError) => lintError.tabId === selectedTabId)

  const StyledTab = withStyles({
    root: {
      textTransform: 'none',
      fontSize: '1rem',
      fontWeight: '400',
      maxWidth: LEFT_MENU_WIDTH,
      flexDirection: 'row',
      justifyContent: 'flex-start',
      '& > *:first-child': {
        marginBottom: '1px !important',
        marginRight: '8px',
      },
    },
    selected: {
      '& > *': {
        color: '#000',
      },
      '&.Mui-selected': {
        backgroundColor: '#DCE5E2',
      },
    },
  })(Tab)

  const selectedTabIsApproved = annualReport.sectionApprovals.find(
    (appr) => appr.sectionId === selectedTabInfo.id
  )?.isApproved
  if (selectedTabIsApproved && !isDebugTrue()) {
    selectedTabLintErrors = []
  }
  const currentSectionCanBeApproved = getApprovableSections(annualReport).some(
    (section) => section.sectionId === selectedTabInfo.id
  )

  const showLintRules = selectedTabLintErrors.length > 0
  const showSectionApprovalPanel =
    currentSectionCanBeApproved && (selectedTabIsApproved || selectedTabLintErrors.length === 0)

  const isUnpaidUnapprovedFardigstall =
    selectedTabInfo.id === 'fardigstall' && !annualReport.goodToGo && !getIsApproved(annualReport, 'fardigstall')
  const showBottomPanel = (showSectionApprovalPanel || showLintRules) && !isUnpaidUnapprovedFardigstall

  return (
    <>
      <ToolbarPortal
        annualReport={annualReport}
        selectedPreview={selectedPreview}
        onPreviewSelected={(newSelectedPreview) => {
          setSelectedPreview(newSelectedPreview)
          document.querySelector('#preview-output-wrapper').scrollBy(0, -99999)
          if (newSelectedPreview === 'arsredovisning') {
            document.querySelector('#preview-output-arsredovisning').scrollIntoView()
            document.querySelector('#preview-output-wrapper').scrollBy(0, -92)
          } else {
            document.querySelector('#preview-output-deklaration').scrollIntoView()
            document.querySelector('#preview-output-wrapper').scrollBy(0, -92)
          }
        }}
      />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        className="automation-page-edit-preview"
      >
        <Grid item xs={12}>
          <div className={classes.root}>
            <div style={{ display: 'flex', flexDirection: 'column' }} className={classes.tabs}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  paddingLeft: '20px',
                  paddingRight: '0px',
                  paddingTop: '25px',
                  paddingBottom: '25px',
                  backgroundColor: '#9FF7C9',
                  alignItems: 'center',
                }}
              >
                <img
                  src={minarsredovisningInverted}
                  width="45"
                  style={{ background: '#00DA7F', borderRadius: '10px' }}
                />
                <div
                  style={{
                    fontFamily: "'Roboto Slab', serif",
                    fontSize: '20px',
                    color: '#222',
                    padding: '5px',
                    paddingLeft: '6px',
                  }}
                >
                  Min Årsredovisning
                </div>
              </div>

              <div style={{ height: '100vh' }}>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={selectedTabId}
                  onChange={(ev, newSelectedTabId) => {
                    history.push(`/edit-report/${annualReport.reportId}/${newSelectedTabId}`)
                  }}
                  aria-label="Main menu"
                  TabIndicatorProps={{ style: { background: 'gray' } }}
                >
                  {allTabInfo.map((tabInfo, tabIdx) => {
                    const currentApprovalObj = annualReport.sectionApprovals.find(
                      (appr) => appr.sectionId === tabInfo.id
                    )
                    const isEmailConfirmed = annualReport.email === annualReport.confirmedEmail
                    const isMarkedAsGreen = currentApprovalObj?.isApproved
                    const kontoinformationErrorCount = isEmailConfirmed ? 0 : 1
                    let tabErrorCount =
                      tabInfo.id === 'kontoinformation'
                        ? kontoinformationErrorCount
                        : annualReport.lintErrors
                            .filter((lintError) => lintError.tabId === tabInfo.id)
                            .filter((lintError) => lintError.lintRuleType !== 'section-approval-violation').length
                    if (tabInfo.id === 'fardigstall' && !annualReport.goodToGo) {
                      tabErrorCount = 0
                    }
                    return (
                      <StyledTab
                        icon={
                          <CircledText
                            text={tabInfo.id === 'kontoinformation' ? 'K' : String(tabIdx + 1)}
                            textColor={isMarkedAsGreen ? 'white' : NOT_APPROVED_COLOR}
                            circleColor={isMarkedAsGreen ? APPROVED_BACKGROUND_COLOR : NOT_APPROVED_COLOR}
                            backgroundColor={isMarkedAsGreen ? APPROVED_BACKGROUND_COLOR : 'transparent'}
                          />
                        }
                        label={
                          isMarkedAsGreen ? (
                            isDebugTrue() ? (
                              <WarningBadge badgeContent={tabErrorCount}>{tabInfo.title}</WarningBadge>
                            ) : (
                              <div>{tabInfo.title}</div>
                            )
                          ) : (
                            <ErrorBadge badgeContent={tabErrorCount}>{tabInfo.title}</ErrorBadge>
                          )
                        }
                        key={tabInfo.id}
                        id={`vertical-tab-${tabInfo.id}`}
                        aria-controls={`vertical-tab-${tabInfo.id}`}
                        value={tabInfo.id}
                        onClick={() => {
                          if (tabPanelRef.current) {
                            tabPanelRef.current.scrollTop = 0
                          }
                        }}
                        className={`automation-tab-${tabInfo.id} ${
                          isMarkedAsGreen ? `automation-tab-${tabInfo.id}-green-badge` : ''
                        }`}
                      />
                    )
                  })}
                </Tabs>
              </div>
            </div>
            <TabPanel
              tabId={selectedTabInfo.id}
              ref={tabPanelRef}
              className={`automation-editable-${selectedTabInfo.id}`}
            >
              {annualReport.blockMessage && (
                <div
                  style={{
                    background: '#BB0000',
                    color: '#fff',
                    fontWeight: 'bold',
                    padding: '15px',
                    marginBottom: '15px',
                    borderRadius: '3px',
                  }}
                >
                  {annualReport.blockMessage}
                </div>
              )}
              {annualReport.dismissableMessage && (
                <div
                  style={{
                    background: '#aee7cc',
                    color: '#202020',
                    fontWeight: 'bold',
                    padding: '15px',
                    marginBottom: '15px',
                    borderRadius: '3px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>{annualReport.dismissableMessage}</div>
                  <div>
                    <Button
                      onClick={async () => {
                        await API.dismissMessage(annualReport.reportId, abortSignal)
                        refetch()
                      }}
                    >
                      OK
                    </Button>
                  </div>
                </div>
              )}
              {selectedTabInfo.element}
              <Box mt={50} />
              {showBottomPanel && (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '16px',
                    left: `calc(${LEFT_MENU_WIDTH}px + 16px + 80px)`,
                    width: `calc(2 * (100% - ${LEFT_MENU_WIDTH}px) / 3 - 9 * 16px - 160px)`,
                    background: '#fff',
                    padding: '16px',
                    borderRadius: '5px',
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
                  }}
                >
                  {showLintRules && (
                    <ul style={{ margin: 0, paddingLeft: '16px', maxHeight: '300px', overflowY: 'auto' }}>
                      {selectedTabLintErrors
                        .filter((lintError) => !lintError.needsSieReimport)
                        .map((lintError, lintErrorIdx) => (
                          <li key={lintError.lintRuleId + lintErrorIdx}>{lintError.message}</li>
                        ))}
                    </ul>
                  )}
                  {showSectionApprovalPanel && (
                    // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#preferred-solutions
                    <SectionApprovalPanel
                      key={selectedTabInfo.id}
                      annualReport={annualReport}
                      sectionId={selectedTabInfo.id}
                      onChange={refetch}
                      selectedTabLintErrors={selectedTabLintErrors}
                      disabled={selectedTabInfo.id === 'fardigstall' ? annualReport.coaLocked : annualReport.locked}
                    />
                  )}
                </div>
              )}
            </TabPanel>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

PageEditPreview.propTypes = {
  history: PropTypes.object.isRequired,
  userConfig: PropTypes.object.isRequired,
  reportId: PropTypes.string.isRequired,
  tabId: PropTypes.string,
}

export default withUserConfig(withRouter(PageEditPreview))
