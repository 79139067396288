import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box/index.js'

import RmDatePicker from './RmDatePicker.jsx'
import API from '../../API.js'
import { useAbortSignal } from '../../AppFetch.js'

const AuditDateEditor = ({ annualReport, onChange, disabled }) => {
  const abortSignal = useAbortSignal()
  return (
    <React.Fragment>
      <div>Datum för revisorspåteckning</div>
      <Box mt={1} />
      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '50px' }}>
        <div>
          <RmDatePicker
            className={`automation-audit-date`}
            defaultValue={annualReport.auditDate || null}
            onChange={async (newDate) => {
              API.saveAnnualReportValue(annualReport.reportId, 'auditDate', newDate || '', abortSignal, onChange, {
                immediate: true,
              })
            }}
            disabled={disabled}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

AuditDateEditor.propTypes = {
  annualReport: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

export default AuditDateEditor
