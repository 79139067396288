// Init Sentry as early as possible
import * as Sentry from '@sentry/browser'
if (window.GLOBAL_SENTRY_FRONTEND_DSN) {
  Sentry.init({ dsn: window.GLOBAL_SENTRY_FRONTEND_DSN })
}
// Init lightweight crash recorder as early as possible
import { submitCrashReport } from './LoggingUtils.js'
import AppBuildConfig from '../shared-universal/AppBuildConfig.js'
if (AppBuildConfig.LOG_JS_ERRORS_IN_SERVER_LOG) {
  window.addEventListener('error', (errorEvent) => {
    const errorDetails = {
      filename: `${errorEvent.filename}:${errorEvent.lineno}:${errorEvent.colno}`,
      message: errorEvent.message,
    }
    submitCrashReport(errorEvent.error, errorDetails)
  })
}

import 'core-js/stable'
// import "unfetch/polyfill/index.js" to workaround issue:
// https://github.com/developit/unfetch/issues/101
import 'unfetch/polyfill/index.js'
import 'abortcontroller-polyfill'
import 'formdata-polyfill'
import 'focus-visible/dist/focus-visible.min.js'
import './UnusedFiles.js'

import cssVars from 'css-vars-ponyfill'
cssVars()

import './Global.css'
import React from 'react'
import ReactDOM from 'react-dom'

import App from './components/App.jsx'

ReactDOM.render(<App />, document.getElementById('react-root'))
