import React from 'react'
import PropTypes from 'prop-types'
import { useStripe, useElements } from '@stripe/react-stripe-js'
import { withRouter } from 'react-router-dom'

import { withUserConfig, UserConfigKeys } from '../../ClientSideUserConfig.jsx'
import PageCheckout from './PageCheckout.jsx'

const PageCheckoutWrapperInner = ({ userConfig, history }) => {
  const stripe = useStripe()
  const elements = useElements()

  if (
    !userConfig.getConfigKey(UserConfigKeys.IS_LOGGED_IN) ||
    !userConfig.getConfigKey(UserConfigKeys.STRIPE_ENABLED)
  ) {
    history.push('/signup')
    return
  }

  return <PageCheckout stripe={stripe} elements={elements} />
}

PageCheckoutWrapperInner.propTypes = {
  userConfig: PropTypes.object.isRequired,
  history: PropTypes.object,
}

export default withUserConfig(withRouter(PageCheckoutWrapperInner))
