import { escapeHtml } from '../shared-universal/Utils.js'

import dayjs from './DayjsWithPlugins.js'
import {
  hasNonZeroRawAmount,
  formatIXbrl,
  hasValue,
  notLangfristigaSkulderVisible,
  shouldNoteBeVisible,
  getOpeningBalancesForChangesToEquityTable,
  getAnnualReportIssuer,
} from './AnnualReportUtils.js'
import { getCertificateOfApprovalSignatureRole, getForetradareRoleLabel } from './SignatureRoles.js'
import { getVisibleDepreciations } from './RemakerUtils.js'
import { UserConfigKeys } from '../shared-universal/ConstantsShared.js'
import { changesToEquityOptionalColumns } from './AnnualReportUtils.js'
import { getDomicileString } from './RemakerUtils.js'
import { loadVisibleBalanceNoteSpecs } from './BalanceNoteSpecs.js'
import { DEFAULT_STYRELSENS_YTTRANDE_OM_VINSTDISPOSITION } from '../shared-universal/RemakerUtils.js'

const ZERO_WIDTH_SPACE = '&#8203;'

const addEssentialTypos = (xbrlName) => {
  // Other issues found:
  // - Bortskrivnafordringar should have been BortskrivnaFordringar
  return xbrlName
    .replace('ForandringEgetKapitalUppskrivningsfondFondemission', 'ForandringEgetKapitaUppskrivningsfondFondemission')
    .replace(
      'AndelarIntresseforetagGemensamtStyrdaForetagForandringAnskaffningsvardenInkop',
      'AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenInkop'
    )
    .replace(
      'AndelarIntresseforetagGemensamtStyrdaForetagForandringAnskaffningsvardenFusion',
      'AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenFusion'
    )
    .replace(
      'AndelarIntresseforetagGemensamtStyrdaForetagForandringAnskaffningsvardenForsaljningar',
      'AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenForsaljningar'
    )
    .replace(
      'AndelarIntresseforetagGemensamtStyrdaForetagForandringAnskaffningsvardenLamnadeAktieagartillskott',
      'AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenLamnadeAktieagartillskott'
    )
    .replace(
      'AndelarIntresseforetagGemensamtStyrdaForetagForandringAnskaffningsvardenAterbetalningLamnatAktieagartillskott',
      'AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott'
    )
    .replace(
      'AndelarIntresseforetagGemensamtStyrdaForetagForandringAnskaffningsvardenAretsResultatAndel',
      'AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenAretsResultatAndel'
    )
    .replace(
      'AndelarIntresseforetagGemensamtStyrdaForetagForandringAnskaffningsvardenOmklassificeringar',
      'AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenOmklassificeringar'
    )
    .replace(
      'AndelarIntresseforetagGemensamtStyrdaForetagForandringAnskaffningsvardenOmrakningsdifferenser',
      'AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenOmrakningsdifferenser'
    )
    .replace(
      'AgarintressenOvrigaForetagForandringAnskaffningsvardenInkop',
      'AgarintressenOvrigaForetagAnskaffningsvardenInkop'
    )
    .replace(
      'AgarintressenOvrigaForetagForandringAnskaffningsvardenFusion',
      'AgarintressenOvrigaForetagAnskaffningsvardenFusion'
    )
    .replace(
      'AgarintressenOvrigaForetagForandringAnskaffningsvardenForsaljningar',
      'AgarintressenOvrigaForetagAnskaffningsvardenForsaljningar'
    )
    .replace(
      'AgarintressenOvrigaForetagForandringAnskaffningsvardenLamnadeAktieagartillskott',
      'AgarintressenOvrigaForetagAnskaffningsvardenLamnadeAktieagartillskott'
    )
    .replace(
      'AgarintressenOvrigaForetagForandringAnskaffningsvardenAterbetalningLamnatAktieagartillskott',
      'AgarintressenOvrigaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott'
    )
    .replace(
      'AgarintressenOvrigaForetagForandringAnskaffningsvardenAretsResultatAndel',
      'AgarintressenOvrigaForetagAnskaffningsvardenAretsResultatAndel'
    )
    .replace(
      'AgarintressenOvrigaForetagForandringAnskaffningsvardenOmklassificeringar',
      'AgarintressenOvrigaForetagAnskaffningsvardenOmklassificeringar'
    )
    .replace(
      'AgarintressenOvrigaForetagForandringAnskaffningsvardenOmrakningsdifferenser',
      'AgarintressenOvrigaForetagAnskaffningsvardenOmrakningsdifferenser'
    )
}

const tableOfContentsHtml = () => `
  <div class="toc-box">
    <div class="toc-box-inner">
      <div class="toc-box-heading">Innehållsförteckning</div>
      <div id="table-of-contents" class="toc-table">
      </div>
    </div>
  </div>
`

const renderNoteReferences = (notes, annualReport, noteRefInHeader) => {
  const noteRefsToRender = (notes || []).filter((noteId) => shouldNoteBeVisible(noteId, annualReport, noteRefInHeader))
  return (
    '<span class="whitespace-pre-wrap">' +
    ((noteRefsToRender.length > 1 ? `Not ${ZERO_WIDTH_SPACE}` : '') +
      noteRefsToRender
        .map((noteId) => `<a href="#${noteId}" class="note-ref">${noteRefsToRender.length === 1 ? 'Not ' : ''}</a>`)
        .join(`, ${ZERO_WIDTH_SPACE}`)) +
    '</span>'
  )
}

const makeContextRef = (template, node, fYearIdx) => {
  const contextRefBase = node.contextRefBase ?? template.contextRefBase
  return `${contextRefBase}${fYearIdx}`
}

const makeFinancialYearTitleHtml = (fYear, fyTitleType) => {
  if (fyTitleType == 'instant') {
    return `${fYear.lastDay}`
  } else if (fyTitleType == 'shortened-iso-range') {
    const first = dayjs(fYear.firstDay)
    const last = dayjs(fYear.lastDay)
    if (last.add(1, 'day').diff(first, 'year', true) !== 1) {
      // förlängt / förkortat
      return `<div class="whitespace-nowrap">${fYear.firstDay}</div><div class="whitespace-nowrap">${
        fYear.lastDay
      }</div>(${last.add(1, 'day').diff(first, 'month', false)} mån)`
    } else if (first.month() !== 0 || first.date() !== 1) {
      return `<div class="whitespace-nowrap">${fYear.firstDay}</div><div class="whitespace-nowrap">${fYear.lastDay}</div>`
    } else {
      // kalenderår
      return `<div class="whitespace-nowrap">${first.format('YYYY')}</div>`
    }
  } else if (fyTitleType == 'none') {
    return ''
  } else {
    return `<div class="whitespace-nowrap">${fYear.firstDay}</div><div class="whitespace-nowrap">${fYear.lastDay}</div>`
  }
}

const renderTable = (tableTemplate, annualReport, fYearCountToRender, opts) => {
  const trs = []
  const { style, items } = tableTemplate
  const renderedFYears = annualReport.financialYears.slice(0, fYearCountToRender)

  const tableStyles = {
    tsIncomeStatement: ['level-1-title'],
    tsBalanceSheet: ['level-0-title', 'level-1-title', 'level-2-title'],
    tsMultiYearSummary: [],
    tsResultDisposition: [],
    tsAverageEmployeeCount: [],
  }

  const currentStyles = tableStyles[style] || []

  const shouldBeVisible = (templateNode) => {
    if (templateNode.showIfZero) {
      return true
    } else if (templateNode.items) {
      return templateNode.items.some(shouldBeVisible)
    } else {
      return renderedFYears.some((fYear) => fYear.xbrlValues[templateNode.xbrlName]?.value)
    }
  }

  const renderLeafNode = (titleClass, tdClass, node, parentNode) => {
    const maybeNoteCell = opts.hideNoteColumn
      ? ''
      : `<td class="${tdClass} numerical-amount">${renderNoteReferences(node.notes, annualReport, false)}</td>`

    return `<tr>
    <td class="${titleClass} ${tdClass} ${opts.oneLineRowName ? 'whitespace-nowrap' : ''} user-select-none">${
      node.title
    }${node.xbrlUnit === 'procent' ? ', %' : opts.monetaryAmountScale == 3 ? ', tkr' : ''}</td>
    ${maybeNoteCell}
    ${renderedFYears
      .map((fYear, fYearIdx) => {
        const contextRef = makeContextRef(tableTemplate, node, fYearIdx)
        return `<td class="numerical-amount ${tdClass}">${formatIXbrl(fYear.xbrlValues, contextRef, node.xbrlName, {
          ...opts,
          scale: node.scale ?? opts.monetaryAmountScale ?? 0,
          unitRef: node.xbrlUnit ?? 'SEK',
          presentationToggleSign: parentNode.xbrlWeight * node.xbrlWeight === -1,
        })}</td>`
      })
      .join('')}
  </tr>`
  }

  const renderSubItems = (section, level, parentNode) => {
    if (shouldBeVisible(section)) {
      const maybeNoteCell = opts.hideNoteColumn
        ? ''
        : `<td class="numerical-amount">${renderNoteReferences(section.notes, annualReport, false)}</td>`

      trs.push(
        `<tr>
        <td class="${currentStyles[level] || ''} user-select-none">${section.title}</td>
        ${maybeNoteCell}
        <td colspan="${renderedFYears.length}"></td>
      </tr>`
      )
      section.items.forEach((row) => {
        if (row.items) {
          // Recurse
          renderSubItems(row, level + 1, section)
        } else if (shouldBeVisible(row)) {
          trs.push(renderLeafNode('', '', row, section))
        }
      })
      if (section.hideSectionSum) {
        trs.push(
          `<tr>
          <td class="${currentStyles[level] || ''} section-sum-row" colspan="${renderedFYears.length + 2}"></td>
        </tr>
      `
        )
      } else {
        trs.push(
          `<tr>
          <td class="${
            currentStyles[level] || ''
          } section-sum-row user-select-none" colspan="2">Summa ${section.title.toLowerCase()}</td>
          ${renderedFYears
            .map((fYear, fYearIdx) => {
              const contextRef = makeContextRef(tableTemplate, section, fYearIdx)
              return `<td class="numerical-amount section-sum-row">${formatIXbrl(
                fYear.xbrlValues,
                contextRef,
                section.xbrlName,
                {
                  ...opts,
                  presentationToggleSign: parentNode.xbrlWeight * section.xbrlWeight === -1,
                }
              )}</td>`
            })
            .join('')}
        </tr>
      `
        )
      }
    }
  }

  items.forEach((section) => {
    if (section.items) {
      renderSubItems(section, 0, tableTemplate)
    } else if (shouldBeVisible(section)) {
      let extraClasses = ''
      if (section.summaryRow) {
        extraClasses += ' stage-summary-row'
      }
      trs.push(renderLeafNode(currentStyles[0] || '', extraClasses, section, tableTemplate))
    }
  })

  const maybeTHead = opts.hideColumnCaptions
    ? ''
    : `
        <tr class="thead">
          <td class="level-0-title"></td>
          ${
            opts.hideNoteColumn
              ? ''
              : `<td class="align-bottom">${renderNoteReferences(tableTemplate.notes, annualReport, true)}</td>`
          }
          ${renderedFYears
            .map(
              (fYear) =>
                `<td class="numerical-amount-column-caption">${makeFinancialYearTitleHtml(
                  fYear,
                  opts.fyTitleType
                )}</td>`
            )
            .join('')}
        </tr>
  `
  return `
    <table class="summary-table break-inside-avoid-table">
      ${maybeTHead}
        ${trs.join('')}
    </table>`
}

const renderCertificateOfApproval = (annualReport, opts, userConfig) => {
  let coaSigner = annualReport.signatures.find((sig) => sig.foretradareId === annualReport.coaSigner)
  if (!coaSigner) {
    coaSigner = {
      firstName: '',
      lastName: '',
      role: '',
      signatureDate: '',
    }
  }

  const beslutGodkandMedJustering = `
    <ix:nonNumeric name="se-bol-base:ArsstammaResultatDispositionInteGodkannaStyrelsensForslag" contextRef="balans0">${
      annualReport.financialYears[0].xbrlValues['FrittEgetKapital']?.value >= 0
        ? 'Årsstämman beslöt att inte godkänna styrelsens förslag till vinstdisposition'
        : 'Årsstämman beslöt att inte godkänna styrelsens förslag till behandling av ansamlad förlust'
    }.</ix:nonNumeric>
    <ix:nonNumeric name="se-bol-base:ArsstammaResultatDispositionBeslutstext" contextRef="balans0">
      Istället beslöt årsstämman att ge aktieutdelning med
      ${formatIXbrl(
        annualReport.financialYears[0].xbrlValues,
        'balans0',
        'ArsstammaResultatDispositionAktieutdelning',
        opts
      )} kr och resterande
      ${formatIXbrl(
        annualReport.financialYears[0].xbrlValues,
        'balans0',
        'ArsstammaResultatDispositionBalanserasINyRakning',
        opts
      )} kr balanseras i ny räkning.
    </ix:nonNumeric>
  `

  let beslutResultatDisposition

  if (getAnnualReportIssuer(annualReport) === 'Likvidatorn') {
    beslutResultatDisposition = ''
  } else if (
    userConfig.getConfigKey(UserConfigKeys.ARSSTAMMA_JUSTERAD_DISPOSITION_ENABLED) &&
    annualReport.arsstammaJusteradDisposition
  ) {
    beslutResultatDisposition = beslutGodkandMedJustering
  } else {
    beslutResultatDisposition = `
    <ix:nonNumeric name="se-bol-base:ArsstammaResultatDispositionGodkannaStyrelsensForslag" contextRef="balans0">${
      annualReport.financialYears[0].xbrlValues['FrittEgetKapital']?.value >= 0
        ? 'Årsstämman beslöt att godkänna styrelsens förslag till vinstdisposition'
        : 'Årsstämman beslöt att godkänna styrelsens förslag till behandling av ansamlad förlust'
    }.</ix:nonNumeric>
  `
  }

  const intygandeOriginal = !opts.skipSignatureLines
    ? 'Jag intygar att innehållet i årsredovisningen överensstämmer med originalet och att originalet undertecknats av samtliga personer som enligt lag ska underteckna dessa.'
    : 'Jag intygar att innehållet i dessa elektroniska handlingar överensstämmer med originalen och att originalen undertecknats av samtliga personer som enligt lag ska underteckna dessa.'
  return `
    <div class="certificate-of-approval">
      <h3 class="certificate-of-approval-heading">Fastställelseintyg</h3>
      <p>
        <ix:nonNumeric name="se-bol-base:ArsstammaIntygande" contextRef="balans0" continuedAt="intygande_forts">
          <ix:nonNumeric name="se-bol-base:FaststallelseResultatBalansrakning" contextRef="balans0">Jag intygar att resultaträkningen och balansräkningen har fastställts på årsstämma</ix:nonNumeric> <span class="whitespace-pre-wrap"><ix:nonNumeric name="se-bol-base:Arsstamma" contextRef="balans0">${
            annualReport.annualMeetingDate
          }</ix:nonNumeric>.</span>${beslutResultatDisposition}
        </ix:nonNumeric>
      </p>
      <p><ix:continuation id="intygande_forts"> <ix:nonNumeric name="se-bol-base:IntygandeOriginalInnehall" contextRef="balans0">${intygandeOriginal}</ix:nonNumeric></ix:continuation></p>
      <div>
        ${
          !opts.skipSignatureLines
            ? renderSignatureDottedLine('')
            : '<p><strong><ix:nonNumeric name="se-bol-base:UnderskriftFaststallelseintygElektroniskt" contextRef="balans0">Elektroniskt underskriven av</ix:nonNumeric>:</strong></p>'
        }
        <div><ix:nonNumeric name="se-bol-base:UnderskriftFaststallelseintygForetradareTilltalsnamn" contextRef="period0">${escapeHtml(
          coaSigner.firstName
        )}</ix:nonNumeric> <ix:nonNumeric name="se-bol-base:UnderskriftFaststallelseintygForetradareEfternamn" contextRef="period0">${escapeHtml(
          coaSigner.lastName
        )}</ix:nonNumeric></div>
        <div><ix:nonNumeric name="se-bol-base:UnderskriftFaststallelseintygForetradareForetradarroll" contextRef="period0">${getCertificateOfApprovalSignatureRole(
          getForetradareRoleLabel(coaSigner.role)
        )}</ix:nonNumeric></div>
        <div>${
          !opts.skipSignatureLines
            ? renderSignatureDottedLine('Ort och datum')
            : `<ix:nonNumeric name="se-bol-base:UnderskriftFastallelseintygDatum" contextRef="balans0" id="ID_DATUM_UNDERTECKNANDE_FASTSTALLELSEINTYG">${dayjs()
                .tz('Europe/Stockholm')
                .format('YYYY-MM-DD')}</ix:nonNumeric>`
        }</div>
      </div>
    </div>
  `
}

const renderChangesToEquity = (annualReport, opts) => {
  const { isFirstYear, openingBalanceXbrlValues } = getOpeningBalancesForChangesToEquityTable(annualReport)
  const closingBalanceXbrlValues = annualReport.financialYears[0].xbrlValues
  const visibleExtraColumns = changesToEquityOptionalColumns.filter(
    (col) =>
      hasNonZeroRawAmount(openingBalanceXbrlValues[col.xbrlName]) ||
      hasNonZeroRawAmount(closingBalanceXbrlValues[col.xbrlName])
  )

  const hasForandringEgetKapitalBalanseratResultatErhallnaAktieagartillskott = hasNonZeroRawAmount(
    closingBalanceXbrlValues['ForandringEgetKapitalBalanseratResultatErhallnaAktieagartillskott']
  )
  const hasForandringEgetKapitalBalanseratResultatAterbetalningAktieagartillskott = hasNonZeroRawAmount(
    closingBalanceXbrlValues['ForandringEgetKapitalBalanseratResultatAterbetalningAktieagartillskott']
  )

  const maybePreviousYearResultDisposition = !isFirstYear
    ? `
  <tr>
    <td>Resultatdisposition enligt årsstämman</td>
  </tr>
  <tr>
    <td>- Utdelning</td>
    ${visibleExtraColumns.map((col) => '<td></td>').join('')}
    <td class="numerical-amount">−${formatIXbrl(
      closingBalanceXbrlValues,
      'period0',
      'ForandringEgetKapitalBalanseratResultatUtdelning',
      opts
    )}</td>
    <td></td>
    <td class="numerical-amount">−${formatIXbrl(
      closingBalanceXbrlValues,
      'period0',
      'ForandringEgetKapitalTotaltUtdelning',
      opts
    )}</td>
  </tr>
  <tr>
    <td>- Balanseras i ny räkning</td>
    ${visibleExtraColumns.map((col) => '<td></td>').join('')}
    <td class="numerical-amount">${formatIXbrl(
      closingBalanceXbrlValues,
      'period0',
      'ForandringEgetKapitalBalanseratResultatBalanserasNyRakning',
      opts
    )}</td>
    <td class="numerical-amount">${formatIXbrl(
      closingBalanceXbrlValues,
      'period0',
      'ForandringEgetKapitalAretsResultatBalanserasNyRakning',
      opts
    )}</td>
    <td></td>
  </tr>
  `
    : ''

  const maybeNyemission = annualReport.egetKapitalNyemission
    ? `
  <tr>
    <td>Nyemission</td>
    ${visibleExtraColumns
      .map((col) => {
        const actualXbrlName = addEssentialTypos(`ForandringEgetKapital${col.xbrlName}Nyemission`)
        return `<td class="numerical-amount">${
          hasNonZeroRawAmount(closingBalanceXbrlValues[actualXbrlName])
            ? formatIXbrl(closingBalanceXbrlValues, 'period0', actualXbrlName, {
                ...opts,
                presentationToggleSign: false,
              })
            : ''
        }</td>`
      })
      .join('')}
      <td class="numerical-amount"></td>
      <td class="numerical-amount"></td>
    <td class="numerical-amount">${formatIXbrl(
      closingBalanceXbrlValues,
      'period0',
      'ForandringEgetKapitalTotaltNyemission',
      opts
    )}</td>
  </tr>`
    : ''

  const maybeFondemission = annualReport.egetKapitalFondemission
    ? `
  <tr>
    <td>Fondemission</td>
    ${visibleExtraColumns
      .map((col) => {
        const actualXbrlName = addEssentialTypos(`ForandringEgetKapital${col.xbrlName}Fondemission`)
        return `<td class="numerical-amount">${
          hasNonZeroRawAmount(closingBalanceXbrlValues[actualXbrlName])
            ? formatIXbrl(closingBalanceXbrlValues, 'period0', actualXbrlName, {
                ...opts,
                presentationToggleSign: col.xbrlName === 'Aktiekapital' ? false : true,
              })
            : ''
        }</td>`
      })
      .join('')}
      <td class="numerical-amount">${
        hasNonZeroRawAmount(closingBalanceXbrlValues['ForandringEgetKapitalBalanseratResultatFondemission'])
          ? formatIXbrl(closingBalanceXbrlValues, 'period0', 'ForandringEgetKapitalBalanseratResultatFondemission', {
              ...opts,
              presentationToggleSign: true,
            })
          : ''
      }</td>
      <td class="numerical-amount">${
        hasNonZeroRawAmount(closingBalanceXbrlValues['ForandringEgetKapitalAretsResultatFondemission'])
          ? formatIXbrl(closingBalanceXbrlValues, 'period0', 'ForandringEgetKapitalAretsResultatFondemission', {
              ...opts,
              presentationToggleSign: true,
            })
          : ''
      }</td>
    <td class="numerical-amount">${formatIXbrl(
      closingBalanceXbrlValues,
      'period0',
      'ForandringEgetKapitalTotaltFondemission',
      opts
    )}</td>
  </tr>`
    : ''

  const maybeUppskrivningAnlaggning = annualReport.egetKapitalUppskrivningAnlaggning
    ? `
  <tr>
    <td>Uppskrivning av anläggningstillgång</td>
    ${visibleExtraColumns
      .map((col) => {
        if (col.xbrlName === 'Uppskrivningsfond') {
          return `<td class="numerical-amount">${formatIXbrl(
            closingBalanceXbrlValues,
            'period0',
            'ForandringEgetKapitalUppskrivningsfondUppskrivningAnlaggningstillgang',
            {
              ...opts,
            }
          )}</td>`
        } else {
          return '<td></td>'
        }
      })
      .join('')}
      <td></td>
      <td></td>
    <td class="numerical-amount">${formatIXbrl(
      closingBalanceXbrlValues,
      'period0',
      'ForandringEgetKapitalTotaltUppskrivningAnlaggningstillgang',
      opts
    )}</td>
  </tr>`
    : ''
  const trimmedForandringEgetKapitalKommentar = (annualReport.comments['ForandringEgetKapitalKommentar'] ?? '').trim()
  const maybeForandringEgetKapitalKommentar = trimmedForandringEgetKapitalKommentar
    ? `<p><ix:nonNumeric name="se-gen-base:ForandringEgetKapitalKommentar" contextRef="period0">${trimmedForandringEgetKapitalKommentar}</ix:nonNumeric></p>`
    : ''

  return `
  <h3>Förändringar i eget kapital</h3>
  <table class="summary-table narrow-table break-inside-avoid-table">
    <tr class="thead">
      <td></td>
      ${visibleExtraColumns.map((col) => `<td class="numerical-amount-column-caption">${col.title}</td>`).join('')}
      <td class="numerical-amount-column-caption">Balanserat resultat</td>
      <td class="numerical-amount-column-caption">Årets resultat</td>
      <td class="numerical-amount-column-caption">Totalt</td>
    </tr>
    <tr>
      <td>Belopp vid årets ingång</td>
      ${visibleExtraColumns
        .map(
          (col) =>
            `<td class="numerical-amount">${formatIXbrl(openingBalanceXbrlValues, 'balans1', col.xbrlName, opts)}</td>`
        )
        .join('')}
      <td class="numerical-amount">${formatIXbrl(openingBalanceXbrlValues, 'balans1', 'BalanseratResultat', opts)}</td>
      <td class="numerical-amount">${formatIXbrl(
        openingBalanceXbrlValues,
        'balans1',
        'AretsResultatEgetKapital',
        opts
      )}</td>
      <td class="numerical-amount">${formatIXbrl(
        openingBalanceXbrlValues,
        'balans1',
        'ForandringEgetKapitalTotalt',
        opts
      )}</td>
    </tr>
    ${maybePreviousYearResultDisposition}
    ${maybeNyemission}
    ${maybeFondemission}
    ${maybeUppskrivningAnlaggning}
    <tr>
      <td>Årets resultat</td>
      ${visibleExtraColumns.map((col) => '<td></td>').join('')}
      <td></td>
      <td class="numerical-amount">${formatIXbrl(
        closingBalanceXbrlValues,
        'period0',
        'ForandringEgetKapitalAretsResultatAretsResultat',
        opts
      )}</td>
      <td class="numerical-amount">${formatIXbrl(
        closingBalanceXbrlValues,
        'period0',
        'ForandringEgetKapitalTotaltAretsResultat',
        opts
      )}</td>
    </tr>
    ${
      hasForandringEgetKapitalBalanseratResultatErhallnaAktieagartillskott
        ? `<tr>
      <td>Erhållna aktieägartillskott</td>
      ${visibleExtraColumns.map((col) => '<td></td>').join('')}
      <td class="numerical-amount">${formatIXbrl(
        closingBalanceXbrlValues,
        'period0',
        'ForandringEgetKapitalBalanseratResultatErhallnaAktieagartillskott',
        opts
      )}</td>
      <td></td>
      <td class="numerical-amount">${formatIXbrl(
        closingBalanceXbrlValues,
        'period0',
        'ForandringEgetKapitalTotaltErhallnaAktieagartillskott',
        opts
      )}</td>
    </tr>`
        : ''
    }
    ${
      hasForandringEgetKapitalBalanseratResultatAterbetalningAktieagartillskott
        ? `<tr>
      <td>Återbetalning av aktieägartillskott</td>
      ${visibleExtraColumns.map((col) => '<td></td>').join('')}
      <td class="numerical-amount">${formatIXbrl(
        closingBalanceXbrlValues,
        'period0',
        'ForandringEgetKapitalBalanseratResultatAterbetalningAktieagartillskott',
        { ...opts, presentationToggleSign: true }
      )}</td>
      <td></td>
      <td class="numerical-amount">${formatIXbrl(
        closingBalanceXbrlValues,
        'period0',
        'ForandringEgetKapitalTotaltAterbetalningAktieagartillskott',
        { ...opts, presentationToggleSign: true }
      )}</td>
    </tr>`
        : ''
    }
    <tr>
      <td>Belopp vid årets utgång</td>
      ${visibleExtraColumns
        .map(
          (col) =>
            `<td class="numerical-amount">${formatIXbrl(closingBalanceXbrlValues, 'balans0', col.xbrlName, opts)}</td>`
        )
        .join('')}
      <td class="numerical-amount">${formatIXbrl(closingBalanceXbrlValues, 'balans0', 'BalanseratResultat', opts)}</td>
      <td class="numerical-amount">${formatIXbrl(
        closingBalanceXbrlValues,
        'balans0',
        'AretsResultatEgetKapital',
        opts
      )}</td>
      <td class="numerical-amount">${formatIXbrl(
        closingBalanceXbrlValues,
        'balans0',
        'ForandringEgetKapitalTotalt',
        opts
      )}</td>
    </tr>
  </table>
  ${maybeForandringEgetKapitalKommentar}
  `
}

const renderResultatDisposition = (annualReport, templates, opts) => {
  if (getAnnualReportIssuer(annualReport) === 'Likvidatorn') {
    return `
      <h3>Resultatdisposition</h3>
      <p>Med hänsyn till att likvidation pågår lämnas inget förslag beträffande resultatdisposition.</p>
      <p>Företagets resultat och ställning i övrigt framgår av efterföljande resultat- och balansräkning med noter.</p>
    `
  }

  const maybeStyrelsensYttrandeOmVinstdisposition = annualReport.financialYears[0].xbrlValues[
    'ForslagDispositionUtdelning'
  ]?.value
    ? `
    <div class="resultatdisposition-yttrande-heading">Styrelsens yttrande om vinstdispositionen</div>
    <ix:nonNumeric name="se-gen-base:StyrelsensYttrandeVinstutdelning" contextRef="period0">${
      annualReport.customizedStyrelsensYttrandeOmVinstdisposition !== null
        ? annualReport.customizedStyrelsensYttrandeOmVinstdisposition
        : DEFAULT_STYRELSENS_YTTRANDE_OM_VINSTDISPOSITION
    }</ix:nonNumeric>`
    : ''

  return `
    <h3>Resultatdisposition</h3>

    <div class="resultatdisposition-part-1-label">Till bolagsstämmans förfogande står följande medel:</div>
    ${renderTable(templates.clientResultatDispositionExpressionsPart1, annualReport, 1, opts)}

    <div class="resultatdisposition-part-2-label">Styrelsen och verkställande direktören föreslår att medlen disponeras enligt följande:</div>
    ${renderTable(templates.clientResultatDispositionExpressionsPart2, annualReport, 1, opts)}

    ${maybeStyrelsensYttrandeOmVinstdisposition}
    `
}

const renderSignatureDottedLine = (textUnderDottedLine1 = '', textUnderDottedLine2 = '', textUnderDottedLine3 = '') => {
  return `
  <div class="break-inside-avoid">
    <div class="dotted-signature-line"></div>
    <div>${textUnderDottedLine1}</div>
    <div>${textUnderDottedLine2}</div>
    <div>${textUnderDottedLine3}</div>
  </div>
`
}

const renderNotBalancePost = (annualReport, balanceNoteSpec, opts) => {
  const maxYearsToRenderCount = 1

  const trimmedBalanceNoteKommentar = (
    annualReport.comments[`Not${balanceNoteSpec.balanceXbrlName}Kommentar`] ?? ''
  ).trim()
  const maybeNotBalanceNoteKommentar = trimmedBalanceNoteKommentar
    ? `
      <div class="note-comment-heading" style="font-weight: bold; margin-top: 15px; margin-bottom: 3px">Kommentar till not</div>
      <div>${escapeHtml(trimmedBalanceNoteKommentar)}</div>
    `
    : ''

  return `
  <table class="summary-table break-inside-avoid-table">
    <tr class="thead">
      <td></td>
      ${annualReport.financialYears
        .slice(0, maxYearsToRenderCount)
        .map((fYear) => {
          return `
          <td class="numerical-amount-column-caption">
            ${makeFinancialYearTitleHtml(fYear, 'instant')}
          </td>
          `
        })
        .join('\n')}
    </tr>
    ${balanceNoteSpec.parts
      .map((part) => {
        const partShowAll = annualReport.notBalansAvancerad[part.xbrlName] ?? false
        const selectedChanges = partShowAll ? part.allChanges : part.changes

        const fYearXbrlValuesWithOpeningBalanceFakeYear = annualReport.financialYears
          .map((fYear) => fYear.xbrlValues)
          .concat({
            [part.xbrlName]: { value: 0, rawAmount: 0 },
          })
        const partOpeningBalancesAreAllZero = annualReport.financialYears
          .slice(0, maxYearsToRenderCount)
          .every((_, fYearIdx) => !fYearXbrlValuesWithOpeningBalanceFakeYear[fYearIdx + 1][part.xbrlName]?.value)
        const partChangesAreAllZero = selectedChanges.every((change) =>
          annualReport.financialYears
            .slice(0, maxYearsToRenderCount)
            .every((fYear, fYearIdx) => !fYear.xbrlValues[change.xbrlName]?.value)
        )
        const partClosesBalancesAreAllZero = annualReport.financialYears
          .slice(0, maxYearsToRenderCount)
          .every((fYear) => !fYear.xbrlValues[part.xbrlName]?.value)
        if (partOpeningBalancesAreAllZero && partChangesAreAllZero && partClosesBalancesAreAllZero) {
          return ''
        }

        return `
        <tr>
          <td>Ingående ${part.title.toLowerCase()}</td>
          ${annualReport.financialYears
            .slice(0, maxYearsToRenderCount)
            .map((_, fYearIdx) => {
              return `<td class="numerical-amount">${formatIXbrl(
                fYearXbrlValuesWithOpeningBalanceFakeYear[fYearIdx + 1],
                `balans${fYearIdx + 1}`,
                part.xbrlName,
                {
                  ...opts,
                  presentationToggleSign: part.xbrlWeight === -1,
                }
              )}</td>`
            })
            .join('\n')}
        </tr>
        ${selectedChanges
          .flatMap((change) => {
            const changeShouldBeVisible =
              selectedChanges.length === part.changes.length ||
              annualReport.financialYears
                .slice(0, maxYearsToRenderCount)
                .some((fYear, fYearIdx) => Boolean(fYear.xbrlValues[change.xbrlName]?.value))
            return changeShouldBeVisible
              ? [
                  `
            <tr>
              <td class="note-indented-cell">${change.title}</td>
              ${annualReport.financialYears
                .slice(0, maxYearsToRenderCount)
                .map((fYear, fYearIdx) => {
                  return `<td class="numerical-amount">${formatIXbrl(
                    fYear.xbrlValues,
                    `period${fYearIdx}`,
                    change.xbrlName,
                    {
                      ...opts,
                      presentationToggleSign: part.xbrlWeight * change.xbrlWeight === -1,
                    }
                  )}</td>`
                })
                .join('\n')}
            </tr>
          `,
                ]
              : []
          })
          .join('\n')}
        <tr>
          <td class="note-sum-cell">Utgående ${part.title.toLowerCase()}</td>
          ${annualReport.financialYears
            .slice(0, maxYearsToRenderCount)
            .map((fYear, fYearIdx) => {
              return `<td class="numerical-amount note-sum-cell note-sum-value-cell">${formatIXbrl(
                fYear.xbrlValues,
                `balans${fYearIdx}`,
                part.xbrlName,
                {
                  ...opts,
                  presentationToggleSign: part.xbrlWeight === -1,
                }
              )}</td>`
            })
            .join('\n')}
        </tr>
      `
      })
      .join('\n')}
    <tr class="level-1-title">
      <td>Redovisat värde</td>
      ${annualReport.financialYears
        .slice(0, maxYearsToRenderCount)
        .map((fYear, fYearIdx) => {
          return `<td class="numerical-amount">${formatIXbrl(
            fYear.xbrlValues,
            `balans${fYearIdx}`,
            balanceNoteSpec.balanceXbrlName,
            opts
          )}</td>`
        })
        .join('\n')}
    </tr>
  </table>
  ${maybeNotBalanceNoteKommentar}
  `
}

const renderNotLangfristigaSkulder = (financialYears, opts) => {
  const maxYearsToRenderCount = 1
  return `
  <table class="summary-table">
    <tr class="thead">
      <td></td>
      ${financialYears
        .slice(0, maxYearsToRenderCount)
        .map((fYear) => {
          return `
          <td class="numerical-amount-column-caption">
            ${makeFinancialYearTitleHtml(fYear, 'instant')}
          </td>
          `
        })
        .join('\n')}
    </tr>
    <tr>
      <td>Skulder som förfaller till betalning senare än 5 år efter balansdagen</td>
      ${financialYears
        .slice(0, maxYearsToRenderCount)
        .map((fYear, fYearIdx) => {
          return `<td class="numerical-amount">${formatIXbrl(
            fYear.xbrlValues,
            `balans${fYearIdx}`,
            'LangfristigaSkulderForfallerSenare5Ar',
            opts
          )}</td>`
        })
        .join('\n')}
    </tr>
  </table>
  `
}
const renderNotCheckrakningskredit = (annualReport, opts) => {
  const maxYearsToRenderCount = 1
  const trimmedNotCheckrakningskreditKommentar = (annualReport.comments['NotCheckrakningskreditKommentar'] ?? '').trim()
  const maybeNotCheckrakningskreditKommentar = trimmedNotCheckrakningskreditKommentar
    ? `
      <div class="note-comment-heading" style="font-weight: bold; margin-top: 15px; margin-bottom: 3px">Kommentar till not</div>
      <div>${escapeHtml(trimmedNotCheckrakningskreditKommentar)}</div>
    `
    : ''

  return `
  <table class="summary-table">
    <tr class="thead">
      <td></td>
      ${annualReport.financialYears
        .slice(0, maxYearsToRenderCount)
        .map((fYear) => {
          return `
          <td class="numerical-amount-column-caption">
            ${makeFinancialYearTitleHtml(fYear, 'instant')}
          </td>
          `
        })
        .join('\n')}
    </tr>
    <tr>
      <td>Beviljat belopp</td>
      ${annualReport.financialYears
        .slice(0, maxYearsToRenderCount)
        .map((fYear, fYearIdx) => {
          return `<td class="numerical-amount">${formatIXbrl(
            fYear.xbrlValues,
            `balans${fYearIdx}`,
            'CheckrakningskreditBeviljatBelopp',
            opts
          )}</td>`
        })
        .join('\n')}
    </tr>
  </table>
  ${maybeNotCheckrakningskreditKommentar}
  `
}

const renderNotStalldaSakerheter = (annualReport, opts) => {
  const maxYearsToRenderCount = 1
  const fYearsToRender = annualReport.financialYears.slice(0, maxYearsToRenderCount)
  const trimmedNotStalldaSakerheterKommentar = (annualReport.comments['NotStalldaSakerheterKommentarer'] ?? '').trim()
  const maybeNotStalldaSakerheterKommentar = trimmedNotStalldaSakerheterKommentar
    ? `
      <div class="note-comment-heading" style="font-weight: bold; margin-top: 15px; margin-bottom: 3px">Kommentar till not</div>
      <div>${escapeHtml(trimmedNotStalldaSakerheterKommentar)}</div>
    `
    : ''

  const maybeRenderTableRow = (title, xbrlName, fYears, opts = {}) => {
    const rowVisible = fYears.some((fYear) => hasValue(fYear.xbrlValues[xbrlName]))
    if (!rowVisible) {
      return ''
    }
    return `
      <tr>
        <td class="${opts.indented ? 'note-indented-cell' : ''} ${opts.cssClass ?? ''}">${title}</td>
        ${fYears
          .map((fYear, fYearIdx) => {
            return `<td class="numerical-amount ${opts.cssClass ?? ''}">${formatIXbrl(
              fYear.xbrlValues,
              `balans${fYearIdx}`,
              xbrlName,
              opts
            )}</td>`
          })
          .join('\n')}
      </tr>
    `
  }

  return `
  <table class="summary-table">
    <tr class="thead">
      <td></td>
      ${fYearsToRender
        .map((fYear) => {
          return `
          <td class="numerical-amount-column-caption">
            ${makeFinancialYearTitleHtml(fYear, 'instant')}
          </td>
          `
        })
        .join('\n')}
    </tr>
    ${maybeRenderTableRow('Företagsinteckningar', 'StalldaSakerheterForetagsinteckningar', fYearsToRender)}
    ${maybeRenderTableRow(
      'Varav till koncernföretag, intresseföretag eller gemensamt styrt företag',
      'StalldaSakerheterForetagsinteckningarKoncernforetag',
      fYearsToRender,
      { indented: true }
    )}
    ${maybeRenderTableRow('Fastighetsinteckningar', 'StalldaSakerheterFastighetsinteckningar', fYearsToRender)}
    ${maybeRenderTableRow(
      'Varav till koncernföretag, intresseföretag eller gemensamt styrt företag',
      'StalldaSakerheterFastighetsinteckningarKoncernforetag',
      fYearsToRender,
      { indented: true }
    )}
    ${maybeRenderTableRow(
      'Tillgångar med äganderättsförbehåll',
      'StalldaSakerheterTillgangarAganderattsforbehall',
      fYearsToRender
    )}
    ${maybeRenderTableRow(
      'Varav till koncernföretag, intresseföretag eller gemensamt styrt företag',
      'StalldaSakerheterTillgangaraganderattsforbehallKoncernforetag',
      fYearsToRender,
      { indented: true }
    )}
    ${maybeRenderTableRow('Belånade fordringar', 'StalldaSakerheterBelanadeFordringar', fYearsToRender)}
    ${maybeRenderTableRow(
      'Varav till koncernföretag, intresseföretag eller gemensamt styrt företag',
      'StalldaSakerheterBelanadeFordringarKoncernforetag',
      fYearsToRender,
      { indented: true }
    )}
    ${maybeRenderTableRow('Andra ställda säkerheter', 'StalldaSakerheterAndraStalldaSakerheter', fYearsToRender)}
    ${maybeRenderTableRow(
      'Varav till koncernföretag, intresseföretag eller gemensamt styrt företag',
      'StalldaSakerheterAndraStalldaSakerheterKoncernforetag',
      fYearsToRender,
      { indented: true }
    )}
    ${maybeRenderTableRow('Summa ställda säkerheter', 'StalldaSakerheter', fYearsToRender, {
      cssClass: 'note-sum-cell',
    })}
  </table>
  ${maybeNotStalldaSakerheterKommentar}
  `
}

const renderSignatures = (annualReport, { skipSignatureLines }) => {
  let signaturesHtml = '<div style="display: flex; justify-content: space-between"><div>'
  const signaturesInFirstColumn = Math.ceil(annualReport.signatures.length / 2)
  if (!skipSignatureLines) {
    signaturesHtml += annualReport.signatures
      .map(
        (signature, idx) =>
          (idx === signaturesInFirstColumn ? '</div><div>' : '') +
          renderSignatureDottedLine(
            `${escapeHtml(signature.firstName)} ${escapeHtml(signature.lastName)}`,
            getForetradareRoleLabel(signature.role) || '',
            signature.signatureDate
          )
      )
      .join('')
  } else {
    signaturesHtml += annualReport.signatures
      .map((signature, signatureIdx) => {
        const tupleId = `UnderskriftArsredovisningForetradareTuple${signatureIdx + 1}`
        return (
          (signatureIdx === signaturesInFirstColumn ? '</div><div>' : '') +
          `
      <div>
        <ix:tuple name="se-gaap-ext:UnderskriftArsredovisningForetradareTuple" tupleID="${tupleId}">
        </ix:tuple>
        <div>
          <ix:nonNumeric name="se-gen-base:UnderskriftHandlingTilltalsnamn" contextRef="period0" order="1.0" tupleRef="${tupleId}">${escapeHtml(
            signature.firstName
          )}</ix:nonNumeric>
          <ix:nonNumeric name="se-gen-base:UnderskriftHandlingEfternamn" contextRef="period0" order="2.0" tupleRef="${tupleId}">${escapeHtml(
            signature.lastName
          )}</ix:nonNumeric>
        </div>
        ${
          !signature.role
            ? ''
            : `
        <div>
          <ix:nonNumeric name="se-gen-base:UnderskriftHandlingRoll" contextRef="period0" order="3.0" tupleRef="${tupleId}">${getForetradareRoleLabel(signature.role)}</ix:nonNumeric>
        </div>`
        }
        <div>
          <ix:nonNumeric name="se-gen-base:UndertecknandeDatum" contextRef="period0" order="4.0" tupleRef="${tupleId}">${
            signature.signatureDate
          }</ix:nonNumeric>
        </div>
      </div>
    `
        )
      })
      .join('<p></p>')
  }
  signaturesHtml += '</div></div>'
  return signaturesHtml
}

const renderRevisorspateckning = (annualReport, { skipSignatureLines }) => {
  let revisorspateckning = `
    <div style="height: 100px"></div>
    <div class="break-inside-avoid">
      <div>Vår revisionsberättelse har lämnats <ix:nonNumeric name="se-gen-base:RevisorspateckningRevisionsberattelseEnligtStandardutformning" contextRef="period0">${annualReport.auditDate}</ix:nonNumeric>.</div>
      <div><ix:nonNumeric name="se-cd-base:ValtRevisionsbolagsnamn" contextRef="period0">${annualReport.auditorCompany}</ix:nonNumeric></div>
      <p></p>
  `
  if (!skipSignatureLines) {
    revisorspateckning += renderSignatureDottedLine(
      `${escapeHtml(annualReport.auditorFirstName)} ${escapeHtml(annualReport.auditorLastName)}`,
      annualReport.auditorRole,
      ''
    )
  } else {
    revisorspateckning += `
      <ix:tuple name="se-gaap-ext:UnderskriftRevisorspateckningTuple" tupleID="UnderskriftRevisorspateckningTuple1"></ix:tuple>
      <div>
        <ix:nonNumeric name="se-gen-base:UnderskriftHandlingTilltalsnamn" contextRef="period0" tupleRef="UnderskriftRevisorspateckningTuple1" order="1.0">${annualReport.auditorFirstName}</ix:nonNumeric> <ix:nonNumeric name="se-gen-base:UnderskriftHandlingEfternamn" contextRef="period0" tupleRef="UnderskriftRevisorspateckningTuple1" order="2.0">${annualReport.auditorLastName}</ix:nonNumeric>
      </div>
      <div>
        <ix:nonNumeric name="se-gen-base:UnderskriftHandlingTitel" contextRef="period0" tupleRef="UnderskriftRevisorspateckningTuple1" order="3.0">${annualReport.auditorRole}</ix:nonNumeric>
      </div>
    `
  }
  revisorspateckning += '</div>'

  return revisorspateckning
}

const renderFrontPage = (annualReport) => {
  return `
    <div class="text-align-center">
      <h1><ix:nonNumeric name="se-cd-base:ForetagetsNamn" contextRef="period0">${escapeHtml(
        annualReport.companyName
      )}</ix:nonNumeric></h1>
      <h4><ix:nonNumeric name="se-cd-base:Organisationsnummer" contextRef="period0">${escapeHtml(
        annualReport.organizationNumber
      )}</ix:nonNumeric></h4>
      <div class="compact">
        ${getAnnualReportIssuer(annualReport)} avger följande årsredovisning för räkenskapsåret ${ZERO_WIDTH_SPACE}<ix:nonNumeric name="se-cd-base:RakenskapsarForstaDag" contextRef="period0">${
          annualReport.financialYears[0].firstDay
        }</ix:nonNumeric>${ZERO_WIDTH_SPACE} - ${ZERO_WIDTH_SPACE}<ix:nonNumeric name="se-cd-base:RakenskapsarSistaDag" contextRef="period0">${
          annualReport.financialYears[0].lastDay
        }</ix:nonNumeric>.
      </div>
      <div>Belopp redovisas i hela kronor om inte annat anges.</div>
    </div>
    ${tableOfContentsHtml()}
`
}

const createAnnualReportPreviewHtml = (annualReport, templates, { decimalCount, skipSignatureLines }, userConfig) => {
  let maybeEntreprenadtjanst = ''
  if (annualReport.notEntreprenadtjanstFastPris) {
    maybeEntreprenadtjanst += '<h4>Tjänste- och entreprenaduppdrag</h4><p>'
    if (annualReport.notEntreprenadtjanstHuvudregel) {
      maybeEntreprenadtjanst += 'Företagets intäkter från uppdrag till fast pris redovisas enligt huvudregeln.'
    } else {
      maybeEntreprenadtjanst += 'Företagets intäkter från uppdrag till fast pris redovisas enligt alternativregeln.'
      if (annualReport.notEntreprenadtjanstInklIndirekta) {
        maybeEntreprenadtjanst +=
          ' Företaget har räknat in indirekta utgifter i värdet av pågående arbete för annans räkning.'
      }
    }
    maybeEntreprenadtjanst += '</p>'
  }

  const visibleDepreciations = getVisibleDepreciations(annualReport)
  const maybeAvskrivningar =
    visibleDepreciations.length === 0
      ? ''
      : `
    <h4>Avskrivningstider</h4>
    <table class="narrow-table">
      ${visibleDepreciations
        .map((depreciation) => {
          return `
          <tr>
            <td>${depreciation.title}</td>
            <td class="numerical-amount-column-caption">
              <ix:nonNumeric xmlns:ix="http://www.xbrl.org/2013/inlineXBRL" contextRef="period0" name="se-gen-base:${
                depreciation.nyttjandeperiodXbrlName
              }" order="2.0" tupleRef="${depreciation.tupleId}">${
                annualReport.depreciations[depreciation.depreciationId] || ''
              } år</ix:nonNumeric>
            </td>
          </tr>
        `
        })
        .join('')}
    </table>
  `

  const balanceNotesHtml = loadVisibleBalanceNoteSpecs(annualReport, templates.clientBalanceSheetTemplate)
    .map((balanceNoteSpec) => {
      return `
        <div class="note-content-box break-inside-avoid">
          <h3 class="note" id="Not${balanceNoteSpec.balanceXbrlName}">${balanceNoteSpec.title}</h3>
          ${renderNotBalancePost(annualReport, balanceNoteSpec, {
            decimalCount,
          })}
        </div>
      `
    })
    .join('')

  let maybeNotLangfristigaSkulder = ''
  if (notLangfristigaSkulderVisible(annualReport.financialYears)) {
    maybeNotLangfristigaSkulder = `
        <div class="note-content-box break-inside-avoid">
          <h3 class="note" id="NotLangfristigaSkulder">Långfristiga skulder</h3>
          ${renderNotLangfristigaSkulder(annualReport.financialYears, {
            decimalCount,
          })}
        </div>
      `
  }

  const hasCheckrakningskreditBalancePost =
    Boolean(annualReport.financialYears[0].xbrlValues['CheckrakningskreditLangfristig']?.value) ||
    Boolean(annualReport.financialYears[0].xbrlValues['CheckrakningskreditKortfristig']?.value)

  const checkrakningskreditBeviljatBelopp =
    annualReport.financialYears[0].xbrlValues['CheckrakningskreditBeviljatBelopp']?.value
  const trimmedNotCheckrakningskreditKommentar = (annualReport.comments['NotCheckrakningskreditKommentar'] ?? '').trim()
  let maybeNotCheckrakningskredit = ''
  if (
    hasCheckrakningskreditBalancePost ||
    annualReport.infogadeNoter['NotCheckrakningskredit'] ||
    typeof checkrakningskreditBeviljatBelopp === 'number' ||
    trimmedNotCheckrakningskreditKommentar !== ''
  ) {
    maybeNotCheckrakningskredit = `
        <div class="note-content-box break-inside-avoid">
          <h3 class="note" id="NotCheckrakningskredit">Checkräkningskredit</h3>
          ${renderNotCheckrakningskredit(annualReport, {
            decimalCount,
          })}
        </div>
      `
  }

  const stalldaSakerhetLeafXbrlNames = [
    'StalldaSakerheterForetagsinteckningar',
    'StalldaSakerheterFastighetsinteckningar',
    'StalldaSakerheterTillgangarAganderattsforbehall',
    'StalldaSakerheterBelanadeFordringar',
    'StalldaSakerheterAndraStalldaSakerheter',
  ]
  const trimmedNotStalldaSakerheterKommentarer = (annualReport.comments['NotStalldaSakerheterKommentarer'] ?? '').trim()
  const stalldaSakerheterVisible =
    annualReport.infogadeNoter['NotStalldaSakerheter'] &&
    (trimmedNotStalldaSakerheterKommentarer ||
      stalldaSakerhetLeafXbrlNames.some(
        (ssXbrlName) =>
          typeof annualReport.financialYears[0].xbrlValues[ssXbrlName]?.value === 'number' ||
          typeof annualReport.financialYears[0].xbrlValues[ssXbrlName + 'Koncernforetag']?.value === 'number'
      ))
  let maybeNotStalldaSakerheter = ''
  if (stalldaSakerheterVisible || trimmedNotStalldaSakerheterKommentarer !== '') {
    maybeNotCheckrakningskredit = `
        <div class="note-content-box break-inside-avoid">
          <h3 class="note" id="NotStalldaSakerheter">Ställda Säkerheter</h3>
          ${renderNotStalldaSakerheter(annualReport, {
            decimalCount,
          })}
        </div>
      `
  }

  const trimmedNotEventualForpliktelserKommentar = (
    annualReport.comments['NotEventualForpliktelserKommentar'] ?? ''
  ).trim()
  const eventualForpliktelserAmount = annualReport.financialYears[0].xbrlValues['EventualForpliktelser']?.value
  let maybeNotEventualforpliktelser = ''
  if (
    annualReport.infogadeNoter['NotEventualforpliktelser'] &&
    (trimmedNotEventualForpliktelserKommentar || typeof eventualForpliktelserAmount === 'number')
  ) {
    const maxYearsToRenderCount = 1

    const maybeNotEventualforpliktelserKommentar = trimmedNotEventualForpliktelserKommentar
      ? `
      <div class="note-comment-heading" style="font-weight: bold; margin-top: 15px; margin-bottom: 3px">Kommentar till not</div>
      <div><ix:nonNumeric name="se-gen-base:NotEventualForpliktelserKommentar" contextRef="balans0">${escapeHtml(trimmedNotEventualForpliktelserKommentar)}</ix:nonNumeric></div>
    `
      : ''

    maybeNotEventualforpliktelser = `
      <div class="note-content-box break-inside-avoid">
        <h3 class="note" id="NotNotEventualForpliktelser">Eventualförpliktelser</h3>
        <table class="summary-table">
          <tr class="thead">
            <td></td>
            ${annualReport.financialYears
              .slice(0, maxYearsToRenderCount)
              .map((fYear) => {
                return `
                <td class="numerical-amount-column-caption">
                  ${makeFinancialYearTitleHtml(fYear, 'instant')}
                </td>
                `
              })
              .join('\n')}
          </tr>
          <tr>
            <td>Eventualförpliktelser</td>
            ${annualReport.financialYears
              .slice(0, maxYearsToRenderCount)
              .map((fYear, fYearIdx) => {
                return `<td class="numerical-amount">${formatIXbrl(
                  fYear.xbrlValues,
                  `balans${fYearIdx}`,
                  'EventualForpliktelser',
                  {
                    decimalCount,
                  }
                )}</td>`
              })
              .join('\n')}
          </tr>
        </table>
        ${maybeNotEventualforpliktelserKommentar}
      </div>
    `
  }

  let maybeNotUpplysningModerforetag = ''
  if (annualReport.infogadeNoter['NotUpplysningModerforetag']) {
    maybeNotUpplysningModerforetag = `
      <div class="note-content-box break-inside-avoid">
        <h3 class="note" id="NotUpplysningModerforetag">Upplysning om moderföretag</h3>
        <ix:tuple name="se-gaap-ext:UppgiftNarmasteModerforetagTuple" tupleID="UppgiftNarmasteModerforetagTuple1"></ix:tuple>
        ${escapeHtml(annualReport.companyName)} (${escapeHtml(annualReport.organizationNumber)}) är ett dotterbolag
        till <ix:nonNumeric name="se-cd-base:ForetagetsNamn" contextRef="period0" tupleRef="UppgiftNarmasteModerforetagTuple1" order="1.0">${escapeHtml(annualReport.notUpplysningModerforetagNamn)}</ix:nonNumeric> (<ix:nonNumeric name="se-cd-base:Organisationsnummer" contextRef="period0" tupleRef="UppgiftNarmasteModerforetagTuple1" order="2.0">${escapeHtml(annualReport.notUpplysningModerforetagOrgNr)}</ix:nonNumeric>) som
        har säte i <ix:nonNumeric name="se-cd-base:ForetagetsSate" contextRef="period0" tupleRef="UppgiftNarmasteModerforetagTuple1" order="3.0">${getDomicileString(templates.allDomiciles, annualReport.notUpplysningModerforetagSate)}</ix:nonNumeric>.
      </div>
    `
  }

  const trimmedNotVasentligaHandelserRakenskapsaretsSlut = annualReport.notSignificantEventsAfterEndDate.trim()
  let maybeNotVasentligaHandelserRakenskapsaretsSlut = ''
  if (
    annualReport.infogadeNoter['NotVasentligaHandelserRakenskapsaretsSlut'] &&
    trimmedNotVasentligaHandelserRakenskapsaretsSlut
  ) {
    maybeNotVasentligaHandelserRakenskapsaretsSlut = `
      <div class="note-content-box break-inside-avoid">
        <h3 class="note" id="NotVasentligaHandelserRakenskapsaretsSlut">Väsentliga händelser efter räkenskapsårets slut</h3>
        ${escapeHtml(trimmedNotVasentligaHandelserRakenskapsaretsSlut)}
      </div>
    `
  }

  const trimmedNotAndraOvrigaUpplysningar = annualReport.notAndraOvrigaUpplysningar.trim()
  let maybeNotAndraOvrigaUpplysningar = ''
  if (annualReport.infogadeNoter['NotAndraOvrigaUpplysningar'] && trimmedNotAndraOvrigaUpplysningar) {
    maybeNotAndraOvrigaUpplysningar = `
      <div class="note-content-box break-inside-avoid">
        <h3 class="note" id="NotAndraOvrigaUpplysningar">Andra övriga upplysningar</h3>
        ${escapeHtml(trimmedNotAndraOvrigaUpplysningar)}
      </div>
    `
  }

  let annualReportHtml = ''
  annualReportHtml += renderFrontPage(annualReport)
  annualReportHtml += renderCertificateOfApproval(
    annualReport,
    {
      decimalCount,
      skipSignatureLines,
    },
    userConfig
  )
  annualReportHtml += `
    <div class="break-after-page"></div>

    <h2>Förvaltningsberättelse</h2>

    <h3>Allmänt om verksamheten</h3>
    <div class="note-content-box">
      <p class="whitespace-pre-wrap">
        <ix:nonNumeric name="se-gen-base:AllmantVerksamheten" contextRef="period0" continuedAt="allmant_om_verksamheten_forts">${escapeHtml(
          annualReport.businessDescription
        )}</ix:nonNumeric>
      </p><p class="whitespace-pre-wrap">
        <ix:continuation id="allmant_om_verksamheten_forts">Företaget har sitt säte i ${getDomicileString(
          templates.allDomiciles,
          annualReport.domicile
        )}.</ix:continuation>
      </p>

      ${
        annualReport.significantEvents !== ''
          ? '<h3>Väsentliga händelser under räkenskapsåret</h3><span class="whitespace-pre-wrap"><ix:nonNumeric name="se-gen-base:VasentligaHandelserRakenskapsaret" contextRef="period0">' +
            escapeHtml(annualReport.significantEvents) +
            '</ix:nonNumeric></span>'
          : ''
      }
    </div>
  `

  const trimmedKommentarFlerarsoversikt = (annualReport.comments['KommentarFlerarsoversikt'] ?? '').trim()
  const maybeKommentarFlerarsoversikt = trimmedKommentarFlerarsoversikt
    ? `<p><ix:nonNumeric name="se-gen-base:KommentarFlerarsoversikt" contextRef="period0">${trimmedKommentarFlerarsoversikt}</ix:nonNumeric></p>`
    : ''

  annualReportHtml += `
    <div class="break-inside-avoid">
      <h3>Flerårsöversikt</h3>
      ${renderTable(templates.clientMultiYearSummaryTemplate, annualReport, annualReport.financialYears.length, {
        fyTitleType: 'shortened-iso-range',
        decimalCount,
        hideNoteColumn: true,
        oneLineRowName: true,
        monetaryAmountScale: annualReport.multiYearSummaryScale,
      })}
      ${maybeKommentarFlerarsoversikt}
    </div>
  `

  annualReportHtml += `
    <div class="break-inside-avoid">
      ${renderChangesToEquity(annualReport, {
        decimalCount,
      })}
    </div>
  `

  annualReportHtml += `
    <div class="break-inside-avoid">
      ${renderResultatDisposition(annualReport, templates, {
        decimalCount,
        hideColumnCaptions: true,
      })}
    </div>
  `

  annualReportHtml += `
    <div class="break-after-page"></div>
    <h2>Resultaträkning</h2>
    ${renderTable(templates.clientIncomeStatementTemplate, annualReport, 2, {
      fyTitleType: 'duration',
      decimalCount,
    })}
  `

  annualReportHtml += `
    <div class="break-after-page"></div>
    <h2>Balansräkning</h2>
    ${renderTable(templates.clientBalanceSheetTemplate, annualReport, 2, {
      fyTitleType: 'instant',
      decimalCount,
    })}
  `

  annualReportHtml += `
    <div class="break-after-page"></div>
    <h2>Noter</h2>


    <div class="note-content-box break-inside-avoid">
      <h3 class="note" id="Redovisningsprinciper">Redovisningsprinciper</h3>
      <ix:nonNumeric name="se-gen-base:RedovisningsVarderingsprinciper" contextRef="period0">
        Årsredovisningen är upprättad i enlighet med årsredovisningslagen och Bokföringsnämndens allmänna råd (BFNAR 2016:10) om årsredovisning i mindre företag.
      </ix:nonNumeric>
      ${maybeAvskrivningar}
      ${maybeEntreprenadtjanst}
    </div>
  `

  annualReportHtml += `
    <div class="note-content-box break-inside-avoid">
      <h3 class="note" id="NotMedelantaletAnstallda">Medelantal anställda</h3>
      ${renderTable(templates.clientAverageEmployeeCountTemplate, annualReport, 1, {
        fyTitleType: 'duration',
        decimalCount,
      })}
    </div>
  `

  annualReportHtml += balanceNotesHtml
  annualReportHtml += maybeNotLangfristigaSkulder
  annualReportHtml += maybeNotCheckrakningskredit
  annualReportHtml += maybeNotStalldaSakerheter
  annualReportHtml += maybeNotEventualforpliktelser
  annualReportHtml += maybeNotUpplysningModerforetag
  annualReportHtml += maybeNotVasentligaHandelserRakenskapsaretsSlut
  annualReportHtml += maybeNotAndraOvrigaUpplysningar

  annualReportHtml += `
    <div class="break-after-page"></div>
    <h2>Underskrifter</h2>
    ${renderSignatures(annualReport, { skipSignatureLines })}
  `

  if (annualReport.revisionsberattelseKrav) {
    annualReportHtml += renderRevisorspateckning(annualReport, { skipSignatureLines })
  }

  return `
    <div id="preview-input">
      ${annualReportHtml}
    </div>
  `
}

export { makeFinancialYearTitleHtml, renderTable, createAnnualReportPreviewHtml, addEssentialTypos }
