import React from 'react'
import PropTypes from 'prop-types'

import styles from './TextBlock.module.css'

const TextBlock = ({ className, children, ...props }) => (
  <p className={`${styles.TextBlock} ${className}`} {...props}>
    {children}
  </p>
)

TextBlock.defaultProps = {
  className: '',
}

TextBlock.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default TextBlock
