import React, { useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

import Bokforingskontroller from './Bokforingskontroller.jsx'
import API from '../../API.js'
import SieDropzone from './SieDropzone.jsx'
import RmTooltip from './RmTooltip.jsx'

const Bokforingsimport = ({ annualReport, onChange, isApproved }) => {
  const [successMessage, setSuccessMessage] = useState('')
  return (
    <React.Fragment>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <h3>Inläst bokföringsfil</h3>
          {annualReport.financialYears[0].filename ? (
            <div>
              <i>{annualReport.financialYears[0].filename}</i> laddades upp{' '}
              <i>{dayjs(annualReport.financialYears[0].created).format('YYYY-MM-DD HH:mm')}</i>
            </div>
          ) : (
            <span>Ingen inläst fil</span>
          )}
        </div>
        <div style={{ width: '300px', height: '250px' }}>
          <RmTooltip
            visible={isApproved}
            title="För att läsa in en ny SIE-fil behöver fliken först markeras som ej godkänd/kontrollerad."
          >
            <SieDropzone
              duploSize={false}
              text="Läs in ny SIE-fil"
              uploadFunc={(signal, sieFile) => API.sieReimport(signal, annualReport.reportId, sieFile)}
              onSieUploaded={async (result) => {
                setSuccessMessage('Ny SIE-fil är inläst')
                onChange()
              }}
              disabled={isApproved}
            />
          </RmTooltip>
          {successMessage && <div>{successMessage}</div>}
        </div>
      </div>

      <Bokforingskontroller annualReport={annualReport} onChange={onChange} isApproved={isApproved} />
    </React.Fragment>
  )
}

Bokforingsimport.propTypes = {
  annualReport: PropTypes.object,
  onChange: PropTypes.func,
  isApproved: PropTypes.bool.isRequired,
}

Bokforingsimport.defaultProps = {
  onChange: () => {},
}

export default Bokforingsimport
