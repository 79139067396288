import React from 'react'
import PropTypes from 'prop-types'

import SubmitButtonDigital from './SubmitButtonDigital.jsx'
import { getIsApproved } from '../../../shared-universal/AnnualReportUtils.js'

const SubmitButton = ({
  annualReport,
  onChange,
  atleastOneUploadedToEgetUtrymme,
  onSubmitStart,
  onSubmitStop,
  submitting,
  disabled,
}) => {
  const approved = getIsApproved(annualReport, 'fardigstall')
  return (
    <SubmitButtonDigital
      annualReport={annualReport}
      onChange={onChange}
      atleastOneUploadedToEgetUtrymme={atleastOneUploadedToEgetUtrymme}
      onSubmitStart={onSubmitStart}
      onSubmitStop={onSubmitStop}
      submitting={submitting}
      disabled={!approved || disabled}
    />
  )
}

SubmitButton.propTypes = {
  annualReport: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  atleastOneUploadedToEgetUtrymme: PropTypes.bool,
  onSubmitStart: PropTypes.func.isRequired,
  onSubmitStop: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  disabled: PropTypes.bool,
}

SubmitButton.defaultProps = {
  disabled: false,
}

export default SubmitButton
