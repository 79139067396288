import React from 'react'
import PropTypes from 'prop-types'

import Modal from './generic/Modal.jsx'
import ModalButton from './ModalButton.jsx'

import styles from './ModalConfirm.module.css'

class ModalConfirm extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    hideFunc: PropTypes.func,
    onConfirm: PropTypes.func,
    yesVerb: PropTypes.string,
    yesButtonColor: PropTypes.string,
  }

  static open({ content, yesVerb, yesButtonColor, anchorElement, onConfirm }) {
    const modalContainer = Modal.open({
      content: (
        <ModalConfirm
          onConfirm={onConfirm}
          hideFunc={() => Modal.close(modalContainer)}
          yesVerb={yesVerb}
          yesButtonColor={yesButtonColor}
        >
          {content}
        </ModalConfirm>
      ),
      anchorElement,
      popupModeClassName: styles.ConfirmInPopupMode,
    })
  }

  static openConfirmNegative(opts) {
    ModalConfirm.open({ yesButtonColor: '#d03434', ...opts })
  }

  static openConfirmPositive(opts) {
    ModalConfirm.open({ yesButtonColor: '#28a745', ...opts })
  }

  static close(modalContainer) {
    Modal.close(modalContainer)
  }

  handleOK() {
    this.props.onConfirm()
    this.props.hideFunc()
  }

  componentDidMount() {
    this.okButton.focus()
  }

  render() {
    return (
      <div tabIndex="-1" onFocus={() => this.okButton.focus()} className={styles.ModalConfirm}>
        <div>{this.props.children}</div>
        <div className={styles.ButtonRow}>
          <ModalButton kind="cancel" className="automation-confirm-cancel-button" onClick={this.props.hideFunc}>
            Cancel
          </ModalButton>
          <ModalButton
            kind="primary"
            className="automation-confirm-ok-button"
            ref={(el) => (this.okButton = el)}
            onClick={() => this.handleOK()}
            color={this.props.yesButtonColor}
          >
            {this.props.yesVerb || 'OK'}
          </ModalButton>
        </div>
      </div>
    )
  }
}

export default ModalConfirm
