import React from 'react'
import PropTypes from 'prop-types'

import Card from './Card.jsx'

import styles from './CardTopLevel.module.css'

const CardTopLevel = ({ children, className = '', ...props }) => {
  return (
    <Card className={`${styles.CardTopLevel} ${className}`} {...props}>
      {children}
    </Card>
  )
}

CardTopLevel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default CardTopLevel
