import React from 'react'
import PropTypes from 'prop-types'
import Radio from '@mui/material/Radio/index.js'
import RadioGroup from '@mui/material/RadioGroup/index.js'
import FormControlLabel from '@mui/material/FormControlLabel/index.js'

import { useAbortSignal } from '../../AppFetch.js'
import API from '../../API.js'

const NotEntreprenadtjanst = ({ annualReport, onChange, disabled }) => {
  const abortSignal = useAbortSignal()
  let entreprenadtjanstValue
  if (annualReport.notEntreprenadtjanstFastPris === null) {
    entreprenadtjanstValue = ''
  } else if (annualReport.notEntreprenadtjanstFastPris === false) {
    entreprenadtjanstValue = '0'
  } else if (annualReport.notEntreprenadtjanstFastPris && annualReport.notEntreprenadtjanstHuvudregel) {
    entreprenadtjanstValue = '1'
  } else if (annualReport.notEntreprenadtjanstFastPris && !annualReport.notEntreprenadtjanstHuvudregel) {
    entreprenadtjanstValue = '2'
  }
  let notEntreprenadtjanstInklIndirektaValue
  if (annualReport.notEntreprenadtjanstInklIndirekta === null) {
    notEntreprenadtjanstInklIndirektaValue = ''
  } else {
    notEntreprenadtjanstInklIndirektaValue = annualReport.notEntreprenadtjanstInklIndirekta === false ? '0' : '1'
  }
  return (
    <React.Fragment>
      <h4>Tjänste- och entreprenaduppdrag</h4>
      <div>Har företaget utfört tjänste- och entreprenaduppdrag till fast pris?</div>
      <RadioGroup
        aria-label="position"
        style={{ marginLeft: '20px' }}
        name="position"
        value={entreprenadtjanstValue}
        onChange={async (ev) => {
          let entreprenadtjanstFastPris
          let entreprenadtjanstHuvudregel
          if (ev.target.value === '0') {
            entreprenadtjanstFastPris = false
          } else if (ev.target.value === '1') {
            entreprenadtjanstFastPris = true
            entreprenadtjanstHuvudregel = true
          } else if (ev.target.value === '2') {
            entreprenadtjanstFastPris = true
            entreprenadtjanstHuvudregel = false
          }
          API.saveAnnualReportValue(
            annualReport.reportId,
            'notEntreprenadtjanstFastPris',
            entreprenadtjanstFastPris,
            abortSignal,
            onChange,
            { immediate: true }
          )
          API.saveAnnualReportValue(
            annualReport.reportId,
            'notEntreprenadtjanstHuvudregel',
            entreprenadtjanstHuvudregel,
            abortSignal,
            onChange,
            { immediate: true }
          )
        }}
      >
        <FormControlLabel
          value="0"
          control={<Radio color="primary" />}
          label="Nej, inga uppdrag till fast pris"
          labelPlacement="end"
          disabled={disabled}
        />
        <FormControlLabel
          value="1"
          control={<Radio color="primary" />}
          label="Ja, fast pris mha huvudregeln"
          labelPlacement="end"
          disabled={disabled}
        />
        <FormControlLabel
          value="2"
          control={<Radio color="primary" />}
          label="Ja, fast pris mha alternativregeln"
          labelPlacement="end"
          disabled={disabled}
        />
      </RadioGroup>
      {annualReport.notEntreprenadtjanstFastPris && !annualReport.notEntreprenadtjanstHuvudregel && (
        <React.Fragment>
          <div>Är indirekta utgifter inkluderade i det fasta priset?</div>
          <RadioGroup
            aria-label="position"
            style={{ marginLeft: '20px' }}
            name="position"
            value={notEntreprenadtjanstInklIndirektaValue}
            onChange={async (ev) => {
              API.saveAnnualReportValue(
                annualReport.reportId,
                'notEntreprenadtjanstInklIndirekta',
                ev.target.value === '1',
                abortSignal,
                onChange,
                { immediate: true }
              )
            }}
          >
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label="Nej, ej inkluderat"
              labelPlacement="end"
              disabled={disabled}
            />
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label="Ja, inkluderat"
              labelPlacement="end"
              disabled={disabled}
            />
          </RadioGroup>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

NotEntreprenadtjanst.propTypes = {
  annualReport: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default NotEntreprenadtjanst
