import React from 'react'
import PropTypes from 'prop-types'

import Spinner from './generic/Spinner.jsx'

import styles from './BaseButton.module.css'

class BaseButton extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.object,
    color: PropTypes.string,
    kind: PropTypes.oneOf(['plain', 'primary', 'cancel', 'ghost', 'noborder']),
    type: PropTypes.oneOf(['button', 'reset', 'submit']),
    spinner: PropTypes.bool,
    spinnerClassName: PropTypes.string,
  }

  static defaultProps = {
    kind: 'plain',
    type: 'button',
    spinner: false,
    spinnerClassName: '',
  }

  focus() {
    this.button.focus()
  }

  computeStyle() {
    const { kind, color } = this.props
    if (kind === 'primary') {
      return {
        buttonTypeClassName: styles.PrimaryButton,
        inlineStyles: color ? { background: color } : {},
      }
    } else if (kind === 'cancel') {
      return {
        buttonTypeClassName: styles.CancelButton,
        inlineStyles: {},
      }
    } else if (kind === 'ghost') {
      return {
        buttonTypeClassName: styles.GhostButton,
        inlineStyles: color
          ? {
              borderColor: color,
              color,
            }
          : {},
      }
    } else if (kind === 'noborder') {
      return {
        buttonTypeClassName: styles.NoborderButton,
        inlineStyles: color
          ? {
              borderColor: color,
              color,
            }
          : {},
      }
    }
    return {
      buttonTypeClassName: '',
      inlineStyles: {},
    }
  }

  render() {
    const { spinner, spinnerClassName, children, className = '', kind, type, style = {}, ...props } = this.props
    const { buttonTypeClassName, inlineStyles } = this.computeStyle()
    return (
      <button
        type={type}
        className={`${styles.BaseButton} ${buttonTypeClassName} ${className}`}
        style={{ ...inlineStyles, ...style }}
        ref={(el) => (this.button = el)}
        {...props}
      >
        {spinner && (
          <Spinner
            color={'#fff'}
            thickness="0.15em"
            className={`${styles.ButtonSpinner} ${spinnerClassName}`}
            delayBeforeVisibleMs={0}
          />
        )}
        {children}
      </button>
    )
  }
}

export default BaseButton
