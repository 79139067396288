import React from 'react'
import PropTypes from 'prop-types'

import EditBox from './generic/EditBox.jsx'

import styles from './StyledEditBox.module.css'

const StyledEditBox = React.forwardRef(({ className, disabled, ...props }, ref) => (
  <EditBox
    ref={ref}
    className={`${className} ${styles.StyledEditBox} ${disabled ? styles.StyledEditBoxDisabled : ''}`}
    disabled={disabled}
    {...props}
  />
))

StyledEditBox.displayName = 'StyledEditBox'

StyledEditBox.propTypes = {
  ...EditBox.propTypes,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}

export default StyledEditBox
