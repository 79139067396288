import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box/index.js'
import Button from '@mui/material/Button/index.js'

import { useAbortSignal } from '../../AppFetch.js'
import API from '../../API.js'
import EditableMultiYearSummary from './EditableMultiYearSummary.jsx'
import EditableXbrlValue from './EditableXbrlValue.jsx'
import RmTextField from './RmTextField.jsx'
import { renderEditableReadonlyRow } from './EditableTableUtils.jsx'
import {
  getOpeningBalancesForChangesToEquityTable,
  getAnnualReportIssuer,
} from '../../../shared-universal/AnnualReportUtils.js'
import EditableAmount from './EditableAmount.jsx'
import EditableFondemission from './EditableFondemission.jsx'
import EditableNyemission from './EditableNyemission.jsx'
import EditableUppskrivningAvAnlaggningstillgang from './EditableUppskrivningAvAnlaggningstillgang.jsx'
import StyrelsenYttrandeOmVinstdisposition from './StyrelsensYttrandeOmVinstdisposition.jsx'
import EditableDomicile from './EditableDomicile.jsx'
import EditableComment from './EditableComment.jsx'

const BodReport = ({ annualReport, onChange, isApproved }) => {
  const FIRST_CELL_WIDTH = '240px'
  const abortSignal = useAbortSignal()
  const { openingBalanceXbrlValues } = getOpeningBalancesForChangesToEquityTable(annualReport)
  const [reactKey, setReactKey] = useState(0)

  const showEditableBalanseratResultat =
    (openingBalanceXbrlValues['BalanseratResultat']?.value || 0) !==
      (annualReport.financialYears[0].xbrlValues['BalanseratResultat']?.value || 0) ||
    (annualReport.financialYears[0].xbrlValues['ForandringEgetKapitalBalanseratResultatUtdelning']?.value || 0) !== 0 ||
    (annualReport.financialYears[0].xbrlValues['ForandringEgetKapitalBalanseratResultatBalanserasNyRakning']?.value ||
      0) !== 0 ||
    (annualReport.financialYears[0].xbrlValues['ForandringEgetKapitalBalanseratResultatErhallnaAktieagartillskott']
      ?.value || 0) !== 0 ||
    (annualReport.financialYears[0].xbrlValues['ForandringEgetKapitalBalanseratResultatAterbetalningAktieagartillskott']
      ?.value || 0) !== 0 ||
    (annualReport.financialYears[0].xbrlValues['ForandringEgetKapitalBalanseratResultatFondemission']?.value || 0) !== 0

  return (
    <React.Fragment>
      <h3>Förvaltningsberättelse</h3>
      <Box mt={1} />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>Allmänt om verksamheten</div>
        {annualReport.prevYearFound && (
          <div>
            <Button
              onClick={() => {
                API.saveAnnualReportValue(annualReport.reportId, 'domicile', annualReport.prevDomicile, abortSignal)
                API.saveAnnualReportValue(
                  annualReport.reportId,
                  'businessDescription',
                  annualReport.prevBusinessDescription,
                  abortSignal,
                  async () => {
                    await onChange()
                    setReactKey(reactKey + 1)
                  }
                )
              }}
            >
              Infoga från föregående år
            </Button>
          </div>
        )}
      </div>
      <Box mt={1} />

      <RmTextField
        defaultValue={annualReport.businessDescription}
        onChange={(ev) => {
          API.saveAnnualReportValue(
            annualReport.reportId,
            'businessDescription',
            ev.target.value,
            abortSignal,
            onChange
          )
        }}
        key={reactKey}
        multiline
        rows="10"
        fullWidth
        disabled={isApproved}
        className="automation-business-description"
      />
      <Box mt={2} />
      <table>
        <tbody>
          <tr>
            <td style={{ width: FIRST_CELL_WIDTH }}>Företaget har sitt säte i</td>
            <td>
              <EditableDomicile
                defaultValue={annualReport.domicile}
                onChange={(newDomicile) => {
                  API.saveAnnualReportValue(annualReport.reportId, 'domicile', newDomicile, abortSignal, onChange)
                }}
                key={reactKey}
                disabled={isApproved}
                className="automation-domicile"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <Box mt={3} />
      <div>Väsentliga händelser under räkenskapsåret</div>
      <Box mt={1} />
      <RmTextField
        defaultValue={annualReport.significantEvents}
        onChange={(ev) => {
          API.saveAnnualReportValue(annualReport.reportId, 'significantEvents', ev.target.value, abortSignal, onChange)
        }}
        multiline
        rows="10"
        fullWidth
        disabled={isApproved}
        className="automation-VasentligaHandelserRakenskapsaret"
      />
      <Box mt={5} />
      <h3>Flerårsöversikt</h3>
      <EditableMultiYearSummary
        annualReport={annualReport}
        onChange={onChange}
        firstCellStyle={{ width: FIRST_CELL_WIDTH }}
        disabled={isApproved}
      />
      <EditableComment
        xbrlName="KommentarFlerarsoversikt"
        addCommentText="Lägg till kommentar till flerårsöversikt"
        expandedTitle="Kommentar till flerårsöversikt"
        annualReport={annualReport}
        onChange={onChange}
        disabled={isApproved}
        classNameAdd="automation-add-KommentarFlerarsoversikt"
        classNameTextarea="automation-KommentarFlerarsoversikt"
      />

      {showEditableBalanseratResultat && (
        <React.Fragment>
          <Box mt={5} />
          <h3>Förändringar i eget kapital</h3>
          <table>
            <tbody>
              {renderEditableReadonlyRow(
                openingBalanceXbrlValues,
                'BalanseratResultat',
                'Ingående balanserat resultat',
                'kr'
              )}
              {annualReport.financialYears.length > 1 && (
                <tr>
                  <td style={{ width: FIRST_CELL_WIDTH }}>Utdelning som har utbetalats</td>
                  <td>
                    <EditableXbrlValue
                      xbrlName="ForandringEgetKapitalBalanseratResultatUtdelning"
                      financialYear={annualReport.financialYears[0]}
                      abortSignal={abortSignal}
                      onSaved={onChange}
                      disabled={isApproved}
                      forceSign={'-'}
                    />
                  </td>
                </tr>
              )}
              <tr>
                <td style={{ width: FIRST_CELL_WIDTH }}>Erhållna aktieägartillskott</td>
                <td>
                  <EditableXbrlValue
                    xbrlName="ForandringEgetKapitalBalanseratResultatErhallnaAktieagartillskott"
                    financialYear={annualReport.financialYears[0]}
                    abortSignal={abortSignal}
                    onSaved={onChange}
                    disabled={isApproved}
                    forceSign={'+'}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ width: FIRST_CELL_WIDTH }}>Återbetalning av aktieägartillskott</td>
                <td>
                  <EditableXbrlValue
                    xbrlName="ForandringEgetKapitalBalanseratResultatAterbetalningAktieagartillskott"
                    financialYear={annualReport.financialYears[0]}
                    abortSignal={abortSignal}
                    onSaved={onChange}
                    disabled={isApproved}
                    forceSign={'-'}
                  />
                </td>
              </tr>
              {renderEditableReadonlyRow(
                annualReport.financialYears[0].xbrlValues,
                'rmForandringEgetKapitalBeraknatBalanseratResultat',
                'Utgående balanserat resultat (beräknat)',
                'kr'
              )}
              <tr>
                <td>Balanserat resultat i balansräkningen</td>
                <td>
                  <EditableAmount
                    className={`automation-BalanseratResultat`}
                    defaultValue={annualReport.financialYears[0].xbrlValues['BalanseratResultat']?.value}
                    unit="kr"
                    disabled={true}
                  />
                </td>
              </tr>
              {renderEditableReadonlyRow(
                annualReport.financialYears[0].xbrlValues,
                'rmForandringEgetKapitalBeraknatBalanseratResultatDifferens',
                'Differens mellan beräknat balanserat resultat och balanserat resultat i balansräkningen',
                'kr'
              )}
            </tbody>
          </table>
          <EditableComment
            xbrlName="ForandringEgetKapitalKommentar"
            expandedTitle="Kommentar till förändringar i eget kapital"
            annualReport={annualReport}
            onChange={onChange}
            disabled={isApproved}
          />
        </React.Fragment>
      )}
      <Box mt={5} />
      <EditableNyemission annualReport={annualReport} onChange={onChange} isApproved={isApproved} />
      <Box mt={5} />
      <EditableFondemission annualReport={annualReport} onChange={onChange} isApproved={isApproved} />
      <Box mt={5} />
      <EditableUppskrivningAvAnlaggningstillgang
        annualReport={annualReport}
        onChange={onChange}
        isApproved={isApproved}
      />
      <Box mt={5} />
      <h3>Resultatdisposition</h3>
      {getAnnualReportIssuer(annualReport) === 'Likvidatorn' && (
        <div>Med hänsyn till att likvidation pågår lämnas inget förslag beträffande resultatdisposition.</div>
      )}
      {getAnnualReportIssuer(annualReport) !== 'Likvidatorn' && (
        <React.Fragment>
          <table>
            <tbody>
              <tr>
                <td style={{ width: FIRST_CELL_WIDTH }}>Förslag till ny utdelning</td>
                <td>
                  <EditableXbrlValue
                    xbrlName="ForslagDispositionUtdelning"
                    financialYear={annualReport.financialYears[0]}
                    abortSignal={abortSignal}
                    onSaved={onChange}
                    disabled={isApproved}
                    allowNegative={false}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          {Boolean(annualReport.financialYears[0].xbrlValues['ForslagDispositionUtdelning']?.value) && (
            <React.Fragment>
              <Box mt={2} />
              <StyrelsenYttrandeOmVinstdisposition
                annualReport={annualReport}
                onChange={onChange}
                isApproved={isApproved}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

BodReport.propTypes = {
  annualReport: PropTypes.object,
  onChange: PropTypes.func,
  isApproved: PropTypes.bool.isRequired,
}

export default BodReport
