import PropTypes from 'prop-types'
import React from 'react'

import PaddingButton from './PaddingButton.jsx'

import styles from './WideButton.module.css'

const WideButton = ({ children, className = '', ...props }) => {
  return (
    <div className={styles.WideButtonContainer}>
      <PaddingButton className={`${styles.WideButton} ${className}`} {...props}>
        {children}
      </PaddingButton>
    </div>
  )
}

WideButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default WideButton
