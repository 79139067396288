import PropTypes from 'prop-types'
import React from 'react'

import styles from './Checkbox.module.css'

const Checkbox = ({ label, onChange, className, inputStyle, ...otherProps }) => {
  return (
    <label className={`${styles.Checkbox} ${className}`}>
      <input
        type="checkbox"
        onChange={(ev) => onChange && onChange(ev.target.checked)}
        style={inputStyle}
        {...otherProps}
      />
      <span>{label}</span>
    </label>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  inputStyle: PropTypes.object,
}

Checkbox.defaultProps = {
  className: '',
  label: '',
  inputStyle: {},
}

export default Checkbox
