import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip/index.js'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'

import EditableAmount from './EditableAmount.jsx'
import { debounced } from '../../../shared-universal/Utils.js'
import { makeFinancialYearTitleHtml } from '../../../shared-universal/AnnualReportRender.js'
import { formatCell } from '../../../shared-universal/AnnualReportUtils.js'
import { appFetch } from '../../AppFetch.js'
import { getUrlParam } from '../../UrlUtils.js'
import RmClickToSelect from './RmClickToSelect.jsx'
import RmTheme from '../../../shared-universal/RmTheme.js'

const DATA_FIELD_RIGHT_MARGIN = '15px'

let fYearToNewXbrlValuesMap = {}
const debouncedSubmitSaveOperations = debounced(500, (abortSignal, afterSaveCallback) => {
  const fYearToNewXbrlValuesToSubmit = fYearToNewXbrlValuesMap
  fYearToNewXbrlValuesMap = {}
  return appFetch('/api/up/save-values', {
    signal: abortSignal,
    method: 'POST',
    body: JSON.stringify(fYearToNewXbrlValuesToSubmit),
  }).then((resp) => {
    afterSaveCallback()
    return resp
  })
})

const saveXbrlValues = (financialYearId, xbrlRecords, abortSignal, afterSaveCallback = () => {}) => {
  if (!Object.hasOwn(fYearToNewXbrlValuesMap, financialYearId)) {
    fYearToNewXbrlValuesMap[financialYearId] = {}
  }
  for (let xbrlEntry of xbrlRecords) {
    const newDbValue = xbrlEntry.value !== '' && xbrlEntry.value !== null ? Number(xbrlEntry.value) : null
    fYearToNewXbrlValuesMap[financialYearId][xbrlEntry.xbrlName] = { value: newDbValue, rawAmount: newDbValue }
  }
  debouncedSubmitSaveOperations(abortSignal, afterSaveCallback)
}

const saveXbrlValue = (financialYearId, xbrlName, value, abortSignal, afterSaveCallback = () => {}) => {
  saveXbrlValues(financialYearId, [{ xbrlName, value }], abortSignal, afterSaveCallback)
}

const renderEditableReadonlyRow = (
  xbrlValues,
  xbrlName,
  title,
  unit = 'kr',
  opts = { colSpan: 1, presentationToggleSign: false }
) => {
  const firstCellStyle = {
    width: '100%',
  }
  return (
    <tr>
      <td style={firstCellStyle} colSpan={opts.colSpan}>
        {title}
      </td>
      <td>
        <div
          style={{
            textAlign: 'right',
            paddingRight: DATA_FIELD_RIGHT_MARGIN,
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
          }}
        >
          <RmClickToSelect
            text={formatCell(xbrlValues[xbrlName], {
              decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
              presentationToggleSign: opts.presentationToggleSign,
            })}
          />{' '}
          {unit}
        </div>
      </td>
    </tr>
  )
}

const isHidden = (financialYears, node, opts) => {
  const xbrlName = node.xbrlName
  if (node.items) {
    return node.items.every((item) => isHidden(financialYears, item, opts))
  }
  return financialYears.every((fYear) => {
    const editableValue = fYear.xbrlValues[xbrlName]?.value
    return node.advanced && opts.advancedIsHidden && editableValue === undefined
  })
}

const border = '2px solid #B0B0B0'
const radius = '10px'
//const lighterLevelColor = '#dce5e2'
const lighterLevelColor = '#e6e6e6'
const detailsLevelColor = '#d6d6d6'

const createIndentation1 = (
  maxIndent,
  depth,
  opts = { isHeader: false, isFooter: false, isLeaf: false, isSummary: false, isSpacer: false }
) => {
  const left = {
    borderLeft: border,
  }
  const bottom = {
    borderBottom: border,
  }
  const top = {
    borderTop: border,
  }
  const topLeftBend = {
    borderTopLeftRadius: radius,
  }
  const bottomLeftBend = {
    borderBottomLeftRadius: radius,
  }
  const poppingBackground = {
    backgroundColor: lighterLevelColor,
  }

  return (
    <>
      {Array(maxIndent ?? 0)
        .fill()
        .map((x, i) => i + 1)
        .map((level, index, arr) => {
          let style
          if (opts.isLeaf) {
            if (depth > level) {
              style = left
            } else {
              style = {}
            }
          } else if (opts.isSpacer) {
            if (depth >= level) {
              style = left
            } else {
              style = {}
            }
          } else if (opts.isHeader) {
            if (depth > level) {
              style = left
            } else {
              style = {}
            }
          } else if (opts.isFooter) {
            if (depth > level) {
              style = left
            } else if (depth === level) {
              style = { ...left, ...bottom, ...bottomLeftBend }
            } else {
              style = bottom
            }
          } else if (opts.isSummary) {
            style = { ...top, ...left, ...topLeftBend, ...poppingBackground }
          }

          if (level === arr.length) {
            if (opts.isSummary) {
              style = { ...top, ...poppingBackground }
            } else {
              style = {}
            }
          }

          return (
            <td key={'index-' + index} style={style}>
              <div style={{ minWidth: '10px', minHeight: '10px' }}></div>
            </td>
          )
        })}
    </>
  )
}
const createIndentation2 = (
  maxIndent,
  depth,
  opts = { isHeader: false, isFooter: false, isLeaf: false, isSummary: false, isSpacer: false }
) => {
  const left = {
    borderLeft: border,
  }
  const top = {
    borderTop: border,
  }
  const bottom = {
    borderBottom: border,
  }
  const topLeftBend = {
    borderTopLeftRadius: radius,
  }
  const bottomLeftBend = {
    borderBottomLeftRadius: radius,
  }
  const poppingBackground = {
    backgroundColor: lighterLevelColor,
  }

  return (
    <>
      {Array(maxIndent ?? 0)
        .fill()
        .map((x, i) => i + 1)
        .map((level, index, arr) => {
          let style
          if (opts.isLeaf) {
            if (depth > level) {
              style = left
            } else {
              style = {}
            }
          } else if (opts.isSpacer) {
            if (depth >= level) {
              style = left
            } else {
              style = {}
            }
          } else if (opts.isHeader) {
            if (depth > level) {
              style = left
            } else if (depth === level) {
              style = { ...left, ...top, ...topLeftBend }
            } else {
              style = top
            }
          } else if (opts.isFooter) {
            if (depth > level) {
              style = left
            } else {
              style = {}
            }
          } else if (opts.isSummary) {
            style = { ...bottom, ...left, ...bottomLeftBend, ...poppingBackground }
          }

          if (level === arr.length) {
            if (opts.isSummary) {
              style = { ...bottom, ...poppingBackground }
            } else {
              style = {}
            }
          }
          return (
            <td key={'index-' + index} style={style}>
              <div style={{ minWidth: '10px', minHeight: '10px' }}></div>
            </td>
          )
        })}
    </>
  )
}
const renderEditableTable = (node, financialYears, opts, depth, parentNode = undefined) => {
  if (isHidden(financialYears, node, opts)) {
    return null
  }

  const createTopLeftCellStyle = (opts) => ({
    ...opts.firstCellStyle,
    borderTopLeftRadius: depth === 3 ? radius : undefined,
    borderTopRightRadius: depth === 3 ? radius : undefined,
    backgroundColor: depth === 3 ? lighterLevelColor : undefined,
    paddingLeft: depth === 3 ? '8px' : undefined,
  })
  const createMiddleLeftCellHeadStyle = (opts) => ({
    ...opts.firstCellStyle,
    backgroundColor: depth === 3 ? lighterLevelColor : undefined,
  })
  const createMiddleCellHeadStyle = (opts) => ({
    backgroundColor: depth === 3 ? lighterLevelColor : undefined,
  })
  const createMiddleCellStyle = (opts) => ({
    backgroundColor: opts.isDetails
      ? detailsLevelColor
      : opts.isSummary
        ? lighterLevelColor
        : depth === 4
          ? lighterLevelColor
          : undefined,
    paddingLeft: depth >= 3 && opts.firstCol ? '8px' : undefined,
    paddingRight: depth >= 3 && opts.lastCol ? '8px' : undefined,
    borderTop: opts.isSummary ? border : undefined,
    borderBottom: opts.isSummary ? border : undefined,
  })

  const createExprCellStyle = (opts) => ({
    backgroundColor: lighterLevelColor,
    // border radius on the right side if isLastCol
    borderTopRightRadius: opts.lastCol ? radius : undefined,
    borderBottomRightRadius: opts.lastCol ? radius : undefined,

    // border on the right side if isLastCol
    borderRight: opts.lastCol ? border : undefined,
    borderTop: border,
    borderBottom: border,
  })

  const createBottomLeftCellStyle = (opts) => ({
    ...opts.firstCellStyle,
    borderBottomLeftRadius: depth === 3 ? radius : undefined,
    backgroundColor: depth === 3 ? lighterLevelColor : undefined,
  })
  const createBottomRightCellStyle = (opts) => ({
    borderBottomRightRadius: depth === 3 && opts.lastCol ? radius : undefined,
    backgroundColor: depth === 3 ? lighterLevelColor : undefined,
  })

  if (node.items) {
    const xbrlName = node.xbrlName
    let datesRendered = false
    return (
      <React.Fragment>
        {depth > 0 && (
          <>
            <tr>
              {createIndentation1(opts.maxIndent, depth, { isHeader: true })}
              <td style={createTopLeftCellStyle(opts)} colSpan={financialYears.length + 1} rowSpan={2}>
                <Box mt={1} mb={1}>
                  <Typography variant={'th' + depth}>{node.title}</Typography>
                </Box>
              </td>
            </tr>
            <tr>{createIndentation2(opts.maxIndent, depth, { isHeader: true })}</tr>
          </>
        )}

        {node.items
          .filter((item) => {
            // Filter out hidden items
            return !isHidden(financialYears, item, opts)
          })
          .map((item, idx, filteredItems) => {
            const xbrlName = item.xbrlName
            const renderDates = !datesRendered && !item.items && opts.fyTitleType !== 'none' && !item.summaryRow
            let extraRow
            if (renderDates) {
              datesRendered = true
              extraRow = (
                <>
                  <tr>
                    {createIndentation1(opts.maxIndent, depth, { isSpacer: true })}
                    <td style={createMiddleLeftCellHeadStyle(opts)} rowSpan={2}></td>
                    {financialYears.map((fYear) => (
                      <td
                        key={fYear.financialYearId}
                        dangerouslySetInnerHTML={{ __html: makeFinancialYearTitleHtml(fYear, opts.fyTitleType) }}
                        style={{
                          ...createMiddleCellHeadStyle(opts),
                          textAlign: 'right',
                          paddingRight: DATA_FIELD_RIGHT_MARGIN,
                          fontWeight: 'bold',
                          verticalAlign: 'top',
                        }}
                        rowSpan={2}
                      ></td>
                    ))}
                  </tr>
                  <tr>{createIndentation2(opts.maxIndent, depth, { isSpacer: true })}</tr>
                </>
              )
            }
            return (
              <React.Fragment key={xbrlName}>
                {extraRow}
                <React.Fragment>{renderEditableTable(item, financialYears, opts, depth + 1, node)}</React.Fragment>
                {/* spacer between line items */}
                {depth === 2 && item.items && idx !== filteredItems.length - 1 && (
                  <>
                    <tr>
                      {createIndentation1(opts.maxIndent, depth, { isSpacer: true })}
                      <td style={createMiddleLeftCellHeadStyle(opts)} rowSpan={2}></td>
                      {financialYears.map((fYear) => (
                        <td key={fYear.financialYearId} style={createMiddleCellHeadStyle(opts)} rowSpan={2}></td>
                      ))}
                    </tr>
                    <tr>{createIndentation2(opts.maxIndent, depth, { isSpacer: true })}</tr>
                  </>
                )}
              </React.Fragment>
            )
          })}
        {!node.hideSectionSum && depth > 0 && (
          <React.Fragment>
            <tr>
              {createIndentation1(opts.maxIndent, depth, { isFooter: true })}
              <td style={createBottomLeftCellStyle(opts)} rowSpan={2}>
                {depth < 3 && (
                  <Box mt={1} mb={1}>
                    <Typography variant={'th' + depth}>Summa {node.title}</Typography>
                  </Box>
                )}
              </td>
              {financialYears.map((fYear, fYearIdx) => (
                <td
                  key={fYear.financialYearId}
                  style={createBottomRightCellStyle({ ...opts, lastCol: financialYears.length - 1 === fYearIdx })}
                  rowSpan={2}
                >
                  <div
                    className={`automation-${xbrlName}-${fYearIdx}`}
                    style={{ marginRight: DATA_FIELD_RIGHT_MARGIN, whiteSpace: 'nowrap' }}
                  >
                    <Box align="right">
                      <Typography variant="summary">
                        {formatCell(fYear.xbrlValues[xbrlName], {
                          decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
                          presentationToggleSign: node.xbrlWeight * (parentNode?.xbrlWeight ?? 1) === -1,
                        })}{' '}
                        kr
                      </Typography>
                    </Box>
                  </div>
                </td>
              ))}
            </tr>
            <tr>{createIndentation2(opts.maxIndent, depth, { isFooter: true })}</tr>
          </React.Fragment>
        )}
        {node.hideSectionSum && depth > 0 && (
          <React.Fragment>
            <tr>{createIndentation1(opts.maxIndent, depth, { isFooter: true })}</tr>
            <tr>{createIndentation2(opts.maxIndent, depth, { isFooter: true })}</tr>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  } else {
    const xbrlName = node.xbrlName
    const getUnit = (xbrlUnit) => {
      if (xbrlUnit === 'procent') {
        return '%'
      } else if (xbrlUnit === 'antal-anstallda') {
        return ''
      } else {
        return 'kr'
      }
    }
    const unit = getUnit(node.xbrlUnit)
    const title = node.summaryRow ? (
      <Box mt={1} mb={1}>
        <Typography variant={'th' + depth}>{node.title}</Typography>
      </Box>
    ) : (
      <span>{node.title}</span>
    )

    const CalculationDetails = ({ title, xbrlName, children }) => {
      return (
        <>
          <tr>
            {createIndentation1(opts.maxIndent, depth, { isLeaf: true })}
            <td style={createMiddleCellStyle({ isDetails: true })} colSpan={2} rowSpan={2}>
              <Accordion
                elevation={0}
                expanded={opts.detailsVisibleForXbrlname[xbrlName]}
                onChange={() => opts.onDetailsToggled(xbrlName)}
              >
                <AccordionSummary style={createMiddleCellStyle({ isDetails: true })} expandIcon={<ExpandMoreIcon />}>
                  {title}
                </AccordionSummary>
                <AccordionDetails style={createMiddleCellStyle({ isDetails: true })}>
                  <table style={createMiddleCellStyle({ isDetails: true })}>
                    <tbody>{children}</tbody>
                  </table>
                </AccordionDetails>
              </Accordion>
            </td>
            {financialYears.slice(1).map((fYear, fYearIdx) => (
              <td
                key={fYear.financialYearId}
                style={createMiddleCellStyle({ ...opts, lastCol: financialYears.length - 1 === fYearIdx })}
                rowSpan={2}
              ></td>
            ))}
          </tr>
          <tr>{createIndentation2(opts.maxIndent, depth, { isLeaf: true })}</tr>
        </>
      )
    }
    CalculationDetails.propTypes = {
      title: PropTypes.string.isRequired,
      xbrlName: PropTypes.string.isRequired,
      children: PropTypes.node.isRequired,
    }

    let extraRows
    if (xbrlName === 'Skatteskulder') {
      extraRows = (
        <React.Fragment>
          <CalculationDetails title="Visa beräkning av skatteskulder" xbrlName="Skatteskulder">
            <tr>
              <td style={opts.firstCellStyle}>Skatteskulder (bokförda)</td>
              {financialYears.map((fYear, fYearIdx) => {
                if (fYearIdx > 0) {
                  return <td key={fYear.financialYearId}></td>
                }
                return (
                  <td key={fYear.financialYearId}>
                    <EditableAmount
                      className={`automation-rmSkatteskulderBokforda`}
                      defaultValue={fYear.xbrlValues['rmSkatteskulderBokforda']?.value}
                      onChange={async (ev) => {
                        saveXbrlValue(
                          fYear.financialYearId,
                          'rmSkatteskulderBokforda',
                          ev.target.value,
                          opts.abortSignal,
                          opts.onChange
                        )
                      }}
                      unit={unit}
                      disabled={opts.forceAllDisabled}
                      scale={node.scale}
                    />
                  </td>
                )
              })}
            </tr>

            {Boolean(financialYears[0].xbrlValues['rmAnnulleringUrsprungligBokfordSkatt'].value) && (
              <tr>
                <td style={opts.firstCellStyle}>Annullering av bokförd skatt</td>
                {financialYears.map((fYear, fYearIdx) => {
                  if (fYearIdx > 0) {
                    return <td key={fYear.financialYearId}></td>
                  }
                  return (
                    <td key={fYear.financialYearId}>
                      <b>
                        <div
                          className={`automation-rmAnnulleringUrsprungligBokfordSkatt`}
                          style={{
                            textAlign: 'right',
                            marginRight: DATA_FIELD_RIGHT_MARGIN,
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {formatCell(fYear.xbrlValues['rmAnnulleringUrsprungligBokfordSkatt'], {
                            decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
                          })}{' '}
                          {unit}
                        </div>
                      </b>
                    </td>
                  )
                })}
              </tr>
            )}
            <tr>
              <td style={opts.firstCellStyle}>Skatteskulder innan årets skatt</td>
              {financialYears.map((fYear, fYearIdx) => {
                if (fYearIdx > 0) {
                  return <td key={fYear.financialYearId}></td>
                }
                return (
                  <td key={fYear.financialYearId}>
                    <b>
                      <div
                        className={`automation-rmSkatteskulderInnanAretsSkatt`}
                        style={{
                          textAlign: 'right',
                          marginRight: DATA_FIELD_RIGHT_MARGIN,
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {formatCell(fYear.xbrlValues['rmSkatteskulderInnanAretsSkatt'], {
                          decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
                        })}{' '}
                        {unit}
                      </div>
                    </b>
                  </td>
                )
              })}
            </tr>
            <tr>
              <td style={opts.firstCellStyle}>Årets skatt</td>
              {financialYears.map((fYear, fYearIdx) => {
                if (fYearIdx > 0) {
                  return <td key={fYear.financialYearId}></td>
                }
                return (
                  <td key={fYear.financialYearId}>
                    <b>
                      <div
                        className={`automation-SkattAretsResultat`}
                        style={{
                          textAlign: 'right',
                          marginRight: DATA_FIELD_RIGHT_MARGIN,
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {formatCell(fYear.xbrlValues['SkattAretsResultat'], {
                          decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
                        })}{' '}
                        {unit}
                      </div>
                    </b>
                  </td>
                )
              })}
            </tr>
            {financialYears.some((fYear) => Boolean(fYear.xbrlValues['rmSkattejustering']?.value)) && (
              <React.Fragment>
                <tr>
                  <td style={opts.firstCellStyle}>Skattejustering (pga ändrad beskattning / restituerad skatt)</td>
                  {financialYears.map((fYear, fYearIdx) => {
                    if (fYearIdx > 0) {
                      return <td key={fYear.financialYearId}></td>
                    }
                    return (
                      <td key={fYear.financialYearId}>
                        <b>
                          <div
                            className={`automation-rmSkattejustering`}
                            style={{
                              textAlign: 'right',
                              marginRight: DATA_FIELD_RIGHT_MARGIN,
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {formatCell(fYear.xbrlValues['rmSkattejustering'], {
                              decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
                              presentationToggleSign: true,
                            })}{' '}
                            {unit}
                          </div>
                        </b>
                      </td>
                    )
                  })}
                </tr>
                <tr>
                  <td style={opts.firstCellStyle}>Förändring av skatteskulder pga årets skatt</td>
                  {financialYears.map((fYear, fYearIdx) => {
                    if (fYearIdx > 0) {
                      return <td key={fYear.financialYearId}></td>
                    }
                    return (
                      <td key={fYear.financialYearId}>
                        <b>
                          <div
                            className={`automation-rmSkatteskulderDifferensPgaAretsSkatt`}
                            style={{
                              textAlign: 'right',
                              marginRight: DATA_FIELD_RIGHT_MARGIN,
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {formatCell(fYear.xbrlValues['rmSkatteskulderDifferensPgaAretsSkatt'], {
                              decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
                            })}{' '}
                            {unit}
                          </div>
                        </b>
                      </td>
                    )
                  })}
                </tr>
              </React.Fragment>
            )}

            <tr>
              <td style={opts.firstCellStyle}>Skatteskulder efter årets skatt</td>
              {financialYears.map((fYear, fYearIdx) => {
                if (fYearIdx > 0) {
                  return <td key={fYear.financialYearId}></td>
                }
                return (
                  <td key={fYear.financialYearId}>
                    <b>
                      <div
                        className={`automation-rmSkatteskulderInnanOmforingAvNegativSkuld`}
                        style={{
                          textAlign: 'right',
                          marginRight: DATA_FIELD_RIGHT_MARGIN,
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {formatCell(fYear.xbrlValues['rmSkatteskulderInnanOmforingAvNegativSkuld'], {
                          decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
                        })}{' '}
                        {unit}
                      </div>
                    </b>
                  </td>
                )
              })}
            </tr>
            <tr>
              <td style={opts.firstCellStyle}>Omföring av negativ skuld (flyttas till fordringar)</td>
              {financialYears.map((fYear, fYearIdx) => {
                if (fYearIdx > 0) {
                  return <td key={fYear.financialYearId}></td>
                }
                return (
                  <td key={fYear.financialYearId}>
                    <b>
                      <div
                        className={`automation-rmSkatteskulderOmforingAvNegativSkuld`}
                        style={{
                          textAlign: 'right',
                          marginRight: DATA_FIELD_RIGHT_MARGIN,
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {formatCell(fYear.xbrlValues['rmSkatteskulderOmforingAvNegativSkuld'], {
                          decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
                        })}{' '}
                        {unit}
                      </div>
                    </b>
                  </td>
                )
              })}
            </tr>
            <tr>
              <td style={opts.firstCellStyle}>Skatteskulder (i balansräkningen)</td>
              {financialYears.map((fYear, fYearIdx) => {
                if (fYearIdx > 0) {
                  return <td key={fYear.financialYearId}></td>
                }
                return (
                  <td key={fYear.financialYearId}>
                    <b>
                      <div
                        className={`automation-Skatteskulder`}
                        style={{
                          textAlign: 'right',
                          marginRight: DATA_FIELD_RIGHT_MARGIN,
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {formatCell(fYear.xbrlValues['Skatteskulder'], {
                          decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
                        })}{' '}
                        {unit}
                      </div>
                    </b>
                  </td>
                )
              })}
            </tr>
          </CalculationDetails>
        </React.Fragment>
      )
    } else if (xbrlName === 'OvrigaFordringarKortfristiga') {
      extraRows = (
        <React.Fragment>
          <CalculationDetails title="Visa beräkning av övriga fordringar" xbrlName="OvrigaFordringarKortfristiga">
            <tr>
              <td style={opts.firstCellStyle}>Övriga fordringar innan omföring av negativ skatteskuld</td>
              {financialYears.map((fYear, fYearIdx) => {
                if (fYearIdx > 0) {
                  return <td key={fYear.financialYearId}></td>
                }
                return (
                  <td key={fYear.financialYearId}>
                    <EditableAmount
                      className={`automation-rmOvrigaFordringarKortfristigaInnanOmforingAvNegativSkuld`}
                      defaultValue={
                        fYear.xbrlValues['rmOvrigaFordringarKortfristigaInnanOmforingAvNegativSkuld']?.value || ''
                      }
                      onChange={async (ev) => {
                        saveXbrlValue(
                          fYear.financialYearId,
                          'rmOvrigaFordringarKortfristigaInnanOmforingAvNegativSkuld',
                          ev.target.value,
                          opts.abortSignal,
                          opts.onChange
                        )
                      }}
                      unit={unit}
                      disabled={opts.forceAllDisabled}
                      scale={node.scale}
                    />
                  </td>
                )
              })}
            </tr>
            <tr>
              <td style={opts.firstCellStyle}>Omföring av negativ skatteskuld</td>
              {financialYears.map((fYear, fYearIdx) => {
                if (fYearIdx > 0) {
                  return <td key={fYear.financialYearId}></td>
                }
                return (
                  <td key={fYear.financialYearId}>
                    <b>
                      <div
                        className={`automation-rmSkatteskulderOmforingAvNegativSkuld`}
                        style={{ textAlign: 'right', marginRight: DATA_FIELD_RIGHT_MARGIN, whiteSpace: 'nowrap' }}
                      >
                        {formatCell(fYear.xbrlValues['rmSkatteskulderOmforingAvNegativSkuld'], {
                          decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
                        })}{' '}
                        {unit}
                      </div>
                    </b>
                  </td>
                )
              })}
            </tr>
            <tr>
              <td style={opts.firstCellStyle}>Omföring av negativa övriga skulder (kortfristiga)</td>
              {financialYears.map((fYear, fYearIdx) => {
                if (fYearIdx > 0) {
                  return <td key={fYear.financialYearId}></td>
                }
                return (
                  <td key={fYear.financialYearId}>
                    <b>
                      <div
                        className={`automation-rmOvrigaKortfristigaSkulderOmforingAvNegativSkuld`}
                        style={{ textAlign: 'right', marginRight: DATA_FIELD_RIGHT_MARGIN, whiteSpace: 'nowrap' }}
                      >
                        {formatCell(fYear.xbrlValues['rmOvrigaKortfristigaSkulderOmforingAvNegativSkuld'], {
                          decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
                        })}{' '}
                        {unit}
                      </div>
                    </b>
                  </td>
                )
              })}
            </tr>
            <tr>
              <td style={opts.firstCellStyle}>Övriga fordringar</td>
              {financialYears.map((fYear, fYearIdx) => {
                if (fYearIdx > 0) {
                  return <td key={fYear.financialYearId}></td>
                }
                return (
                  <td key={fYear.financialYearId}>
                    <b>
                      <div
                        className={`automation-OvrigaFordringarKortfristiga`}
                        style={{ textAlign: 'right', marginRight: DATA_FIELD_RIGHT_MARGIN, whiteSpace: 'nowrap' }}
                      >
                        {formatCell(fYear.xbrlValues['OvrigaFordringarKortfristiga'], {
                          decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
                        })}{' '}
                        {unit}
                      </div>
                    </b>
                  </td>
                )
              })}
            </tr>
          </CalculationDetails>
        </React.Fragment>
      )
    } else if (xbrlName === 'OvrigaKortfristigaSkulder') {
      extraRows = (
        <React.Fragment>
          <CalculationDetails title="Visa beräkning av övriga skulder" xbrlName="OvrigaKortfristigaSkulder">
            <tr>
              <td style={opts.firstCellStyle}>Övriga skulder innan omföring av negativa övriga skulder</td>
              {financialYears.map((fYear, fYearIdx) => {
                if (fYearIdx > 0) {
                  return <td key={fYear.financialYearId}></td>
                }
                return (
                  <td key={fYear.financialYearId}>
                    <EditableAmount
                      className={`automation-rmOvrigaKortfristigaSkulderInnanOmforingAvNegativSkuld`}
                      defaultValue={fYear.xbrlValues['rmOvrigaKortfristigaSkulderInnanOmforingAvNegativSkuld']?.value}
                      onChange={async (ev) => {
                        saveXbrlValue(
                          fYear.financialYearId,
                          'rmOvrigaKortfristigaSkulderInnanOmforingAvNegativSkuld',
                          ev.target.value,
                          opts.abortSignal,
                          opts.onChange
                        )
                      }}
                      unit={unit}
                      disabled={opts.forceAllDisabled}
                      scale={node.scale}
                    />
                  </td>
                )
              })}
            </tr>
            <tr>
              <td style={opts.firstCellStyle}>Omföring av negativ övriga kortfristig skuld</td>
              {financialYears.map((fYear, fYearIdx) => {
                if (fYearIdx > 0) {
                  return <td key={fYear.financialYearId}></td>
                }
                return (
                  <td key={fYear.financialYearId}>
                    <b>
                      <div
                        className={`automation-rmOvrigaKortfristigaSkulderOmforingAvNegativSkuld`}
                        style={{ textAlign: 'right', marginRight: DATA_FIELD_RIGHT_MARGIN, whiteSpace: 'nowrap' }}
                      >
                        {formatCell(fYear.xbrlValues['rmOvrigaKortfristigaSkulderOmforingAvNegativSkuld'], {
                          decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
                        })}{' '}
                        {unit}
                      </div>
                    </b>
                  </td>
                )
              })}
            </tr>
            <tr>
              <td style={opts.firstCellStyle}>Övriga skulder</td>
              {financialYears.map((fYear, fYearIdx) => {
                if (fYearIdx > 0) {
                  return <td key={fYear.financialYearId}></td>
                }
                return (
                  <td key={fYear.financialYearId}>
                    <b>
                      <div
                        className={`automation-OvrigaKortfristigaSkulder`}
                        style={{ textAlign: 'right', marginRight: DATA_FIELD_RIGHT_MARGIN, whiteSpace: 'nowrap' }}
                      >
                        {formatCell(fYear.xbrlValues['OvrigaKortfristigaSkulder'], {
                          decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
                        })}{' '}
                        {unit}
                      </div>
                    </b>
                  </td>
                )
              })}
            </tr>
          </CalculationDetails>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <tr>
          {createIndentation1(opts.maxIndent, depth, { isLeaf: !node.summaryRow, isSummary: node.summaryRow })}
          <td style={createMiddleCellStyle({ ...opts, firstCol: true, isSummary: node.summaryRow })} rowSpan={2}>
            {title}
          </td>
          {financialYears.map((fYear, fYearIdx) => {
            if (
              fYearIdx < opts.readOnlyColumnCount ||
              (node.lockTooltipCurrentYear && fYearIdx === 0) ||
              (node.lockTooltipPrevYear && fYearIdx === 1)
            ) {
              return (
                <td key={fYear.financialYearId} style={createMiddleCellStyle(opts)} rowSpan={2}>
                  <Tooltip
                    title={(fYearIdx === 0 ? node.lockTooltipCurrentYear : node.lockTooltipPrevYear) || ''}
                    placement="left"
                  >
                    <EditableAmount
                      disabled={true}
                      className={`automation-${xbrlName}-${fYearIdx}`}
                      value={fYear.xbrlValues[xbrlName]?.value ?? '0'}
                      unit={unit}
                      scale={node.scale}
                      forceSign={node.xbrlWeight * (parentNode?.xbrlWeight ?? 1) === -1 ? '-' : ''}
                      forceAllowNegative={true}
                    />
                  </Tooltip>
                </td>
              )
            } else if (
              node.isExpr &&
              !opts.editableExpressions &&
              (node.emitIf !== 'currentYear' || (node.emitIf === 'currentYear' && fYearIdx === 0))
            ) {
              return (
                <td
                  key={fYear.financialYearId}
                  style={{
                    ...createMiddleCellStyle(opts),
                    ...createExprCellStyle({ lastCol: financialYears.length - 1 === fYearIdx }),
                  }}
                  rowSpan={2}
                >
                  <div
                    className={`automation-${xbrlName}-${fYearIdx}`}
                    style={{ textAlign: 'right', marginRight: DATA_FIELD_RIGHT_MARGIN, whiteSpace: 'nowrap' }}
                  >
                    <Box mt={1} mb={1}>
                      <Typography variant={'th' + depth}>
                        {formatCell(fYear.xbrlValues[xbrlName], {
                          decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
                        })}{' '}
                        {unit}
                      </Typography>
                    </Box>
                  </div>
                </td>
              )
            } else {
              const editableValue = fYear.xbrlValues[xbrlName]?.value
              return (
                <td
                  key={fYear.financialYearId}
                  style={createMiddleCellStyle({ ...opts, lastCol: financialYears.length - 1 === fYearIdx })}
                  rowSpan={2}
                >
                  <EditableAmount
                    className={`automation-${xbrlName}-${fYearIdx}`}
                    defaultValue={editableValue === undefined || editableValue === null ? '' : editableValue}
                    onChange={async (ev) => {
                      saveXbrlValue(fYear.financialYearId, xbrlName, ev.target.value, opts.abortSignal, opts.onChange)
                    }}
                    unit={unit}
                    disabled={opts.forceAllDisabled}
                    scale={node.scale}
                    forceSign={node.xbrlWeight * (parentNode?.xbrlWeight ?? 1) === -1 ? '-' : ''}
                    forceAllowNegative={true}
                    allowDecimalInput={node.allowDecimalInput}
                  />
                </td>
              )
            }
          })}
        </tr>
        <tr>{createIndentation2(opts.maxIndent, depth, { isLeaf: !node.summaryRow, isSummary: node.summaryRow })}</tr>

        {extraRows}
      </React.Fragment>
    )
  }
}

const ComparisonNumbersNudge = ({ annualReport, forText, badgeBottomMargin }) => {
  let instructionText
  if (annualReport.financialYears.length === 1) {
    if (annualReport.financialYears[0].filename) {
      instructionText = 'De förifyllda värdena är beräknade från din bokföring.'
    } else {
      instructionText = `Fyll i värden för ${forText.toLowerCase()} (eller importera en SIE-fil)`
    }
  } else {
    if (annualReport.prevYearFound) {
      if (annualReport.financialYears[0].filename) {
        instructionText =
          'De förifyllda värdena för nuvarande år är beräknade från din bokföring, förifyllda värden för jämförelseåret är hämtade från tidigare årsredovisning.'
      } else {
        instructionText = `Fyll i värden för nuvarande års ${forText.toLowerCase()} (eller importera en SIE-fil). Förifyllda värden för jämförelseåret är hämtade från tidigare årsredovisning.`
      }
    } else {
      if (annualReport.financialYears[0].filename) {
        instructionText =
          'De förifyllda värdena för nuvarande år är beräknade från din bokföring, värden för jämförelseåret behöver fyllas i manuellt (hämta rätt siffror från din föregående årsredovisning).'
      } else {
        instructionText = `Fyll i värden för nuvarande års ${forText.toLowerCase()} (eller importera en SIE-fil). Värden för jämförelseåret behöver också fyllas i manuellt (hämta rätt siffror från din föregående årsredovisning).`
      }
    }
  }
  return (
    <React.Fragment>
      <div style={{ padding: '40px' }}>{instructionText}</div>
      {annualReport.financialYears.length > 1 && !annualReport.prevYearFound && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            fontWeight: 'bold',
            color: RmTheme.PRIMARY_COLOR,
            marginBottom: badgeBottomMargin,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '167px' }}>
            <div style={{ textAlign: 'center' }}>Jämförelsetal behöver fyllas i manuellt!</div>
            <div>
              <KeyboardDoubleArrowDownIcon fontSize="inherit" style={{ fontSize: '60px' }} />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

ComparisonNumbersNudge.propTypes = {
  annualReport: PropTypes.object.isRequired,
  forText: PropTypes.string.isRequired,
  badgeBottomMargin: PropTypes.string.isRequired,
}

export {
  renderEditableTable,
  renderEditableReadonlyRow,
  saveXbrlValues,
  saveXbrlValue,
  DATA_FIELD_RIGHT_MARGIN,
  ComparisonNumbersNudge,
}
