import React from 'react'
import PropTypes from 'prop-types'

import { useAbortSignal } from '../../AppFetch.js'
import RmTextField from './RmTextField.jsx'
import API from '../../API.js'
import NoteHeading from './NoteHeading.jsx'

const NotAndraOvrigaUpplysningarEditor = ({ annualReport, onChange, disabled }) => {
  const abortSignal = useAbortSignal()
  return (
    <React.Fragment>
      <NoteHeading
        title="Andra övriga upplysningar"
        closeButton={true}
        noteXbrlName="NotAndraOvrigaUpplysningar"
        annualReport={annualReport}
        onChange={onChange}
      />
      <RmTextField
        defaultValue={annualReport.notAndraOvrigaUpplysningar}
        onChange={(ev) => {
          API.saveAnnualReportValue(
            annualReport.reportId,
            'notAndraOvrigaUpplysningar',
            ev.target.value,
            abortSignal,
            onChange
          )
        }}
        multiline
        rows="10"
        fullWidth
        disabled={disabled}
      />
    </React.Fragment>
  )
}

NotAndraOvrigaUpplysningarEditor.propTypes = {
  annualReport: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default NotAndraOvrigaUpplysningarEditor
