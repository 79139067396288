import React from 'react'
import PropTypes from 'prop-types'

import { useAbortSignal } from '../../AppFetch.js'
import RmTextField from './RmTextField.jsx'
import API from '../../API.js'
import NoteHeading from './NoteHeading.jsx'

const NotVasentligaHandelserRakenskapsaretsSlutEditor = ({ annualReport, onChange, disabled }) => {
  const abortSignal = useAbortSignal()
  return (
    <React.Fragment>
      <NoteHeading
        title="Väsentliga händelser efter räkenskapsårets slut"
        closeButton={true}
        noteXbrlName="NotVasentligaHandelserRakenskapsaretsSlut"
        annualReport={annualReport}
        onChange={onChange}
      />
      <RmTextField
        defaultValue={annualReport.notSignificantEventsAfterEndDate}
        onChange={(ev) => {
          API.saveAnnualReportValue(
            annualReport.reportId,
            'notSignificantEventsAfterEndDate',
            ev.target.value,
            abortSignal,
            onChange
          )
        }}
        multiline
        rows="10"
        fullWidth
        disabled={disabled}
      />
    </React.Fragment>
  )
}

NotVasentligaHandelserRakenskapsaretsSlutEditor.propTypes = {
  annualReport: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default NotVasentligaHandelserRakenskapsaretsSlutEditor
