import React from 'react'
import PropTypes from 'prop-types'
import FormControlLabel from '@mui/material/FormControlLabel/index.js'
import FormHelperText from '@mui/material/FormHelperText/index.js'
import Radio from '@mui/material/Radio/index.js'
import RadioGroup from '@mui/material/RadioGroup/index.js'

const RmRadioButtons = ({ value, disabled, items, onChange, ...props }) => {
  return (
    <RadioGroup style={{ marginLeft: '20px' }} value={value} onChange={onChange} {...props}>
      {items.map((item) => (
        <React.Fragment key={item.value}>
          <FormControlLabel
            value={item.value}
            label={item.label}
            disabled={item.disabled || disabled}
            control={<Radio color="primary" />}
            labelPlacement="end"
            className={item.className}
          />
          {item.helperText && (
            <FormHelperText style={{ marginLeft: '32px', marginTop: '-12px', marginBottom: '10px' }}>
              {item.helperText}
            </FormHelperText>
          )}
        </React.Fragment>
      ))}
    </RadioGroup>
  )
}

RmRadioButtons.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string,
      disabled: PropTypes.bool,
      helperText: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func,
}

export default RmRadioButtons
