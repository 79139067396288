import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import styles from './Modal.module.css'

class Modal extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    onRequestClose: PropTypes.func,
    anchorElement: PropTypes.instanceOf(Element),
    className: PropTypes.string,
    fullscreen: PropTypes.bool,
    fullscreenIf: PropTypes.func,
    fullscreenModeClassName: PropTypes.string,
    popupModeClassName: PropTypes.string,
  }

  static defaultProps = {
    fullscreenModeClassName: '',
    popupModeClassName: '',
  }

  static open({ content, ...other }) {
    const modalContainer = document.createElement('div')
    document.body.appendChild(modalContainer)
    // Set "overflow: hidden" to prevent body scrolling for scroll wheel event
    // while modal is open.
    document.body.classList.add(styles.BodyUnderneathModal)
    ReactDOM.render(
      <Modal onRequestClose={() => Modal.close(modalContainer)} {...other}>
        {content}
      </Modal>,
      modalContainer
    )

    return modalContainer
  }

  static hide(modalContainer) {
    modalContainer.style.display = 'none'
  }

  static close(modalContainer) {
    ReactDOM.unmountComponentAtNode(modalContainer)
    document.body.removeChild(modalContainer)
    if (!document.querySelector('.code-selector-modal')) {
      // The last modal has been removed, re-enable scroll on body etc
      document.body.classList.remove(styles.BodyUnderneathModal)
    }
  }

  constructor() {
    super()
    this.previousFocusHolder = document.activeElement
  }

  handleKeyUp = (ev) => {
    if (ev.keyCode === 27) {
      this.props.onRequestClose()
    }
  }

  centerModalInViewport = () => {
    this.modalElement.style.left = document.documentElement.clientWidth / 2 + 'px'
    this.modalElement.style.top = document.documentElement.clientHeight / 2 + 'px'
  }

  wantsFullscreen() {
    return (
      this.props.fullscreen === true || (typeof this.props.fullscreenIf === 'function' && this.props.fullscreenIf())
    )
  }

  setModalPosition = () => {
    if (this.wantsFullscreen()) {
      this.modalElement.classList.add(styles.Modal__FullscreenMode)
      if (this.props.fullscreenModeClassName) {
        this.modalElement.classList.add(this.props.fullscreenModeClassName)
      }
      this.modalElement.classList.remove(styles.Modal__PopupMode)
      if (this.props.popupModeClassName) {
        this.modalElement.classList.remove(this.props.popupModeClassName)
      }
      this.modalElement.style.left = '0px'
      this.modalElement.style.top = '0px'
    } else {
      this.modalElement.classList.remove(styles.Modal__FullscreenMode)
      if (this.props.fullscreenModeClassName) {
        this.modalElement.classList.remove(this.props.fullscreenModeClassName)
      }
      this.modalElement.classList.add(styles.Modal__PopupMode)
      if (this.props.popupModeClassName) {
        this.modalElement.classList.add(this.props.popupModeClassName)
      }
      if (this.props.anchorElement) {
        const bbox = this.props.anchorElement.getBoundingClientRect()
        // The modalElement has "transform:translate(-50%, -50%)" so we set left/top
        // to the coordinate where we want the middle of the modalElement to be:
        this.modalElement.style.left = bbox.x + bbox.width / 2 + 'px'
        this.modalElement.style.top = bbox.y + bbox.height / 2 + 'px'
      } else {
        // If no anchorElement is specified, we center the modal in the viewport.
        this.centerModalInViewport()
      }
    }
  }

  handleWindowResize = () => {
    this.setModalPosition()
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize)
    this.setModalPosition()
    // If an element outside of the modal has focus we steal focus so that we
    // can intercept ESC keypress event and close the modal when we see it.
    // However, if the calling code has already populated this.props.children
    // with a focused element (e.g. OK/Cancel buttons with focus on OK), then
    // we don't need to steal focus (key events will bubble to us anyway) and
    // if we'd mess up the focus handling done by the calling code. This is
    // because all the componentDidMount() calls for our children has already
    // executed when this code runs.
    if (!this.modalElement.querySelector(':focus')) {
      this.modalElement.focus()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
    this.previousFocusHolder.focus()
  }

  render() {
    /* Setting tabIndex="-1" below means that the div can receive focus
     * programatically but is not part of the TAB-order, see also:
     * https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex
     * The onKeyUp works without the tabIndex but the .focus() in
     * componentWillUnmount() does not.
     */
    return (
      <div className={styles.BackdropOverlay}>
        <div
          className={`code-selector-modal ${this.props.className}`}
          tabIndex="-1"
          onKeyUp={this.handleKeyUp}
          ref={(el) => (this.modalElement = el)}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default Modal
