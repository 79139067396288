import { consoleLog } from './SanctionedConsole.js'
import he from 'he'

const escapeHtml = (str) => he.escape(str)

const isValidEmail = (email) => {
  // https://emailregex.com/
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailRegex.test(String(email))
}

const isValidPhoneNumber = (phoneNumber) => {
  const phoneNumberRegex = /^\+?[\d\s\-.()]+$/
  return phoneNumberRegex.test(String(phoneNumber))
}

const numberWithGroupedDigits = (num) => Number(num).toLocaleString('en-us').replace(/,/, ' ')

function sleepMillis(millis) {
  return new Promise((resolve) => {
    setTimeout(resolve, millis)
  })
}

const debounced = (delayMillis, func) => {
  const returnedPromisesResolveFuncs = []
  let timerId = undefined
  return (...args) => {
    if (timerId) {
      clearTimeout(timerId)
    }
    return new Promise((resolve) => {
      returnedPromisesResolveFuncs.push(resolve)
      timerId = setTimeout(() => {
        timerId = undefined
        const returnValue = func(...args)
        returnedPromisesResolveFuncs.forEach((resolve) => resolve(returnValue))
      }, delayMillis)
    })
  }
}

// Creates and returns a function "f" that will call "func", except if "f" is
// called more than "count" times per "intervalMillis", then it will delay for
// "slowdownMillis" before making the call to "func".
const makeSlowdownIfRateLimitedFunc = (count, intervalMillis, slowdownMillis, func) => {
  let previousCallTimestamps = []
  return (...args) => {
    previousCallTimestamps = previousCallTimestamps.filter((timestamp) => Date.now() - timestamp < intervalMillis)
    previousCallTimestamps.push(Date.now())
    if (previousCallTimestamps.length > count) {
      consoleLog(
        `function returned by makeSlowdownIfRateLimitedFunc() has been called more than ${count} times in ${intervalMillis} ms, will add ${slowdownMillis} ms delay for this call`
      )
      setTimeout(() => {
        func.apply(this, args)
      }, slowdownMillis)
    } else {
      func.apply(this, args)
    }
  }
}

const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const isJsonString = (str) => {
  try {
    JSON.parse(str)
    return true
  } catch (ex) {
    if (ex instanceof SyntaxError) {
      return false
    }
    throw ex
  }
}

const pluralize = (num, word, suffix = 's') => `${num} ${word}${num !== 1 ? suffix : ''}`

const stringToBoolean = (strBool) => {
  if (strBool === 'true' || strBool === 'yes' || strBool === '1' || strBool === 'on') {
    return true
  } else if (strBool === 'false' || strBool === 'no' || strBool === '0' || strBool === 'off') {
    return false
  } else {
    return undefined
  }
}

const CHARCODE_0 = '0'.charCodeAt(0)
const MAPPING_EVEN = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9]
function getLuhnRemainder(value) {
  let length = value.length
  let accumulator = 0
  let bit = 0

  while (length-- > 0) {
    bit ^= 1
    accumulator += bit ? value.charCodeAt(length) - CHARCODE_0 : MAPPING_EVEN[value.charCodeAt(length) - CHARCODE_0]
  }

  return accumulator % 10
}

const isValidLuhn = (numStr) => getLuhnRemainder(numStr) === 0

const isValidOrganizationNumber = (orgNumStr) =>
  orgNumStr.length == 11 && Number(orgNumStr.slice(2, 4)) >= 20 && isValidLuhn(orgNumStr.replace(/-/g, ''))

const isValidPersonnummer = (personNrStr) => {
  const century = Number(personNrStr.slice(0, 2))
  const month = Number(personNrStr.slice(4, 6))
  return (
    personNrStr.length == 13 &&
    (century === 19 || century === 20) &&
    month >= 1 &&
    month <= 12 &&
    isValidLuhn(personNrStr.slice(2).replace(/-/g, ''))
  )
}

const keepSafeChars = (str) => {
  return str
    .replace(/å/gi, 'a')
    .replace(/ä/gi, 'a')
    .replace(/ö/gi, 'o')
    .replace(/[^a-z0-9]/gi, '-')
    .replace(/-+/g, '-')
    .toLowerCase()
}

export {
  escapeHtml,
  isValidEmail,
  isValidPhoneNumber,
  numberWithGroupedDigits,
  sleepMillis,
  debounced,
  makeSlowdownIfRateLimitedFunc,
  uuid,
  isJsonString,
  pluralize,
  stringToBoolean,
  isValidOrganizationNumber,
  isValidPersonnummer,
  keepSafeChars,
}
