import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { useAppFetch } from '../../AppFetch.js'
import SpinnerBlock from '../generic/SpinnerBlock.jsx'
import { Redirect } from 'react-router-dom'

const PageStartAuthenticated = ({ history }) => {
  const { loading, responseJson: annualReports } = useAppFetch(`/api/up/list-annual-reports`)

  if (loading) {
    return <SpinnerBlock />
  }

  if (!annualReports || annualReports.length === 0) {
    return <Redirect to={{ pathname: '/new' }} />
  }

  if (annualReports.length === 1) {
    return <Redirect to={{ pathname: `/edit-report/${annualReports[0].reportId}` }} />
  }

  return <Redirect to={{ pathname: `/list` }} />
}

PageStartAuthenticated.propTypes = {
  history: PropTypes.object,
}

export default withRouter(PageStartAuthenticated)
