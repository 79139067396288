import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField/index.js'

const RmTextField = React.forwardRef(({ disabled, style, ...props }, ref) => {
  return (
    <TextField
      variant="outlined"
      size="small"
      style={{ ...style, ...(disabled ? { background: 'var(--body-bgcolor)' } : { background: '#ffffff' }) }}
      disabled={disabled}
      {...props}
      ref={ref}
    />
  )
})

RmTextField.displayName = 'RmTextField'

RmTextField.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  style: PropTypes.object,
}

RmTextField.defaultProps = {
  disabled: false,
  onChange: () => {},
  style: {},
}

export default RmTextField
