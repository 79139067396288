import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useAbortSignal } from '../../AppFetch.js'
import { renderEditableTable, ComparisonNumbersNudge } from './EditableTableUtils.jsx'
import RmSwitch from './RmSwitch.jsx'

const EditableBalanceSheet = ({ annualReport, onChange, isApproved }) => {
  const abortSignal = useAbortSignal()
  const [advancedIsVisible, setAdvancedIsVisible] = useState(false)
  const [detailsVisibleForXbrlname, setDetailsVisibleForXbrlname] = useState({})
  return (
    <React.Fragment>
      <ComparisonNumbersNudge annualReport={annualReport} forText="Balansräkningen" badgeBottomMargin="-90px" />
      <RmSwitch label="Visa alla poster" value={advancedIsVisible} onChange={setAdvancedIsVisible} />
      <table width="100%">
        <tbody>
          {renderEditableTable(
            annualReport.clientBalanceSheetTemplate,
            annualReport.financialYears.slice(0, 2),
            {
              fyTitleType: 'instant',
              readOnlyColumnCount: 0,
              editableExpressions: false,
              forceAllDisabled: isApproved,
              advancedIsHidden: !advancedIsVisible,
              firstCellStyle: { width: '100%' },
              abortSignal,
              onChange,
              maxIndent: 3,
              detailsVisibleForXbrlname,
              onDetailsToggled: (xbrlName) =>
                setDetailsVisibleForXbrlname({
                  ...detailsVisibleForXbrlname,
                  [xbrlName]: !detailsVisibleForXbrlname[xbrlName],
                }),
            },
            0
          )}
        </tbody>
      </table>
    </React.Fragment>
  )
}

EditableBalanceSheet.propTypes = {
  annualReport: PropTypes.object,
  onChange: PropTypes.func,
  isApproved: PropTypes.bool.isRequired,
}

export default EditableBalanceSheet
