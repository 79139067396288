import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import ErrorBoundary from './ErrorBoundary.jsx'
import AuthenticatedRoute from './AuthenticatedRoute.jsx'
import PageSignupForm from './pages/PageSignupForm.jsx'
import PageCheckoutWrapper from './pages/PageCheckoutWrapper.jsx'
import PageLoginForm from './pages/PageLoginForm.jsx'
import PageRequestPasswordReset from './pages/PageRequestPasswordReset.jsx'
import PageFinishPasswordReset from './pages/PageFinishPasswordReset.jsx'
import PageStartAnonymous from './pages/PageStartAnonymous.jsx'
import PageStartAuthenticated from './pages/PageStartAuthenticated.jsx'
import PageProfileContainer from './pages/PageProfileContainer.jsx'
import PageLogout from './pages/PageLogout.js'
import PageCheckoutFinished from './pages/PageCheckoutFinished.jsx'
import PageRenewSubscription from './pages/PageRenewSubscription.jsx'
import PageSelectPlan from './pages/PageSelectPlan.jsx'
import PageError404 from './pages/PageError404.jsx'
import { withUserConfig, UserConfigKeys } from '../ClientSideUserConfig.jsx'
import TopMenu from './TopMenu.jsx'
import PageEditPreview from './pages/PageEditPreview.jsx'
import PagePreview from './pages/PagePreview.jsx'
import PageTokenLogin from './pages/PageTokenLogin.jsx'
import PageContinueWithEmail from './pages/PageContinueWithEmail.jsx'
import PageAnvandarvillkor from './pages/PageAnvandarvillkor.jsx'
import PagePersonuppgiftspolicy from './pages/PagePersonuppgiftspolicy.jsx'
import PageConfirmEmail from './pages/PageConfirmEmail.jsx'
import PageNewReport from './pages/PageNewReport.jsx'
import PageListReports from './pages/PageListReports.jsx'

const AppClientRoutes = ({ userConfig }) => {
  return (
    <ErrorBoundary>
      <Switch>
        {!userConfig.getConfigKey(UserConfigKeys.IS_LOGGED_IN) && (
          <Route exact path="/">
            <PageStartAnonymous />
          </Route>
        )}
        {userConfig.getConfigKey(UserConfigKeys.IS_LOGGED_IN) && (
          <Route exact path="/">
            <PageStartAuthenticated />
          </Route>
        )}
        <Route exact path="/confirm-email" component={PageConfirmEmail} />
        <Route exact path="/request-password-reset" component={PageRequestPasswordReset} />
        <Route exact path="/finish-password-reset" component={PageFinishPasswordReset} />
        <Route exact path="/start-email-login" component={PageContinueWithEmail} />
        <Route exact path="/token-login" component={PageTokenLogin} />
        <Route exact path="/logout" component={PageLogout} />
        <Route exact path="/anvandarvillkor" component={PageAnvandarvillkor} />
        <Route exact path="/personuppgiftspolicy" component={PagePersonuppgiftspolicy} />
        <Route
          exact
          path="/edit-report/:reportId"
          render={(routeProps) => <PageEditPreview reportId={routeProps.match.params.reportId} />}
        />
        <Route
          exact
          path="/edit-report/:reportId/:tabId"
          render={(routeProps) => (
            <PageEditPreview reportId={routeProps.match.params.reportId} tabId={routeProps.match.params.tabId} />
          )}
        />
        <Route
          exact
          path="/preview-report/:reportId"
          render={(routeProps) => <PagePreview reportId={routeProps.match.params.reportId} />}
        />
        <AuthenticatedRoute exact path="/new" render={() => <PageNewReport />} />
        <AuthenticatedRoute exact path="/list" render={() => <PageListReports />} />

        <AuthenticatedRoute
          exact
          path="/subscription"
          render={() => (
            <div>
              <TopMenu />
              <PageRenewSubscription />
            </div>
          )}
        />

        <Route>
          <TopMenu />
          <Switch>
            <Route exact path="/select-plan" component={PageSelectPlan} />
            <Route exact path="/signup" component={PageSignupForm} />
            <Route exact path="/checkout" component={PageCheckoutWrapper} />
            <Route exact path="/login" component={PageLoginForm} />
            <AuthenticatedRoute exact path="/checkout-finished" component={PageCheckoutFinished} />
            <AuthenticatedRoute exact path="/profile" component={PageProfileContainer} />
            <Route
              exact
              path="/crash-sync"
              render={() => {
                throw Error('sync client-side crash triggered')
              }}
            />
            <Route
              exact
              path="/crash-async"
              render={() => {
                setTimeout(() => {
                  throw Error('async client-side crash triggered')
                }, 100)
                return null
              }}
            />
            <Route
              exact
              path="/csp-violation"
              render={() => {
                fetch('https://mozilla.org')
              }}
            />
            <Route path="*">
              <PageError404 />
            </Route>
          </Switch>
        </Route>
      </Switch>
    </ErrorBoundary>
  )
}

AppClientRoutes.propTypes = {
  userConfig: PropTypes.object.isRequired,
}

export default withUserConfig(AppClientRoutes)
