import React from 'react'
import PropTypes from 'prop-types'
import BlockIcon from '@mui/icons-material/Block'
import DoneIcon from '@mui/icons-material/Done'

import API from '../../API.js'
import { useAbortSignal } from '../../AppFetch.js'
import RmComboBox from './RmComboBox.jsx'
import { AB_K2_LEAF_BAS_ACCOUNTS } from '../../../shared-universal/BasAccounts.js'
import { formatCell } from '../../../shared-universal/AnnualReportUtils.js'
import { getUrlParam } from '../../UrlUtils.js'

const Bokforingskontroller = ({ annualReport, onChange, isApproved }) => {
  const abortSignal = useAbortSignal()
  const firstCellStyle = { width: '100%' }
  const iconCellStyle = { paddingRight: '6px' }
  const NotOkIcon = () => <BlockIcon fontSize="medium" style={{ color: '#d10000', marginTop: '5px' }} />
  const OkIcon = () => <DoneIcon fontSize="medium" style={{ color: 'green', marginTop: '5px' }} />
  const sieReimportLintErrors = annualReport.lintErrors.filter((lintError) => lintError.needsSieReimport)
  return (
    <React.Fragment>
      <table>
        <tbody>
          {annualReport.financialYears[0].unmappedAccounts.length > 0 && (
            <tr>
              <td colSpan={4}>
                <h3>Okända konton som behöver kopplas till konto i BAS-planen</h3>
              </td>
            </tr>
          )}
          {annualReport.financialYears[0].unmappedAccounts.map((unmappedEntry) => {
            const unmappedCell = {
              value: Number(unmappedEntry.balance.toFixed(0)),
              rawAmount: unmappedEntry.balance,
            }
            return (
              <tr key={unmappedEntry.account}>
                <td style={iconCellStyle}>
                  {!annualReport.financialYears[0].accountMappings[unmappedEntry.account] && <NotOkIcon />}
                  {annualReport.financialYears[0].accountMappings[unmappedEntry.account] && <OkIcon />}
                </td>

                <td style={firstCellStyle}>
                  {unmappedEntry.account}: {unmappedEntry.accountName}
                </td>
                <td style={{ whiteSpace: 'nowrap', paddingRight: '20px', textAlign: 'right' }}>
                  {formatCell(unmappedCell, {
                    decimalCount: getUrlParam('decimals') ? Number(getUrlParam('decimals')) || 2 : 0,
                  })}{' '}
                  kr
                </td>
                <td>
                  <RmComboBox
                    options={AB_K2_LEAF_BAS_ACCOUNTS}
                    getOptionLabel={(accountEntry) => `${accountEntry.account}: ${accountEntry.accountName}`}
                    isOptionEqualToValue={(a, b) => a.xbrlName === b.xbrlName}
                    defaultValue={AB_K2_LEAF_BAS_ACCOUNTS.find(
                      (accountEntry) => accountEntry.account === unmappedEntry.targetAccount
                    )}
                    onChange={async (selectedOption) => {
                      await API.mapAccount(
                        annualReport.financialYears[0].financialYearId,
                        unmappedEntry.account,
                        selectedOption?.account || null,
                        abortSignal
                      )
                      onChange()
                    }}
                    style={{ width: '400px' }}
                    disabled={isApproved}
                  />
                </td>
              </tr>
            )
          })}
          {annualReport.financialYears[0].unmappedAccounts.length > 0 && (
            <tr>
              <td colSpan={4} style={{ paddingTop: '40px' }}></td>
            </tr>
          )}

          <tr>
            <td colSpan={4}>
              <h3>Bokföringskontroller</h3>
            </td>
          </tr>

          {sieReimportLintErrors.map((lintError) => {
            return (
              <tr key={lintError.lintRuleId}>
                <td style={iconCellStyle}>
                  <NotOkIcon />
                </td>
                <td colSpan={3} style={firstCellStyle}>
                  {lintError.title}
                </td>
              </tr>
            )
          })}
          {sieReimportLintErrors.length === 0 && (
            <tr>
              <td colSpan={4}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ paddingRight: '6px' }}>
                    <OkIcon />
                  </div>
                  <div>Våra bokföringskontroller hittade inga fel</div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </React.Fragment>
  )
}

Bokforingskontroller.propTypes = {
  annualReport: PropTypes.object,
  onChange: PropTypes.func,
  isApproved: PropTypes.bool.isRequired,
}

Bokforingskontroller.defaultProps = {
  onChange: () => {},
}

export default Bokforingskontroller
