import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip/index.js'

const RmTooltip = ({ title, visible, children, ...props }) => {
  if (!visible) {
    return <span>{children}</span>
  }
  return (
    <Tooltip title={title} {...props}>
      <span>{children}</span>
    </Tooltip>
  )
}

RmTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

RmTooltip.defaultProps = {
  visible: true,
}

export default RmTooltip
