import React from 'react'

import NyArsredovisning from '../remaker/NyArsredovisning.jsx'
import RemakerHeader from '../remaker/RemakerHeader.jsx'
import Container from '@mui/material/Container/index.js'

const PageNewReport = () => {
  return (
    <div className="automation-page-new-report">
      <RemakerHeader />
      <Container maxWidth="sm">
        <br />
        <h3>Ny årsredovisning</h3>
        <br />
        <NyArsredovisning />
      </Container>
    </div>
  )
}

PageNewReport.propTypes = {}

export default PageNewReport
