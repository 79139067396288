import React from 'react'
import PropTypes from 'prop-types'

import { appFetch, doFetchBlob } from '../AppFetch.js'
import { debounced, makeSlowdownIfRateLimitedFunc, uuid } from '../../shared-universal/Utils.js'
import StyledEditBox from './StyledEditBox.jsx'
import FillButton from './FillButton.jsx'

import styles from './ImageLibraryPanel.module.css'

class ImageLibraryPanel extends React.Component {
  static propTypes = {
    onFileWillBeSelected: PropTypes.func,
    onFileSelected: PropTypes.func.isRequired,
  }

  static defaultProps = {
    className: '',
  }

  initialState = {
    searchQuery: '',
    submittedSearchQuery: '',
    resultPagesLoaded: 0,
    searchResults: [],
    isFetchingMoreImages: false,
    hasLoadedAllImagesServerHad: false,
    hasFinishedRenderingCurrentSearchResult: false,
    uploadedThumbnailUrl: undefined,
    errorMsg: undefined,
  }
  state = this.initialState
  controller = new AbortController()

  DEFAULT_SEARCH_QUERY = 'food'

  componentDidMount() {
    if (this.searchQueryEditBox) {
      // If the image library tab is displayed initially, then focus the search input.
      // If the upload tab is initially displayed, no nothing.
      this.searchQueryEditBox.focus()
    }
    window.addEventListener('resize', this.handleWindowResize)
    this.submitSearch(this.DEFAULT_SEARCH_QUERY)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
    if (this.controller) {
      this.controller.abort()
    }
  }

  handleWindowResize = () => {
    this.loadMoreImagesIfNecessary()
  }

  searchImageLibrary = async (query, page = 1) => {
    const signal = this.controller.signal
    const { responseJson, status } = await appFetch(`/api/authp/search-image-library?q=${query}&page=${page}`, {
      signal,
    })
    this.setState({ errorMsg: status !== 200 ? 'ERROR: failed to search image library' : undefined })
    return responseJson
  }

  sendSearchRequest = makeSlowdownIfRateLimitedFunc(22, 11000, 500, async () => {
    let { results: moreSearchResults, hasMore } = await this.searchImageLibrary(
      this.state.submittedSearchQuery,
      this.state.resultPagesLoaded + 1
    )
    // Occasionally the search results will contain duplicate entries. For example, imagine that
    // userA searches for "foobar" and looks at search results page 1 and page 2, and assume
    // that img1.png appears on page 2. From now on, page 1 and 2 for the query "foobar", will
    // be cached for some time in our backend (typically 24 hours, this is often mandated by
    // third-party image libraries). Now assume further that img1.png moves from "page 2" to
    // "page 3", and a few hours later userB comes by and searches for "foobar" but he/she looks
    // at pages 1, 2 and 3. Our server will return img1.png in the cached result for page 2, but
    // it will also return img1.png in page 3. React wants a unique key for each element so we
    // generate an UUID for each one. This means img1.png will appear in both page 2 and 3.
    if (moreSearchResults) {
      moreSearchResults = moreSearchResults.map((result) => ({ ...result, id: uuid() }))
    }

    this.setState((prevState) => ({
      searchResults: [...prevState.searchResults, ...moreSearchResults],
      resultPagesLoaded: prevState.resultPagesLoaded + 1,
      isFetchingMoreImages: false,
      hasLoadedAllImagesServerHad: !hasMore,
    }))
  })

  loadMoreSearchResults = () => {
    if (this.state.submittedSearchQuery === '') {
      this.setState(this.initialState)
      return
    }
    this.setState(
      {
        isFetchingMoreImages: true,
      },
      () => {
        this.sendSearchRequest()
      }
    )
  }

  isLoadingMoreImagesNecessary = () => {
    // When the "Upload Image File" tab is active the "galleryViewport" is set
    // to null because the viewport component has been unmounted. This code can
    // be called when the viewport component is unmounted because A) the browser
    // window resize event handler calls it unconditionally, and B) the function
    // handleThumbnailLoaded() is debounced so once it actually runs the
    // viewport component might have been unmounted already. To ensure that the
    // E2E tests doesn't exhibit any flakiness due to this, we check that
    // galleryViewport is present before using it.
    if (this.galleryViewport) {
      if (
        this.galleryViewport.scrollTop + this.galleryViewport.clientHeight >=
        this.galleryViewport.scrollHeight - 500
      ) {
        if (!this.state.isFetchingMoreImages && !this.state.hasLoadedAllImagesServerHad) {
          return true
        }
      }
    }
    return false
  }

  loadMoreImagesIfNecessary = () => {
    if (this.isLoadingMoreImagesNecessary()) {
      this.loadMoreSearchResults()
    }
  }

  submitSearch = (searchQuery) => {
    // If the user enters a new search query while another search is still
    // loading, then we most likely have a /api/authp/search-image-library request
    // in flight so even if we are setting searchResults to [] below that
    // pending request for the old search query will return and append a set
    // of search results to the top. The end result is that the first few images
    // belongs to the old search query and the rest is coming from the new
    // search query, which is not nice. By aborting the in flight query here
    // we ensure that the final result will be only results from the new search
    // query. This can be replicated on slow connections, where it's quite
    // common that the default query is still running when the user submits his
    // first search query.
    this.controller.abort()
    this.controller = new AbortController()
    this.setState(
      {
        submittedSearchQuery: searchQuery,
        resultPagesLoaded: 0,
        searchResults: [],
      },
      () => {
        this.loadMoreSearchResults()
      }
    )
  }

  async handleSearchResultSelected(searchResult) {
    this.props.onFileWillBeSelected()
    const { responseBlob } = await doFetchBlob(searchResult.imageUrl, {
      signal: this.controller.signal,
      credentials: 'omit',
    })
    this.props.onFileSelected(responseBlob)
  }

  handleThumbnailLoaded = debounced(20, () => {
    // On slow connections we will have time to insert all images into the DOM
    // before the "load" events starts to trickle in one-by-one (in some cases
    // with several seconds between each image). Once the first "load" event
    // comes there will be multiple images in the DOM that hasn't finished
    // loading yet so the .every() will mostly return false. This prevents the
    // isLoadingMoreImagesNecessary() from running too often, and more
    // importantly prevents .setState() from being called once per image.
    //
    // However, on fast connections or when images are cached, the browser will
    // immediately fire the "load" event after inserting the element. For this
    // case, to avoid lots of unnecessary DOM operations and .setState() calls
    // we debounce the "load more" check.
    //
    // It would probably be a better solution to increment a "imagesLoaded"
    // count in state and use the DOM only for the scrollTop check?
    const imgNodes = Array.prototype.slice.call(document.querySelectorAll('img'))
    if (imgNodes.every((img) => img.complete)) {
      if (!this.isLoadingMoreImagesNecessary()) {
        this.setState({ hasFinishedRenderingCurrentSearchResult: true })
      } else {
        this.loadMoreSearchResults()
      }
    }
  })

  render() {
    return (
      <React.Fragment>
        <div className={styles.SearchQueryRow}>
          <StyledEditBox
            ref={(searchQueryEditBox) => {
              this.searchQueryEditBox = searchQueryEditBox
            }}
            value={this.state.searchQuery}
            className={`${styles.SearchQueryEditbox} automation-search-query`}
            onChange={(searchQuery) => this.setState({ searchQuery, hasFinishedRenderingCurrentSearchResult: false })}
            onEnterKey={() => this.submitSearch(this.state.searchQuery)}
            placeholder={'Search'}
          />
          <FillButton
            kind="primary"
            onClick={() => this.submitSearch(this.state.searchQuery)}
            className={`${styles.SearchButton} automation-search-button`}
          >
            Search
          </FillButton>
        </div>
        <div
          tabIndex="-1"
          ref={(el) => (this.galleryViewport = el)}
          className={styles.GalleryViewport}
          onScroll={(ev) => this.loadMoreImagesIfNecessary()}
        >
          <div className={styles.GalleryContent}>
            {this.state.errorMsg && (
              <div
                style={{
                  border: '2px solid #df2525',
                  borderRadius: '3px',
                  background: '#ffc9c9',
                  color: '#df2525',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                  minWidth: '600px',
                  textAlign: 'center',
                }}
              >
                {this.state.errorMsg}
              </div>
            )}
            {!this.state.errorMsg && (
              <React.Fragment>
                {this.state.hasFinishedRenderingCurrentSearchResult && (
                  <div
                    style={{ visibility: 'collapse' }}
                    className={'automation-search-results-finished-loading'}
                  ></div>
                )}
                {this.state.resultPagesLoaded !== 0 && this.state.searchResults.length === 0 && (
                  <div className={styles.NoSearchResultMessageText}>No images found.</div>
                )}
                {this.state.searchResults.length !== 0 &&
                  this.state.searchResults.map((result) => {
                    return (
                      <div key={result.id} className={styles.GalleryThumbnailFrame}>
                        <img
                          src={result.thumbnailUrl}
                          className={`${styles.GalleryThumbnail} automation-search-result-thumbnail`}
                          onClick={() => this.handleSearchResultSelected(result)}
                          draggable={false}
                          onLoad={this.handleThumbnailLoaded}
                        />
                      </div>
                    )
                  })}
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ImageLibraryPanel
