import React from 'react'

import PageContent from '../PageContent.jsx'
import AppBuildConfig from '../../../shared-universal/AppBuildConfig.js'

const PagePrivacyPolicy = () => {
  return (
    <PageContent style={{ padding: '25px' }}>
      <h2>Användarvillkor {AppBuildConfig.APP_NAME}</h2>
      Användarvillkor för {AppBuildConfig.APP_NAME} ({AppBuildConfig.HOMEPAGE_URL_WITHOUT_SCHEME}).
      <br />
      Uppdaterad: 2023-09-29
      <p>
        Välkommen till {AppBuildConfig.APP_NAME}! Tack för att du väljer att använda våra tjänster. Min Årsredovisning
        är en webbtjänst för att sammanställa och lämna in årsredovisning (K2) och deklaration (INK2) för aktiebolag
        (Tjänsten). Dessa användarvillkor (Villkoren) reglerar din användning av vår webbtjänst, inklusive allt
        innehåll, funktioner och tjänster som vi tillhandahåller. När ni använder Tjänsten {AppBuildConfig.APP_NAME}{' '}
        ingår ni ett bindande avtal med {AppBuildConfig.COMPANY_NAME} (org nr {AppBuildConfig.COMPANY_ORGNR}) som driver
        Tjänsten. Avtalet avser dessa villkor samt tillhörande bilagor.
      </p>
      <ol>
        <li>
          Genom att använda våra tjänster accepterar du dessa villkor. Om du inte godkänner dessa villkor, vänligen
          sluta använda vår webbtjänst.
        </li>
        <li>
          Ansvar för konto: Du är ansvarig för att skydda ditt användarkonto. Du är också ansvarig för all aktivitet som
          sker med ditt konto.
        </li>
        <li>
          Korrekt och relevant information: Du måste tillhandahålla korrekt och fullständig information vid registrering
          och vid användning av Tjänsten. Inmatad data, bilder och all användning av Tjänsten måste vara relevanta för
          Tjänstens ändåmål.
        </li>
        <li>
          Integritet och datainsamling: Vi samlar in och använder din personliga information enligt vår{' '}
          <a href="/personuppgiftspolicy">personuppgiftspolicy</a>. Genom att använda Tjänsten godkänner du vår
          personuppgiftspolicy.
        </li>
        <li>
          Ansvarsbegränsning
          <ol type="A">
            <li>
              Vi strävar efter att tillhandahålla en pålitlig tjänst, men vi kan inte garantera att Tjänsten kommer att
              vara felfri eller tillgänglig vid alla tider. Vi följer gällande lagar och regler för årsredovisning och
              deklaration. Notera att detta inte innebär att Min Årsredovisning ansvar för att slutprodukterna ger en
              korrekt bild av bolagets ställning och resultat eller att årsredovisning och deklaration kommer att klara
              Bolagsverkets / Skatteverkets granskningar. Inga krav kan ställas mot {AppBuildConfig.COMPANY_NAME} som
              ett resultat av att man använt Tjänsten för att skapa sin årsredovisning eller deklaration. Undertecknare
              av årsredovisning och deklaration är ansvariga för att innehållet är rättvisande.
            </li>
            <li>
              Vi ansvarar inte för förluster eller skador som uppstår till följd av din användning av vår tjänst eller
              förlitande på information som du hittar på vår tjänst.
            </li>
          </ol>
        </li>
        <li>
          Ändringar i villkoren: Vi förbehåller oss rätten att ändra dessa villkor när som helst. Vi kommer att meddela
          dig om väsentliga ändringar, och det är ditt ansvar att granska de uppdaterade villkoren.
        </li>
        <li>
          Uppsägning: Vi kan avsluta eller suspendera ditt konto och din tillgång till tjänsten om du bryter mot dessa
          villkor.
        </li>
        <li>
          Personuppgifter: Användare av vår Tjänst väljer vilka personuppgifter som matas in, Tjänsten hämtar även
          automatiskt personuppgifter om företagets företrädare.
        </li>
        <li>
          Tillämplig lag och tvistlösning. Eventuella tvister ska lösas genom förhandlingar. Om tvisten inte kan lösas
          genom förhandlingar ska den lösas i enlighet med svensk lag. Tack för att du använder{' '}
          {AppBuildConfig.APP_NAME} webbtjänst! Om du har frågor eller bekymmer, vänligen kontakta oss på{' '}
          {AppBuildConfig.SUPPORT_EMAIL}.
        </li>
      </ol>
      <h3>Bilagor</h3>
      <ul>
        <li>
          <a href="/personuppgiftspolicy">Personuppgiftspolicy</a>
        </li>
      </ul>
    </PageContent>
  )
}

PagePrivacyPolicy.propTypes = {}

export default PagePrivacyPolicy
