import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import RemakerHeader from './remaker/RemakerHeader.jsx'
import RemakerCenteredContent from './remaker/RemakerCenteredContent.jsx'

import { submitCrashReport } from '../LoggingUtils.js'

class ErrorBoundary extends React.Component {
  state = {
    error: undefined,
    errorInfo: undefined,
    errorPath: undefined,
  }

  componentDidCatch(error, errorInfo) {
    submitCrashReport(error, errorInfo)
    this.setState({ error, errorInfo, errorPath: this.props.location.pathname })
  }

  componentDidUpdate() {
    if (this.state.error && this.state.errorPath !== this.props.location.pathname) {
      this.setState({
        error: undefined,
        errorInfo: undefined,
        errorPath: undefined,
      })
    }
  }

  render() {
    if (this.state.error) {
      return (
        <React.Fragment>
          <RemakerHeader />
          <RemakerCenteredContent>
            <div style={{ color: 'darkred', fontWeight: 'bold' }}>An unexpected client-side error occurred.</div>
          </RemakerCenteredContent>
        </React.Fragment>
      )
    }
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object.isRequired,
}

export default withRouter(ErrorBoundary)
