import React from 'react'
import PropTypes from 'prop-types'

const RemakerCenteredContent = ({ children, style }) => {
  return <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto', ...style }}>{children}</div>
}

RemakerCenteredContent.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
}

RemakerCenteredContent.defaultProps = {
  style: {},
}

export default RemakerCenteredContent
