import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import SwipeDownIcon from '@mui/icons-material/SwipeDown'

import SpinnerBlock from '../generic/SpinnerBlock.jsx'
import { useAppFetch } from '../../AppFetch.js'
import { renderAnnualReportPreview } from '../remaker/AnnualReportPreviewer.js'
import { withUserConfig } from '../../ClientSideUserConfig.jsx'

const RenderInPreviewToolbar = ({ children }) =>
  ReactDOM.createPortal(children, document.getElementById('fullscreen-preview-toolbar'))

const PagePreview = ({ reportId, userConfig }) => {
  // Show or hide the report preview divs
  useEffect(() => {
    const previewOutput = document.querySelector('#fullscreen-preview-wrapper')
    previewOutput.style.display = 'block'
    const reactRoot = document.querySelector('#react-root')
    reactRoot.style.display = 'none'

    return () => {
      previewOutput.style.display = 'none'
      reactRoot.style.display = 'block'
      document.head.querySelectorAll('[data-rm-inserted-styles]').forEach((e) => e.parentNode?.removeChild(e))
    }
  }, [])

  const { loading, responseJson: annualReport } = useAppFetch(`/api/up/annual-report?reportId=${reportId}`)
  useEffect(() => {
    if (annualReport) {
      renderAnnualReportPreview(annualReport, document.querySelector('#fullscreen-preview-output'), userConfig)
    }
  }, [annualReport, userConfig])

  if (loading) {
    return <SpinnerBlock />
  }

  const ScrollTargetButton = ({ title, targetHref }) => (
    <a
      href={targetHref}
      style={{
        textDecoration: 'none',
      }}
    >
      <Button variant="outlined" fullWidth={true} style={{ justifyContent: 'left' }} startIcon={<SwipeDownIcon />}>
        {title}
      </Button>
    </a>
  )
  ScrollTargetButton.propTypes = {
    title: PropTypes.string.isRequired,
    targetHref: PropTypes.string.isRequired,
  }

  return (
    <RenderInPreviewToolbar>
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}>
        <a
          href={`/edit-report/${annualReport.reportId}`}
          style={{
            textDecoration: 'none',
            marginBottom: '15px',
          }}
        >
          <Button variant="outlined" fullWidth={true} style={{ justifyContent: 'left' }} startIcon={<CloseIcon />}>
            Stäng fullskärmsläge
          </Button>
        </a>
        <ScrollTargetButton title="Resultaträkning" targetHref="#title-element-2" />
        <ScrollTargetButton title="Balansräkning" targetHref="#title-element-3" />
        <ScrollTargetButton title="Noter" targetHref="#title-element-4" />
      </div>
    </RenderInPreviewToolbar>
  )
}

PagePreview.propTypes = {
  userConfig: PropTypes.object.isRequired,
  reportId: PropTypes.string,
}

export default withUserConfig(PagePreview)
