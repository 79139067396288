import React, { useState } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import ReplayIcon from '@mui/icons-material/Replay'
import CircularProgress from '@mui/material/CircularProgress'

import API from '../../API.js'
import { useAbortSignal } from '../../AppFetch.js'
import RmDatePicker from './RmDatePicker.jsx'
import { getForetradareRoleLabel } from '../../../shared-universal/SignatureRoles.js'
import EditablePersonnummer from './EditablePersonnummer.jsx'
import RmTooltip from './RmTooltip.jsx'
import { sleepMillis } from '../../../shared-universal/Utils.js'
import SignatureActionMenu from './SignatureActionMenu.jsx'
import Checkmark from '../styled/Checkmark.jsx'
import EditableEmail from '../remaker/EditableEmail.jsx'

const REFRESH_SIGNATURES_FORCED_WAIT_SECONDS = 3

const SignatureDatesEditor = ({ annualReport, onChange, disabled, onSignLinkSent }) => {
  const abortSignal = useAbortSignal()
  const SIGNATURE_TEXTCELL_STYLE = {
    paddingTop: '11px',
  }
  const [refreshing, setRefreshing] = useState(false)
  const [refreshButtonDisabled, setRefreshButtonDisabled] = useState(false)
  const hanteraVisible =
    annualReport.signMethod === 'bankid' && annualReport.hasOriginal && !annualReport.hasOriginalSigned
  return (
    <React.Fragment>
      <table style={{ minWidth: '700px', borderSpacing: '15px 8px' }}>
        <thead style={{ fontWeight: 'bold' }}>
          <tr>
            <td>Namn</td>
            <td>Roll</td>
            {annualReport.signMethod === 'bankid' && <td>Personnummer</td>}
            {annualReport.signMethod === 'bankid' && <td>E-mail</td>}
            <td>
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <div>Signaturdatum</div>
                <div>
                  {annualReport.signMethod === 'bankid' &&
                    annualReport.hasOriginal &&
                    !annualReport.hasOriginalSigned && (
                      <RmTooltip
                        title={
                          refreshButtonDisabled
                            ? `Signaturstatus kan hämtas igen om ${REFRESH_SIGNATURES_FORCED_WAIT_SECONDS} sekunder`
                            : 'Hämta senaste signaturstatus från Kivra'
                        }
                      >
                        <IconButton
                          onClick={async () => {
                            setRefreshing(true)
                            setRefreshButtonDisabled(true)
                            await API.refreshSignatureStatus(annualReport.reportId, abortSignal)
                            await sleepMillis(500)
                            setRefreshing(false)
                            onChange()
                            setTimeout(() => {
                              setRefreshButtonDisabled(false)
                            }, REFRESH_SIGNATURES_FORCED_WAIT_SECONDS * 1000)
                          }}
                          disabled={refreshButtonDisabled}
                        >
                          <ReplayIcon fontSize="small" />
                        </IconButton>
                      </RmTooltip>
                    )}
                </div>
              </div>
            </td>
            {hanteraVisible && <td>Hantera</td>}
          </tr>
        </thead>
        <tbody>
          {annualReport.signatures.map((signature, idx) => (
            <tr key={idx} style={{ verticalAlign: 'top' }}>
              <td style={SIGNATURE_TEXTCELL_STYLE}>
                {signature.firstName} {signature.lastName}
              </td>
              <td style={SIGNATURE_TEXTCELL_STYLE}>{getForetradareRoleLabel(signature.role) || ''}</td>
              {annualReport.signMethod === 'bankid' && (
                <React.Fragment>
                  <td>
                    <EditablePersonnummer
                      className={`automation-personnr-${idx}`}
                      value={signature.personNr}
                      onChange={(ev) => {
                        API.saveForetradareValue(
                          signature.foretradareId,
                          'personNr',
                          ev.target.value,
                          abortSignal,
                          onChange
                        )
                      }}
                      disabled={disabled}
                    />
                  </td>
                  <td>
                    <EditableEmail
                      className={`automation-email-${idx}`}
                      defaultValue={signature.email}
                      onChange={(newEmail) => {
                        API.saveForetradareValue(signature.foretradareId, 'email', newEmail, abortSignal, onChange)
                      }}
                      disabled={disabled}
                    />
                  </td>
                  <td style={SIGNATURE_TEXTCELL_STYLE}>
                    {refreshing && <CircularProgress size={20} />}
                    {!refreshing && (
                      <React.Fragment>
                        {signature.signatureDate ? (
                          <RmTooltip title={`${signature.signedBy} signerade med BankID ${signature.signedAtSv}`}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                              <div>{signature.signatureDate}</div>
                              <div>
                                <Checkmark style={{ marginTop: '5px' }} />
                              </div>
                            </div>
                          </RmTooltip>
                        ) : (
                          'Ej signerat'
                        )}
                      </React.Fragment>
                    )}
                  </td>
                </React.Fragment>
              )}
              {annualReport.signMethod !== 'bankid' && (
                <td>
                  <RmDatePicker
                    className={`automation-signature-date-${idx}`}
                    defaultValue={signature.signatureDate || null}
                    onChange={async (newDate) => {
                      API.saveForetradareValue(signature.foretradareId, 'signatureDate', newDate, abortSignal, onChange)
                    }}
                    disabled={disabled}
                  />
                </td>
              )}
              {hanteraVisible && (
                <td>
                  <SignatureActionMenu signature={signature} onSignLinkSent={onSignLinkSent} />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </React.Fragment>
  )
}

SignatureDatesEditor.propTypes = {
  annualReport: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSignLinkSent: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default SignatureDatesEditor
