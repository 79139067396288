import React from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'

import AppTitle from '../AppTitle.jsx'
import TextBlock from '../TextBlock.jsx'
import Field from '../Field.jsx'
import StyledEditBox from '../StyledEditBox.jsx'
import VerticalList from '../VerticalList.jsx'
import PaddingButton from '../PaddingButton.jsx'
import FlashMessage from '../FlashMessage.jsx'
import { appFetch } from '../../AppFetch.js'
import CardTopLevel from '../CardTopLevel.jsx'
import HoverUnderlineLink from '../generic/HoverUnderlineLink.jsx'
import IconBadge from '../generic/IconBadge.jsx'
import IconCheckmark from '../generic/IconCheckmark.jsx'
import SpinnerBlock from '../generic/SpinnerBlock.jsx'
import HttpErrorCodes from '../../../shared-universal/HttpErrorCodes.js'

const ComponentModes = {
  INITIAL_MODE: Symbol(),
  LOADING_USERNAME: Symbol(),
  PASSWORD_MANAGER_AUTOFILL_HACK_MODE: Symbol(),
  WAITING_FOR_PASSWORD_INPUT: Symbol(),
  REQUEST_INITATED: Symbol(),
  REQUEST_COMPLETED: Symbol(),
  SHOWING_ERROR_NO_RETRY: Symbol(),
  SHOWING_ERROR_ALLOW_RETRY: Symbol(),
}

class PageFinishPasswordReset extends React.Component {
  static propTypes = {
    history: PropTypes.object,
  }

  initialState = {
    mode: ComponentModes.INITIAL_MODE,
    username: '',
    password1: '',
    password2: '',
    flashMessage: '',
  }
  state = this.initialState
  controller = new AbortController()

  async componentDidMount() {
    this.setState({ mode: ComponentModes.LOADING_USERNAME })
    const { status, responseText, responseJson } = await appFetch('/api/query-password-reset', {
      method: 'POST',
      body: JSON.stringify({
        passwordResetToken: this.getUrlParam('token'),
      }),
      signal: this.controller.signal,
    })
    if (status === 200) {
      this.setState(
        {
          mode: ComponentModes.PASSWORD_MANAGER_AUTOFILL_HACK_MODE,
          username: responseJson.username,
        },
        () => {
          /* Hack to make sure LastPass does NOT autofill the existing password in the "new-password"
           input field. LastPass purposely ignores autocomplete=false and autocomplete="new-password".
           We also set data-lpignore="true" but that only removes the gray lastpass icon inside the
           input field, we need this hack to prevent the actual autofill from happening.
           Also note that we still want the input to have name="password" because otherwise the built-in
           "Update password?" prompt from the Chrome password manager fails to detect the new
           password properly.

           Certain browser require higher MAGIC_DELAY_MS, e.g. for Chrome it's enough with MAGIC_DELAY_MS = 0
           so the setState() runs on the next tick, but for Firefox 66 waiting 16ms means that the password
           manager autofill happens after the password1:'' setState but waiting 17ms means that the setState
           happens after the autofill and the "new password" textbox comes up blank like we want. Since there
           is no event/callback for "password manager autofill finished" (many browsers doesn't even fire a
           change event when they autofill), we need to pick a particular amount of time to wait and hope for
           the best.
         */
          const MAGIC_DELAY_MS = 40
          setTimeout(() => {
            this.setState(
              {
                password1: '',
              },
              () => {
                this.setState({ mode: ComponentModes.WAITING_FOR_PASSWORD_INPUT })
              }
            )
          }, MAGIC_DELAY_MS)
        }
      )
    } else {
      this.checkResponseErrors(responseText, responseJson)
    }

    if (this.elementPassword1) {
      this.elementPassword1.focus()
    }
  }

  componentWillUnmount() {
    if (this.controller) {
      this.controller.abort()
    }
  }

  getUrlParam = (paramName) => new Map(new URLSearchParams(location.search).entries()).get(paramName)

  setNewPassword = async (ev) => {
    ev.preventDefault()
    if (this.state.password1 !== this.state.password2) {
      this.setState({ flashMessage: 'The fields "Password" and "Password (again)" must be the same.' })
      return
    }

    this.setState({ mode: ComponentModes.REQUEST_INITATED })
    const { status, responseText, responseJson } = await appFetch('/api/finish-password-reset', {
      method: 'POST',
      body: JSON.stringify({
        passwordResetToken: this.getUrlParam('token'),
        newPassword: this.state.password1,
      }),
      signal: this.controller.signal,
    })
    if (status === 200) {
      this.setState({ mode: ComponentModes.REQUEST_COMPLETED })
    } else {
      this.checkResponseErrors(responseText, responseJson)
    }
  }

  checkResponseErrors = (responseText, responseJson) => {
    if (responseJson.errorCode === HttpErrorCodes.PASSWORD_TOKEN_EXPIRED) {
      this.setState({
        mode: ComponentModes.SHOWING_ERROR_NO_RETRY,
        flashMessage: (
          <React.Fragment>
            The password reset e-mail you used to open this page has expired, please request another password reset by
            clicking <HoverUnderlineLink to="/request-password-reset">here</HoverUnderlineLink>.
          </React.Fragment>
        ),
      })
    } else if (responseJson.errorCode === HttpErrorCodes.PASSWORD_TOKEN_REVOKED) {
      this.setState({
        mode: ComponentModes.SHOWING_ERROR_NO_RETRY,
        flashMessage: (
          <React.Fragment>
            The password reset e-mail you used to open this page is no longer valid. If you still wish to change your
            password, please request another password reset by clicking{' '}
            <HoverUnderlineLink to="/request-password-reset">here</HoverUnderlineLink>.
          </React.Fragment>
        ),
      })
    } else if (responseJson.errorCode === HttpErrorCodes.UI_ERROR_MESSAGE) {
      this.setState({
        mode: ComponentModes.SHOWING_ERROR_ALLOW_RETRY,
        flashMessage: responseJson.errorMessage,
      })
    } else {
      this.setState({
        mode: ComponentModes.SHOWING_ERROR_NO_RETRY,
        flashMessage: `ERROR: ${responseText}`,
      })
    }
  }

  render() {
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <AppTitle />
          <CardTopLevel>
            {this.state.mode === ComponentModes.REQUEST_COMPLETED && (
              <IconBadge icon={<IconCheckmark />} className="automation-password-reset-finished">
                The new password has been set, click <HoverUnderlineLink to="/login">here</HoverUnderlineLink> to login.
              </IconBadge>
            )}
            {this.state.mode === ComponentModes.LOADING_USERNAME && <SpinnerBlock />}
            {this.state.mode !== ComponentModes.REQUEST_COMPLETED &&
              this.state.mode !== ComponentModes.LOADING_USERNAME && (
                <form>
                  <input type="hidden" name="passwordResetToken" value={this.getUrlParam('token')} />
                  {this.state.mode !== ComponentModes.SHOWING_ERROR_NO_RETRY && (
                    <TextBlock>To reset your password, please enter the new password twice.</TextBlock>
                  )}
                  <FlashMessage
                    message={this.state.flashMessage}
                    className={'automation-flash-message'}
                    style={{ marginBottom: '20px' }}
                  />
                  {this.state.mode !== ComponentModes.SHOWING_ERROR_NO_RETRY && (
                    <React.Fragment>
                      <Field label="Username">
                        <StyledEditBox
                          type="text"
                          ref={(el) => (this.elementUsername = el)}
                          value={this.state.username}
                          className={'automation-username'}
                          disabled={true}
                          autoComplete="username"
                          name="username"
                        />
                      </Field>
                      <div
                        className={`${
                          this.state.mode === ComponentModes.WAITING_FOR_PASSWORD_INPUT
                            ? 'automation-finish-password-reset-page-loaded'
                            : ''
                        }`}
                        style={{
                          opacity: this.state.mode === ComponentModes.PASSWORD_MANAGER_AUTOFILL_HACK_MODE ? '0' : '1',
                        }}
                      >
                        <Field label="Password">
                          <StyledEditBox
                            type="password"
                            ref={(element) => (this.elementPassword1 = element)}
                            value={this.state.password1}
                            onChange={(password) => this.setState({ password1: password })}
                            onEnterKey={() => this.elementPassword2.focus()}
                            className={'automation-password'}
                            autoComplete="new-password"
                            name="password"
                            data-lpignore="true" /* prevents gray lastpass icon, doesn't prevent lastpass autofill */
                          />
                        </Field>
                        <Field label="Password (again)">
                          <StyledEditBox
                            type="password"
                            ref={(element) => (this.elementPassword2 = element)}
                            value={this.state.password2}
                            onChange={(password) => this.setState({ password2: password })}
                            onEnterKey={this.setNewPassword}
                            className={'automation-password-confirm'}
                            autoComplete="new-password"
                            name="confirmpassword"
                            data-lpignore="true" /* prevents gray lastpass icon, doesn't prevent lastpass autofill */
                          />
                        </Field>
                      </div>
                    </React.Fragment>
                  )}
                  <VerticalList spaceBetweenPx={10} align="flex-end" style={{ marginTop: '20px' }}>
                    {(this.state.mode === ComponentModes.WAITING_FOR_PASSWORD_INPUT ||
                      this.state.mode === ComponentModes.SHOWING_ERROR_ALLOW_RETRY ||
                      this.state.mode === ComponentModes.REQUEST_INITATED) && (
                      <PaddingButton
                        kind="primary"
                        className={'automation-set-new-password'}
                        onClick={this.setNewPassword}
                        spinner={this.state.mode === ComponentModes.REQUEST_INITATED}
                        disabled={this.state.mode === ComponentModes.REQUEST_INITATED}
                        style={{ width: '200px' }}
                      >
                        Set New Password
                      </PaddingButton>
                    )}
                    <PaddingButton
                      kind={this.state.mode !== ComponentModes.SHOWING_ERROR_NO_RETRY ? 'cancel' : 'primary'}
                      className={'automation-cancel'}
                      onClick={() => this.props.history.push('/')}
                      style={{ width: '200px' }}
                    >
                      {this.state.mode !== ComponentModes.SHOWING_ERROR_NO_RETRY ? 'Cancel' : 'Close'}
                    </PaddingButton>
                  </VerticalList>
                </form>
              )}
          </CardTopLevel>
        </div>
      </div>
    )
  }
}

export default withRouter(PageFinishPasswordReset)
