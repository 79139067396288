import React from 'react'
import PropTypes from 'prop-types'

import { useAbortSignal } from '../../AppFetch.js'
import { renderEditableReadonlyRow } from './EditableTableUtils.jsx'
import EditableAmount from './EditableAmount.jsx'
import EditableXbrlValue from './EditableXbrlValue.jsx'
import { makeFinancialYearTitleHtml } from '../../../shared-universal/AnnualReportRender.js'
import { DATA_FIELD_RIGHT_MARGIN } from './EditableTableUtils.jsx'
import { loadBalanceNoteSpec } from '../../../shared-universal/BalanceNoteSpecs.js'
import RmSwitch from './RmSwitch.jsx'
import API from '../../API.js'
import EditableComment from './EditableComment.jsx'

const NotBalancePostEditor = ({ balanceXbrl, annualReport, onChange, disabled }) => {
  const abortSignal = useAbortSignal()
  const firstCellStyle = {
    width: '100%',
  }
  const balanceNoteSpec = loadBalanceNoteSpec(balanceXbrl, annualReport.clientBalanceSheetTemplate)
  return (
    <React.Fragment>
      <table>
        <thead>
          <tr>
            <th style={firstCellStyle}></th>
            <td
              key={annualReport.financialYears[0].financialYearId}
              dangerouslySetInnerHTML={{
                __html: makeFinancialYearTitleHtml(annualReport.financialYears[0], 'instant'),
              }}
              style={{
                textAlign: 'right',
                paddingRight: DATA_FIELD_RIGHT_MARGIN,
                fontWeight: 'bold',
                verticalAlign: 'top',
              }}
            ></td>
          </tr>
        </thead>
        <tbody>
          {balanceNoteSpec.parts.map((part) => {
            const selectedChanges =
              annualReport.notBalansAvancerad[part.xbrlName] ?? false ? part.allChanges : part.changes
            return (
              <React.Fragment key={part.partId}>
                <tr>
                  <td style={firstCellStyle}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div>Ingående {part.title.toLowerCase()}</div>
                      <div>
                        <RmSwitch
                          label="Visa alla"
                          value={annualReport.notBalansAvancerad[part.xbrlName] ?? false}
                          onChange={(newValue) => {
                            API.saveAnnualReportValue(
                              annualReport.reportId,
                              'notBalansAvancerad',
                              { [part.xbrlName]: newValue },
                              abortSignal,
                              onChange,
                              {
                                immediate: true,
                              }
                            )
                          }}
                          disabled={disabled}
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    {annualReport.financialYears.length > 1 && (
                      <EditableXbrlValue
                        xbrlName={part.xbrlName}
                        financialYear={annualReport.financialYears[1]}
                        abortSignal={abortSignal}
                        onSaved={onChange}
                        disabled={disabled}
                        forceSign={part.xbrlWeight == -1 ? '-' : undefined}
                      />
                    )}
                    {annualReport.financialYears.length == 1 && (
                      <EditableAmount defaultValue="0" unit="kr" disabled={true} />
                    )}
                  </td>
                </tr>
                {selectedChanges.map((change) => {
                  if (selectedChanges.length === 1) {
                    return (
                      <tr key={change.changeId}>
                        <td style={firstCellStyle}>{change.title}</td>
                        <td>
                          <EditableAmount
                            className={`automation-${change.xbrlName}`}
                            value={annualReport.financialYears[0].xbrlValues[change.xbrlName]?.value}
                            unit="kr"
                            disabled={true}
                            forceSign={
                              change.showAdornment || part.xbrlWeight === -1
                                ? part.xbrlWeight * change.xbrlWeight === 1
                                  ? change.showAdornment
                                    ? '+'
                                    : undefined
                                  : '-'
                                : undefined
                            }
                            forceAllowNegative={true}
                          />
                        </td>
                      </tr>
                    )
                  } else {
                    return (
                      <tr key={change.changeId}>
                        <td style={firstCellStyle}>{change.title}</td>
                        <td>
                          <EditableXbrlValue
                            xbrlName={change.xbrlName}
                            financialYear={annualReport.financialYears[0]}
                            abortSignal={abortSignal}
                            onSaved={onChange}
                            disabled={disabled}
                            forceSign={
                              change.showAdornment || part.xbrlWeight === -1
                                ? part.xbrlWeight * change.xbrlWeight === 1
                                  ? change.showAdornment
                                    ? '+'
                                    : undefined
                                  : '-'
                                : undefined
                            }
                            forceAllowNegative={part.xbrlWeight === -1 && !change.showAdornment}
                          />
                        </td>
                      </tr>
                    )
                  }
                })}
                {selectedChanges.length > 1 &&
                  renderEditableReadonlyRow(
                    annualReport.financialYears[0].xbrlValues,
                    `rmBeraknad${part.xbrlName}`,
                    'Beräknad summa'
                  )}
                <tr>
                  <td style={firstCellStyle}>Utgående {part.title.toLowerCase()}</td>
                  <td>
                    <EditableXbrlValue
                      xbrlName={part.xbrlName}
                      financialYear={annualReport.financialYears[0]}
                      abortSignal={abortSignal}
                      onSaved={onChange}
                      disabled={disabled}
                      forceSign={part.xbrlWeight === -1 ? '-' : undefined}
                    />
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
              </React.Fragment>
            )
          })}
          {renderEditableReadonlyRow(
            annualReport.financialYears[0].xbrlValues,
            `rmBeraknad${balanceXbrl}`,
            'Beräknat värde enligt summa i not'
          )}
          {renderEditableReadonlyRow(
            annualReport.financialYears[0].xbrlValues,
            balanceXbrl,
            'Redovisat värde i balansräkningen'
          )}
        </tbody>
      </table>
      <br />
      <EditableComment
        xbrlName={`Not${balanceXbrl}Kommentar`}
        expandedTitle="Kommentar till not"
        annualReport={annualReport}
        onChange={onChange}
        disabled={disabled}
      />
    </React.Fragment>
  )
}

NotBalancePostEditor.propTypes = {
  balanceXbrl: PropTypes.string.isRequired,
  annualReport: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default NotBalancePostEditor
