import React from 'react'

import PageContent from '../PageContent.jsx'
import AppBuildConfig from '../../../shared-universal/AppBuildConfig.js'

const PageError404 = () => (
  <PageContent>
    <center>
      <h2>Error: Page not found</h2>
      <p>
        Could not find any page matching the specified address.<br></br>
        Got questions? Please <a href={AppBuildConfig.SUPPORT_URL}>contact us</a>.
      </p>
    </center>
  </PageContent>
)

PageError404.propTypes = {}

export default PageError404
